import { FC, useEffect } from "react";
import { getAllActiveItems } from "../../../../api/items";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./draftProductsFormItems.module.scss";
import DroppableItems from "../DroppableItems";
import { convertToProductItems } from "../../utils/convertToProductItems";
import { ProductItem } from "../../types";

interface DraftProductsFormItemsOwnProps {
  setDraftProductItems: (items: ProductItem[]) => void;
  productItems: ProductItem[] | [];
}

const DraftProductsFormItems: FC<DraftProductsFormItemsOwnProps> = ({
  setDraftProductItems,
  productItems,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeItems, isLoading } = useAppSelector(
    (state) => state.activeItems
  );
  const { userCompanyId } = useAppSelector((state) => state.companies);
  const filter = useAppSelector((state) => state.productFormFilter);

  useEffect(() => {
    if (userCompanyId) {
      dispatch(
        getAllActiveItems(userCompanyId as number, {
          ...filter,
          paginate: 100,
          status: "",
        })
      );
    }
  }, [dispatch, filter, userCompanyId]);

  const tableColumns = [
    {
      Header: "GTIN",
      accessor: "gtin",
    },
    {
      Header: "ITEM DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "DESCRIPTOR",
      accessor: "descriptor",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
  ];

  return (
    <>
      <div className={styles.productItemsWrapper}>
        <DroppableItems
          tableColumns={tableColumns}
          dataSource={activeItems ? convertToProductItems(activeItems) : []}
          isLoading={isLoading}
          setDraftProductItems={(items) => setDraftProductItems(items)}
          productItems={productItems}
        />
      </div>
    </>
  );
};

export default DraftProductsFormItems;
