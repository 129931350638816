import errPopup from '../../../common/icons/error-image.png';
import styles from './form.module.scss';

interface PopupProps {
    status: string;
    className: string;
}

export const Popup = ({ status, className }: PopupProps) => {
    return (
        <div
            className={`${styles.popup} ${
                status === 'success' ? styles.success : styles.error
            } ${className}`}
        >
            {status === 'success' && (
                <div className={styles.popup__wrap}>
                    <div className={styles.popup__mark_success}>✔</div>
                    <div className={styles.popup__text}>
                        Form has been successfully saved
                    </div>
                </div>
            )}
            {status === 'error' && (
                <div className={styles.popup__wrap}>
                    <div className={styles.popup__mark_error}>
                        <img src={errPopup} />
                    </div>
                    <div className={styles.popup__text}>Form is not saved</div>
                </div>
            )}
            {status === 'gtinError' && (
                <div className={styles.popup__wrap}>
                    <div className={styles.popup__mark_error}>
                        <img src={errPopup} />
                    </div>
                    <div className={styles.popup__text}>
                        The gtin has already been taken
                    </div>
                </div>
            )}
        </div>
    );
};
