import { Button, Modal } from 'antd';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useActions } from '../../../../hooks/useActions';
import { ReactComponent as ItemsLogo } from '../../../common/icons/items.svg';
import styles from './draftItemsForm.module.scss';
import './draftItemsForm.global.scss';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { getItemFormFieldsByCompanyId } from '../../../../api/itemForm';
import { convertToItemFormFieldsWithSize } from '../../../itemForm/utils/convertToItemFormFieldsWithSize';
import { ItemFormTabIds } from '../../../itemForm/constants';
import { Form } from './Form';
import { Popup } from './popup';

const DraftItemsFormModal = () => {
    const dispatch = useAppDispatch();
    const [formIsChange, setFormIsChange] = useState(false);
    const { setIsOpenDraftItemsFormModal, setIsOpenDraftItemAreYouSureModal } =
        useActions();
    const { draftItem } = useAppSelector((state) => state.draftItems);
    const { activeItem } = useAppSelector((state) => state.activeItems);

    const { isOpenDraftItemsFormModal, draftItemsFormModalActiveTab } =
        useAppSelector((state) => state.modals);
    const [isMainFieldsFull, setIsMainFieldsFull] = useState<any>(false);

    const { userCompanyId } = useAppSelector((state) => state.companies);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);
    const [isLastTab, setIsLastTab] = useState<boolean>(false);
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
    );
    const { setModalActiveTab } = useActions();
    const [activeTabs, setActiveTabs] = useState<any>([]);

    useEffect(() => {
        if (!itemFormFields) return;
        let array: any = [];
        itemFormFields.forEach((elem: any) => {
            if (
                elem.name === 'main' ||
                elem.name === 'measurements' ||
                elem.name === 'digital assets' ||
                elem?.items?.length
            ) {
                array.push(elem);
            }
        });
        setActiveTabs(array);
    }, [itemFormFields]);

    useEffect(() => {
        if (itemFormFields && itemFormFields.length > 0) {
            dispatch(setModalActiveTab(itemFormFields[0].name));
        }
    }, [itemFormFields]);

    let itemFormFieldsMain =
        itemFormFields &&
        convertToItemFormFieldsWithSize(
            itemFormFields?.filter(
                (field) => field.item_tab_id === ItemFormTabIds.MAIN
            )
        );

    let blocksCount = itemFormFieldsMain
        ? Math.ceil((itemFormFieldsMain.length - 10) / 15) + 1
        : 0;

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getItemFormFieldsByCompanyId(String(userCompanyId)));
        }
    }, [dispatch, userCompanyId]);

    const getForm = () => {
        const element = itemFormFields?.find(
            (elem) => elem.name === selectedTab
        );
        
        return (
            <Form
                isDraft={activeItem ? false : true}
                element={element}
                dataForm={activeItem ? activeItem : draftItem}
                setIsMainFieldsFull={setIsMainFieldsFull}
                isLastTab={isLastTab}
            />
        );
    };

    useEffect(() => {
        if (!activeTabs) return;
        if (activeTabs[activeTabs.length - 1]?.name === selectedTab) {
            setIsLastTab(true);
        } else {
            setIsLastTab(false);
        }
    }, [activeTabs, selectedTab]);

    return (
        <div className={styles.itemFormFieldFormModal} style={{ top: '30px' }}>
            {itemFormFields && (
                <Modal
                    centered={true}
                    visible={isOpenDraftItemsFormModal}
                    onCancel={() => {
                        !formIsChange &&
                            dispatch(setModalActiveTab(itemFormFields[0].name));
                        setIsOpenDraftItemsFormModal(false);
                        if (formIsChange) {
                            setIsOpenDraftItemsFormModal(true);
                            setIsOpenDraftItemAreYouSureModal(true);
                        }
                    }}
                    closable={true}
                    title={
                        <div className={styles.tableTitle}>
                            <ItemsLogo className={styles.tableTitleLogo} />
                            <div className={styles.tableTitleText}>
                                Trade Item
                            </div>
                        </div>
                    }
                    footer={null}
                    destroyOnClose={true}
                    className={styles.itemFormModalWithDinamicField}
                >
                    <div className={styles.modalWrapper}>
                        <div className={styles.buttonsWrapper}>
                            {itemFormFields.map((elem: any) => {
                                if (
                                    elem.name === 'main' ||
                                    elem.name === 'measurements' ||
                                    elem.name === 'digital assets' ||
                                    elem?.items?.length
                                ) {
                                    return (
                                        <Button
                                            className={
                                                selectedTab === elem.name
                                                    ? styles.buttonChecked
                                                    : isMainFieldsFull
                                                    ? styles.button
                                                    : styles.disableBtn
                                            }
                                            onClick={() => {
                                                if (isMainFieldsFull) {
                                                    dispatch(
                                                        setModalActiveTab(
                                                            elem.name
                                                        )
                                                    );
                                                }
                                            }}
                                        >
                                            {elem.name.toUpperCase()}
                                        </Button>
                                    );
                                }
                            })}
                            <Button
                                className={
                                    selectedTab === 'Allergens'
                                        ? styles.buttonChecked
                                        : isMainFieldsFull
                                        ? styles.button
                                        : styles.disableBtn
                                }
                                onClick={() => {
                                    if (isMainFieldsFull) {
                                        dispatch(
                                            setModalActiveTab(
                                                'Allergens'
                                            )
                                        );
                                    }
                                }}
                            >
                                ALLERGENS
                            </Button>
                            <Button
                                className={
                                    selectedTab === 'Nutritional'
                                        ? styles.buttonChecked
                                        : isMainFieldsFull
                                        ? styles.button
                                        : styles.disableBtn
                                }
                                onClick={() => {
                                    if (isMainFieldsFull) {
                                        dispatch(
                                            setModalActiveTab(
                                                'Nutritional'
                                            )
                                        );
                                    }
                                }}
                            >
                                NUTRITIONAL
                            </Button>
                        </div>

                        {getForm()}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default DraftItemsFormModal;
