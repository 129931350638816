import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import {
  convertToSortedByData,
  convertToSelectData,
} from "../../helpers/convertToSortedByData";
import { FilterType } from "../../modules/common/constants/filter";
import { SelectData, SelectTagData } from "../../modules/common/types";
import { additionalFilterControlSlice } from "../../redux/reducers/additionalFilterControl";
import { filterControlSlice } from "../../redux/reducers/filterControl";
import { optionListsSlice } from "../../redux/reducers/optionLists";
import { productFormFilterControlSlice } from "../../redux/reducers/productFormFilterControl";

const additionalFilterActions = async (dispatch: Dispatch, url: string) => {
  try {
    dispatch(
      additionalFilterControlSlice.actions.getAdditionalFilterControlSortedByDataPending()
    );

    const { data } = await authAxios(dispatch).get(url);

    const convertData: SelectTagData[] = convertToSortedByData(data);

    dispatch(
      additionalFilterControlSlice.actions.getAdditionalFilterControlSortedByDataFulfilled(
        {
          sortedByData: convertData,
        }
      )
    );
  } catch (error: any) {
    dispatch(
      additionalFilterControlSlice.actions.getAdditionalFilterControlSortedByDataRejected(
        {
          error: error.data.message,
        }
      )
    );
  }
};

const filterActions = async (dispatch: Dispatch, url: string) => {
  try {
    dispatch(filterControlSlice.actions.getFilterControlSortedByDataPending());

    const { data } = await authAxios(dispatch).get(url);

    const convertData: SelectTagData[] = convertToSortedByData(data);

    dispatch(
      filterControlSlice.actions.getFilterControlSortedByDataFulfilled({
        sortedByData: convertData,
      })
    );
  } catch (error: any) {
    dispatch(
      filterControlSlice.actions.getFilterControlSortedByDataRejected({
        error: error.data.message,
      })
    );
  }
};

const productFormFilterActions = async (dispatch: Dispatch, url: string) => {
  try {
    dispatch(
      productFormFilterControlSlice.actions.getProductFormFilterControlSortedByDataPending()
    );

    const { data } = await authAxios(dispatch).get(url);

    const convertData: SelectTagData[] = convertToSortedByData(data);

    dispatch(
      productFormFilterControlSlice.actions.getProductFormFilterControlSortedByDataFulfilled(
        {
          sortedByData: convertData,
        }
      )
    );
  } catch (error: any) {
    dispatch(
      productFormFilterControlSlice.actions.getProductFormFilterControlSortedByDataRejected(
        {
          error: error.data.message,
        }
      )
    );
  }
};

export const getCompaniesNames = (type?: FilterType) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (type === FilterType.ADDITIONAL) {
      additionalFilterActions(dispatch, `/api/filters/companies-name`);
    } else {
      filterActions(dispatch, `/api/filters/companies-name`);
    }
  };
};

export const getGlnNames = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    filterActions(dispatch, `/api/filters/companies-gln`);
  };
};

export const getUserNames = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    filterActions(dispatch, `/api/filters/user-names`);
  };
};

export const getDescriptors = (type?: FilterType) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (type === FilterType.ADDITIONAL) {
      additionalFilterActions(dispatch, `/api/filters/trade-item-descriptors`);
    } else {
      filterActions(dispatch, `/api/filters/trade-item-descriptors`);
    }
  };
};

export const getItemsDescriptorsForProductFilter = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    productFormFilterActions(dispatch, `/api/filters/trade-item-descriptors`);
  };
};

export const getItemsGtins = (type?: FilterType, companyId?: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (type === FilterType.ADDITIONAL) {
      if (companyId) {
        additionalFilterActions(
          dispatch,
          `/api/filters/items-gtin/${companyId}`
        );
      } else {
        additionalFilterActions(dispatch, `/api/filters/items-gtin`);
      }
    } else {
      if (companyId) {
        filterActions(dispatch, `/api/filters/draft-items-gtin/${companyId}`);
      } else {
        filterActions(dispatch, `/api/filters/draft-items-gtin`);
      }
    }
  };
};

export const getItemsGtinsForProductFilter = (companyId?: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    productFormFilterActions(dispatch, `/api/filters/items-gtin/${companyId}`);
  };
};

export const getProductsGtins = (type?: FilterType, companyId?: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (type === FilterType.ADDITIONAL) {
      if (companyId) {
        additionalFilterActions(
          dispatch,
          `/api/filters/products-gtin/${companyId}`
        );
      } else {
        additionalFilterActions(dispatch, `/api/filters/products-gtin`);
      }
    } else {
      if (companyId) {
        filterActions(
          dispatch,
          `/api/filters/draft-products-gtin/${companyId}`
        );
      } else {
        filterActions(dispatch, `/api/filters/draft-products-gtin`);
      }
    }
  };
};

export const getProductsDataRecipientGtins = (type?: FilterType, companyId?: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (type === FilterType.ADDITIONAL) {
        if (companyId) {
          additionalFilterActions(
            dispatch,
            `/api/filters/retailer-products-gtin/${companyId}`
          );
        } else {
          additionalFilterActions(dispatch, `/api/filters/products-gtin`);
        }
    } else {
      if (companyId) {
          filterActions(
            dispatch,
            `/api/filters/draft-products-gtin/${companyId}`
          );
        } else {
          filterActions(dispatch, `/api/filters/draft-products-gtin`);
      }
    }
  };
};

export const getItemsDataRecipientGtins = (type?: FilterType, companyId?: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
      if (type === FilterType.ADDITIONAL) {
          if (companyId) {
            additionalFilterActions(
              dispatch,
              `/api/filters/retailer-items-gtin/${companyId}`
            );
          } else {
            additionalFilterActions(dispatch, `/api/filters/items-gtin`);
          }
      } else {
        if (companyId) {
            filterActions(
              dispatch,
              `/api/filters/draft-items-gtin/${companyId}`
            );
          } else {
            filterActions(dispatch, `/api/filters/draft-items-gtin`);
        }
      }
    };
  };

export const getProductsDataRecipientGlns = (type?: FilterType, companyId?: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
      if (type === FilterType.ADDITIONAL) {
          if (companyId) {
            additionalFilterActions(
              dispatch,
              `/api/filters/retailer-products-glns/${companyId}`
            );
          }
      } 
    };
  };

  export const getProductsDataRecipientGpcs = (type?: FilterType, companyId?: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
      if (type === FilterType.ADDITIONAL) {
          if (companyId) {
            additionalFilterActions(
              dispatch,
              `/api/filters/retailer-products-gpcs/${companyId}`
            );
          }
      } 
    };
  };

  export const getProductsDataRecipientBrands = (type?: FilterType, companyId?: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
      if (type === FilterType.ADDITIONAL) {
          if (companyId) {
            additionalFilterActions(
              dispatch,
              `/api/filters/retailer-products-brands/${companyId}`
            );
          }
      } 
    };
  };

export const getDescriptorsList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(
        `/api/filters/trade-item-descriptors`
      );

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getDescriptorsListFulfilled({
          descriptorsList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getCountries = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/countries`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getCountriesListFulfilled({
          countriesList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getFileTypes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/type-items`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getFileTypesListFulfilled({
          fileTypesList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getFileFormats = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(
        `/api/filters/file-formats`
      );

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getFileFormatsListFulfilled({
          fileFormatsList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getSizes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/sizes`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getSizesListFulfilled({
          sizesList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getCompaniesList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/companies-name`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getCompaniesListFulfilled({
          companiesList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getItemTabsList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/item-tabs`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getItemTabsListFulfilled({
          itemTabsList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getFieldTypesList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/field-types`);

      const convertSelectData: SelectData[] = convertToSelectData(data);

      dispatch(
        optionListsSlice.actions.getFieldTypesListFulfilled({
          fieldTypesList: convertSelectData,
        })
      );
    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getLanguages = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/languages`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getLanguagesListFulfilled({
          languagesList: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getAllergenCodes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/allergen-codes`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getAllergenCodesList({
          allergenCodesList: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getLevelOfContainmentCodes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/level-of-containment-codes`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getLevelOfContainmentCodesList({
          levelOfContainmentList: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getNutrientCodes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/nutrient-codes`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getNutrientCodesList({
          nutrientCodes: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getMeasurementPrecisions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/measurement-precisions`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getMeasurementPrecisionsList({
          measurementPrecisions: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getUnitMeasurements = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/unit-measures`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getUnitMeasurementsList({
          unitMeasurements: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};

export const getDerivationCodes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(optionListsSlice.actions.getListPending());

      const { data } = await authAxios(dispatch).get(`/api/filters/derivation-codes`);

      const convertSelectData: SelectData[] = convertToSelectData(data);
      
      dispatch(
        optionListsSlice.actions.getDerivationCodesList({
          derivationCodes: convertSelectData,
        })
      );

    } catch (error: any) {
      dispatch(
        optionListsSlice.actions.getListRejected({
          error: error.data.message,
        })
      );
    }
  };
};