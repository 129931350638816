import { Select } from "antd";
import { FC } from "react";
import { convertToSelectData } from "../../../../helpers";
import { useActions } from "../../../../hooks/useActions";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { SelectData } from "../../../common/types";
import "./userCompaniesSelect.global.scss";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "../../../../routes";

const { Option } = Select;

const UserCompaniesSelect: FC = (): JSX.Element => {
  const { setUserCompanyId, setUserCompanyType } = useActions();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { userCompanyId } = useAppSelector((state) => state.companies);
  const handleChange = (value:any) => {
    setUserCompanyId(Number(value));
    let selectedCompany = user?.companies.filter((company) => company.id == value)
    setUserCompanyType(selectedCompany.length > 0?selectedCompany[0].type:null)
    navigate(RoutesNames.ITEMS);
  }

  return (
    <div className="userCompanieSelect">
      <Select
        size="large"
        value={String(userCompanyId)}
        onChange={(value) => {
          handleChange(value)
          //setUserCompanyId(Number(value));
        }}
      >
        {convertToSelectData(user?.companies as any).map(
          (sortOption: SelectData) => (
            <Option key={sortOption.name} value={`${sortOption.value}`}>
              {sortOption.name}
            </Option>
          )
        )}
      </Select>
    </div>
  );
};

export default UserCompaniesSelect;
