import { ProductItem } from "../types";

const DescriptorsPriority: any = {
  TL: 0,
  PL: 1,
  MX: 2,
  CS: 3,
  DS: 4,
  PK: 5,
  EA: 6,
};

export const setDescriptorsPriority = (items: ProductItem[]) => {
  return items.sort((prev: ProductItem, current: ProductItem) => {
    return (
      DescriptorsPriority[prev.descriptor] -
      DescriptorsPriority[current.descriptor]
    );
  });
};
