import { createSlice } from '@reduxjs/toolkit';
import {
    DeleteItemFormFieldsAction,
    GetItemFormFieldFulfilledAction,
    GetItemFormFieldsFulfilledAction,
    GetItemFormStaticFieldsFulfilledAction,
    ItemFormRejectedAction,
    ItemFormState,
    FieldsToAddToCompanyAction,
} from '../../../modules/itemForm/types';

const initialState: ItemFormState = {
    itemFormFields: null,
    fieldsToAddToCompany: [],
    itemFormStaticFields: null,
    itemFormField: null,
    itemFormFieldsForDeletion: [],
    totalItems: 0,
    isLoading: false,
    selectedTabForDeletion: null,
    error: null,
    company_id: null,
    popup: '',
    itemDescription: '',
    itemImages: []
};

export const itemFormSlice = createSlice({
    name: 'itemFormSlice',
    initialState,
    reducers: {
        getAllItemFormFieldsPending: (state: ItemFormState) => {
            state.isLoading = true;
        },
        getAllItemFormFieldsFulfilled: (
            state: ItemFormState,
            action: GetItemFormFieldsFulfilledAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.itemFormFields = action.payload.itemFormFields;
            state.totalItems = action.payload.totalItems;
            state.company_id = action.payload.company_id;
        },
        getAllItemFormFieldsRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        getAllItemFormStaticFieldsPending: (state: ItemFormState) => {
            state.isLoading = true;
        },
        getAllItemFormStaticFieldsFulfilled: (
            state: ItemFormState,
            action: GetItemFormStaticFieldsFulfilledAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.itemFormStaticFields = action.payload;
        },
        getAllItemFormStaticFieldsRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        getItemFormFieldPending: (state: ItemFormState) => {
            state.isLoading = true;
        },
        getItemFormFieldFulfilled: (
            state: ItemFormState,
            action: GetItemFormFieldFulfilledAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.itemFormField = action.payload;
        },
        getItemFormFieldRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createItemFormFieldPending: (state: ItemFormState) => {
            state.isLoading = true;
        },
        createItemFormFieldFulfilled: (state: ItemFormState) => {
            state.isLoading = false;
            state.error = null;
        },
        createItemFormFieldRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        editItemFormFieldPending: (state: ItemFormState) => {
            state.isLoading = true;
        },
        editItemFormFieldFulfilled: (state: ItemFormState) => {
            state.isLoading = false;
            state.error = null;
        },
        editItemFormFieldRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeStatusItemFormFieldPending: (state: ItemFormState) => {
            state.isLoading = true;
        },

        changeStatusItemFormFieldFulfilled: (state: ItemFormState) => {
            state.isLoading = false;
            state.error = null;
        },
        changeStatusItemFormFieldRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        deleteItemFormFieldsPending: (state: ItemFormState) => {
            state.isLoading = true;
        },

        deleteItemFormFieldsFulfilled: (state: ItemFormState) => {
            state.isLoading = false;
            state.error = null;
        },
        deleteItemFormFieldsRejected: (
            state: ItemFormState,
            action: ItemFormRejectedAction
        ) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        setItemFormFieldsForDeletion: (
            state: ItemFormState,
            action: DeleteItemFormFieldsAction
        ) => {
            state.itemFormFieldsForDeletion = action.payload;
        },
        setSelectedTabForDeletion: (state: any, action: any) => {
            state.selectedTabForDeletion = action.payload;
        },
        setPopup: (state: any, action: any) => {
            state.popup = action.payload;
        },
        setFieldsToAddToCompany: (
            state: ItemFormState,
            action: FieldsToAddToCompanyAction
        ) => {
            state.fieldsToAddToCompany = action.payload;
        },
        setItemDescription: (
            state: ItemFormState,
            action: any
        ) => {
            state.itemDescription = action.payload
        },
        setItemImages: (
            state: ItemFormState,
            action: any
        ) => {
            state.itemImages = action.payload
        },
        clearItemFormFieldsForDeletion: (state: ItemFormState) => {
            state.itemFormFieldsForDeletion = [];
        },
        cleareItemFormFieldStore: (state: ItemFormState) => {
            state.itemFormField = null;
        },
        cleareItemFormFieldsStore: (state: ItemFormState) => {
            state.itemFormFields = null;
            state.itemFormField = null;
            state.itemFormStaticFields = null;
            state.totalItems = 0;
            state.itemFormFieldsForDeletion = [];
            state.isLoading = false;
            state.error = null;
        },
    },
});
