import { Col, Row, Divider, Empty } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styles from "./itemsPage.module.scss";
import responsible1 from "../../../common/icons/responsible1.png";
import responsible2 from "../../../common/icons/responsible2.png";
import responsible3 from "../../../common/icons/responsible3.png";
import sustainability1 from "../../../common/icons/sustainability1.png";
import sustainability2 from "../../../common/icons/sustainability2.png";
import sustainability3 from "../../../common/icons/sustainability3.png";
import sustainability4 from "../../../common/icons/sustainability4.png";
import WineBottleDefault from "../../../common/img/wine-bottle-default.png";

interface FormProps {
  element: any;
  data: any;
  setIsMainFieldsFull: any;
  isLastTab: any;
  isDraft: boolean;
}

export const ItemCatalog = ({element, data, setIsMainFieldsFull, isLastTab, isDraft, }: FormProps) => {
  const [origin, setOrigin] = useState(null);
  const [type, setType] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [additionals, setAdditionals] = useState(null);
  const [nutritionalValue, serNutritionalValue] = useState([]);

  useEffect(() => {
      if(data !== null && data !== undefined && data.id !== undefined ) {
          let origin = data.item_forms.find((item) => {return item.item_field.key == "country-of-origin"});
          setOrigin(origin);

          let type = data.item_forms.find((item) => {return item.item_field.key == "grape-variety-code"});
          setType(type);

          if(data.item_forms !== null) {
              let ingredientsJson = data.item_forms.find((item) => {return item.item_field.key == "ingredients"});
              let ingredientsField = ingredientsJson !== undefined?JSON.parse(ingredientsJson.value):null;
              if(ingredientsField !== null) {
                setIngredients(ingredientsField[0]);  
              }
          }

          let additionals = data.item_forms.filter((item) => {return item.item_field.item_tab_name == "Alcohol" && item.item_field.is_elabel});
          setAdditionals(additionals)

          let nutritionalValue = data.item_nutrients ? data.item_nutrients.filter((item) => { return item.qtyMeasurementPrecisionValue !== ""}) : []
          serNutritionalValue(nutritionalValue)
      }

  }, [data]);

  return (
      <>
      {data !== null && data !== undefined && data.id !== undefined ?
       <div style={{maxWidth: '1300px', margin: '0 auto', boxShadow: '0px 10px 6px rgba(0,0,0,0.8)'}}>
            <Row>
                <Col span={12} className={styles.imgWrapper}>
                </Col>
                <Col span={12} className={styles.dataWrapper}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Col className={styles.title}>Ingredients</Col>
                            <Col className={styles.description}>
                              {ingredients !== undefined && ingredients !== null ?
                                  <div>{ingredients.languageText}</div>
                              : ''}
                            </Col>
                            <Col className={styles.title}>Nutrition</Col>
                            <Divider className={styles.divider}/>
                            {data.item_nutrients !== undefined && data.item_nutrients.length > 0 && nutritionalValue.length > 0 ?
                            <div>
                                <Row>
                                    <Col span={12} className={styles.nutritionalHeader}>TYPE</Col>
                                    <Col span={12} style={{textAlign: 'right'}} className={styles.nutritionalHeader}>PER 100ml</Col>
                                </Row>
                                <Divider className={styles.divider}/>
                                {data.item_nutrients.map((nutrient) => {
                                  if(nutrient.qtyMeasurementPrecisionValue !== "") {
                                      if(nutrient.nutrientCode === "FAT") {
                                          let saturatedFat = data.item_nutrients.filter((item) => { return item.nutrientCode === "FASAT"})
                                          return <Row className={styles.additional}>
                                                      <Col span={15} style={{padding: '10px'}}>
                                                          <Row justify="start">
                                                              <Col span={24}><span style={{fontWeight: '600'}}> {nutrient.nutrientName}
                                                                </span> {nutrient.qtyMeasurementPrecisionValue}{nutrient.unitMeasure}</Col>
                                                              <Col span={24}>
                                                                <ul>
                                                                  <li>{saturatedFat[0].nutrientName} {saturatedFat[0].qtyMeasurementPrecisionValue} {saturatedFat[0].unitMeasure}
                                                                  </li>
                                                                </ul>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col span={9} style={{padding: '10px'}}>
                                                          <Col span={24}><Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row></Col>
                                                          <Col span={24}><Row justify="end" style={{paddingTop: '10px'}}>{saturatedFat[0].percentMeasurementPrecisionValue}</Row></Col>
                                                      </Col>
                                                  </Row>
                                      } else if(nutrient.nutrientCode ===  "CHOCSM") {
                                          let totalSugar = data.item_nutrients.filter((item) => { return item.nutrientCode === "SUGAR"})
                                          let totalFiber = data.item_nutrients.filter((item) => { return item.nutrientCode === "FIBTG"})
                                          return  <Row className={styles.additional} >
                                                      <Col span={16} style={{padding: '10px'}}>
                                                          <Row justify="start">
                                                              <Col span={24}><span style={{fontWeight: '600'}}>{nutrient.nutrientName}
                                                                    </span> {nutrient.qtyMeasurementPrecisionValue} {nutrient.unitMeasure}</Col>
                                                              <Col span={24}>
                                                                  <ul>
                                                                      <li>{totalSugar[0].nutrientName} {totalSugar[0].qtyMeasurementPrecisionValue} {totalSugar[0].unitMeasure}</li>
                                                                      <li>{totalFiber[0].nutrientName} {totalFiber[0].qtyMeasurementPrecisionValue} {totalFiber[0].unitMeasure}</li>
                                                                  </ul>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col span={8} style={{padding: '10px'}}>
                                                          <Col span={24}><Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row></Col>
                                                          <Col span={24}><Row justify="end" style={{paddingTop: '10px'}}>{totalSugar[0].percentMeasurementPrecisionValue}</Row></Col>
                                                          <Col span={24}><Row justify="end">{totalFiber[0].percentMeasurementPrecisionValue}</Row></Col>
                                                      </Col>
                                                  </Row>
                                      } else if(nutrient.nutrientCode === "ENERSF" || nutrient.nutrientCode === "NA" || nutrient.nutrientCode === "PRO-") {
                                          return <Row className={styles.additional}>
                                                      <Col span={15} style={{padding: '10px'}}>
                                                          <Row justify="start"><Col span={24}><span style={{fontWeight: '600'}}>{nutrient.nutrientName}</span> {nutrient.qtyMeasurementPrecisionValue} {nutrient.unitMeasure}</Col></Row>
                                                      </Col>
                                                      <Col span={9} style={{padding: '10px'}}>
                                                          <Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row>
                                                      </Col>
                                                  </Row>
                                      } 
                                  }
                              })}
                            </div>
                            :''}

                            <Col className={styles.title}>Allergens</Col>
                            <Divider  className={styles.divider}/>
                            {data.item_allergens !== undefined && data.item_allergens.length > 0 ?
                              data.item_allergens.map((allergen) => {
                                return <Col className={styles.description}><div>
                                            {allergen.allergenName}
                                        </div></Col>
                              })
                          : ''}

                            <Col className={styles.title}>Additional product information</Col>
                            <Divider  className={styles.divider}/>
                            {additionals !== undefined && additionals !== null && additionals.length > 0 ?
                              additionals.map((additional) => {
                                  return <Row className={styles.additional} >
                                              <Col span={15} style={{padding: '10px', textTransform: 'capitalize'}}>
                                                  <Row justify="start">
                                                      {additional.item_field.name}
                                                  </Row>
                                              </Col>
                                              <Col span={9} style={{padding: '10px'}}>
                                                  <Row justify="end">{additional.value}</Row>
                                              </Col>
                                          </Row>
                              })
                          : ''}
                        </Col>
                        <Col span={12}>
                            <Col className={styles.name}>{data.description}</Col>
                            <Col className={styles.type}>{type !== null && type !== undefined ?type.value:''}</Col>
                            <Col className={styles.origin}>{origin !== null && origin !== undefined ?origin.value:''}</Col>
                            <Col style={{textAlign: 'center'}}><img src={WineBottleDefault} style={{height: '436px'}}/></Col>
                            <Col className={styles.title}>Sustainability
                            </Col>
                            <Divider  className={styles.divider}/>
                             <Col className={styles.images}>
                                <img src={sustainability1} />
                                <img src={sustainability2} />
                                <img src={sustainability3} />
                                <img src={sustainability4} />
                            </Col>
                            <Col  className={styles.title}>Responsible use
                            </Col>
                            <Divider  className={styles.divider}/>
                            <Col>
                                <Row className={styles.images}>
                                    <img src={responsible1} />
                                    <img src={responsible2} />
                                    <img src={responsible3} />
                                </Row>
                                
                                    <Col className={styles.responsibleText}>Always drink in moderation.</Col>
                                    <Col className={styles.responsibleText}>Alcohol abuse is dangerous for your health.
                                    </Col>
                                
                            </Col>
                           
                        </Col>
                    </Row>
                    <Divider className={styles.divider}/>
                </Col>
            </Row>
        </div>
        :<Empty />}
      </>
  );
}