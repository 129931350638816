import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveProductsState,
  DeletionActivationDeactivationDraftProductsAction,
  ProductsRejectedAction,
  GetActiveProductFulfilledAction,
  GetActiveProductsFulfilledAction,
} from "../../../modules/products/types";

const initialState: ActiveProductsState = {
  activeProducts: null,
  activeProduct: null,
  totalProducts: 0,
  activeProductsForDeactivationDeletion: [],
  isLoading: false,
  error: null,
};

export const activeProductsSlice = createSlice({
  name: "activeProductsSlice",
  initialState,
  reducers: {
    getAllActiveProductsPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },
    getAllActiveProductsFulfilled: (
      state: ActiveProductsState,
      action: GetActiveProductsFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.activeProducts = action.payload.activeProducts;
      state.totalProducts = action.payload.totalProducts;
    },
    getAllActiveProductsRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getActiveProductPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },
    getActiveProductFulfilled: (
      state: ActiveProductsState,
      action: GetActiveProductFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.activeProduct = action.payload.activeProduct;
    },
    getActiveProductRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createActiveProductPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },
    createActiveProductFulfilled: (state: ActiveProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    createActiveProductRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editActiveProductPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },
    editActiveProductFulfilled: (state: ActiveProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    editActiveProductRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusActiveProductPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },

    changeStatusActiveProductFulfilled: (state: ActiveProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusActiveProductRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteActiveProductsPending: (state: ActiveProductsState) => {
      state.isLoading = true;
    },

    deleteActiveProductsFulfilled: (state: ActiveProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteActiveProductsRejected: (
      state: ActiveProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setActiveProductsForDeactivationDeletion: (
      state: ActiveProductsState,
      action: DeletionActivationDeactivationDraftProductsAction
    ) => {
      state.activeProductsForDeactivationDeletion = action.payload;
    },
    clearActiveProductsForDeactivationDeletion: (state: ActiveProductsState) => {
      state.activeProductsForDeactivationDeletion = [];
    },
    cleareActiveProductStore: (state: ActiveProductsState) => {
      state.activeProduct = null;
    },
    cleareActiveProductsStore: (state: ActiveProductsState) => {
      state.activeProducts = null;
      state.activeProduct = null;
      state.totalProducts = 0;
      state.activeProductsForDeactivationDeletion = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});
