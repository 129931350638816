/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./itemFormStaticFieldsList.module.scss";
import { getItemFormStaticFields } from "../../../../api/itemForm";

const ItemFormStaticFieldsList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { itemFormStaticFields } = useAppSelector(
    (state) => state.itemForm
  );

  useEffect(() => {
    if (!itemFormStaticFields) {
      dispatch(getItemFormStaticFields());
    }
  }, [dispatch]);

  const getColumns = () => [
    {
      title: "",
      dataIndex: "emptyStart",
      key: "emptyStart",
    },
    {
      title: "FIELD NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "FIELD TYPE",
      dataIndex: "field_type_name",
      key: "field_type_name",
    },
    {
      title: "FORM TAB TYPE",
      dataIndex: "item_tab_name",
      key: "item_tab_name",
    },
    {
      title: "STATUS",
      dataIndex: "static",
      align: "center" as "center",
      key: "static",
    },
  ];

  const getDataSource = () => {
    return itemFormStaticFields?.map(
      ({ name, field_type_name, item_tab_name }, index) => {
        return {
          key: index + name,
          emptyStart: <div className={styles.emptyStart} />,
          name: <div className={styles.name}>{name}</div>,
          field_type_name: <div className={styles.fieldTypeName}>{field_type_name}</div>,
          item_tab_name: <div className={styles.itemTabName}>{item_tab_name}</div>,
          static: (
            <div className={styles.staticWrapper}>
              <div className={styles.staticIcon}>STATIC</div>
            </div>
          ),
        };
      }
    );
  };

  return (
    <div className={styles.tableWrapper}>
      <Table
        className={styles.table}
        locale={{ emptyText: "No search results found" }}
        dataSource={getDataSource()}
        columns={getColumns()}
        scroll={{ y: "196px" }}
        pagination={false}
      />
    </div>
  );
};

export default ItemFormStaticFieldsList;
