import { useState, useEffect } from 'react';
import { Input, Col, Row, Select, Button, Tooltip } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Option } from 'antd/lib/mentions';
import InputFieldTitle from '../InputFieldTitle';
import InputField from '../InputField';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { SelectData } from '../../types';
import elabelNutrientCodes from '../../../../helpers/nutrientCodes';
import styles from './index.module.scss';
import { getNutrientCodes } from '../../../../api/filter';
import { getMeasurementPrecisions } from '../../../../api/filter';
import { getUnitMeasurements } from '../../../../api/filter';
import { getDerivationCodes } from '../../../../api/filter';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

type NutritionalInputProps = {
    itemNutritionals?: any;
    setItemNutritionals?: any;
    item?: any;
} & InputProps;

const Nutritional = ({
    itemNutritionals,
    setItemNutritionals,
    item,
    ...props
}: NutritionalInputProps) => {
    const {nutrientCodes, measurementPrecisions, unitMeasurements, derivationCodes} = useAppSelector(
        (state) => state.optionLists
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
      if(nutrientCodes !== undefined && nutrientCodes !== null && nutrientCodes.length == 0) {
        dispatch(getNutrientCodes());
      }

     if(item?.item_nutrients !== undefined && item?.item_nutrients.length > 0) {
      const nutrients: any = [];
    
      item.item_nutrients.map((value: any) => {
              nutrients[value.nutrientCode] = {
                nutrientName: value.nutrientName, 
                nutrientCode: value.nutrientCode, 
                qtyMeasurementPrecisionCode: value.qtyMeasurementPrecisionCode,
                qtyMeasurementPrecisionValue: value.qtyMeasurementPrecisionValue, 
                unitMeasure: value.unitMeasure, 
                percentMeasurementPrecisionCode: value.percentMeasurementPrecisionCode, 
                percentMeasurementPrecisionValue: value.percentMeasurementPrecisionValue, 
                derivationCode: value.derivationCode
          }
      });
      setItemNutritionals(nutrients);
    } else {
      const nutrientCodesReceived: any = [];
      nutrientCodes.map((value: any) => {
          nutrientCodesReceived[value.value] = {nutrientName: value.name, nutrientCode: value.value, qtyMeasurementPrecisionCode: '', qtyMeasurementPrecisionValue: '', unitMeasure: '', percentMeasurementPrecisionCode: '', percentMeasurementPrecisionValue: '', derivationCode: ''}
      });

      setItemNutritionals(nutrientCodesReceived);
    }
        
    }, [nutrientCodes]);

    useEffect(() => {
      if(measurementPrecisions !== undefined && measurementPrecisions !== null && measurementPrecisions.length == 0) {
        dispatch(getMeasurementPrecisions());
      }
    }, [measurementPrecisions]);

    useEffect(() => {
      if(unitMeasurements !== undefined && unitMeasurements !== null && unitMeasurements.length == 0) {
        dispatch(getUnitMeasurements());
      }
    }, [unitMeasurements]);

    useEffect(() => {
      if(derivationCodes !== undefined && derivationCodes !== null && derivationCodes.length == 0) {
        dispatch(getDerivationCodes());
      }
    }, [derivationCodes]);


    const setNutrientsLine = (nutrientCode: any, fieldLabel: any, fieldValue: any) => {
        let copyOfItemNutritionals = {...itemNutritionals}
        copyOfItemNutritionals[nutrientCode][fieldLabel] = fieldValue
        setItemNutritionals(copyOfItemNutritionals)
    }

    const energyMeasurements = ['CAL', 'KCAL', 'KJ']
    const otherMeasurements = ['G','MCG', 'MG']

    return (<div>
     <>
      <div>
      <Row justify="center" align="middle" >
        <Col span={4} ><Row justify="center" align="middle" className={styles.headerNutritional} style={{height: '110px'}}>Nutrient <br />Code</Row></Col> 
        <Col span={10}>
          <Row justify="center" align="middle" className={styles.headerNutritional} style={{height: '53px'}}>Nutrient <br /> Quantity</Row>
          <Row className={styles.headerNutritional} style={{height: '53px'}}>
            <Col span={8}><Row justify="center" align="middle" style={{height: '53px', fontWeight: '700', fontSize: '12px'}}>Measurement<br/> Precision</Row></Col>
            <Col span={8}><Row justify="center" align="middle" style={{height: '53px', fontWeight: '700', fontSize: '12px'}}>Value</Row></Col>
            <Col span={8}><Row justify="center" align="middle" style={{height: '53px', fontWeight: '700', fontSize: '12px'}}>Unit <br /> of Measure</Row></Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row justify="center" align="middle" className={styles.headerNutritional} style={{height: '53px'}} >Percentage of daily value in take</Row>
          <Row className={styles.headerNutritional} style={{height: '53px'}}>
            <Col span={12}><Row justify="center" align="middle" style={{height: '53px', fontWeight: '700', fontSize: '12px'}}>Measurement <br/>Precision</Row></Col>
            <Col span={12}><Row justify="center" align="middle" style={{height: '53px', fontWeight: '700', fontSize: '12px'}}>%</Row></Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row justify="center" align="middle" className={styles.headerNutritional} style={{height: '110px'}}>Nutrient Value <br/>Derivation Code</Row>
        </Col>
      </Row>
      </div>
      <div style={{padding: '15px', height: '455px', overflowY: 'scroll'}}>
      {nutrientCodes.map((nutrient: any, index: any) => {
        if(elabelNutrientCodes.indexOf(nutrient.value) > -1){ 
         return <Row justify="space-between" gutter={16}>
            <Col span={4} style={{fontWeight: '700'}}>{nutrient.name}</Col>
            <Col span={10}><Row gutter={16}>
            <Col span={8}>
            <InputFieldTitle
              title=""
              required={false}
            >
              <Select
                size="middle"
                value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['qtyMeasurementPrecisionCode']:''}
                onChange={(value, data: any) => {
                  setNutrientsLine(nutrient.value, 'qtyMeasurementPrecisionCode', value)
                }}
              >
                {measurementPrecisions.map((sortOption: SelectData) => (
                  <Option key={sortOption.name} value={`${sortOption.value}`}>
                    {sortOption.name}
                  </Option>
                ))}
              </Select>
            </InputFieldTitle>
            </Col>
            <Col span={8}>
            <InputFieldTitle
              title=""
              required={false}
            >
            <InputField
              value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['qtyMeasurementPrecisionValue']:''}
              required={false}
              onChange={(e) => setNutrientsLine(nutrient.value, 'qtyMeasurementPrecisionValue', e.target.value)}
              suffix={
                <Tooltip title="The law requires nutritional information based on a 100ml serving.">
                  <InfoCircleOutlined style={{ color: 'rgba(143,10,14,.75)' }} />
                </Tooltip>
              }
            />
            </InputFieldTitle>
            </Col>
            <Col span={8}>
            <InputFieldTitle
              title=""
              required={false}
            >
              <Select
                size="middle"
                value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['unitMeasure']:''}
                onChange={(value, data: any) => {
                  setNutrientsLine(nutrient.value, 'unitMeasure', value);
                }}
              >
                {unitMeasurements.map((sortOption: SelectData) => (
                  <Option key={sortOption.name} value={`${sortOption.value}`} 
                    disabled={nutrient.value === 'ENERSF' && energyMeasurements.includes(`${sortOption.value}`)?false:
                    nutrient.value !== 'ENERSF' && otherMeasurements.includes(`${sortOption.value}`)?false:true}>
                    {sortOption.name}
                  </Option>
                ))}
              </Select>
            </InputFieldTitle>
            </Col></Row>
            </Col>
            <Col span={6}>
              <Row gutter={16}><Col span={12}>
              <InputFieldTitle
                title=""
                required={false}
              >
                <Select
                  size="middle"
                  value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['percentMeasurementPrecisionCode']:''}
                  onChange={(value, data: any) => {
                    setNutrientsLine(nutrient.value, 'percentMeasurementPrecisionCode', value);
                  }}
                >
                  {measurementPrecisions.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
              </Col>
              <Col span={12}>
              <InputFieldTitle
                title=""
                required={false}
              >
              <InputField
                value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['percentMeasurementPrecisionValue']:''}
                required={false}
                onChange={(e) => setNutrientsLine(nutrient.value, 'percentMeasurementPrecisionValue', e.target.value)}
                suffix={
                  <Tooltip title="The law requires nutritional information based on a 100ml serving.">
                    <InfoCircleOutlined style={{ color: 'rgba(143,10,14,.75)' }} />
                  </Tooltip>
                }
              /></InputFieldTitle>
              </Col></Row></Col>
              <Col span={4}>
              <InputFieldTitle
                title=""
                required={false}
              >
                <Select
                  size="middle"
                  value={itemNutritionals[nutrient.value] !== undefined?itemNutritionals[nutrient.value]['derivationCode']:''}
                  onChange={(value, data: any) => {
                    setNutrientsLine(nutrient.value, 'derivationCode', value);
                    //setLevelOfContainmentName(data.key)
                  }}
                >
                  {derivationCodes.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name}
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
              </Col>
          </Row>
        }
      })}
        </div>
      </>
    </div>)
}

export default Nutritional;