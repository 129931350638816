import { Col, Row, Tabs } from 'antd';
import { IconInfoBlock } from './IconInfoBlock';
import {ReactComponent as IconSuppliers} from '../../../common/icons/supplier_pack.svg';
import {ReactComponent as IconTotal} from '../../../common/icons/total_icons.svg';
import {ReactComponent as IconCategory} from '../../../common/icons/categories.svg';
import style from './summarySupplier.module.scss'

export const IconInfoHolder = () => {
    
    return (
        <>
            <Row>
                <IconInfoBlock style={style} span={7} offset={0} Icon={IconTotal} label={'Total Products'} amount={1694} />
                <IconInfoBlock style={style} span={7} offset={1} Icon={IconSuppliers} label={'Total Retailers'} amount={1} />
                <IconInfoBlock style={style} span={7} offset={1} Icon={IconCategory} label={'Product Categories'} amount={8} />
            </Row>
        </>
    );
};