/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import { FC, useEffect } from 'react';
import styles from './itemFormListPanel.module.scss';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useActions } from '../../../../hooks/useActions';
import { SelectData } from '../../../common/types';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getCompaniesNames } from '../../../../api/filter';
import { ReactComponent as TrashIcon } from '../../../common/icons/trash.svg';
import { SortedByItemFormFields } from '../../constants';
import FilterControl from '../../../common/components/FilterControl';
import { getAllCompanies } from '../../../../api/companies';

interface ItemFormFilterOwnProps {
    setOpenModal: (value: boolean) => void;
    countCheckedItemFormFields: number;
    totalItemFormFieldsCount: number;
}

const sortedByValues: SelectData[] = [
    {
        name: SortedByItemFormFields.COMPANIES,
        value: SortedByItemFormFields.COMPANIES,
    },
];

const ItemFormFilter: FC<ItemFormFilterOwnProps> = ({
    setOpenModal,
    countCheckedItemFormFields,
    totalItemFormFieldsCount,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector((state) => state.filter);
    const {
        cleareCompanyStore,
        setIsConfirmItemFormFieldDeletionModal,
        setItemFormFieldsForDeletion,
        setIsOpenCompanyAddFieldsModal,
    } = useActions();

    const { itemFormFieldsForDeletion } = useAppSelector(
        (state) => state.itemForm
    );

    const { sortedByData, isSortedByStatus } = useAppSelector(
        (state) => state.filterControl
    );

    useEffect(() => {
        dispatch(getAllCompanies(filter));
    }, []);

    useEffect(() => {
        dispatch(getCompaniesNames());
    }, [dispatch]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.filterControlWrapper}>
                <FilterControl
                    searchName="Items"
                    cleareActivationDeletionIdes={() =>
                        setItemFormFieldsForDeletion([])
                    }
                    isSearch={false}
                    sortedBy={sortedByValues}
                    sortedByData={sortedByData}
                    isSortedByStatus={isSortedByStatus}
                    dateRangePicker={false}
                    getSortedByData={(sortValue) => {
                        if (sortValue === SortedByItemFormFields.COMPANIES) {
                            dispatch(getCompaniesNames());
                        }
                    }}
                />
                <div className={styles.countInfoWrapper}>
                    <div className={styles.countInfoField}>
                        {!countCheckedItemFormFields
                            ? totalItemFormFieldsCount
                            : countCheckedItemFormFields}
                    </div>
                    <div className={styles.countInfoTitle}>
                        {!countCheckedItemFormFields
                            ? 'Total attributes'
                            : 'Attributes selected'}
                    </div>
                </div>
            </div>
            <div className={styles.buttonsGroup}>
                {itemFormFieldsForDeletion.length !== 0 && (
                    <div
                        className={styles.buttonDelete}
                        onClick={() => {
                            setIsConfirmItemFormFieldDeletionModal(true);
                        }}
                    >
                        <TrashIcon className={styles.trashIcon} />
                        <div>Delete</div>
                    </div>
                )}
                <Button
                    type="primary"
                    className={`apply ${styles.buttonAdd}`}
                    onClick={() => {
                        setIsOpenCompanyAddFieldsModal(true);
                    }}
                >
                    {'Apply fields'}
                </Button>
                <Button
                    type="primary"
                    className={styles.buttonAdd}
                    onClick={() => {
                        cleareCompanyStore();
                        setOpenModal(true);
                    }}
                >
                    <div>Add Field</div>
                </Button>
            </div>
        </div>
    );
};

export default ItemFormFilter;
