import { createSlice } from "@reduxjs/toolkit";
import {
  UploadFullfillAction,
  UploadRejectedAction,
  UploadState,
} from "../../../modules/upload/types";

const initialState: UploadState = {
  sucessfullMessage: null,
  isLoading: false,
  error: null,
};

export const uploadSlice = createSlice({
  name: "uploadSlice",
  initialState,
  reducers: {
    uploadPending: (state: UploadState) => {
      state.isLoading = true;
    },
    uploadFulfilled: (state: UploadState, action: UploadFullfillAction) => {
      state.isLoading = false;
      state.error = null;
      state.sucessfullMessage = action.payload;
    },
    uploadRejected: (state: UploadState, action: UploadRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    cleareSuccesfulUpload: (state: UploadState) => {
      state.sucessfullMessage = null;
    },
  },
});
