import { FC } from "react";
import "./modalsGroup.global.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import AppModal from "../../../common/components/AppModal";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  changeActiveProductsStatus,
  deleteActiveProducts,
} from "../../../../api/products";

const ModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeProductsForDeactivationDeletion } = useAppSelector(
    (state) => state.activeProducts
  );
  const {
    isConfirmActiveProductDeletionModal,
    isOpenActiveProductChangeStatusModal,
  } = useAppSelector((state) => state.modals);
  const {
    setActiveProductsForDeactivationDeletion,
    setIsConfirmActiveProductDeletionModal,
    setIsOpenActiveProductChangeStatusModal,
    setIsChangeProductsDeletionActivationActions,
  } = useActions();

  return (
    <>
      <AppModal
        visible={isOpenActiveProductChangeStatusModal}
        handleOk={() => {
          dispatch(
            changeActiveProductsStatus(
              activeProductsForDeactivationDeletion.map((value) => value.id)
            )
          );
          setActiveProductsForDeactivationDeletion([]);
          setIsOpenActiveProductChangeStatusModal(false);
          setIsChangeProductsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsOpenActiveProductChangeStatusModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to put Active product(s) to Drafts?"
        onCancel={() => setIsOpenActiveProductChangeStatusModal(false)}
      />

      <AppModal
        visible={isConfirmActiveProductDeletionModal}
        handleOk={() => {
          dispatch(
            deleteActiveProducts(
              activeProductsForDeactivationDeletion.map((value) => value.id)
            )
          );
          setActiveProductsForDeactivationDeletion([]);
          setIsConfirmActiveProductDeletionModal(false);
          setIsChangeProductsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsConfirmActiveProductDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${activeProductsForDeactivationDeletion.length} Active Product(s)?`}
        onCancel={() => setIsConfirmActiveProductDeletionModal(false)}
      />
    </>
  );
};

export default ModalsGroup;
