import { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Col, Row, Select, Button, Tabs, Empty, notification, QRCode, Alert} from 'antd';
import InputFieldTitle from '../../common/components/InputFieldTitle';
import InputField from '../../common/components/InputField';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useActions } from '../../../hooks/useActions';
import MultiLanguageInput from '../../common/components/MultiLanguageInput';
import Allergen from '../../common/components/Allergen';
import { createDraftItem, editDraftItem } from '../../../api/items';
import { Option } from 'antd/lib/mentions';
import Nutritional from '../../common/components/Nutritional';
import { GET_QR_LINK, POST_ELABEL } from '../../../api/qrcode';
import { Popup } from '../../items/components/ItemDetailsForm/popup';
import { QrcodeOutlined } from '@ant-design/icons';
import QaCodeIcon from '../../common/icons/info-icon-QR.svg';
import MultiFileUploader from '../../common/components/MultiFileUploader';
import { convertFilesForRequest } from "../../../helpers";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface FormProps {
	element: any;
	dataForm: any;
	setIsMainFieldsFull: any;
	isLastTab: any;
	isDraft: boolean;
}

interface OptionsProps {
	created_at: string;
	id: number;
	item_field_id: number;
	name: string;
	updated_at: string;
}

interface ItemProps {
	created_at: string;
	id: 6;
	is_file: boolean;
	is_size: boolean;
	is_language: boolean;
	is_multiple: boolean;
	is_elabel: boolean;
	item_tab_id: number;
	key: any;
	mandatory: string | null;
	status: boolean;
	updated_at: string;
	name: string;
	field_type_id: number;
	field_select_options: OptionsProps[];
}

interface ElabelData {
	data: any;
	gtin: string;
	type: string;
	url: string;
	lotNumber: string;
	serialNumber: string;
}

const initialElabelData: ElabelData = {
	gtin: '',
	type: 'gs1qrcode',
	url: 'https://preprod.evino.co.za/testelabel',
	lotNumber: '',
	serialNumber: '',
	data: null,
}

export const Elabel = ({element, dataForm, setIsMainFieldsFull, isLastTab, isDraft, }: FormProps) => {
	const dispatch = useAppDispatch();
	const vindixDomain = process.env.REACT_APP_VINDIX_DOMAIN;
	const { userCompanyId } = useAppSelector((state) => state.companies);
	const { itemFormFields } = useAppSelector((state) => state.itemForm);
	const [elabelData] = useState<ElabelData>(initialElabelData);
	const [formData, setFormData] = useState<any>({
		id: null,
		gtin: null,
		description: null,
		trade_item_descriptor_id: 1,
		brand: null,
		target_market_id: Number(250),
		quantity: 1
	});
	const [dinamicFormData, setDinamicFormData] = useState<any>([]);
	const [dinamicFormDataError, setDinamicFormDataError] = useState<any>([]);
	const [itemAllergens, setItemAllergens] = useState<any[]>([]);
	const [itemNutritionals, setItemNutritionals] = useState<any>([]);
	const [activeKey, setActiveKey] = useState('main');
    const { t } = useTranslation();

	const selectedTab: any = useAppSelector(
		(state) => state.modals.draftItemsFormModalActiveTab
		);

	const [link, setLink] = useState('');
	const [elabelPath, setElabelPath] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [gtinError, setGtinError] = useState<string>('');
	const [metaError, setMetaError] = useState<boolean>(false);
  const [newLink, setNewLink] = useState('');
  const [newLinkError, setNewLinkError] = useState(false);
	const [formDataError, setFormDataError] = useState<any>({});
	const [mandatoryDinamicField, setMandatoryDinamicField] = useState({});
	const { popup }: any = useAppSelector((state) => state.itemForm);
	const [chosenImage, setChosenImage] = useState(0);
	const [fileUrl, setFileUrl] = useState<any>();
	const [metaData, setMetaData] = useState<any[]>([]);
	const [mandatoryMetaDataFields, setMandatoryMetaDataFiealds] = useState({});
	const { setModalActiveTab } = useActions();
	const [legallyStatus, setLegallyStatus] = useState("INCOMPLETE");
	const [legallyStatusVisible, setLegallyStatusVisible] = useState(false);
	const urlParams = useParams();

	useEffect(() => {
		if (!metaData) return;
		if (typeof metaData[chosenImage]?.file !== 'string') {
			setFileUrl('');
		}
	}, [chosenImage]);

	useEffect(() => {
		if (!dataForm) {
			setIsMainFieldsFull(false);
		} else {
			setIsMainFieldsFull(true);
		}
	}, [dataForm]);

	useEffect(() => {
		if (!formData.gtin) return;
		if (!/^[0-9]+$/.test(formData.gtin)) {
			setGtinError('GTIN is not valid');
		} else {
			setGtinError('');
		}
	}, [formData.gtin]);

	useEffect(() => {
		if(link !== undefined && link !== null && link !== '') {
			saveElabel(); 		
		}
	}, [link]);

	useEffect(() => {
		if(dataForm !== undefined && dataForm !== null) {
			const data = { ...formData };
			data.id = dataForm.id;

			setLegallyStatus(dataForm.legallyRequiredData);
			
			if (activeKey === 'main') {
				data.gtin = dataForm.gtin;
				data.description = dataForm.description;
				data.trade_item_descriptor_id = dataForm.trade_item_descriptor_id
				data.brand = dataForm.brand;
			}

	      	//Digital assets
			data.file_links = dataForm?.file_links;
			const dataFile: any = [];
			dataForm.file_links.forEach((elem: any) => {
				const fieldsObj: any = {};
				elem.fields.forEach((e: any) => {
					fieldsObj[e.item_field.id] = e.value;
				});
				dataFile.push({
					file: elem.file,
					fields: fieldsObj,
				});
			});

			setMetaData(dataFile);

	      	//Static data
			setFormData(data);

	      	//Dynamic data
			let dinamicData: any = { ...dinamicFormData };
			dataForm?.item_forms?.forEach((elem: any) => {
				if(elem.item_field !== undefined && elem.item_field !== null) {
					dinamicData[elem.item_field.key] = elem.value;  
				}

			});

			setDinamicFormData(dinamicData);

	    //Elabel path image
			if(dataForm.item_elabel !== undefined && dataForm.item_elabel !== null && dataForm.item_elabel !== '') {
				let GS1QrCode = dataForm.item_elabel.file.split("(8200)"); 
				setElabelPath(GS1QrCode[1])
			}

			//Allergens
			if(dataForm.item_allergens !== undefined && dataForm.item_allergens.length > 0) {
        setItemAllergens(dataForm.item_allergens)
      }

      //Nutritional
      if(dataForm?.item_nutrients !== undefined && dataForm?.item_nutrients.length > 0) {
	      const nutrients: any = [];
	    
	      dataForm.item_nutrients.map((value: any) => {
	              nutrients[value.nutrientCode] = {
	                nutrientName: value.nutrientName, 
	                nutrientCode: value.nutrientCode, 
	                qtyMeasurementPrecisionCode: value.qtyMeasurementPrecisionCode,
	                qtyMeasurementPrecisionValue: value.qtyMeasurementPrecisionValue, 
	                unitMeasure: value.unitMeasure, 
	                percentMeasurementPrecisionCode: value.percentMeasurementPrecisionCode, 
	                percentMeasurementPrecisionValue: value.percentMeasurementPrecisionValue, 
	                derivationCode: value.derivationCode
	          }
	      });
	      setItemNutritionals(nutrients);
    	}
		}

	}, [dataForm]);

	useEffect(() => {
		if (!element) return;
		const data: any = {};
		element?.items?.forEach((elem: any) => {
			if (
				elem.mandatory === 'Yes' &&
				+elem.field_type_id !== 2 &&
				!elem.is_file
				) {
					data[elem.key] = true;
				}
		});

		setMandatoryDinamicField(data);

		const metaData: any = {};
		element?.items?.forEach((elem: any) => {
			if (
				elem.mandatory === 'Yes' &&
				+elem.field_type_id !== 2 &&
				elem.is_file
				) {
					metaData[elem.id] = true;
				}
		});
		setMandatoryMetaDataFiealds(metaData);
		const languageData: any = { ...dinamicFormData };
		var isLanguageKeys: Array<string> = [];
		element?.items?.forEach((elem: ItemProps) => {
			const item = dataForm?.item_forms?.find((elemDraft: any) => {
				if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
					return elemDraft.item_field.key === elem.key  
				}

			});
			languageData[elem.key] = item ? item.value : null;
        	//Keep language field keys
			if(item) {
				if(item.item_field.is_language) {
					isLanguageKeys.push(elem.key);  
				}

			}
		});

		languageData['isLanguageKeys'] = isLanguageKeys;
		setDinamicFormData(languageData);
	}, [element]);


	// check if a field is filled at fill time
	useEffect(() => {
	    const errors: any = { ...formDataError };
	    const errorDinamic = { ...dinamicFormDataError };
	    
	    for (let prop in formData) {
	        if (formData[prop]) {
	            errors[prop] = false;
	        }
	    }
	    for (let prop in mandatoryDinamicField) {
	      	if (dinamicFormData[prop]) {
	        	errorDinamic[prop] = false;
	    	}
	    }
	    
	    //Set mandatory for Allergens
	    if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length > 0) {
	    	errorDinamic['allergen-codes'] = false;
			}

		setDinamicFormDataError(errorDinamic);
		setFormDataError(errors);

		const data: any = {};
   	const dataFormObj = { ...formData };
    const result: any = [];
    dataFormObj?.file_links?.forEach((elem: any) => {
        if (elem.fields.length) {
          	const fieldsObj: any = {};
          	elem.fields.forEach((e: any) => {
            	if (e.item_field.id) {
              		fieldsObj[e.item_field.id] = e.value;
          		}
      		});
          	result.push({
            	file: elem.file,
            	fields: { ...fieldsObj },
        	});
      	} else {
	        result.push({
	            file: elem.file,
	            fields: { ...data },
	        });
      	}
  	});
    
    setMetaData(result);
	}, [formData, dinamicFormData]);

	const handleFieldValueChange = (attr: string, value: any) => {
		const data = { ...formData };
		data[attr] = value;
		setFormData(data);
	}
	
	const onChangeTab = (key: string) => {
		setActiveKey(key);
		dispatch(
              setModalActiveTab(
                key
              )
          );
	};

	const handleSubmit = () => {
		let isValid = true;
		const errors = { ...formDataError };

		if (activeKey === 'main') {
			if (!/^[0-9]+$/.test(formData.gtin)) {
				isValid = false;
				errors.gtin = true;
			}
			if (!formData.gtin) {
				isValid = false;
				errors.gtin = true;
			}
			if (!formData.description) {
				isValid = false;
				errors.description = true;
			}
			if (!formData.brand) {
				isValid = false;
				errors.brand = true;
			}
		}
		setFormDataError(errors);

	   if (element?.name === 'digital assets') {
	      	if (newLinkError) {
		        isValid = false;
		    }
		    const errorMetaData: any = { ...mandatoryMetaDataFields };
		    const data = JSON.parse(JSON.stringify(metaData));
		    data.forEach((elem: any) => {
		        for (let prop in elem.fields) {
		          	if (typeof elem.file === 'string') {
		            	for (let p in errorMetaData) {
		              		if (prop === p) {
		                		if (!elem.fields[prop]) {
		                  			isValid = false;
		                  			setMetaError(true);
		              			}
		          			}
		      			}
		    		}
		    	}
		    });
	    }

	    setFormDataError(errors);
		
		const errorDinamicData: any = { ...mandatoryDinamicField };
		const errorData = { ...dinamicFormDataError };
		for (let prop in errorDinamicData) {
			if (!dinamicFormData[prop]) {
				isValid = false;
				errorData[prop] = true;
			}
		}

		setDinamicFormDataError(errorData);

		if(element?.name == 'Ingredients') {
			const errorDinamicData: any = { ...mandatoryDinamicField };
			const errorData = { ...dinamicFormDataError };

			for (let prop in errorDinamicData) {
				if (!dinamicFormData[prop]) {
					isValid = false;
					errorData[prop] = true;
				} else {
					const languageData = typeof dinamicFormData[prop] === 'string'?JSON.parse(dinamicFormData[prop]):dinamicFormData[prop];
					languageData.map((line) => {
						if(line['languageText'] !== '' && line['languageCode'] === '' || 
							line['languageText'] === '' && line['languageCode'] !== '' ||
							line['languageText'] === '' && line['languageCode'] === '') {
							isValid = false;
							errorData[prop] = true;
						}
					})
				}
			}
			setDinamicFormDataError(errorData);
		}

		if(selectedTab == 'allergen') {
			const errorDinamicData: any = { ...mandatoryDinamicField };
			const errorData = { ...dinamicFormDataError };

			if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length == 0) {
				isValid = false;
				errorData['allergen-codes'] = true;
			}

			setDinamicFormDataError(errorData);
		}

		if (isValid) {
			const converFormData: any = { ...formData };
			delete converFormData.file_links;
			delete dinamicFormData.isLanguageKeys;
			
			const requestData = {
				...converFormData,
				files: convertFilesForRequest(metaData),
				item_forms: JSON.stringify({ ...dinamicFormData }),
				item_allergens: JSON.stringify({ ...itemAllergens }),
				item_nutrients: JSON.stringify({ ...itemNutritionals }),
			};

			if (dataForm === null) {
				if (activeKey === 'main') {
					dispatch(createDraftItem(Number(userCompanyId), requestData, 'main'));
					setActiveKey("digital assets")
					dispatch(setModalActiveTab("digital assets"))
				} else {
					dispatch(
						editDraftItem(Number(userCompanyId), requestData, '', isLastTab)
						);
					setActiveKey("digital assets")
					dispatch(setModalActiveTab("digital assets"))
				}
			} else {
				dispatch(
					editDraftItem(Number(userCompanyId), requestData, '', isLastTab)
					);
				setActiveKey("digital assets")
				dispatch(setModalActiveTab("digital assets"))
			}
		}
	}

	const handleChange = (name: string, value: any) => {
		let data = { ...dinamicFormData };
		data[name] = value;
		setDinamicFormData(data);
	};

	const handleGenerateElabel = () => {
		setLegallyStatusVisible(true)
		let date = '';
		GET_QR_LINK(
			elabelData.type,
			vindixDomain,
			formData.gtin,
			elabelData.lotNumber,
			elabelData.serialNumber,
			date
			)
		.then((res) => {
			setLink(res.data.link);
			setActiveKey('elabel');
			dispatch(setModalActiveTab("elabel"));
		})
		.catch((err) => {
            let errorMessage = err.message

            if(err.response.data && err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
                Object.values(err.response.data.errors).map((value, key) => {
                    if(Array.isArray(value) && value.length > 0) {
                        errorMessage = value[0]
                    }
                })
            }
			notification.error({
				message: errorMessage,
				description: '',
				duration: 6,
	            //top: 125
			});
		})
		.finally(() => {
			setIsLoading(false);
		});
	}

	const saveElabel = () => {
		const data = {
			file: link,
			gtin: formData.gtin,
		};

		POST_ELABEL(data)
		.then((res) => {
			notification.success({
				message: 'e-Label generated successfully',
				description: '',
				duration: 6,
			});

			let GS1QrCode = link.split("(8200)");
			setElabelPath(GS1QrCode[1])
		})
		.catch((err) => {
			notification.error({
				message: err.message,
				description: '',
				duration: 6,
			});
		})
		.finally(() => {
			setIsLoading(false);
		});
	};
	
	const downloadQRCode = () => {
		const canvas = document.getElementById('elabelImg')?.querySelector<HTMLCanvasElement>('canvas');
		if (canvas) {
			const url = canvas.toDataURL();
			const a = document.createElement('a');
			a.download = 'QRCode-'+formData.gtin+'.png';
			a.href = url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	const handleClose = () => {
    setLegallyStatusVisible(false);
  };

	return (<>
	<Row>
		<Col span={24} className="wine">
		{legallyStatusVisible?
			<div style={{position: 'absolute', right: '195px', marginTop: '5px', zIndex: '9999'}}>
				<Alert
	      	message={legallyStatus === "COMPLETE"?'Legally required data completed':'Need to enter the data required by law'}
	      	description={legallyStatus === "COMPLETE"?<div>Your e-Label contains all legally required data.</div>:<div>You can produce the e-label before you have the <br/>legally required fields, but you must update them <br />once the wine is in production.</div>}
	      	type={legallyStatus === "COMPLETE"?'success':'warning'}
	      	showIcon
	      	closable
	      	afterClose={handleClose}
		    />
		  </div>
		:''}
		<Tabs defaultActiveKey="main" 
		activeKey={selectedTab} 
		onChange={onChangeTab} 
		tabBarExtraContent={
				<div className={styles.generateElabel} onClick={handleGenerateElabel}>
					<QrcodeOutlined /> {t('Generate e-Label')}
				</div>}>
			{itemFormFields !== undefined && itemFormFields !== null && itemFormFields.length > 0?
			itemFormFields.map((element: any) => {
				if(element.items !== undefined && element.items.length > 0 || element.name === 'digital assets' || element.name === 'main') {
					return <Tabs.TabPane tab={t(element.title.toLowerCase())} key={element.name}>
					<form className="itemFormFieldDinamicForm" style={{background: '#F9F9F9', borderRadius: '4px', padding: '25px'}}>
						<Popup
						status="success"
						className={popup === 'success' ? styles.active : ''}
						/>
						<Popup
						status="error"
						className={popup === 'error' ? styles.active : ''}
						/>
						<Popup
						status="gtinError"
						className={popup === 'gtinError' ? styles.active : ''}
						/>
						{element?.name === 'main' && (
						<>
						<Row justify="start" >
							<Col span={4}>{t('GTIN-EAN-Barcode')}</Col>
							<Col xs={{span: 8}} lg={{span: 5}}>
							<InputField
							maxLength={14}
							placeholder={t('GTIN-EAN-Barcode')}
							required={false}
							errorMessage={
								gtinError
								? gtinError
								: formDataError.gtin
								? t('This field is required')
								: ''
							}
							value={formData.gtin}
							disabled={urlParams.gtin !== '' && urlParams.gtin !== undefined && urlParams.gtin !== null}
							onChange={(e: any) => handleFieldValueChange('gtin', e.target.value)}
							/>
							</Col>
						</Row>
						<Row justify="start">
							<Col span={4}>{t('Description')}</Col>
							<Col xs={{span: 8}} lg={{span: 5}}>
							<InputField
							placeholder={t('Description')}
							required={false}
							errorMessage={
								formDataError.description ? t('This field is required') : ''
							}
							value={formData.description}
							onChange={(e: any) => handleFieldValueChange('description', e.target.value)}
							/>
							</Col>
						</Row>
						<Row justify="start">
							<Col span={4}>{t('Brand')}</Col>
							<Col xs={{span: 8}} lg={{span: 5}}>
							<InputField
							placeholder={t('Brand')}
							errorMessage={
								formDataError.brand ? t('This field is required') : ''
							}
							required={false}
							value={formData.brand}
							onChange={(e: any) => handleFieldValueChange('brand', e.target.value)}
							/>
							</Col>
						</Row>
						</>
						)}
						{element?.name === 'digital assets' && (
						<>
						<Row>
							<MultiFileUploader
							isHideUploadButton={false}
							fieldsForImage={formData?.file_links}
							imagesData={
								formData?.file_links?.length &&
								formData.file_links[0]?.file !== null
								? formData?.file_links?.map((file: any, index: any) => {
									if (typeof file.file === 'string') {
										return {
											name: file.file,
											url: file.file,
											uid: `${file.file}/${index}`,
										};
									} else {
										return file.file;
									}
								})
								: []
							}
							setImages={(filesData) => {
								                                          // delete one last file
								if (filesData.length === 0) {
									setChosenImage(-1);
									const data = { ...formData };
									data.file_links = [];
									return setFormData(data);
								}

								                                          // delete files
								if (filesData.length < formData.file_links.length) {
									const index = formData.file_links.findIndex(
										(value: any) => {
											let res = true;
											filesData.forEach((item) => {
												if (
													item.file.name === value.file.name ||
													item.file.name === value.file ||
													item.file === value.file
													)
													res = false;
											});
											return res;
										}
										);
									const result = [...formData.file_links];
									result.splice(index, 1);
									setChosenImage(result.length - 1);
									const data = { ...formData };
									data.file_links = result;
									return setFormData(data);
								}

								                                          // add files
								const arr = filesData;
								arr.splice(0, formData.file_links.length);
								const result = [...formData.file_links, ...arr];
								setChosenImage(result.length - 1);
								const data = { ...formData };
								data.file_links = result;
								setFileUrl('');
								setFormData(data);
							}}
							setFileUrl={(fileUrl) => {
								setFileUrl(fileUrl);
							}}
							setChosenImage={setChosenImage}
							/>
						</Row>
						</>
						)}
						
						{element.items.map((item) => {
							switch(item.field_type_id) { 
								case 1: {
									//Select
									return 	<Row justify="start">
												<Col span={4}>{t(item.name)}</Col>
												<Col xs={{span: 8}} lg={{span: 5}}>
													<InputFieldTitle
													title=""
													required={false}
													errorMessage={
														dinamicFormDataError[item.key]
														? 'This fiels is required'
														: ''
													}
													>
													<Select
													showSearch
                                                    optionFilterProp="children"
													size="middle"
													value={dinamicFormData[item.key]}
													placeholder={t(item.name)}
													onChange={(value) => handleChange(item.key, value)}
													>
													{item?.field_select_options?.map(
														(sortOption: OptionsProps) => (
															<Option
															key={sortOption.name}
															value={`${sortOption.name}`}
															>
															{t(sortOption.name)}
															</Option>
															)
														)}
													</Select>
													</InputFieldTitle>
												</Col>
											</Row>
									break; 
								} 
								case 2: { 
									//Switch 
									break; 
								}
								case 3: {
									//Input text
									if(item.is_language && item.is_multiple) {
										return <MultiLanguageInput 
												element={item} 
												dinamicFormData={dinamicFormData} 
												setDinamicFormData={setDinamicFormData}
												dinamicFormDataError={dinamicFormDataError}
											/>
									} else {
										return 	<Row justify="start">
													<Col span={4}>{t(item.name)}</Col>
													<Col xs={{span: 8}} lg={{span: 5}}>
														<InputField
														value={dinamicFormData[item.key]}
														placeholder={t(item.name)}
														required={false}
														errorMessage={
			                                                dinamicFormDataError[item.key]
			                                                  ? 'This fiels is required'
			                                                  : ''
			                                            }
														onChange={(e) => handleChange(item.key, e.target.value)}
														/>
													</Col>
												</Row>
									}
									break;
								}
								case 4: {
									//Input number
									break;
								}
								case 5: {
									//Datetime
									break;
								} 
								default: {
									break; 
								} 
							}
						})}
						<Row style={{position: 'absolute', bottom: '30px', right: '30px'}}>
							{activeKey !== 'elabel'?<div className={styles.saveButton} onClick={handleSubmit}>{activeKey === 'main'? t('Next Step') : t('Save') }</div>:''}
						</Row>
					</form>
					</Tabs.TabPane>
				}
			})
			:''}
			<Tabs.TabPane tab="Allergens" key="allergen">
				<form className="itemFormFieldDinamicForm" style={{background: '#F9F9F9', borderRadius: '4px', padding: '25px'}}>
					<Popup
					status="success"
					className={popup === 'success' ? styles.active : ''}
					/>
					<Popup
					status="error"
					className={popup === 'error' ? styles.active : ''}
					/>
					<Popup
					status="gtinError"
					className={popup === 'gtinError' ? styles.active : ''}
					/>
					<Allergen
						itemAllergens={itemAllergens}
						setItemAllergens={setItemAllergens}
						item={dataForm}
						dinamicFormDataError={dinamicFormDataError}
					/>
					<Row style={{position: 'absolute', bottom: '30px', right: '30px'}}>
					{activeKey !== 'elabel'?<div className={styles.saveButton} onClick={handleSubmit}>{activeKey === 'main'?'Next Step':'Save'}</div>:''}
					</Row>
				</form>
			</Tabs.TabPane>
			<Tabs.TabPane tab="Nutritional" key="nutritional" >
				<form className="itemFormFieldDinamicForm" style={{background: '#F9F9F9', borderRadius: '4px', padding: '25px'}}>
					<Popup
					status="success"
					className={popup === 'success' ? styles.active : ''}
					/>
					<Popup
					status="error"
					className={popup === 'error' ? styles.active : ''}
					/>
					<Popup
					status="gtinError"
					className={popup === 'gtinError' ? styles.active : ''}
					/>
					<Nutritional 
					itemNutritionals={itemNutritionals}
					setItemNutritionals={setItemNutritionals}
					item={dataForm}
					/>
					<Row style={{position: 'absolute', bottom: '30px', right: '30px'}}>
					{activeKey !== 'elabel'?<div className={styles.saveButton} onClick={handleSubmit}>{activeKey === 'main'?'Next Step':'Save'}</div>:''}
					</Row>
				</form>
			</Tabs.TabPane>
			<Tabs.TabPane tab="e-Label" key="elabel">
				<form className="itemFormFieldDinamicForm" style={{background: '#F9F9F9', borderRadius: '4px', padding: '25px'}}>
					<Popup
					status="success"
					className={popup === 'success' ? styles.active : ''}
					/>
					<Popup
					status="error"
					className={popup === 'error' ? styles.active : ''}
					/>
					<Popup
					status="gtinError"
					className={popup === 'gtinError' ? styles.active : ''}
					/>
					{elabelPath !== undefined && elabelPath !== null && elabelPath !== ''?
					<Col>
						<Row justify="center" className={styles.qrCodeReady}><span>Your <span style={{fontWeight: 700}}> QR Code </span> is ready!</span></Row>
						<div id="elabelImg">
							<Row justify="center">
								<QRCode value={elabelPath} icon={QaCodeIcon} className={styles.qrCodeImg}/>
							</Row>
							<Row justify="center" style={{paddingTop: '20px'}}>
								<div className={styles.saveButton}>
									<Button onClick={downloadQRCode} >
										Download QR Code
									</Button>
								</div>
							</Row>
						</div>
					</Col>
					:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					}
				</form>
			</Tabs.TabPane>
		</Tabs>

		</Col>
	</Row>
	</>
	)
}
