import { Col, Row, Button } from 'antd';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import template1 from "../../common/img/vindix/catalogue/template_1_preview_empty.png"
import template2 from "../../common/img/vindix/catalogue/COVER2.png"
import template3 from "../../common/img/vindix/catalogue/COVER3.png"
import styles from './templateSelector.module.scss';
import { useTranslation } from 'react-i18next';

import { EyeOutlined, BlockOutlined, CloudUploadOutlined, LogoutOutlined, UserOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons';

export const TemplateSelector = () => {
    const navigate = useNavigate();

    const handleCatalogueClick = (catalogue: string) => {
        navigate('/catalogue/customize/' + catalogue)
    }

    const { t } = useTranslation();

    return (
        <>
            <Row style={{background: '#fff', padding: '20px 0'}}>
                <Col span={24}>
                    <h1 className={styles.formTitle}>1. {t('Select the template')}</h1>
                    <p style={{marginBottom: '40px'}}>{t('We will generate your new catalogue based on this template look and feel')}</p>
                </Col>
                <Col span={8} className={styles.selectorEntry} onClick={() => handleCatalogueClick('template1')}>
                    <img src={template1} style={{width: '261px'}} />
                    <br/>
                    <Row style={{textAlign: 'left', paddingLeft: 'calc(50% - 130px)', verticalAlign: 'middle'}}>
                        <Button style={{ background: '#8F0A0E', color: '#FFF', fontSize: '18px', padding: '1px 8px', height: 'auto', verticalAlign: 'middle'}}>
                            <EyeOutlined />
                        </Button>
                    </Row>
                </Col>
                <Col span={8} className={styles.selectorEntry} onClick={() => handleCatalogueClick('template2')}>
                    <img src={template2} style={{width: '261px'}} />
                    <br/>
                    <Row style={{textAlign: 'left', paddingLeft: 'calc(50% - 130px)', verticalAlign: 'middle'}}>
                        <Button style={{ background: '#8F0A0E', color: '#FFF', fontSize: '18px', padding: '1px 8px', height: 'auto', verticalAlign: 'middle'}}>
                            <EyeOutlined />
                        </Button>
                    </Row>
                </Col>
                <Col span={8} className={styles.selectorEntry} onClick={() => handleCatalogueClick('template3')}>
                    <img src={template3} style={{width: '261px'}} />
                    <br/>
                    <Row style={{textAlign: 'left', paddingLeft: 'calc(50% - 130px)', verticalAlign: 'middle'}}>
                        <Button style={{ background: '#8F0A0E', color: '#FFF', fontSize: '18px', padding: '1px 8px', height: 'auto', verticalAlign: 'middle'}}>
                            <EyeOutlined />
                        </Button>
                    </Row>
                </Col>
            </Row>
        </>
    );
};