import { createSlice } from "@reduxjs/toolkit";
import {
  ItemsRejectedAction,
  DraftItemsState,
  GetDraftItemFulfilledAction,
  GetDraftItemsFulfilledAction,
  DeletionActivationDeactivationDraftItemsAction,
  DraftItemFulfilledAction,
  DraftItemsValidationErrorAction,
} from "../../../modules/items/types";

const initialState: DraftItemsState = {
  draftItems: null,
  draftItem: null,
  totalItems: 0,
  totalValidItems: 0,
  draftItemsForActivationDeletion: [],
  isLoading: false,
  validationError: null,
  error: null,
};

export const draftItemsSlice = createSlice({
  name: "draftItemsSlice",
  initialState,
  reducers: {
    getAllDraftItemsPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },
    getAllDraftItemsFulfilled: (
      state: DraftItemsState,
      action: GetDraftItemsFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftItems = action.payload.draftItems;
      state.totalItems = action.payload.totalItems;
      state.totalValidItems = action.payload.totalValidItems;
    },
    getAllDraftItemsRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDraftItemPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },
    getDraftItemFulfilled: (
      state: DraftItemsState,
      action: GetDraftItemFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftItem = action.payload.draftItem;
    },
    getDraftItemRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createDraftItemPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },
    createDraftItemFulfilled: (
      state: DraftItemsState,
      action: DraftItemFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftItem = action.payload;
    },
    createDraftItemRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editDraftItemPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },
    editDraftItemFulfilled: (
      state: DraftItemsState,
      action: DraftItemFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftItem = action.payload;
    },
    editDraftItemRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusDraftItemPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },

    changeStatusDraftItemFulfilled: (state: DraftItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusDraftItemRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteDraftItemsPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },

    deleteDraftItemsFulfilled: (state: DraftItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteDraftItemsRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    validateDraftItemPending: (state: DraftItemsState) => {
      state.isLoading = true;
    },

    validateDraftItemFulfilled: (
      state: DraftItemsState,
      action: DraftItemFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftItem = action.payload;
    },
    validateDraftItemRejected: (
      state: DraftItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setDraftItemsForActivationDeletion: (
      state: DraftItemsState,
      action: DeletionActivationDeactivationDraftItemsAction
    ) => {
      state.draftItemsForActivationDeletion = action.payload;
    },
    setValidationError: (
      state: DraftItemsState,
      action: DraftItemsValidationErrorAction
    ) => {
      state.isLoading = false;
      state.validationError = action.payload;
    },
    clearDraftItemsForActivationDeletion: (state: DraftItemsState) => {
      state.draftItemsForActivationDeletion = [];
    },
    cleareDraftItemStore: (state: DraftItemsState) => {
      state.draftItem = null;
    },
    cleareDraftItemsStore: (state: DraftItemsState) => {
      state.draftItems = null;
      state.draftItem = null;
      state.totalItems = 0;
      state.draftItemsForActivationDeletion = [];
      state.isLoading = false;
      state.error = null;
    },
    cleareDraftItemsValidationError: (state: DraftItemsState) => {
      state.isLoading = false;
      state.validationError = null;
    },
  },
});
