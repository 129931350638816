import { FC } from "react";
import AppLayout from "../../common/components/AppLayout";
import styles from "./uploadPage.module.scss";
import FileUploadArea from "../components/FileUploadArea";
import { CloudUploadOutlined } from '@ant-design/icons';

const UploadPage: FC = (): JSX.Element => {
  return (
    <AppLayout
      title="Upload"
      headerLogo={<CloudUploadOutlined />}
    >
      <div className={styles.contentWrapper}>
        <FileUploadArea />
      </div>
    </AppLayout>
  );
};

export default UploadPage;
