import { FC, useEffect, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import AppLayout from "../../common/components/AppLayout/vindix";
import { Elabel } from "../components";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { convertToItemFormFieldsWithSize } from '../../itemForm/utils/convertToItemFormFieldsWithSize';
import { ItemFormTabIds } from '../../itemForm/constants';
import { getElabelItemFormFieldsByCompanyId } from '../../../api/itemForm';
import { QrcodeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ElabelPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
    const { activeItem } = useAppSelector((state) => state.activeItems);
    const { draftItem } = useAppSelector((state) => state.draftItems);
    const [isMainFieldsFull, setIsMainFieldsFull] = useState<any>(false);
    const [isLastTab, setIsLastTab] = useState<boolean>(false);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);
    const [activeTabs, setActiveTabs] = useState<any>([]);
    const { userCompanyId } = useAppSelector((state) => state.companies);
    const { setModalActiveTab } = useActions();
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
    );
    const element = itemFormFields?.find(
            (elem) => elem.name === selectedTab
        );
    const { t } = useTranslation();
    useEffect(() => {
        if (!itemFormFields) return;
        let array: any = [];
        itemFormFields.forEach((elem: any) => {
            if (
                elem.name === 'main' ||
                elem.name === 'digital assets' ||
                elem?.items?.length > 0
            ) {
                array.push(elem);
            }
        });
        setActiveTabs(array);
    }, [itemFormFields]);

    useEffect(() => {
        if (itemFormFields) {
            dispatch(setModalActiveTab(itemFormFields[0].name));
        }
    }, [itemFormFields]);

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getElabelItemFormFieldsByCompanyId(String(userCompanyId)));
        }
    }, [dispatch, userCompanyId]);


  return (
    <AppLayout
      title={t('Generate e-Label')}
      headerLogo={<QrcodeOutlined />}
    >
      <Elabel 
        isDraft={activeItem ? false : true}
        element={element}
        dataForm={activeItem ? activeItem : draftItem}
        setIsMainFieldsFull={setIsMainFieldsFull}
        isLastTab={isLastTab}
      />
    </AppLayout>
  );
};

export default ElabelPage;