import AppLayout from '../common/components/AppLayout';
import { ReactComponent as QaCodeIcon } from '../common/icons/digital_assets_icon.svg';
import styles from './index.module.scss';
import { Autocomplete } from './components/Autocomplete';
import { Button, DatePicker, Input, Select } from 'antd';
import InputFieldTitle from '../common/components/InputFieldTitle';
import './form.global.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { SelectData } from '../common/types';
import { Option } from 'antd/lib/mentions';
import moment, { MomentInput } from 'moment';
import { GET_GTIN, GET_QR_LINK_DYNAMIC, POST_QR_CODE } from '../../api/qrcode';
import { notification } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAppSelector } from '../../hooks/useAppSelector';

interface FormData {
  data: any;
  gtin: string;
  type: string;
  url: string;
  lotNumber: string;
  serialNumber: string;
}

const initialFormData: FormData = {
  gtin: '',
  type: '',
  url: '',
  lotNumber: '',
  serialNumber: '',
  data: null,
};

const initialError: errors = {
  url: '',
  gtin: '',
  type: '',
};

interface errors {
  url: string;
  gtin: string;
  type: string;
}

interface fieldType {
    value: string;
    name: string;
    type: string;
}

interface fieldContent {
    value: string;
    type: string;
    content: string;
}

const PublishPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [linkData, setLinkData] = useState<Array<fieldContent>>([]);
  const [selectedField, setSelectedField] = useState<fieldContent>(null);
  const selectArray = [
    { value: 'gs1qrcode', name: 'QR code with GS1 Digital Link URI' },
    { value: 'datamatrix', name: 'GS1 Data Matrix with element string' },
  ];
  const digitalLinkFields = [
    { value: '00', name: 'SSCC', type: 'text' },
    // { value: '01', name: 'GTIN', type: 'text' },
    { value: '02', name: 'Content', type: 'text' },
    { value: '10', name: 'Batch/Lot', type: 'text' },
    { value: '11', name: 'Prod date', type: 'date' },
    { value: '12', name: 'Due date', type: 'date' },
    { value: '13', name: 'Pack date', type: 'date' },
    { value: '15', name: 'Best before', type: 'date' },
    { value: '17', name: 'Expiration date', type: 'date' },
    { value: '20', name: 'Variant', type: 'text' },
    { value: '21', name: 'Serial', type: 'text' },
    { value: '22', name: 'HIBCC', type: 'text' },
    { value: '240', name: 'Additional ID', type: 'text' },
    { value: '241', name: 'CUST. PART No.', type: 'text' },
    { value: '250', name: 'Secondary serial', type: 'text' },
    { value: '251', name: 'Ref. to source', type: 'text' },
    { value: '30', name: 'Var. count', type: 'text' },
    { value: '310n', name: 'Net weight', type: 'text' },
    { value: '311n', name: 'Length', type: 'text' },
    { value: '312n', name: 'Width', type: 'text' },
    { value: '313n', name: 'Depth', type: 'text' },
    { value: '315n', name: 'Net volume', type: 'text' },
  ];
  const [errors, setErrors] = useState(initialError);
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gtinOptions, setGtinOptions] = useState<any>();
  const { userCompanyType } = useAppSelector((state) => state.companies);

  useEffect(() => {
    GET_GTIN(formData.gtin).then((res) => {
      setGtinOptions(res.data.data);
    });
  }, [formData.gtin]);

  function isValidUrl(url: string): boolean {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: e.target.value,
    }));

    if (name === 'url') {
      if (!isValidUrl(e.target.value) && e.target.value) {
        setErrors({ ...errors, url: 'Please, enter a valid url' });
      }
    }
  };

  useEffect(() => {
    const arrayErrors = { ...errors };

    if (formData.gtin.length >= 14 && formData.gtin) {
      arrayErrors.gtin = '';
    }
    if (formData.url && isValidUrl(formData.url)) {
      arrayErrors.url = '';
    }
    if (formData.type) {
      arrayErrors.type = '';
    }
    setErrors(arrayErrors);
  }, [formData]);

  const generateQrCode = () => {
    let isValid = true;
    const arrayErrors = { ...errors };
    if (formData.gtin.length < 8) {
      isValid = false;
      arrayErrors.gtin = 'The minimum number of characters must be 8';
    }
    if (!formData.gtin) {
      isValid = false;
      arrayErrors.gtin = 'GTIN field is required';
    }
    if (!formData.type) {
      isValid = false;
      arrayErrors.type = 'Type field is required';
    }
    if (!formData.url) {
      arrayErrors.url = 'URL field is required';
    }
    if (errors.url) {
      isValid = false;
    }
    setErrors(arrayErrors);
    if (isValid) {
      setIsLoading(true);
      let date = formData.data ? moment(formData.data).format('YYDDMM') : '';
      // GET_QR_LINK(
    //     formData.type,
    //     formData.url,
    //     formData.gtin,
    //     formData.lotNumber,
    //     formData.serialNumber,
    //     date
    //   )
      GET_QR_LINK_DYNAMIC(
        formData.type,
        formData.url,
        formData.gtin,
        [{value: '01', content: formData.gtin, type: 'text'}, ...linkData]
      )
        .then((res) => {
          setLink(res.data.link);
        })
        .catch((err) => {
          notification.error({
            message: err.message,
            description: '',
            duration: 6,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const saveQrCode = () => {
    setIsLoading(true);
    const data = {
      file: link,
      gtin: formData.gtin,
    };

    POST_QR_CODE(data)
      .then((res) => {
        notification.success({
          message: 'Data saved successfully',
          description: '',
          duration: 6,
        });
      })
      .catch((err) => {
        notification.error({
          message: err.message,
          description: '',
          duration: 6,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addField = (field) => {

  }

  const selectField = (fieldKey) => {
    let field = digitalLinkFields.find(o => o.value === fieldKey)
    setSelectedField({value: field.value, content: '', type: field.type})
  }
console.log(linkData)

  return (
    <AppLayout
      headerLogo={<QaCodeIcon />}
      title="Digital Links"
    >
      <div />
      <div className={styles.wrapper}>
        <div style={{ padding: '0 20px' }}>
          <div className={styles.fieldWrapper}>
            <InputFieldTitle
              title=""
              required={false}
              errorMessage={errors.gtin}
            >
              <Autocomplete
                setGtinOptions={setGtinOptions}
                options={gtinOptions}
                disabled={link ? true : false}
                value={formData.gtin}
                onChange={(value: any) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    gtin: value,
                  }))
                }
                placeholder="Search GTIN"
                maxLength={14}
              />
            </InputFieldTitle>
          </div>
          <div className={styles.fieldWrapper}>
            <InputFieldTitle
              title=""
              required={false}
              errorMessage={errors.type}
            >
              <Select
                className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                size="middle"
                value={formData.type}
                onChange={(value) => setFormData({ ...formData, type: value })}
              >
                {selectArray?.map((sortOption: SelectData) => (
                  <Option key={sortOption.name} value={`${sortOption.value}`}>
                    {sortOption.name}
                  </Option>
                ))}
              </Select>
            </InputFieldTitle>
          </div>

          <div className={styles.fieldWrapper}>
            <InputFieldTitle
              title="URI stem"
              required={false}
              errorMessage={errors.url}
            >
              <Input
                className={styles.selectField}
                value={formData.url}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, 'url')
                }
              />
            </InputFieldTitle>
          </div>
        </div>

        <div className={styles.additionalWrapper}>
          <h3 style={{marginTop: '0'}}>Additional fields</h3>
            { linkData.map((link, key) => {
                return <div className={styles.fieldWrapper}>
                <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
              >
                {/* Dropdown with all the fields in the digital link */}
                <Select
                  className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                  size="middle"
                  value={link.value}
                  disabled={true}
                >
                  {digitalLinkFields?.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                      {sortOption.name} ({sortOption.value})
                    </Option>
                  ))}
                </Select>
              </InputFieldTitle>
              {/* Show input type text or date based on your selection */}
              { link === null || link.type === 'text' ?
              <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
              >
                <Input
                  className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                  size="middle"
                  value={link.content}
                  type="text"
                  disabled={true}
                />
              </InputFieldTitle> :
              <InputFieldTitle
              title=""
              required={false}
              errorMessage={errors.type}
            >
              <Input
                className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                size="middle"
                value={link.content}
                type="date"
                disabled={true}
              />
            </InputFieldTitle>
              }</div>
            })}

            <div className={styles.fieldWrapper}>
                <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
                >
                {/* Dropdown with all the fields in the digital link */}
                <Select
                    className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                    size="middle"
                    value={selectedField}
                    onChange={(value) => selectField(value)}
                >
                    {digitalLinkFields?.map((sortOption: SelectData) => (
                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                        {sortOption.name} ({sortOption.value})
                    </Option>
                    ))}
                </Select>
                </InputFieldTitle>
                {/* Show input type text or date based on your selection */}
                { selectedField === null || selectedField.type === 'text' ?
                <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
                >
                <Input
                    className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                    size="middle"
                    value={selectedField?.content}
                    type="text"
                    onChange={(value) => setSelectedField({ ...selectedField, content: value.target.value })}
                />
                </InputFieldTitle> :
                <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
            >
                <Input
                className={`${styles.selectField} ${styles.itemFormFieldForm}`}
                size="middle"
                value={selectedField?.content}
                type="date"
                onChange={(value) => setSelectedField({ ...selectedField, content: value.target.value })}
                />
            </InputFieldTitle>
                }
                <InputFieldTitle
                title=""
                required={false}
                errorMessage={errors.type}
                style={{width: 'auto'}}
                >
                <Button
                    className={`${styles.selectFieldButton} ${styles.itemFormFieldForm}`}
                    value={formData.type}
                    onClick={(value) => setLinkData([...linkData, selectedField])}
                >Add</Button>
                </InputFieldTitle>
            </div>
          {/* <div className={styles.fieldWrapper}>
            <InputFieldTitle
              title="Expiration Date"
              required={false}
              style={{ width: '305px' }}
            >
              <DatePicker
                className={styles.selectField}
                value={formData.data ? dayjs(formData.data) : null}
                format={'DD/MM/YYYY'}
                onChange={(value) => setFormData({ ...formData, data: value })}
              />
            </InputFieldTitle>
          </div>
          <div className={styles.fieldWrapper}>
            <InputFieldTitle title="Lot Number" required={false}>
              <Input
                className={styles.selectField}
                value={formData.lotNumber}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, 'lotNumber')
                }
              />
            </InputFieldTitle>
          </div>
          <div className={styles.fieldWrapper}>
            <InputFieldTitle title="Serial Number" required={false}>
              <Input
                className={styles.selectField}
                value={formData.serialNumber}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, 'serialNumber')
                }
              />
            </InputFieldTitle>
          </div> */}
        </div>
        <div className={styles.qr}>
          <img src={link} />
        </div>
        <div className={styles.saveBtn}>
          <Button
            loading={isLoading}
            className={userCompanyType === 'wine'?styles.buttonWine:styles.button}
            onClick={generateQrCode}
            style={{marginRight: '20px'}}
          >
            {'Generate'}
          </Button>
          {link?
          <Button
            loading={isLoading}
            className={userCompanyType === 'wine'?styles.buttonWine:styles.button}
            onClick={saveQrCode}
          >
            {'Save'}
          </Button>
          :''}
        </div>
      </div>
    </AppLayout>
  );
};

export default PublishPage;
