import { FC, useState } from 'react';
import "../../styles/colors.scss";
import styles from './sideBar.module.scss';

import { ReactComponent as ProductsLogo } from '../../icons/products.svg';
import { ReactComponent as ItemsLogo } from '../../icons/items.svg';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import { ReactComponent as ItemFormLogo } from '../../icons/itemForm.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { signOut } from '../../../../api/auth';
import { NavLink, useLocation } from 'react-router-dom';
import { RoutesNames } from '../../../../routes';
import AppModal from '../AppModal';
import UserCompaniesSelect from '../../../companies/components/UserCompaniesSelect';
import { useActions } from '../../../../hooks/useActions';
import { QrcodeOutlined, BlockOutlined, CloudUploadOutlined, LogoutOutlined, UserOutlined, UploadOutlined, GoldOutlined } from '@ant-design/icons';
import { ReactComponent as QaCodeIcon } from '../../icons/digital_assets_icon.svg';
import dashboardIcon from '../../icons/dashboard.svg';

const SideBar: FC = (): JSX.Element => {
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [isSignOutModalVisible, setIsSignOutModalVisible] = useState(false);
    const {
        clearFilter,
        clearFilterControl,
        clearAdditionalFilter,
        clearAdditionalFilterControl,
    } = useActions();
    const location = useLocation()

    return (
        <>
            <aside className={styles.sidebar}>
                <div>
                    {/* <header className={styles.header}>
                        <div className={styles.headerTitle}>
                            <div className={styles.headerTitleTop}>
                                POWERED BY
                            </div>
                            <div className={styles.headerTitleBottom}>
                                <Logo className={styles.headerLogo} />
                                <span className={styles.titleBottomName}>
                                    TrustedSource
                                </span>
                            </div>
                        </div>
                    </header> */}
                    <nav className={styles.navbar}>
                        <NavLink to={RoutesNames.DASHBOARD}>
                            <div className="nav__link">
                                <img src={dashboardIcon} className={styles.outlinedIcon} />
                                Dashboard
                            </div>
                        </NavLink>
                        {!user?.is_admin && (
                            <NavLink to={RoutesNames.UPLOAD}>
                                <div className="nav__link">
                                    <UploadOutlined className={styles.outlinedIcon} />
                                    Upload
                                </div>
                            </NavLink>
                        )}
                        {user?.is_admin && (
                            <NavLink
                                to={RoutesNames.COMPANIES}
                                onClick={() => {
                                    clearFilter();
                                    clearFilterControl();
                                    clearAdditionalFilter();
                                    clearAdditionalFilterControl();
                                }}
                            >
                                <div className="nav__link">
                                    <ItemsLogo className="icon" />
                                    Manage Companies
                                </div>
                            </NavLink>
                        )}
                        {user?.is_admin && (
                            <NavLink
                                to={RoutesNames.USERS}
                                onClick={() => {
                                    clearAdditionalFilter();
                                    clearAdditionalFilterControl();
                                }}
                            >
                                <div className="nav__link">
                                    <ProductsLogo className="icon" />
                                    Manage Users
                                </div>
                            </NavLink>
                        )}
                        {user?.is_admin && (
                            <NavLink
                                to={RoutesNames.ITEM_FORM}
                                onClick={() => {
                                    clearFilter();
                                    clearFilterControl();
                                    clearAdditionalFilter();
                                    clearAdditionalFilterControl();
                                }}
                            >
                                <div className="nav__link">
                                    <ItemFormLogo className="iconItemForm" />
                                    Data Model
                                </div>
                            </NavLink>
                        )}
                        <NavLink
                            to={RoutesNames.ITEMS}
                            onClick={() => {
                                clearFilter();
                                clearFilterControl();
                                clearAdditionalFilter();
                                clearAdditionalFilterControl();
                            }}
                        >
                            <div className="nav__link">
                                <BlockOutlined className={styles.outlinedIcon }/>
                                Trade items
                            </div>
                        </NavLink>
                        <NavLink
                            to={RoutesNames.PRODUCTS}
                            onClick={() => {
                                clearFilter();
                                clearFilterControl();
                                clearAdditionalFilter();
                                clearAdditionalFilterControl();
                            }}
                        >
                            <div className="nav__link">
                                <GoldOutlined className={styles.outlinedIcon} />
                                Products
                            </div>
                        </NavLink>
                        {!user?.is_admin && (<NavLink to="/digital_links">
                            <div className="nav__link">
                                <div className={styles.outlinedIcon}><QaCodeIcon /></div>
                                Digital Links
                            </div>
                        </NavLink>)}
                        {user?.is_admin || (
                            <NavLink to="/publish">
                                <div className="nav__link">
                                    <CloudUploadOutlined className={styles.outlinedIcon} />
                                    Publish
                                </div>
                            </NavLink>
                        )}
                    </nav>
                </div>
                <footer className={styles.footer}>
                    <button
                        className={styles.logout}
                        onClick={() => {
                            setIsSignOutModalVisible(true);
                        }}
                    >
                        <LogoutOutlined className={styles.outlinedIcon} />
                        <span className={styles.logoutTitle}>Logout</span>
                    </button>
                    <div className={styles.user}>
                        {!user?.avatar ? (
                            <UserOutlined className={styles.outlinedIcon} />
                        ) : (
                            <img
                                className={styles.iconAvatar}
                                src={user?.avatar}
                                alt="user avatar"
                            />
                        )}
                        <span className={styles.userTitle}>{user?.email}</span>
                    </div>
                    {!user?.is_admin && <div className={styles.userCompaniesSelect}><UserCompaniesSelect /></div>}
                </footer>
            </aside>

            <AppModal
                visible={isSignOutModalVisible}
                handleOk={() => {
                    dispatch(signOut());
                    setIsSignOutModalVisible(false);
                }}
                handleCancel={() => {
                    setIsSignOutModalVisible(false);
                }}
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want logout?"
                onCancel={() => setIsSignOutModalVisible(false)}
            />
        </>
    );
};

export default SideBar;
