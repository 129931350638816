import { createSlice } from '@reduxjs/toolkit';
import { ModalTabs } from '../../../modules/common/constants/modalTabs';
import {
    SetModalActiveTabAction,
    SuccesActionParams,
    SucessfulStatus,
} from '../../../modules/common/types';
import {
    CompanyChangeStatusAction,
    CompanyChangeStatusProps,
} from '../../../modules/companies/types';
import {
    ItemFormFieldChangeStatusAction,
    ItemFormFieldChangeStatusProps,
} from '../../../modules/itemForm/types';
import {
    UserChangeStatusAction,
    UserChangeStatusProps,
} from '../../../modules/users/types';

interface ModalHandlerAction {
    type: string;
    payload: boolean;
}

interface ModalsState {
    companySuccessfulStatus: SucessfulStatus | null;
    isChangeCompanyDeletionActivationActions: boolean;
    isOpenCompanyAreYouSureModal: boolean;
    isOpenCompanyChangeStatusModal: CompanyChangeStatusProps;
    isOpenCompaniesFormModal: boolean;
    isConfirmCompanyDeletionModal: boolean;

    userSuccessfulStatus: SucessfulStatus | null;
    isChangeUserDeletionActivationActions: boolean;
    isOpenUserAreYouSureModal: boolean;
    isOpenUserChangeStatusModal: UserChangeStatusProps;
    isOpenUsersFormModal: boolean;
    isConfirmUserDeletionModal: boolean;

    draftItemsFormModalActiveTab: ModalTabs | null;
    isChangeItemsDeletionActivationActions: boolean;
    isOpenDraftItemAreYouSureModal: boolean;
    isOpenDraftItemChangeStatusModal: boolean;
    isOpenDraftItemsFormModal: boolean;
    isConfirmDraftItemDeletionModal: boolean;
    isOpenActiveItemAreYouSureModal: boolean;
    isOpenActiveItemChangeStatusModal: boolean;
    isConfirmActiveItemDeletionModal: boolean;

    draftProductSuccessfulStatus: SucessfulStatus | null;
    isChangeProductsDeletionActivationActions: boolean;
    isOpenDraftProductAreYouSureModal: boolean;
    isOpenDraftProductChangeStatusModal: boolean;
    isOpenProductFormForView: boolean;
    isOpenDraftProductsFormModal: boolean;
    isConfirmDraftProductDeletionModal: boolean;
    isOpenActiveProductAreYouSureModal: boolean;
    isOpenActiveProductChangeStatusModal: boolean;
    isConfirmActiveProductDeletionModal: boolean;

    itemFormFieldSuccessfulStatus: SucessfulStatus | null;
    isChangeItemFormFieldDeletionActivationActions: boolean;
    isOpenItemFormFieldAreYouSureModal: boolean;
    isOpenItemFormFieldChangeStatusModal: ItemFormFieldChangeStatusProps;
    isOpenItemFormFieldFormModal: boolean;
    isOpenCreateTabModal: boolean;
    isConfirmItemFormFieldDeletionModal: boolean;
    isOpenAreYouSureDeleteTabModal: boolean;
    isOpenCompanyAddFieldsModal: boolean;
}

const initialState: ModalsState = {
    companySuccessfulStatus: null,
    isChangeCompanyDeletionActivationActions: false,
    isOpenCompanyAreYouSureModal: false,
    isOpenCompanyChangeStatusModal: {
        isOpen: false,
        changedStatusCompany: null,
    },
    isOpenCompaniesFormModal: false,
    isConfirmCompanyDeletionModal: false,

    userSuccessfulStatus: null,
    isChangeUserDeletionActivationActions: false,
    isOpenUserAreYouSureModal: false,
    isOpenUserChangeStatusModal: {
        isOpen: false,
        changedStatusUser: null,
    },
    isOpenUsersFormModal: false,
    isConfirmUserDeletionModal: false,

    draftItemsFormModalActiveTab: null,
    isChangeItemsDeletionActivationActions: false,
    isOpenDraftItemAreYouSureModal: false,
    isOpenDraftItemChangeStatusModal: false,
    isOpenDraftItemsFormModal: false,
    isConfirmDraftItemDeletionModal: false,
    isOpenActiveItemAreYouSureModal: false,
    isOpenActiveItemChangeStatusModal: false,
    isConfirmActiveItemDeletionModal: false,

    draftProductSuccessfulStatus: null,
    isChangeProductsDeletionActivationActions: false,
    isOpenDraftProductAreYouSureModal: false,
    isOpenDraftProductChangeStatusModal: false,
    isOpenProductFormForView: false,
    isOpenDraftProductsFormModal: false,
    isConfirmDraftProductDeletionModal: false,
    isOpenActiveProductAreYouSureModal: false,
    isOpenActiveProductChangeStatusModal: false,
    isConfirmActiveProductDeletionModal: false,

    itemFormFieldSuccessfulStatus: null,
    isChangeItemFormFieldDeletionActivationActions: false,
    isOpenItemFormFieldAreYouSureModal: false,
    isOpenItemFormFieldChangeStatusModal: {
        isOpen: false,
        changedStatusItemFormField: null,
    },
    isOpenItemFormFieldFormModal: false,
    isOpenCreateTabModal: false,
    isOpenAreYouSureDeleteTabModal: false,
    isConfirmItemFormFieldDeletionModal: false,
    isOpenCompanyAddFieldsModal: false,
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setCompanySuccessfulStatus: (
            state: ModalsState,
            action: SuccesActionParams
        ) => {
            state.companySuccessfulStatus = action.payload;
        },
        setIsChangeCompanyDeletionActivationActions: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isChangeCompanyDeletionActivationActions = action.payload;
        },
        setIsOpenCompanyAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenCompanyAreYouSureModal = action.payload;
        },
        setIsOpenCompanyChangeStatusModal: (
            state: ModalsState,
            action: CompanyChangeStatusAction
        ) => {
            state.isOpenCompanyChangeStatusModal = action.payload;
        },
        setIsOpenCompaniesFormModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenCompaniesFormModal = action.payload;
        },
        setIsConfirmCompanyDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmCompanyDeletionModal = action.payload;
        },
        setUserSuccessfulStatus: (
            state: ModalsState,
            action: SuccesActionParams
        ) => {
            state.userSuccessfulStatus = action.payload;
        },
        setIsChangeUserDeletionActivationActions: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isChangeUserDeletionActivationActions = action.payload;
        },
        setIsOpenUserAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenUserAreYouSureModal = action.payload;
        },
        setIsOpenUserChangeStatusModal: (
            state: ModalsState,
            action: UserChangeStatusAction
        ) => {
            state.isOpenUserChangeStatusModal = action.payload;
        },
        setIsOpenUsersFormModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenUsersFormModal = action.payload;
        },
        setIsConfirmUserDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmUserDeletionModal = action.payload;
        },
        setIsOpenDraftItemAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftItemAreYouSureModal = action.payload;
        },
        setIsOpenDraftItemChangeStatusModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftItemChangeStatusModal = action.payload;
        },
        setIsOpenDraftItemsFormModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftItemsFormModal = action.payload;
        },
        setIsConfirmDraftItemDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmDraftItemDeletionModal = action.payload;
        },
        setIsOpenActiveItemAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenActiveItemAreYouSureModal = action.payload;
        },
        setIsOpenActiveItemChangeStatusModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenActiveItemChangeStatusModal = action.payload;
        },
        setIsConfirmActiveItemDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmActiveItemDeletionModal = action.payload;
        },
        setIsChangeItemsDeletionActivationActions: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isChangeItemsDeletionActivationActions = action.payload;
        },
        setModalActiveTab: (state: ModalsState, action: any) => {
            state.draftItemsFormModalActiveTab = action.payload;
        },
        clearModalActiveTab: (state: ModalsState) => {
            state.draftItemsFormModalActiveTab = null;
        },
        setIsOpenDraftProductAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftProductAreYouSureModal = action.payload;
        },
        setIsOpenDraftProductChangeStatusModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftProductChangeStatusModal = action.payload;
        },
        setIsOpenProductFormForView: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenProductFormForView = action.payload;
        },
        setIsOpenDraftProductsFormModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenDraftProductsFormModal = action.payload;
        },
        setIsConfirmDraftProductDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmDraftProductDeletionModal = action.payload;
        },
        setIsOpenActiveProductAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenActiveProductAreYouSureModal = action.payload;
        },
        setIsOpenActiveProductChangeStatusModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenActiveProductChangeStatusModal = action.payload;
        },
        setIsConfirmActiveProductDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmActiveProductDeletionModal = action.payload;
        },
        setIsChangeProductsDeletionActivationActions: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isChangeProductsDeletionActivationActions = action.payload;
        },
        setDraftProductSuccessfulStatus: (
            state: ModalsState,
            action: SuccesActionParams
        ) => {
            state.draftProductSuccessfulStatus = action.payload;
        },
        setItemFormFieldSuccessfulStatus: (
            state: ModalsState,
            action: SuccesActionParams
        ) => {
            state.itemFormFieldSuccessfulStatus = action.payload;
        },
        setIsChangeItemFormFieldDeletionActivationActions: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isChangeItemFormFieldDeletionActivationActions =
                action.payload;
        },
        setIsOpenItemFormFieldAreYouSureModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenItemFormFieldAreYouSureModal = action.payload;
        },
        setIsOpenItemFormFieldChangeStatusModal: (
            state: ModalsState,
            action: ItemFormFieldChangeStatusAction
        ) => {
            state.isOpenItemFormFieldChangeStatusModal = action.payload;
        },
        setIsOpenItemFormFieldFormModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenItemFormFieldFormModal = action.payload;
        },
        setIsOpenCreateTabModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenCreateTabModal = action.payload;
        },
        setIsOpenAreYouSureDeleteTabModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenAreYouSureDeleteTabModal = action.payload;
        },
        setIsOpenCompanyAddFieldsModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isOpenCompanyAddFieldsModal = action.payload;
        },
        setIsConfirmItemFormFieldDeletionModal: (
            state: ModalsState,
            action: ModalHandlerAction
        ) => {
            state.isConfirmItemFormFieldDeletionModal = action.payload;
        },

        cleareSuccessfulStatus: (state: ModalsState) => {
            state.companySuccessfulStatus = null;
            state.userSuccessfulStatus = null;
            state.draftProductSuccessfulStatus = null;
            state.itemFormFieldSuccessfulStatus = null;
        },
    },
});
