import { ReactNode, FC } from "react";
import Header from "../HeaderLogo";
import SideBar from "../SideBar";
import styles from "./appLayout.module.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import RetailerLogo from "../../img/supplier_hub/SupplierHub-logo.png";
import "../../styles/colors-supplier.scss";

interface AppLayoutOwnProps {
  children: ReactNode;
  headerLogo: ReactNode;
  title: string;
}

const AppLayout: FC<AppLayoutOwnProps> = ({ children, title, headerLogo }): JSX.Element => {
  const { userCompanyType } = useAppSelector((state) => state.companies);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <Header logo={RetailerLogo}/>
        <div style={{display: 'flex'}}>
            <SideBar />
            <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
