import { Button } from "antd";
import { FC, useEffect } from "react";
import styles from "./filter.module.scss";
import FilterControl from "../../../common/components/FilterControl";
import {
  SortedByItemsFilterTypes,
  SortedByItemsFilterValues,
} from "../../constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  getCompaniesNames,
  getDescriptors,
  getItemsGtins,
} from "../../../../api/filter";
import { useActions } from "../../../../hooks/useActions";
import { SelectData } from "../../../common/types";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ReactComponent as ActiveItemIcon } from "../../../common/icons/active.svg";
import { ReactComponent as TrashIcon } from "../../../common/icons/trash.svg";
import { FilterType } from "../../../common/constants/filter";

interface FilterOwnProps {
  countCheckedActiveItems: number;
  totalDraftItemsCount: number;
}

const sortedByValues: SelectData[] = [
  {
    name: SortedByItemsFilterTypes.GTIN,
    value: SortedByItemsFilterValues.GTINS,
  },
  {
    name: SortedByItemsFilterTypes.DESCRIPTORS,
    value: SortedByItemsFilterValues.DESCRIPTORS,
  },
];

const Filter: FC<FilterOwnProps> = ({
  countCheckedActiveItems,
  totalDraftItemsCount,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setIsConfirmActiveItemDeletionModal,
    setIsOpenActiveItemChangeStatusModal,
    setActiveItemsForDeactivationDeletion,
  } = useActions();

  const { activeItemsForDeactivationDeletion } = useAppSelector(
    (state) => state.activeItems
  );

  const { user } = useAppSelector((state) => state.auth);

  const { sortedByData, isSortedByStatus } = useAppSelector(
    (state) => state.additionalFilterControl
  );

  const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);

  useEffect(() => {
    if (userCompanyId) {
      dispatch(getItemsGtins(FilterType.ADDITIONAL, userCompanyId as number));
    } else {
      dispatch(getItemsGtins(FilterType.ADDITIONAL));
    }
  }, [dispatch, userCompanyId]);

  const sortedByValuesWithCompanies = () => {
    if (user?.is_admin) {
      return [
        ...sortedByValues,
        {
          name: SortedByItemsFilterTypes.COMPANIES,
          value: SortedByItemsFilterValues.COMPANIES,
        },
      ];
    }

    return sortedByValues;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterControlWrapper}>
        <div className={styles.title}>
          <ActiveItemIcon className={styles.titleIcon} />
          <div className={styles.titleText}>
            <div className={userCompanyType === 'wine'?styles.titleTextTopWine:styles.titleTextTop}>Active</div>
          </div>
        </div>
        <FilterControl
          searchName="Items"
          cleareActivationDeletionIdes={() =>
            setActiveItemsForDeactivationDeletion([])
          }
          sortedBy={sortedByValuesWithCompanies()}
          sortedByData={sortedByData}
          isSortedByStatus={isSortedByStatus}
          filterControlType={FilterType.ADDITIONAL}
          dateRangePicker={true}
          getSortedByData={(sortValue) => {
            if (sortValue === SortedByItemsFilterValues.GTINS) {
              if (userCompanyId) {
                dispatch(
                  getItemsGtins(FilterType.ADDITIONAL, userCompanyId as number)
                );
              } else {
                dispatch(getItemsGtins(FilterType.ADDITIONAL));
              }
            }
            if (sortValue === SortedByItemsFilterValues.DESCRIPTORS) {
              dispatch(getDescriptors(FilterType.ADDITIONAL));
            }
            if (sortValue === SortedByItemsFilterValues.COMPANIES) {
              dispatch(getCompaniesNames(FilterType.ADDITIONAL));
            }
          }}
        />
        <div className={styles.countInfoWrapper}>
          <div className={userCompanyType === 'wine'?styles.countInfoFieldWine:styles.countInfoField}>
            {!countCheckedActiveItems
              ? totalDraftItemsCount
              : countCheckedActiveItems}
          </div>
          <div className={styles.countInfoTitle}>
            {!countCheckedActiveItems ? "Active items" : "Selected"}
          </div>
        </div>
      </div>
      {activeItemsForDeactivationDeletion.length !== 0 && (
        <div className={styles.buttonsIsCheckGroup}>
          <div
            className={userCompanyType === 'wine'?styles.buttonDeleteWine:styles.buttonDelete}
            onClick={() => {
              setIsConfirmActiveItemDeletionModal(true);
            }}
          >
            <TrashIcon className={styles.trashIcon} />
            <div>Delete</div>
          </div>
          <Button
            className={userCompanyType === 'wine'?styles.buttonActivateWine:styles.buttonActivate}
            type="primary"
            onClick={() => {
              setIsOpenActiveItemChangeStatusModal(true);
            }}
          >
            <div>To drafts</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filter;
