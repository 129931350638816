/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import AppModal from "../../../common/components/AppModal";
import RecoverForm from "../../components/RecoverForm";
import { ReactComponent as SuccessLogo } from "../../../common/icons/success.svg";
import { RoutesNames } from "../../../../routes";
import AppAuthLayout from "../../../common/components/AppAuthLayout";
import { checkResetLink } from "../../../../api/auth";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import ExpiredResetLink from "../../components/ExpiredResetLink";

const RecoverPage: FC = (): JSX.Element => {
  const { isResetLinkValid } = useAppSelector((state) => state.auth);
  const [isRecoveryPasswordModalVisible, setIsRecoveryPasswordModalVisible] =
    useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();

  useEffect(() => {
    if (urlParams && urlParams.email) {
      dispatch(checkResetLink(urlParams.email));
    }
  }, [dispatch]);

  const viewInfo = () => {
    if (typeof isResetLinkValid === "boolean") {
      if (isResetLinkValid) {
        return (
          <AppAuthLayout>
            <RecoverForm
              dispatch={dispatch}
              setOpenModal={setIsRecoveryPasswordModalVisible}
              urlParams={urlParams}
            />
            <AppModal
              modalIcon={<SuccessLogo style={{ width: 70, height: 70 }} />}
              visible={isRecoveryPasswordModalVisible}
              handleOk={() => {
                setIsRecoveryPasswordModalVisible(false);
                navigate(RoutesNames.LOGIN);
              }}
              buttonOkName="OK"
              modalInfoText="The password has been changed successfully"
              onCancel={() => setIsRecoveryPasswordModalVisible(false)}
              isOnlyButtonOk={true}
            />
          </AppAuthLayout>
        );
      } else {
        return <ExpiredResetLink />;
      }
    }
  };

  return <>{viewInfo()}</>;
};

export default RecoverPage;
