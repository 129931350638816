export const convertToFormData = (objData: any) => {
  const keys = Object.keys(objData);
  let formData = new FormData();
  keys.forEach((key) => {
    if (!!objData[key]) {
      if (Array.isArray(objData[key])) {
        if (key === "files") {
          if (objData[key].length !== 0) {
            objData[key].forEach((file: any, index: number) => {
              formData.append(`${key}[${index}][file]`, file.file);
              formData.append(`${key}[${index}][fields]`, JSON.stringify(file.fields));
            });
          } else {
            formData.append(`${key}`, "");
          }
        } else {
          formData.append(`${key}`, JSON.stringify(objData[key]));
        }
      } else {
        formData.append(`${key}`, objData[key]);
      }
    } else {
      formData.append(`${key}`, "");
    }
  });

  return formData;
};
