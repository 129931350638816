import { FC } from "react";

import styles from "./header.module.scss";

interface HeaderOwnProps {
  children: React.ReactNode;
  title: string;
}

const Header: FC<HeaderOwnProps> = ({ title, children }): JSX.Element => {
  return (
    <header className={styles.container}>
      {children}
      <div style={{paddingLeft: '20px'}}>{title}</div>
    </header>
  );
};

export default Header;
