import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Input, AutoComplete } from 'antd';
import { SelectData } from '../../common/types';
import { Option } from 'antd/lib/mentions';

interface AutocompleteProps {
  value: string;
  placeholder: string;
  onChange: any;
  maxLength: number;
  disabled: boolean;
  options: any;
  setGtinOptions: any;
}

export const Autocomplete = ({
  value,
  placeholder,
  onChange,
  maxLength,
  disabled,
  options,
  setGtinOptions,
}: AutocompleteProps) => {
  const [arr, setArr] = useState<any>([]);

  useEffect(() => {
    if (!options) return;
    let res: any = [];
    options?.forEach((elem: any) => {
      res.push({ value: elem.gtin });
    });
    setArr(res);
  }, [options]);

  return (
    <AutoComplete
      disabled={disabled}
      value={value}
      onChange={onChange}
      style={{ width: 305 }}
      options={arr}
      maxLength={maxLength}
      placeholder={placeholder}
      //   filterOption={(inputValue, option) =>
      //     option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      //   }
    />
  );
};
