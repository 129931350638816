import { Button, Checkbox, Pagination, Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Status } from "../../../common/constants/status";
import styles from "./itemFormFieldsList.module.scss";
import classnames from "classnames";
import { useActions } from "../../../../hooks/useActions";
import {
  getAllItemFormFields,
  getItemFormFieldById,
} from "../../../../api/itemForm";
import { DeletionItemFormFieldProps } from "../../types";
import ItemFormFilter from "../ItemFormFilter";
import ItemFormStaticFieldsList from "../ItemFormStaticFieldsList";

const ItemFormFieldsList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setFilter,
    setIsOpenItemFormFieldFormModal,
    setIsOpenItemFormFieldChangeStatusModal,
    setItemFormFieldsForDeletion,
    cleareItemFormFieldStore,
  } = useActions();

  const {
    itemFormFields,
    totalItems,
    isLoading,
    itemFormFieldsForDeletion,
  } = useAppSelector((state) => state.itemForm);

  const {
    itemFormFieldSuccessfulStatus,
    isChangeItemFormFieldDeletionActivationActions,
  } = useAppSelector((state) => state.modals);

  const filter = useAppSelector((state) => state.filter);

  useEffect(() => {
    dispatch(getAllItemFormFields(filter));
  }, [
    dispatch,
    filter,
    itemFormFieldSuccessfulStatus,
    isChangeItemFormFieldDeletionActivationActions,
  ]);

  const getColumns = () => [
    {
      title: (
        <Checkbox
          className={styles.mainCheckbox}
          checked={itemFormFieldsForDeletion.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const checkAllItemFormFields = itemFormFields?.map((field) => {
                return { isChecked: true, id: field.id };
              });
              setItemFormFieldsForDeletion(
                checkAllItemFormFields as DeletionItemFormFieldProps[]
              );
            } else {
              setItemFormFieldsForDeletion([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "FIELD NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "FIELD TYPE",
      dataIndex: "field_type_name",
      key: "field_type_name",
    },
    {
      title: "FORM TAB TYPE",
      dataIndex: "item_tab_name",
      key: "item_tab_name",
    },
    {
      title: "STATUS",
      align: "center" as "center",
      dataIndex: "isActive",
      key: "isActive",
    },
  ];

  const getDataSource = () => {
    return itemFormFields?.map(
      ({ id, name, field_type_name, item_tab_name, company_name, status }) => {
        return {
          key: id,
          check: (
            <Checkbox
              checked={
                itemFormFieldsForDeletion.find(
                  (value: DeletionItemFormFieldProps) => value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setItemFormFieldsForDeletion([
                    ...itemFormFieldsForDeletion,
                    data as DeletionItemFormFieldProps,
                  ]);
                } else {
                  setItemFormFieldsForDeletion(
                    itemFormFieldsForDeletion.filter((field) => field.id !== id)
                  );
                }
              }}
            />
          ),
          name: (
            <div
              className={styles.fieldInfo}
              onClick={() => {
                cleareItemFormFieldStore();
                dispatch(getItemFormFieldById(id as number));
                setIsOpenItemFormFieldFormModal(true);
              }}
            >
              {name}
            </div>
          ),
          field_type_name: (
            <div className={styles.fieldTypeName}>{field_type_name}</div>
          ),
          item_tab_name: (
            <div className={styles.itemTabName}>{item_tab_name}</div>
          ),
          isActive: (
            <Button
              className={classnames([
                status
                  ? styles.buttonStatusActive
                  : styles.buttonStatusInactive,
              ])}
              type="primary"
              onClick={() => {
                setIsOpenItemFormFieldChangeStatusModal({
                  isOpen: true,
                  changedStatusItemFormField: {
                    id: id as number,
                    name,
                    status: status as string,
                  },
                });
              }}
            >
              {status ? Status.ACIVE : Status.INACTIVE}
            </Button>
          ),
        };
      }
    );
  };

  return (
    <>
      <ItemFormFilter
        setOpenModal={setIsOpenItemFormFieldFormModal}
        totalItemFormFieldsCount={totalItems}
        countCheckedItemFormFields={itemFormFieldsForDeletion.length}
      />
      <ItemFormStaticFieldsList />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{ emptyText: "No search results found" }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: "calc(100vh - 458px)" }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={itemFormFields?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setFilter({ page, paginate: perPage }));
              setItemFormFieldsForDeletion([]);
            } else {
              dispatch(setFilter({ page }));
              setItemFormFieldsForDeletion([]);
            }
          }}
          total={totalItems || 10}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </div>
    </>
  );
};

export default ItemFormFieldsList;
