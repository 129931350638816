import { Dispatch } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { localStorageUtil } from ".";
import { authSlice } from "../redux/reducers/auth";
import { AuthData } from "../modules/auth/types";
import settings from "../settings";
import { sessionStorageUtil } from "./sessionStorage";

const authAxios = (dispatch: Dispatch) => {
  const authData: AuthData = JSON.parse(
    localStorage.getItem("authData")
      ? localStorage.getItem("authData")
      : (sessionStorage.getItem("authData") as any)
  );

  const instanceAxios = axios.create({
    baseURL: settings.baseURL,
    headers: {
      Authorization: authData ? `Bearer ${authData.token}` : "",
    },
  });

  instanceAxios.interceptors.response.use(
    (response) => response.data,
    (error: AxiosError) => {
      const status = error.response?.status || 500;

      switch (status) {
        case 401: {
          sessionStorageUtil.removeStorageItem("authData");
          localStorageUtil.removeStorageItem("authData");
          dispatch(authSlice.actions.setIsAuth(false));
          return Promise.reject(error.response);
        }
        case 422: {
          return Promise.reject(error.response);
        }
        case 403: {
          return Promise.reject(error.response);
        }
        case 404: {
          return Promise.reject(error.response);
        }
      }
    }
  );

  return instanceAxios;
};

export default authAxios;
