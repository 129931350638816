import { createSlice } from "@reduxjs/toolkit";
import {
    ItemInfosState,
    GetItemInfosAction,
} from "../../../modules/items/types";

const initialState: ItemInfosState = {
  itemInfos: [],
  totalItems: 0,
};

export const itemInfosSlice = createSlice({
  name: "itemInfosSlice",
  initialState,
  reducers: {
    getItemInfos: (state: ItemInfosState, action: GetItemInfosAction) => {
      state.itemInfos = action.payload.itemInfos;
      state.totalItems = action.payload.totalItems;
    },
  },
});
