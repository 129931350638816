import { FC } from "react";
import styles from "./expiredResetLink.module.scss";
import { ReactComponent as TrustedLogo } from "../../../common/icons/AuthlogoPXM.svg";
import { NavLink } from "react-router-dom";
import { RoutesNames } from "../../../../routes";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import settings from '../../../../settings';
import { authSlice } from "../../../../redux/reducers/auth";
import VindixLogo from "../../../common/icons/vindixlogowhite.png";

const ExpiredResetLink: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  dispatch(authSlice.actions.setIsWineApp(settings.vindixDomain == window.location.origin && window.location.pathname !== '/admin/login'));
  const { isWineApp } = useAppSelector((store) => store.auth);

  return (
    <div className={isWineApp?styles.wineWrapper:styles.wrapper}>
      <div className={styles.contentWrapper}>
        {isWineApp?
        <img src={VindixLogo} width="200px" />
        :<TrustedLogo className={styles.logo} />}
        <div className={styles.infoWrapper}>
          <h1 className={styles.infoText}>Sorry, this link is expired</h1>
          <NavLink className={styles.buttonLink} to={RoutesNames.LOGIN}>
            GO TO LOGIN PAGE
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ExpiredResetLink;
