import { Item } from "../../items/types";
import { ProductItem } from "../types";

export const convertToProductItems = (items: Item[]): ProductItem[] => {
  return items.map((item) => {
    return {
      id: item.id,
      gtin: item.gtin,
      description: item.description,
      descriptor: item.trade_item_descriptor_name,
      quantity: item.quantity,
    };
  }) as ProductItem[];
};

// export const convertToProductItemsRequest = (items: Item[]) => {
//   let productItemsObject: any = {};
//   items.forEach((item) => {
//     productItemsObject[String(item.id)] = item.quantity;
//   });
//   return productItemsObject;
// };
