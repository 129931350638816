import { FC, useEffect, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import AppLayout from "../../common/components/AppLayout";
import styles from "./itemsPage.module.scss";
import { ReactComponent as ItemsLogo } from "../../common/icons/items.svg";
import { ItemDetailsForm } from "../components/ItemDetailsForm";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { convertToItemFormFieldsWithSize } from '../../itemForm/utils/convertToItemFormFieldsWithSize';
import { ItemFormTabIds } from '../../itemForm/constants';
import { getItemFormFieldsByCompanyId } from '../../../api/itemForm';
import { BlockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ItemDetailsFormPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
    const { activeItem } = useAppSelector((state) => state.activeItems);
    const { draftItem } = useAppSelector((state) => state.draftItems);
    const [isMainFieldsFull, setIsMainFieldsFull] = useState<any>(false);
    const [isLastTab, setIsLastTab] = useState<boolean>(false);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);
    const [activeTabs, setActiveTabs] = useState<any>([]);
    const { userCompanyId } = useAppSelector((state) => state.companies);
    const { setModalActiveTab } = useActions();
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
    );
    const element = itemFormFields?.find(
            (elem) => elem.name === selectedTab
        );
    
    const { t } = useTranslation();

    useEffect(() => {
        if (!itemFormFields) return;
        let array: any = [];
        itemFormFields.forEach((elem: any) => {
            if (
                elem.name === 'main' ||
                elem.name === 'measurements' ||
                elem.name === 'digital assets' ||
                elem?.items?.length
            ) {
                array.push(elem);
            }
        });
        setActiveTabs(array);
    }, [itemFormFields]);

    useEffect(() => {
        if (itemFormFields) {
            dispatch(setModalActiveTab(itemFormFields[0].name));
        }
    }, [itemFormFields]);

    let itemFormFieldsMain =
        itemFormFields &&
        convertToItemFormFieldsWithSize(
            itemFormFields?.filter(
                (field) => field.item_tab_id === ItemFormTabIds.MAIN
            )
        );

    let blocksCount = itemFormFieldsMain
        ? Math.ceil((itemFormFieldsMain.length - 10) / 15) + 1
        : 0;

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getItemFormFieldsByCompanyId(String(userCompanyId)));
        }
    }, [dispatch, userCompanyId]);
  return (
    <AppLayout
      title={t("Items")}
      headerLogo={<BlockOutlined />}
    >
      <ItemDetailsForm 
        isDraft={activeItem ? false : true}
        element={element}
        dataForm={activeItem ? activeItem : draftItem}
        setIsMainFieldsFull={setIsMainFieldsFull}
        isLastTab={isLastTab}
      />
    </AppLayout>
  );
};

export default ItemDetailsFormPage;