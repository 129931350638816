import { Modal } from "antd";
import { FC, useState } from "react";
import "./modalsGroup.global.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { NumberStatus, Status } from "../../../common/constants/status";
import { useActions } from "../../../../hooks/useActions";
import AppModal from "../../../common/components/AppModal";
import { IUser } from "../../types";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { changeUserStatusRequest, deleteUsers } from "../../../../api/users";
import UsersForm from "../UsersForm";

const ModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [formIsChange, setFormIsChange] = useState(false);
  const { isLoading, validationError, user, usersForDeletion } = useAppSelector(
    (state) => state.users
  );
  const {
    userSuccessfulStatus,
    isConfirmUserDeletionModal,
    isOpenUsersFormModal,
    isOpenUserAreYouSureModal,
    isOpenUserChangeStatusModal,
  } = useAppSelector((state) => state.modals);
  const {
    cleareSuccessfulStatus,
    cleareUserStore,
    setUsersForDeletion,
    setIsConfirmUserDeletionModal,
    setIsOpenUsersFormModal,
    setIsOpenUserAreYouSureModal,
    setIsOpenUserChangeStatusModal,
    setIsChangeUserDeletionActivationActions,
    cleareUsersValidationError,
  } = useActions();
  return (
    <>
      <Modal
        visible={isOpenUsersFormModal}
        footer={null}
        title={user ? "USER'S PROFILE" : "CREATE NEW USER"}
        destroyOnClose
        onCancel={() => {
          setIsOpenUsersFormModal(false);
          cleareUsersValidationError();
          if (formIsChange) {
            setIsOpenUsersFormModal(true);
            setIsOpenUserAreYouSureModal(true);
          }
        }}
        className="userFormModal"
      >
        <UsersForm
          dispatch={dispatch}
          setFormIsChange={setFormIsChange}
          user={user as IUser}
          isLoadingUser={isLoading}
          isErrorUser={validationError}
        />
      </Modal>

      <AppModal
        visible={isOpenUserAreYouSureModal}
        handleOk={() => {
          cleareUserStore();
          setIsOpenUsersFormModal(false);
          setIsOpenUserAreYouSureModal(false);
        }}
        handleCancel={() => setIsOpenUserAreYouSureModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to close
          without saving?"
        onCancel={() => setIsOpenUserAreYouSureModal(false)}
      />

      <AppModal
        visible={userSuccessfulStatus?.status}
        handleOk={() => {
          cleareSuccessfulStatus();
        }}
        buttonOkName="OK"
        modalInfoText={`Congratulations!`}
        modalInfoTextMiddle={userSuccessfulStatus?.message}
        onCancel={() => cleareSuccessfulStatus()}
        isOnlyButtonOk={true}
      />

      <AppModal
        visible={isOpenUserChangeStatusModal.isOpen}
        handleOk={() => {
          if (
            isOpenUserChangeStatusModal.changedStatusUser?.status ===
            Status.ACIVE
          ) {
            dispatch(
              changeUserStatusRequest({
                id: isOpenUserChangeStatusModal.changedStatusUser?.id as number,
                status: NumberStatus.INACTIVE,
              })
            );
          } else {
            dispatch(
              changeUserStatusRequest({
                id: isOpenUserChangeStatusModal.changedStatusUser?.id as number,
                status: NumberStatus.ACIVE,
              })
            );
          }

          setIsChangeUserDeletionActivationActions(true);
          setIsOpenUserChangeStatusModal({
            isOpen: false,
            changedStatusUser: null,
          });
        }}
        handleCancel={() =>
          setIsOpenUserChangeStatusModal({
            isOpen: false,
            changedStatusUser: null,
          })
        }
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to Change Status for User?"
        onCancel={() =>
          setIsOpenUserChangeStatusModal({
            isOpen: false,
            changedStatusUser: null,
          })
        }
      />

      <AppModal
        visible={isConfirmUserDeletionModal}
        handleOk={() => {
          dispatch(deleteUsers(usersForDeletion.map((value) => value.user_id)));
          setIsChangeUserDeletionActivationActions(true);
          setUsersForDeletion([]);
          setIsConfirmUserDeletionModal(false);
        }}
        handleCancel={() => setIsConfirmUserDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${usersForDeletion.length} User(s)?`}
        onCancel={() => setIsConfirmUserDeletionModal(false)}
      />
    </>
  );
};

export default ModalsGroup;
