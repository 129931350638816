import { Checkbox, Pagination, Table } from 'antd';
import { FC, useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ValidationStatus } from '../../../common/constants/status';
import styles from './draftItemsList.module.scss';
import { useActions } from '../../../../hooks/useActions';
import DraftItemsFilter from '../DraftItemsFilter';
import { DeletionActivateDeactiveItemProps } from '../../types';
import {
    getAllDraftItems,
    getDraftItemById,
    getAllDraftItemsForView,
    validateDraftItem,
} from '../../../../api/items';
import { ReactComponent as CheckMarkIcon } from '../../../common/icons/checkMark.svg';
import { ReactComponent as CrossIcon } from '../../../common/icons/cross.svg';
import { ReactComponent as DraftItemIcon } from '../../../common/icons/drafts.svg';
import { ReactComponent as LegallyInvalidIcon } from '../../../common/icons/legallyInvalid.svg';
import { ReactComponent as LegallyValidIcon } from '../../../common/icons/legallyValid.svg';
import { ModalTabs } from '../../../common/constants/modalTabs';
import { checkValidateFields } from '../../utils/checkValidateFields';
import { getItemFormFieldsByCompanyId } from '../../../../api/itemForm';
import { converToItemFormsDataWithValue } from '../../utils/converToItemFormsData';
import { RoutesNames } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import Icon, {
    QrcodeOutlined
} from '@ant-design/icons';
import ProductHierarchyElement from "../../../common/components/ProductHierarchy/ProductHierarchyElement"

const DraftItemsList: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        setFilter,
        setIsOpenDraftItemsFormModal,
        setDraftItemsForActivationDeletion,
        cleareDraftItemStore,
        cleareActiveItemStore,
        setModalActiveTab,
    } = useActions();

    const {
        draftItems,
        draftItem,
        totalItems,
        isLoading,
        draftItemsForActivationDeletion,
    } = useAppSelector((state) => state.draftItems);

    const { isChangeItemsDeletionActivationActions } = useAppSelector(
        (state) => state.modals
    );

    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);
    const { user } = useAppSelector((state) => state.auth);
    const filter = useAppSelector((state) => state.filter);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.is_admin) {
            dispatch(getAllDraftItems(userCompanyId as number, filter));
        } else {
            dispatch(getAllDraftItemsForView(filter));
        }

        if (
            draftItem &&
            draftItem.validated === ValidationStatus.INCOMPLETE &&
            checkValidateFields(draftItem) &&
            userCompanyId
        ) {
            dispatch(
                validateDraftItem(userCompanyId as number, {
                    ...draftItem,
                    item_forms: JSON.stringify(
                        converToItemFormsDataWithValue(draftItem.item_forms)
                    ),
                })
            );
        }
    }, [
        dispatch,
        filter,
        draftItem,
        isChangeItemsDeletionActivationActions,
        user?.is_admin,
        userCompanyId,
    ]);

    const getColumns = () => [
        {
            title: !user?.is_admin && (
                <Checkbox
                    className={styles.mainCheckbox}
                    checked={draftItemsForActivationDeletion.length !== 0}
                    onChange={(e) => {
                        if (e.target.checked) {
                            const checkAllDraftItems = draftItems?.map(
                                (draftItem) => {
                                    return {
                                        isChecked: true,
                                        id: draftItem.id,
                                    };
                                }
                            );
                            setDraftItemsForActivationDeletion(
                                checkAllDraftItems as DeletionActivateDeactiveItemProps[]
                            );
                        } else {
                            setDraftItemsForActivationDeletion([]);
                        }
                    }}
                />
            ),
            dataIndex: 'check',
            align: 'center' as 'center',
            key: 'check',
        },
        {
            title: 'GTIN',
            dataIndex: 'gtin',
            key: 'gtin',
        },
        {
            title: 'ITEM DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'DESCRIPTOR',
            dataIndex: 'trade_item_descriptor_name',
            key: 'trade_item_descriptor_name',
        },
        {
            title: 'BRAND',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: user?.is_admin && 'COMPANY',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: userCompanyType === 'wine'?'ELABEL':'',
            dataIndex: 'item_elabel',
            key: 'item_elabel',
        },
        {
            title: 'STATUS',
            dataIndex: userCompanyType === 'wine'?'legallyRequiredData':'validated',
            key: userCompanyType === 'wine'?'legallyRequiredData':'validated',
        },
    ];

    const QrcodeOutlined = () => (
        <svg viewBox="64 64 896 896" focusable="false" data-icon="qrcode" width="40px" height="40px" fill="currentColor" aria-hidden="true">
            <path d="M468 128H160c-17.7 0-32 14.3-32 32v308c0 4.4 3.6 8 8 8h332c4.4 0 8-3.6 8-8V136c0-4.4-3.6-8-8-8zm-56 284H192V192h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm194 210H136c-4.4 0-8 3.6-8 8v308c0 17.7 14.3 32 32 32h308c4.4 0 8-3.6 8-8V556c0-4.4-3.6-8-8-8zm-56 284H192V612h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm590-630H556c-4.4 0-8 3.6-8 8v332c0 4.4 3.6 8 8 8h332c4.4 0 8-3.6 8-8V160c0-17.7-14.3-32-32-32zm-32 284H612V192h220v220zm-138-74h56c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm194 210h-48c-4.4 0-8 3.6-8 8v134h-78V556c0-4.4-3.6-8-8-8H556c-4.4 0-8 3.6-8 8v332c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h78v102c0 4.4 3.6 8 8 8h190c4.4 0 8-3.6 8-8V556c0-4.4-3.6-8-8-8zM746 832h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm142 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
        </svg>
    );

    const getDataSource = () => {
        return draftItems?.map(
            ({
                id,
                description,
                trade_item_descriptor_name,
                brand,
                company_name,
                company_id,
                date,
                validated,
                quantity,
                gtin,
                item_elabel,
                legallyRequiredData
            }) => {
                return {
                    key: id,
                    check: !user?.is_admin && (
                        <Checkbox
                            checked={
                                draftItemsForActivationDeletion.find(
                                    (
                                        value: DeletionActivateDeactiveItemProps
                                    ) => value.id === id
                                )?.isChecked
                            }
                            className={styles.checkbox}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const data = {
                                        isChecked: e.target.checked,
                                        id,
                                    };
                                    setDraftItemsForActivationDeletion([
                                        ...draftItemsForActivationDeletion,
                                        data as DeletionActivateDeactiveItemProps,
                                    ]);
                                } else {
                                    setDraftItemsForActivationDeletion(
                                        draftItemsForActivationDeletion.filter(
                                            (item) => item.id !== id
                                        )
                                    );
                                }
                            }}
                        />
                    ),
                    gtin: (
                        <div
                            className={styles.draftItemInfo}
                            onClick={() => {
                                !userCompanyId &&
                                    dispatch(
                                        getItemFormFieldsByCompanyId(
                                            String(company_id)
                                        )
                                    );
                                cleareDraftItemStore();
                                cleareActiveItemStore();
                                dispatch(getDraftItemById(id as number));
                                navigate(`/items/${gtin}`);
                                //navigate(RoutesNames.ITEM_DETAILS_FORM);
                                //setIsOpenDraftItemsFormModal(true);
                                // setModalActiveTab(ModalTabs.MAIN_FORM);
                            }}
                        >
                            <div className={styles.gtin}>{gtin}</div>
                        </div>
                    ),
                    description: (
                        <span
                            className={
                                user?.is_admin
                                    ? styles.descriptionAdmin
                                    : styles.description
                            }
                        >
                            {description}
                        </span>
                    ),
                    trade_item_descriptor_name: (
                        <div className={styles.descriptor}>
                            <ProductHierarchyElement descriptor={trade_item_descriptor_name as string} gtin={gtin} big={false} highlight={false}/>
                        </div>
                    ),
                    brand: <div className={styles.brand}>{brand}</div>,
                    company: user?.is_admin && (
                        <div className={styles.companyName}>{company_name}</div>
                    ),
                    date: <div className={styles.date}>{date}</div>,
                    quantity: <div className={styles.quantity}>{quantity}</div>,
                    item_elabel: userCompanyType === 'wine' && (
                        <div
                            className={styles.quantity}
                            onClick={() => {
                                !userCompanyId &&
                                    dispatch(
                                        getItemFormFieldsByCompanyId(
                                            String(company_id)
                                        )
                                    );
                                cleareDraftItemStore();
                                cleareActiveItemStore();
                                dispatch(getDraftItemById(id as number));
                                navigate(RoutesNames.ELABEL_DETAILS);
                                //setIsOpenDraftItemsFormModal(true);
                                // setModalActiveTab(ModalTabs.MAIN_FORM);
                            }}
                        >
                            <div className={styles.gtin}>{<Icon component={QrcodeOutlined} style={{color: item_elabel !== undefined && item_elabel !== null?'#000000':'#BDBDBD', cursor: 'pointer'}} title="Generate e-Label"/>}</div>
                        </div>
                    ),
                    validated: userCompanyType !== 'wine' && (
                        validated === ValidationStatus.INCOMPLETE ? (
                            <div className={styles.buttonStatusInvalidate}>
                                <CrossIcon className={styles.buttonIcon} />
                                {ValidationStatus.INCOMPLETE.toLowerCase()}
                                <div />
                            </div>
                        ) : (
                            <div className={styles.buttonStatusValidate}>
                                <CheckMarkIcon className={styles.buttonIcon} />
                                {ValidationStatus.COMPLETE.toLowerCase()}
                                <div />
                            </div>
                        )
                    ),
                    legallyRequiredData: userCompanyType === 'wine' && (
                        legallyRequiredData === ValidationStatus.INCOMPLETE ? (
                            <div className={styles.buttonLegallyInvalidate}>
                                <LegallyInvalidIcon />
                                Need to enter the data required by law
                            </div>
                        ) : (
                            <div className={styles.buttonLegallyValidate}>
                                <LegallyValidIcon />
                                Legally required data completed
                            </div>
                        )
                    )
                };
            }
        );
    };

    return (
        <>
            <DraftItemsFilter
                setOpenModal={setIsOpenDraftItemsFormModal}
                totalDraftItemsCount={totalItems}
                countCheckedDraftItems={draftItemsForActivationDeletion.length}
            />
            <div className={userCompanyType !== 'wine'?styles.tableWrapper:styles.wineTableWrapper}>
                <Table
                    className={styles.table}
                    locale={{
                        emptyText: (
                            <div>
                                <DraftItemIcon className={styles.emptyIcon} />
                                <div>No drafts yet</div>
                            </div>
                        ),
                    }}
                    dataSource={getDataSource()}
                    columns={getColumns()}
                    pagination={false}
                    scroll={{ y: userCompanyType !== 'wine'?225:'60vh'}}
                    loading={isLoading}
                />
            </div>
            <div className={styles.footerWrapper}>
                <Pagination
                    disabled={draftItems?.length === 0}
                    className={styles.pagination}
                    current={filter.page}
                    onChange={(page, perPage) => {
                        if (perPage) {
                            dispatch(setFilter({ page, paginate: perPage }));
                            setDraftItemsForActivationDeletion([]);
                        } else {
                            dispatch(setFilter({ page }));
                            setDraftItemsForActivationDeletion([]);
                        }
                    }}
                    total={totalItems || 10}
                    showSizeChanger={true}
                    pageSizeOptions={['10', '20', '50', '100']}
                />
            </div>
        </>
    );
};

export default DraftItemsList;
