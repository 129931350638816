import { createSlice } from "@reduxjs/toolkit";
import {
    RetailersState,
    getSupplierStatsAction
} from "../../../modules/retailers/types";

const initialState: RetailersState = {
  suppliers: [],
  products: 0
};

export const retailersSlice = createSlice({
  name: "retailersSlice",
  initialState,
  reducers: {
    getSupplierStats: (state: RetailersState, action: getSupplierStatsAction) => {
      console.log("3")
      console.log(action);
      state.suppliers = [{supplier: 'A', products: 1}];
    },
    // getAllCompaniesFulfilled: (
    //   state: RetailersState,
    //   action: GetCompaniesFulfilledAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = null;
    //   state.companies = action.payload.companies;
    //   state.totalItems = action.payload.totalItems;
    // },
    // getAllCompaniesRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // getCompanyPending: (state: RetailersState) => {
    //   state.isLoading = true;
    // },
    // getCompanyFulfilled: (
    //   state: RetailersState,
    //   action: GetCompanyFulfilledAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = null;
    //   state.company = action.payload.company;
    // },
    // getCompanyRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // createCompanyPending: (state: RetailersState) => {
    //   state.isLoading = true;
    // },
    // createCompanyFulfilled: (state: RetailersState) => {
    //   state.isLoading = false;
    //   state.error = null;
    // },
    // createCompanyRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // editCompanyPending: (state: RetailersState) => {
    //   state.isLoading = true;
    // },
    // editCompanyFulfilled: (state: RetailersState) => {
    //   state.isLoading = false;
    //   state.error = null;
    // },
    // editCompanyRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // changeStatusCompanyPending: (state: RetailersState) => {
    //   state.isLoading = true;
    // },

    // changeStatusCompanyFulfilled: (state: RetailersState) => {
    //   state.isLoading = false;
    //   state.error = null;
    // },
    // changeStatusCompanyRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // deleteCompaniesPending: (state: RetailersState) => {
    //   state.isLoading = true;
    // },

    // deleteCompaniesFulfilled: (state: RetailersState) => {
    //   state.isLoading = false;
    //   state.error = null;
    // },
    // deleteCompaniesRejected: (
    //   state: RetailersState,
    //   action: CompaniesRejectedAction
    // ) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // setCompaniesForDeletion: (
    //   state: RetailersState,
    //   action: DeleteCompaniesAction
    // ) => {
    //   state.companiesForDeletion = action.payload;
    // },
    // setUserCompanyId: (
    //   state: RetailersState,
    //   action: SetUserCompanyIdAction
    // ) => {
    //   state.userCompanyId = action.payload;
    // },
    // setValidationError: (state: RetailersState, action: CompaniesValidationErrorAction) => {
    //   state.isLoading = false;
    //   state.validationError = action.payload;
    // },
    // clearCompaniesForDeletion: (state: RetailersState) => {
    //   state.companiesForDeletion = [];
    // },
    // cleareCompanyStore: (state: RetailersState) => {
    //   state.company = null;
    // },
    // cleareCompaniesStore: (state: RetailersState) => {
    //   state.companies = null;
    //   state.company = null;
    //   state.totalItems = 0;
    //   state.companiesForDeletion = [];
    //   state.isLoading = false;
    //   state.userCompanyId = "";
    //   state.error = null;
    // },
    // cleareCompanyValidationError: (state: RetailersState) => {
    //   state.isLoading = false;
    //   state.validationError = null;
    // },
    // setUserCompanyType: (
    //   state: RetailersState,
    //   action: SetUserCompanyTypeAction
    // ) => {
    //   state.userCompanyType = action.payload;
    // },
  },
});
