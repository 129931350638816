import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import AppAuthLayout from "../../../common/components/AppAuthLayout/vindix";
import ForgotForm from "../../components/ForgotForm/vindix";

const ForgotPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <AppAuthLayout>
      <ForgotForm dispatch={dispatch} navigate={navigate} />
    </AppAuthLayout>
  );
};

export default ForgotPage;
