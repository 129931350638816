import { createSlice } from "@reduxjs/toolkit";
import {
  ProductsRejectedAction,
  DraftProductsState,
  GetDraftProductFulfilledAction,
  GetDraftProductsFulfilledAction,
  DeletionActivationDeactivationDraftProductsAction,
  DraftProductFulfilledAction,
  DraftProductsValidationErrorAction,
} from "../../../modules/products/types";

const initialState: DraftProductsState = {
  draftProducts: null,
  draftProduct: null,
  totalProducts: 0,
  totalValidProducts: 0,
  draftProductsForActivationDeletion: [],
  isLoading: false,
  validationError: null,
  error: null,
};

export const draftProductsSlice = createSlice({
  name: "draftProductsSlice",
  initialState,
  reducers: {
    getAllDraftProductsPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },
    getAllDraftProductsFulfilled: (
      state: DraftProductsState,
      action: GetDraftProductsFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftProducts = action.payload.draftProducts;
      state.totalProducts = action.payload.totalProducts;
      state.totalValidProducts = action.payload.totalValidProducts;
    },
    getAllDraftProductsRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDraftProductPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },
    getDraftProductFulfilled: (
      state: DraftProductsState,
      action: GetDraftProductFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftProduct = action.payload.draftProduct;
    },
    getDraftProductRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createDraftProductPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },
    createDraftProductFulfilled: (
      state: DraftProductsState,
      action: DraftProductFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftProduct = action.payload;
    },
    createDraftProductRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editDraftProductPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },
    editDraftProductFulfilled: (
      state: DraftProductsState,
      action: DraftProductFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftProduct = action.payload;
    },
    editDraftProductRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusDraftProductPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },

    changeStatusDraftProductFulfilled: (state: DraftProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusDraftProductRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteDraftProductsPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },

    deleteDraftProductsFulfilled: (state: DraftProductsState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteDraftProductsRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    validateDraftProductPending: (state: DraftProductsState) => {
      state.isLoading = true;
    },

    validateDraftProductFulfilled: (
      state: DraftProductsState,
      action: DraftProductFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.draftProduct = action.payload;
    },
    validateDraftProductRejected: (
      state: DraftProductsState,
      action: ProductsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setDraftProductsForActivationDeletion: (
      state: DraftProductsState,
      action: DeletionActivationDeactivationDraftProductsAction
    ) => {
      state.draftProductsForActivationDeletion = action.payload;
    },
    setValidationError: (
      state: DraftProductsState,
      action: DraftProductsValidationErrorAction
    ) => {
      state.isLoading = false;
      state.validationError = action.payload;
    },
    clearDraftProductsForActivationDeletion: (state: DraftProductsState) => {
      state.draftProductsForActivationDeletion = [];
    },
    cleareDraftProductStore: (state: DraftProductsState) => {
      state.draftProduct = null;
    },
    cleareDraftProductsStore: (state: DraftProductsState) => {
      state.draftProducts = null;
      state.draftProduct = null;
      state.totalProducts = 0;
      state.draftProductsForActivationDeletion = [];
      state.isLoading = false;
      state.error = null;
    },
    cleareDraftProductsValidationError: (state: DraftProductsState) => {
      state.isLoading = false;
      state.validationError = null;
    },
  },
});
