import { FC } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import AppAuthLayout from "../../../common/components/AppAuthLayout";
import SignInAdminForm from "../../components/SignInAdminForm";

const SignInAdminPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <AppAuthLayout>
      <SignInAdminForm dispatch={dispatch} />
    </AppAuthLayout>
  );
};

export default SignInAdminPage;
