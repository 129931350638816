import { FC } from "react";
import InputField from "../../../common/components/InputField";
import styles from "./itemFormInputTextField.module.scss";

interface ItemFormInputTextFieldOwnProps {
  fieldName: string;
  fieldValue: string;
  isFieldDisabled: boolean;
  fieldPlaceholder: string;
  setFieldValue: (value: string) => void;
}

const ItemFormInputTextField: FC<ItemFormInputTextFieldOwnProps> = ({
  fieldName,
  fieldValue,
  isFieldDisabled,
  fieldPlaceholder,
  setFieldValue,
}) => {
  return (
    <div className={styles.fieldWrapper}>
      <div title={fieldName} className={styles.fieldName}>
        {fieldName}
      </div>
      <InputField
        value={fieldValue}
        className={styles.field}
        disabled={isFieldDisabled}
        placeholder={fieldPlaceholder.toUpperCase()}
        required={false}
        onChange={(event) => setFieldValue(event.target.value)}
      />
    </div>
  );
};

export default ItemFormInputTextField;
