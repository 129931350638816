import { FC } from 'react';
import styles from './appAuthLayoutVindix.module.scss';
import { ReactComponent as TrustedLogo } from '../../icons/AuthlogoPXM.svg';
import VindixLoginImage from "../../icons/bottlevindix.png";
import LoginImage from '../../icons/LoginImage.png';
import settings from '../../../../settings';
import { Col, Row, Image } from 'antd';
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { authSlice } from "../../../../redux/reducers/auth";

interface AppLayoutOwnProps {
    children: React.ReactNode;
}

const AppAuthLayout: FC<AppLayoutOwnProps> = ({ children }): JSX.Element => {
    const dispatch = useAppDispatch();
    dispatch(authSlice.actions.setIsWineApp(settings.vindixDomain == window.location.origin && window.location.pathname !== '/admin/login'));

    return (
        <Row className={styles.wineWrapper}>
                <Col className={styles.leftColumn} span={12}>
                   <Row justify="center" align="bottom">
                       <img src={VindixLoginImage} style={{position: 'absolute', bottom: '0'}} />
                   </Row>
                </Col>
            <Col className={styles.rightColumn} span={12}>{children}</Col>
        </Row>
    );
};

export default AppAuthLayout;
