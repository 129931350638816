import { FC, useEffect } from "react";
import {
  SortedByItemsFilterTypes,
  SortedByItemsFilterValues,
} from "../../../items/constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  getItemsDescriptorsForProductFilter,
  getItemsGtinsForProductFilter,
} from "../../../../api/filter";
import styles from "./filter.module.scss";
import { SelectData } from "../../../common/types";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import ProductFormFilterControl from "../ProductFormFilterControl";

const sortedByValues: SelectData[] = [
  {
    name: SortedByItemsFilterTypes.GTIN,
    value: SortedByItemsFilterValues.GTINS,
  },
  {
    name: SortedByItemsFilterTypes.DESCRIPTORS,
    value: SortedByItemsFilterValues.DESCRIPTORS,
  },
];

const Filter: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { sortedByData } = useAppSelector(
    (state) => state.productFormFilterControl
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);

  useEffect(() => {
    dispatch(getItemsGtinsForProductFilter(userCompanyId as number));
  }, [dispatch, userCompanyId]);

  return (
    <div className={styles.filterControlWrapper}>
      <ProductFormFilterControl
        searchName="Items"
        sortedBy={sortedByValues}
        sortedByData={sortedByData}
        getSortedByData={(sortValue) => {
          if (sortValue === SortedByItemsFilterValues.GTINS) {
            dispatch(getItemsGtinsForProductFilter(userCompanyId as number));
          }
          if (sortValue === SortedByItemsFilterValues.DESCRIPTORS) {
            dispatch(getItemsDescriptorsForProductFilter());
          }
        }}
      />
    </div>
  );
};

export default Filter;
