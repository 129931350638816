import { FC, useState } from "react";
import "./modalsGroup.global.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import AppModal from "../../../common/components/AppModal";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import styles from "./modalsGroup.module.scss";
import { ReactComponent as ProductsLogo } from "../../../common/icons/products.svg";
import {
  changeDraftProductsStatus,
  deleteDraftProducts,
} from "../../../../api/products";
import { Modal } from "antd";
import DraftProductsForm from "../DraftProductsForm";
import { IProduct } from "../../types";
import DraftItemsFormModal from "../../../items/components/DraftItemsFormModal";

const DraftProductsModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [formIsChange, setFormIsChange] = useState(false);
  const { isLoading, validationError, draftProduct, draftProductsForActivationDeletion } =
    useAppSelector((state) => state.draftProducts);

  const { userCompanyId } = useAppSelector((state) => state.companies);

  const {
    isConfirmDraftProductDeletionModal,
    isOpenDraftProductChangeStatusModal,
    isOpenDraftProductsFormModal,
    isOpenDraftProductAreYouSureModal,
    draftProductSuccessfulStatus,
  } = useAppSelector((state) => state.modals);

  const {
    setDraftProductsForActivationDeletion,
    setIsConfirmDraftProductDeletionModal,
    setIsOpenDraftProductChangeStatusModal,
    setIsChangeProductsDeletionActivationActions,
    setIsOpenDraftProductsFormModal,
    setIsOpenDraftProductAreYouSureModal,
    clearProductFormFilter,
    clearProductFormFilterControl,
    cleareDraftProductStore,
    cleareSuccessfulStatus,
    setIsOpenProductFormForView,
    cleareDraftProductsValidationError
  } = useActions();
  return (
    <>
      <DraftItemsFormModal />

      <Modal
        visible={isOpenDraftProductsFormModal}
        footer={null}
        title={
          <div className={styles.tableTitle}>
            <ProductsLogo className={styles.tableTitleLogo} />
            <div className={styles.tableTitleText}>TRADE PRODUCT</div>
          </div>
        }
        destroyOnClose
        onCancel={() => {
          setIsOpenDraftProductsFormModal(false);
          clearProductFormFilter();
          clearProductFormFilterControl();
          cleareDraftProductsValidationError()
          setTimeout(() => {
            setIsOpenProductFormForView(false);
          }, 1000);
          if (formIsChange) {
            setIsOpenDraftProductsFormModal(true);
            setIsOpenDraftProductAreYouSureModal(true);
          }
        }}
        className="draftProductsFormModal"
      >
        <DraftProductsForm
          dispatch={dispatch}
          setFormIsChange={setFormIsChange}
          draftProduct={draftProduct as IProduct}
          draftProductIsLoading={isLoading}
          draftProductIsError={validationError}
          userCompanyId={userCompanyId as number}
        />
      </Modal>

      <AppModal
        visible={isOpenDraftProductAreYouSureModal}
        handleOk={() => {
          cleareDraftProductStore();
          setIsOpenDraftProductsFormModal(false);
          setIsOpenDraftProductAreYouSureModal(false);
        }}
        handleCancel={() => setIsOpenDraftProductAreYouSureModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to close
          without saving?"
        onCancel={() => setIsOpenDraftProductAreYouSureModal(false)}
      />

      <AppModal
        visible={draftProductSuccessfulStatus?.status}
        handleOk={() => {
          cleareSuccessfulStatus();
        }}
        buttonOkName="OK"
        modalInfoText={`Congratulations!`}
        modalInfoTextMiddle={draftProductSuccessfulStatus?.message}
        onCancel={() => cleareSuccessfulStatus()}
        isOnlyButtonOk={true}
      />

      <AppModal
        visible={isOpenDraftProductChangeStatusModal}
        handleOk={() => {
          dispatch(
            changeDraftProductsStatus(
              draftProductsForActivationDeletion.map((value) => value.id)
            )
          );
          setDraftProductsForActivationDeletion([]);
          setIsOpenDraftProductChangeStatusModal(false);
          setIsChangeProductsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsOpenDraftProductChangeStatusModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to change status to Active?"
        onCancel={() => setIsOpenDraftProductChangeStatusModal(false)}
      />

      <AppModal
        visible={isConfirmDraftProductDeletionModal}
        handleOk={() => {
          dispatch(
            deleteDraftProducts(
              draftProductsForActivationDeletion.map((value) => value.id)
            )
          );
          setDraftProductsForActivationDeletion([]);
          setIsConfirmDraftProductDeletionModal(false);
          setIsChangeProductsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsConfirmDraftProductDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${draftProductsForActivationDeletion.length} Draft Product(s)?`}
        onCancel={() => setIsConfirmDraftProductDeletionModal(false)}
      />
    </>
  );
};

export default DraftProductsModalsGroup;
