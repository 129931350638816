import { FC, useState, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { GoldOutlined } from '@ant-design/icons';
import { Checkbox, Pagination, Table, Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import { DeletionActivateDeactiveItemProps } from '../../items/types';
import {
    getRetailerItemByGtin,
    getAllDraftItems,
    getAllActiveItems,
    getAllActiveItemsForView,
} from '../../../api/items';
import { ReactComponent as ActiveItemIcon } from '../../common/icons/active.svg';
import style from "../components/templateSelector.module.scss"
import styles from '../../items/components/ActiveItemsList/activeItemsList.module.scss';
import noImage from "../../common/icons/noImage.png";
import ProductHierarchyElement from "../../common/components/ProductHierarchy/ProductHierarchyElement";
import { catalogueSlice } from '../../../redux/reducers/catalogue';
import { useTranslation } from 'react-i18next';

const CatalogueSelectWinesVindix: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userCompanyId } = useAppSelector((state) => state.companies);
    const { user } = useAppSelector((state) => state.auth);
    const filter = useAppSelector((state) => state.additionalFilter);
    const urlParams = useParams();

    const { t } = useTranslation();

    const {
        setAdditionalFilter,
        setActiveItemsForDeactivationDeletion,
        cleareActiveItemStore,
        cleareDraftItemStore,
        setIsOpenDraftItemsFormModal,
        setModalActiveTab,
    } = useActions();

    const {
        activeItems,
        totalItems,
        isLoading,
        activeItemsForDeactivationDeletion,
    } = useAppSelector((state) => state.activeItems);

    const {
        draftItems
    } = useAppSelector((state) => state.draftItems);

    const { isChangeItemsDeletionActivationActions } = useAppSelector(
        (state) => state.modals
    );

    const goCataloguePreview = () => {
        const selectedItemsRaw = activeItemsForDeactivationDeletion?.filter(
            (activeItem) => 
                activeItem.isChecked === true
        );

        const selectedItems = selectedItemsRaw.map((item) => {
            return item.id
        })
        console.log(selectedItems);
        dispatch(catalogueSlice.actions.setWines({wines: selectedItems}));
        navigate('/catalogue/preview/' + urlParams?.template)
    }

    useEffect(() => {
        if (!user?.is_admin) {
            dispatch(getAllActiveItems(userCompanyId as number, filter));
            dispatch(getAllDraftItems(userCompanyId as number, filter));
        } else {
            dispatch(getAllActiveItemsForView(filter));
        }
    }, [
        dispatch,
        filter,
        isChangeItemsDeletionActivationActions,
        user?.is_admin,
        userCompanyId,
    ]);

    const getColumns = () => [
        {
            title: !user?.is_admin && (
                <Checkbox
                    className={styles.mainCheckbox}
                    checked={activeItemsForDeactivationDeletion.length !== 0}
                    onChange={(e) => {
                        if (e.target.checked) {
                            const checkAllActiveItems = activeItems?.map(
                                (activeItem) => {
                                    return {
                                        isChecked: true,
                                        id: activeItem.id,
                                    };
                                }
                            );
                            setActiveItemsForDeactivationDeletion(
                                 checkAllActiveItems as DeletionActivateDeactiveItemProps[]
                            );
                        } else {
                            const checkAllActiveItems = activeItems?.map(
                                (activeItem) => {
                                    return {
                                        isChecked: false,
                                        id: activeItem.id,
                                    };
                                }
                            );
                            setActiveItemsForDeactivationDeletion([]);
                        }
                    }}
                />
            ),
            dataIndex: 'check',
            align: 'center' as 'center',
            key: 'check',
        },
        {
            title: '',
            dataIndex: 'photo',
            key: 'photo',
        },
        {
            title: 'GTIN',
            dataIndex: 'gtin',
            key: 'gtin',
        },
        {
            title: t('ITEM DESCRIPTION'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('DESCRIPTOR'),
            dataIndex: 'trade_item_descriptor_name',
            key: 'trade_item_descriptor_name',
        },
        {
            title: t('BRAND'),
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: user?.is_admin && t('COMPANY'),
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: t('DATE'),
            dataIndex: 'date',
            key: 'date',
        },
        // {
        //     title: 'QUANTITY',
        //     dataIndex: 'quantity',
        //     key: 'quantity',
        // },
        {
            title: '',
            dataIndex: 'empty',
            key: 'empty',
        },
    ];

    const getDataSource = () => {
        return draftItems?.map(
            ({
                id,
                description,
                trade_item_descriptor_name,
                brand,
                company_name,
                company_id,
                date,
                gtin,
                quantity,
                photo
            }) => {
                return {
                    key: id,
                    check: !user?.is_admin && (
                        <Checkbox
                            checked={
                                activeItemsForDeactivationDeletion.find(
                                    (
                                        value: DeletionActivateDeactiveItemProps
                                    ) => value.id === id
                                )?.isChecked
                            }
                            className={styles.checkbox}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const data = {
                                        isChecked: e.target.checked,
                                        id,
                                    };
                                    setActiveItemsForDeactivationDeletion([
                                        ...activeItemsForDeactivationDeletion,
                                        data as DeletionActivateDeactiveItemProps,
                                    ]);
                                } else {
                                    setActiveItemsForDeactivationDeletion(
                                        activeItemsForDeactivationDeletion.filter(
                                            (item) => item.id !== id
                                        )
                                    );
                                }
                            }}
                        />
                    ),
                    photo: <div style={{width: '20px', height: '40px', overflow: 'hidden'}} >{photo ? <img src={photo.file} style={{width: '20px'}}/> : <img src={noImage} style={{width: '20px', marginTop: '11px'}} />}</div>,
                    gtin: (
                        <div
                            className={styles.activeItemInfo}
                            onClick={() => {
                                // cleareDraftItemStore();
                                // cleareActiveItemStore();
                                // !userCompanyId &&
                                //     dispatch(
                                //         getItemFormFieldsByCompanyId(
                                //             String(company_id)
                                //         )
                                //     );
                                dispatch(getRetailerItemByGtin(gtin));
                                navigate(`/items/${gtin}/details`);
                                //navigate(RoutesNames.ITEM_DETAILS);
                                //setIsOpenDraftItemsFormModal(true);
                                //setModalActiveTab('main');
                            }}
                        >
                            {gtin}
                        </div>
                    ),
                    description: (
                        <span
                            className={
                                user?.is_admin
                                    ? styles.descriptionAdmin
                                    : styles.description
                            }
                        >
                            {description}
                        </span>
                    ),
                    trade_item_descriptor_name: (
                        <div className={styles.descriptor}>
                            <ProductHierarchyElement descriptor={trade_item_descriptor_name as string} gtin={gtin} big={false} highlight={false}/>
                        </div>
                    ),
                    brand: <div className={styles.brand}>{brand}</div>,
                    company: user?.is_admin && (
                        <div className={styles.companyName}>{company_name}</div>
                    ),
                    date: <div className={styles.date}>{date}</div>,
                    quantity: <div className={styles.quantity}>{quantity}</div>,
                    empty: <div className={styles.emptyField} />,
                };
            }
        );
    };


  const steps = [
    {
      title: 'Template',
      content: 'First-content',
    },
    {
      title: 'Customize',
      content: 'Second-content',
    },
    {
      title: 'Select products',
      content: 'Last-content',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  
  return (
    <AppLayout
      title="Catalogues"
      headerLogo={<GoldOutlined />}
    > 
        {/* <ActiveItemsFilter
            totalDraftItemsCount={totalItems}
            countCheckedActiveItems={
                activeItemsForDeactivationDeletion.length
            }
        /> */}
        <div className={styles.tableWrapper}>
        <h1 className={style.formTitle} style={{textAlign: 'center'}}>3. {t('Select the wines')}</h1>
            <Table
                className={styles.table}
                locale={{
                    emptyText: (
                        <div>
                            <ActiveItemIcon className={styles.emptyIcon} />
                            <div>Not items activated</div>
                        </div>
                    ),
                }}
                dataSource={getDataSource()}
                columns={getColumns()}
                pagination={false}
                scroll={{ y: 'calc(100vh - 350px)' }}
                loading={isLoading}
            />
        </div>
        <div className={styles.footerWrapper}>
            {/* <Pagination
                disabled={activeItems?.length === 0}
                className={styles.pagination}
                current={filter.page}
                onChange={(page, perPage) => {
                    if (perPage) {
                        dispatch(
                            setAdditionalFilter({ page, paginate: perPage })
                        );
                        setActiveItemsForDeactivationDeletion([]);
                    } else {
                        dispatch(setAdditionalFilter({ page }));
                        setActiveItemsForDeactivationDeletion([]);
                    }
                }}
                total={totalItems || 10}
                showSizeChanger={true}
                pageSizeOptions={['10', '20', '50', '100']}
            /> */}
        </div>

        <div style={{textAlign: 'right'}}>
            <Button onClick={() => goCataloguePreview()} style={{backgroundColor: '#8F0A0E', borderRadius: '8px', border: '2px solid #8F0A0E', color: 'white', marginTop: '20px'}}>{t('Finish')}</Button>
        </div>
      
    </AppLayout>
  );
};

export default CatalogueSelectWinesVindix;
