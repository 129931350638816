import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./activeProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import ActiveProductsFilter from "../ActiveProductsFilter";
import { DeletionActivateDeactiveProductProps } from "../../types";
import { ReactComponent as ActiveProductIcon } from "../../../common/icons/products.svg";
import {
  getAllActiveProducts,
  getAllActiveProductsForView,
  getDraftProductById,
  getRetailerProductByGtin
} from "../../../../api/products";
import ProductItemsTable from "../ProductItemsTable";
import ProductHierarchy from "../../../common/components/ProductHierarchy";
import noImage from "../../../common/icons/noImage.png"
import { useNavigate } from "react-router-dom";

interface ProductListProps {
    title?: string;
    showImages?: boolean;
    showCheckbox?: boolean;
    showFilters?: boolean;
    showPagination?: boolean;
    pageSize?: number;
    size: number;
}

const SuppliersList: FC<ProductListProps> = ({
    title,
    showImages,
    showCheckbox,
    showFilters,
    showPagination,
    pageSize,
    size
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    setAdditionalFilter,
    setActiveProductsForDeactivationDeletion,
    setIsOpenProductFormForView,
    cleareDraftProductStore,
    setIsOpenDraftProductsFormModal,
  } = useActions();

  const {
    activeProducts,
    totalProducts,
    isLoading,
    activeProductsForDeactivationDeletion,
  } = useAppSelector((state) => state.activeProducts);

  const { isChangeProductsDeletionActivationActions } = useAppSelector(
    (state) => state.modals
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.additionalFilter);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(pageSize || 15);
  title = title ?? 'Products';

  useEffect(() => {
    if (!user?.is_admin) {
      dispatch(getAllActiveProducts(userCompanyId as number, {...filter, paginate: perPage}));
    } else {
      dispatch(getAllActiveProductsForView({...filter, paginate: perPage}));
    }
  }, [
    dispatch,
    filter,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
    perPage
  ]);

  const getColumns = () => {
    let columns = [];
    if(showImages) {
        columns.push({
        title: '',
        dataIndex: "photo",
        key: "photo",
    })};
  
    if(showCheckbox) {
        columns.push({
        title: !user?.is_admin && (
            <Checkbox
            className={styles.mainCheckbox}
            checked={activeProductsForDeactivationDeletion.length !== 0}
            onChange={(e) => {
                if (e.target.checked) {
                const checkAllActiveProducts = activeProducts?.map(
                    (activeProduct) => {
                    return { isChecked: true, id: activeProduct.id };
                    }
                );
                setActiveProductsForDeactivationDeletion(
                    checkAllActiveProducts as DeletionActivateDeactiveProductProps[]
                );
                } else {
                setActiveProductsForDeactivationDeletion([]);
                }
            }}
            />
        ),
        dataIndex: "check",
        align: "center" as "center",
        key: "check",
        });
    }

    columns = columns.concat([
        {
        title: "PRODUCT ID",
        dataIndex: "gtin",
        key: "gtin",
        },
        {
        title: "PRODUCT DESCRIPTION",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        },
        {
            title: "BRAND",
            dataIndex: "brand",
            key: "brand",
        },
        {
            title: "COMPANY",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Category Name",
            dataIndex: "gpc",
            key: "gpc",
        },
        {
            title: "SKU",
            dataIndex: "skuid",
            key: "skuid",
        },
        {
            title: "HIERARCHY",
            dataIndex: "hierarchy",
            key: "hierarchy",
        },
        {
        title: "",
        dataIndex: "empty",
        key: "empty",
        },
        Table.EXPAND_COLUMN,
    ])

    return columns;
  };

  const getDataSource = () => {
    return activeProducts?.map(
      ({ id, description, date, items, company_name, gtin, photo, brand, gpc, skuid }) => {
        return {
          key: id,
          check: !user?.is_admin && (
            <Checkbox
              checked={
                activeProductsForDeactivationDeletion.find(
                  (value: DeletionActivateDeactiveProductProps) =>
                    value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setActiveProductsForDeactivationDeletion([
                    ...activeProductsForDeactivationDeletion,
                    data as DeletionActivateDeactiveProductProps,
                  ]);
                } else {
                  setActiveProductsForDeactivationDeletion(
                    activeProductsForDeactivationDeletion.filter(
                      (product) => product.id !== id
                    )
                  );
                }
              }}
            />
          ),
          photo: (
            showImages && photo ?
            <div style={{borderRadius: '8px', border: '1px solid #BFBFBF', textAlign: 'center', padding: '0', overflow: 'hidden', width: '40px'}}>
                {
                    <img src={'https://media.trustedsource.co.za/medium?url=' + photo?.file} style={{width: '100%', verticalAlign: 'middle'}}/>
                }
            </div> :
            <div style={{textAlign: 'center', padding: '0', overflow: 'hidden', width: '40px'}}>
                {
                    <img src={noImage} style={{width: '100%', verticalAlign: 'middle'}}/>
                }
            </div>
          ),
          gtin: (
            <div
              className={styles.gtin}
              onClick={() => {
                setIsOpenProductFormForView(true);
                cleareDraftProductStore();
                dispatch(getRetailerProductByGtin(gtin));
                //navigate(`/items/${gtin}/details`);
                setIsOpenDraftProductsFormModal(true);
              }}
            >
              {gtin}
            </div>
          ),
          description: (
            <span
              className={
                user?.is_admin ? styles.descriptionAdmin : styles.description
              }
            >
              {description}
            </span>
          ),
          company: (
            <div className={styles.companyName}>{company_name}</div>
          ),
          brand: (
            <div className={styles.companyName}>{brand}</div>
          ),
          gpc: (
            <div className={styles.companyName}>{gpc}</div>
          ),
          skuid: (
            <div className={styles.companyName}>{skuid}</div>
          ),
          hierarchy: (
            <ProductHierarchy 
                items={items} 
                style={{minWidth: '262px'}}
                />
          ),
          date: <div className={styles.date}>{date}</div>,
          empty: <div className={styles.emptyField} />,
          items,
        };
      }
    );
  };

  const getScroll = () => {
    let s = size !== undefined && size !== null ?
        `calc(100vh - ${size}px)` :
        `300px`;
    return s;
  }

  return (
    <>
        {showFilters === undefined || showFilters === true ?
      <ActiveProductsFilter
        title={title}
        totalDraftProductsCount={totalProducts}
        countCheckedActiveProducts={
          activeProductsForDeactivationDeletion.length
        }
      /> : '' }
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <ActiveProductIcon className={styles.emptyIcon} />
                <div>Not products activated</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          expandable={{
            expandedRowRender: (product) => (
              <ProductItemsTable productItems={product.items} />
            ),
          }}
          columns={getColumns()}
          pagination={false}
          // calc(-475px + 100vh)
          // calc(-294px + 100vh)
          scroll={{ y: getScroll() }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        {showPagination === undefined || showPagination === true ?
        <Pagination
          disabled={activeProducts?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setAdditionalFilter({ page, paginate: perPage }));
              setActiveProductsForDeactivationDeletion([]);
            } else {
              dispatch(setAdditionalFilter({ page }));
              setActiveProductsForDeactivationDeletion([]);
            }
            setPerPage(perPage)
          }}
          total={totalProducts || perPage}
          showSizeChanger={true}
          pageSizeOptions={["10", "15", "20", "50", "100"]}
          pageSize={perPage}
        /> : '' }
      </div>
    </>
  );
};

export default SuppliersList;
