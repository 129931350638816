import React from "react";
import settings from "../settings";
import components from "../components";
import ForgotPage from "../modules/auth/pages/Forgot";
import RecoverPage from "../modules/auth/pages/Recover";
import CompaniesPage from "../modules/companies/pages/Companies";
import UploadPage from "../modules/upload/pages/upload";
import ItemsPage from "../modules/items/pages/Items";
import ProductsPage from "../modules/products/pages/products";
import UsersPage from "../modules/users/pages/users";
import SignInAdminPage from "../modules/auth/pages/SignInAdmin";
import ItemFormPage from "../modules/itemForm/pages/ItemForm";
import PublishPage from "../modules/publish/pages";
import DigitalLinks from "../modules/digitalLinks";
import ItemFormPublicPage from "../modules/itemForm/pages/ItemFormPublic";
import ItemDetailsFormPage from "../modules/items/pages/ItemDetailsForm";
import ItemDetailsPage from "../modules/items/pages/ItemDetails";
import Elabel from "../modules/elabel/pages/Elabel";

export interface IRoute {
  path: string;
  element: React.ComponentType;
}

export enum RoutesNames {
  LOGIN = "/login",
  LOGIN_ADMIN = "/admin/login",
  FORGOT = "/login/forgot",
  COMPANIES = "/companies",
  USERS = "/users",
  ITEM_FORM = '/item-form',
  UPLOAD = "/upload",
  ITEMS = "/items",
  PRODUCTS = "/products",
  SUPPLIERS = "/suppliers",
  REPORTS = "/reports",
  RECOVER_PASSWORD = "/password/reset/:token/:email",
  PUBLISH = "/publish",
  DIGITAL_LINKS = '/digital_links',
  PUBLIC_ITEM = '/01/:gtin',
  ITEM_DETAILS_FORM = '/items/:gtin',
  ITEM_DETAILS = '/items/:gtin/details',
  ELABEL = '/elabel',
  ELABEL_DETAILS = '/elabel/:gtin',
  SET_PASSWORD = "/password/reset/:token/:email/:new",
  DASHBOARD = "/dashboard",
  ITEM_CATALOG_PAGE = "/items/:gtin/catalog",
  CATALOGUE_PAGE = "/catalogue",
  CATALOGUE_CUSTOMIZE = "/catalogue/customize/:template",
  CATALOGUE_SELECT_WINES = "/catalogue/select-wines/:template",
  CATALOGUE_PREVIEW = "/catalogue/preview/:template"
}

console.log(settings.platform)

export const publicRoutes: IRoute[] = [
  { path: RoutesNames.LOGIN, element: components[settings.platform].login },
  { path: RoutesNames.LOGIN_ADMIN, element: SignInAdminPage },
  { path: RoutesNames.FORGOT, element: components[settings.platform].forgot },
  { path: RoutesNames.RECOVER_PASSWORD, element: RecoverPage },
  { path: RoutesNames.SET_PASSWORD, element: RecoverPage },
];

export const publicPrivateRoutes: IRoute[] = [
    { path: RoutesNames.PUBLIC_ITEM, element: ItemFormPublicPage },
]

export const privateRoutes: IRoute[] = [
  { path: RoutesNames.COMPANIES, element: CompaniesPage },
  { path: RoutesNames.USERS, element: UsersPage },
  { path: RoutesNames.ITEM_FORM, element: ItemFormPage },
  { path: RoutesNames.UPLOAD, element: components[settings.platform].upload },
  { path: RoutesNames.ITEMS, element: components[settings.platform].items },
  { path: RoutesNames.PRODUCTS, element: components[settings.platform].products },
  { path: RoutesNames.PUBLISH, element: PublishPage },
  { path: RoutesNames.DIGITAL_LINKS, element: components[settings.platform].digitalLinks },
  { path: RoutesNames.ITEM_DETAILS_FORM, element: components[settings.platform].itemForm },
  { path: RoutesNames.ITEM_DETAILS, element: components[settings.platform].productDetails },
  { path: RoutesNames.ELABEL, element: Elabel },
  { path: RoutesNames.ELABEL_DETAILS, element: Elabel },
  { path: RoutesNames.DASHBOARD, element: components[settings.platform].dashboard },
  { path: RoutesNames.SUPPLIERS, element: components[settings.platform].suppliers },
  { path: RoutesNames.REPORTS, element: components[settings.platform].reports },
  { path: RoutesNames.ITEM_CATALOG_PAGE, element: components[settings.platform].item_catalog_page },
  { path: RoutesNames.CATALOGUE_PAGE, element: components[settings.platform].catalogue },
  { path: RoutesNames.CATALOGUE_CUSTOMIZE, element: components[settings.platform].catalogue_customize },
  { path: RoutesNames.CATALOGUE_SELECT_WINES, element: components[settings.platform].catalogue_select_wines },
  { path: RoutesNames.CATALOGUE_PREVIEW, element: components[settings.platform].catalogue_preview },
];
