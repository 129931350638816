import React, { useEffect, useState } from "react";
import { Col, Row, Tabs } from 'antd';
//import NutritionalDetails from "./NutritionalDetails";

export default function Nutritional(nutritionalData) {
    // Count nutrientDetail entries
    var nutrientDetailSize = 0;
    var nutrientDetailTS = 0;

    if(Array.isArray(nutritionalData)) {
        nutritionalData.map((value, key) => {
            if(typeof value['nutrientDetail'] !== 'undefined' && value['nutrientDetail'].length > 0) {
                nutrientDetailSize++
            } else if (typeof value['source'] !== 'undefined' && value['source'] == "TS") {
                nutrientDetailTS++
            }
        })
    }

    const displayNutritionalDetail = (detail) => {
        let nutrientStructure = buildNutrients(detail)

        let lineStyle = { borderBottom: "1px solid #bbb"}

        const nutrientCodes = 
        {
            'CHOAVL':   'Total Carbohydrate',
            'FIBTG' :   'Dietary Fiber',
            'FAT'   :   'Total Fat',
            'PRO-'  :   'Total Protein',
            'FASAT' :   'Saturated Fat',
            'SUGAR-':   'Total Sugars',
            'FATRN':    'Fatty acids, total trans',
            'CHOCSM':   'Carbohydrate, total; summation',
            'CHOL-':    'Cholesterol; unknown',
            'NA':       'Sodium',
            'NACL':     'Salt',
            'ENER-':    'Energy; unknown',
            'PROTMFP':  'Protein from meat, fish and poultry',
            'ENER-KJ':  'Energy (Kj)',
            'FATNLEA':  'Total fat (by NLEA definition)',
            'FAPUCIS':  'FA, polyunsat.cis',
            'ENERPA':   'Energy % from alc.',
            'FAMSCIS':  'FA, monounsat.cis',
            'SALTEQ':   'Salt Equivalent:',
            'AAAN':     'Amino acids, total aromatic; Per quantity of nitrogen',
            '10FDHF':   '10-formyldihydrofolate',
        }
        
        let result = Object.keys(nutrientStructure).map((keyFam)=>{
            let familyNutrient = getNutrient(nutrientStructure[keyFam], keyFam);
            return  <Col>
                        {familyNutrient !== undefined ?
                                <Col style={lineStyle}>
                                    <Col>
                                        <Col ><span>{nutrientCodes[familyNutrient["nutrientTypeCode"]]}</span></Col>
                                        <Col ><span>{Array.isArray(familyNutrient["quantityContained"])?familyNutrient["quantityContained"][0]:familyNutrient["quantityContained"]} {familyNutrient["quantityContainedUom"] !== undefined?familyNutrient["quantityContainedUom"]:''}</span></Col>
                                    </Col>
                                    <Col>
                                        {familyNutrient["dailyValueIntakePercent"] !== undefined?
                                        <span>{parseFloat(familyNutrient["dailyValueIntakePercent"]).toFixed(2)}%</span>
                                        :familyNutrient["dailyvalueIntakePercent"] !== undefined?
                                            <span>{parseFloat(familyNutrient["dailyvalueIntakePercent"]).toFixed(2)}%</span>
                                            :''
                                        }
                                    </Col>
                                </Col>
                            :
                                ''
                        }
                        {
                            nutrientStructure[keyFam].map((nut) => (
                                keyFam !== nut["nutrientTypeCode"] ?
                                        <Col style={lineStyle}>
                                            <Col>
                                                <Col><span>{nutrientCodes[nut["nutrientTypeCode"]]}</span></Col>
                                                <Col><span>{Array.isArray(nut["quantityContained"])?nut["quantityContained"][0]:nut["quantityContained"]} {nut["quantityContainedUom"] !== undefined?nut["quantityContainedUom"]:''}</span></Col>
                                            </Col>
                                            <Col>
                                                {nut["dailyValueIntakePercent"] !== undefined?
                                                    <span>{parseFloat(nut["dailyValueIntakePercent"]).toFixed(2)}%</span>
                                                :nut["dailyvalueIntakePercent"] !== undefined?
                                                    <span>{parseFloat(nut["dailyvalueIntakePercent"]).toFixed(2)}%</span>
                                                    :''
                                                }
                                            </Col>
                                        </Col>
                                    :
                                    ''
                            ))
                        }
                    </Col>
        })

        return result;
    }

    const nutrientFamily = {
        'FAT' :     ['FAT', 'FASAT', 'FATRN', 'FATNLEA'],
        'CHOAVL' :  ['CHOAVL', 'SUGAR-', 'FIBTG'],
        'PRO-' :    ['PRO-', 'PROTMFP'],
    }

    const getNutrient = (list, code) => {
        let nutrient = undefined;
        let list_code = '';
        list.map((n) => {
            list_code = Array.isArray(n['nutrientTypeCode']) ? n['nutrientTypeCode'][0] : n['nutrientTypeCode'];
            if(n['nutrientTypeCode'] == code) {
                nutrient = n;
            }
        });
        return nutrient;
    }

    const getNutrientFamily = (code) => {
        let family = 'OTHER';
        Object.keys(nutrientFamily).map((key) => {
            if(nutrientFamily[key].includes(code)) {
                family = key;
            }
        });

        return family;
    }

    const buildNutrients = (arrayNutrients) => {
        console.log(arrayNutrients)
        let nutrientStructure = {};
        if(Array.isArray(arrayNutrients)) {
            arrayNutrients.map((value) => {
                let code = Array.isArray(value['nutrientTypeCode']) ? value['nutrientTypeCode'][0] : value['nutrientTypeCode'];
                let family = getNutrientFamily(code);
                if(nutrientStructure[family] === undefined) {
                    nutrientStructure[family] = [];
                }
                nutrientStructure[family].push(value);
            });
        } else {
            let code = arrayNutrients.nutrientTypeCode;
            let family = getNutrientFamily(code);
            if(nutrientStructure[family] === undefined) {
                nutrientStructure[family] = [];
            }
            nutrientStructure[family].push(arrayNutrients);
        }
        return nutrientStructure;
    }


    let headerStyle = {borderBottom: "10px solid #000", padding: "0 0 0.25rem", margin: "0 0 0.5rem" }
    let boxStyle = {border: "1px solid #000", width: "400px", padding: "0.5rem"}
    let subHeader = {borderBottom: "2px solid rgb(0, 0, 0)"}

    console.log(nutritionalData)
    console.log(nutrientDetailTS)

    return  nutritionalData !== undefined && (nutrientDetailSize > 0 || nutrientDetailTS > 0) ?
                
                    <Col>
                        {nutritionalData.map((nElem) => (
                            typeof nElem['nutrientDetail'] !== 'undefined' ?
                            <Col style={{marginBottom: "30px"}}>
                                <Row style={boxStyle}>
                                    <Col style={headerStyle}>
                                        <Col style={{borderBottom: "1px solid #000"}}><span>Nutrition Facts</span></Col>
                                        <Col>
                                            <span>
                                                {nElem['servingSizeDescription'] !== undefined ? Array.isArray(nElem['servingSizeDescription']) ? parseFloat(nElem['servingSizeDescription'][0]).toFixed(2) ? parseFloat(nElem['servingSizeDescription'][0]).toFixed(2) + 'SERVING PER ' : '': !parseFloat(nElem['servingSizeDescription']).toFixed(2)?parseFloat(nElem['servingSizeDescription']).toFixed(2) + 'SERVING PER ': '' : ""}
                                            </span>
                                        </Col>
                                        <Col>
                                            <span>
                                                SERVING SIZE {nElem['servingSize'] !== undefined ? Array.isArray(nElem['servingSize']) ? parseFloat(nElem['servingSize'][0]).toFixed(2) : parseFloat(nElem['servingSize']).toFixed(2) : ""}
                                            </span>
                                        </Col>
                                    </Col>
                                    <Col style={subHeader}>
                                        <Col><span>Amount Per Serving</span></Col>
                                        {typeof nElem['calories'] !== 'undefined' ?
                                            <Col>
                                                <Col><span>Calories</span></Col>
                                                <Col><span>{ parseFloat(nElem['calories']['cal']).toFixed(2) } kcal / {parseFloat(nElem['calories']['jul']).toFixed(2) } kj</span></Col>
                                            </Col>
                                        : ''
                                        }
                                    </Col>
                                    <Col>
                                        <Col><span>% Daily Value*</span></Col>
                                    </Col>
                                    
                                    {displayNutritionalDetail(nElem["nutrientDetail"])}
                                     
                                    <Col>
                                        <Col>
                                            <Col><span>* PERCENT DAILY VALUES ARE BASED ON A 2,000 CALORIE DIET. YOUR DAILY VALUES MAY BE HIGHER OR LOWER DEPENDING ON YOUR CALORIE NEEDS</span></Col>
                                        </Col>
                                        <Col>
                                            <Col><span>CALORIES PER GRAM:</span></Col>
                                        </Col>
                                        <Col>
                                            <Col><span>Fat 9 &bull; Carbohydrate 4 &bull; Protein 4</span></Col>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                            : ''
                        ))}
                    </Col>
                    : ''
                    
}