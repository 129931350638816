import authAxios from "../../../../helpers/authAxios";
import { Button } from "antd";
import { FC, useEffect } from "react";
import styles from "./filter.module.scss";
import FilterControl from "../../../common/components/FilterControl";
import {
  SortedByProductsFilterTypes,
  SortedByProductsFilterValues,
} from "../../constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getCompaniesNames, 
    getProductsGtins, 
    getProductsDataRecipientGtins, 
    getItemsDataRecipientGtins,
    getProductsDataRecipientGlns, 
    getProductsDataRecipientGpcs, 
    getProductsDataRecipientBrands 
} from "../../../../api/filter";
import { useActions } from "../../../../hooks/useActions";
import { SelectData } from "../../../common/types";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ReactComponent as ActiveProductIcon } from "../../../common/icons/activeProduct.svg";
import { ReactComponent as TrashIcon } from "../../../common/icons/trash.svg";
import { FilterType } from "../../../common/constants/filter";

interface FilterOwnProps {
  countCheckedActiveProducts: number;
  totalDraftProductsCount: number;
  title?: string;
}

const sortedByValues: SelectData[] = [
  {
    name: SortedByProductsFilterTypes.PRODUCT_ID,
    value: SortedByProductsFilterValues.GTINS,
  },
  {
    name: SortedByProductsFilterTypes.SUPPLIERS,
    value: SortedByProductsFilterValues.SUPPLIERS,
  },
  {
    name: SortedByProductsFilterTypes.GPCS,
    value: SortedByProductsFilterValues.GPCS,
  },
  {
    name: SortedByProductsFilterTypes.BRANDS,
    value: SortedByProductsFilterValues.BRANDS,
  },
];

const Filter: FC<FilterOwnProps> = ({
  countCheckedActiveProducts,
  totalDraftProductsCount,
  title
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setIsConfirmActiveProductDeletionModal,
    setIsOpenActiveProductChangeStatusModal,
    setActiveProductsForDeactivationDeletion,
  } = useActions();

  const { activeProductsForDeactivationDeletion } = useAppSelector(
    (state) => state.activeProducts
  );

  const { user } = useAppSelector((state) => state.auth);

  const { sortedByData, isSortedByStatus } = useAppSelector(
    (state) => state.additionalFilterControl
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const filter = useAppSelector((state) => state.additionalFilter);

  useEffect(() => {
    if (userCompanyId) {
        dispatch(
            //getProductsGtins(FilterType.ADDITIONAL, userCompanyId as number)
            getItemsDataRecipientGtins(FilterType.ADDITIONAL, userCompanyId as number)
          );
          dispatch(
            //getProductsGtins(FilterType.ADDITIONAL, userCompanyId as number)
            getProductsDataRecipientGlns(FilterType.ADDITIONAL, userCompanyId as number)
          );
          dispatch(
            //getProductsGtins(FilterType.ADDITIONAL, userCompanyId as number)
            getProductsDataRecipientGpcs(FilterType.ADDITIONAL, userCompanyId as number)
          );
          dispatch(
            //getProductsGtins(FilterType.ADDITIONAL, userCompanyId as number)
            getProductsDataRecipientBrands(FilterType.ADDITIONAL, userCompanyId as number)
          );
    } else {
      //dispatch(getProductsGtins(FilterType.ADDITIONAL));
      dispatch(getItemsDataRecipientGtins(FilterType.ADDITIONAL));
    }
  }, [dispatch, userCompanyId]);

  const sortedByValuesWithCompanies = () => {
    if (user?.is_admin) {
      return [
        ...sortedByValues,
        {
          name: SortedByProductsFilterTypes.COMPANIES,
          value: SortedByProductsFilterValues.COMPANIES,
        },
      ];
    }

    return sortedByValues;
  };

  const handleExportClick = async () => {

    let props = {...filter};
    const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
    const checkDateParams = !props.dateStart || !props.dateEnd;

    const requestDataWithParams = () => {
        if (!checkIdsParams && !checkDateParams) {
        return {
            [props.sortedByFilterIds?.sortByName as any]: props
            .sortedByFilterIds?.filterIds as number[],
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
        };
        } else {
        if (!checkIdsParams) {
            return {
            [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
            };
        }

        if (!checkDateParams) {
            return {
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
            };
        }
        }
    };
    //dispatch(itemInfoSlice.actions.getItemInfo());

    const data = await authAxios(dispatch).post(
        `/api/item-infos/export?&search=${props.search}&company_id=${userCompanyId}`, requestDataWithParams()
    ) as string;
    
    const url = window.URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
}

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterControlWrapper}>
        <div className={styles.title}>
          <ActiveProductIcon className={styles.titleIcon} />
          <div className={styles.titleText}>
            <div className={styles.titleTextTop}>{title}</div>
            <div
              className={styles.titleTextBottom}
            >{`${totalDraftProductsCount}`}</div>
          </div>
        </div>
        <FilterControl
          searchName="Products"
          cleareActivationDeletionIdes={() =>
            setActiveProductsForDeactivationDeletion([])
          }
          sortedBy={sortedByValuesWithCompanies()}
          sortedByData={sortedByData}
          isSortedByStatus={isSortedByStatus}
          filterControlType={FilterType.ADDITIONAL}
          dateRangePicker={false}
          getSortedByData={(sortValue) => {
            if (sortValue === SortedByProductsFilterValues.GTINS) {
              if (userCompanyId) {
                dispatch(
                    getItemsDataRecipientGtins(
                    FilterType.ADDITIONAL,
                    userCompanyId as number
                  )
                );
              } else {
                dispatch(getProductsGtins(FilterType.ADDITIONAL));
              }
            }
            if (sortValue === SortedByProductsFilterValues.SUPPLIERS) {
                dispatch(getProductsDataRecipientGlns(FilterType.ADDITIONAL, userCompanyId as number));
            }
            if (sortValue === SortedByProductsFilterValues.GPCS) {
                dispatch(getProductsDataRecipientGpcs(FilterType.ADDITIONAL, userCompanyId as number));
            }
            if (sortValue === SortedByProductsFilterValues.BRANDS) {
                dispatch(getProductsDataRecipientBrands(FilterType.ADDITIONAL, userCompanyId as number));
            }
          }}
        />
        <div className={styles.countInfoWrapper}>
          {/*<div className={styles.countInfoField}>
            {!countCheckedActiveProducts
              ? totalDraftProductsCount
              : countCheckedActiveProducts}
          </div>
           <div className={styles.countInfoTitle}>
            {!countCheckedActiveProducts ? "Active products" : "Selected"}
          </div> */}
        </div>
      </div>
      
      <button className={styles.exportButton} onClick={handleExportClick}>EXPORT</button>

      {activeProductsForDeactivationDeletion.length !== 0 && (
        <div className={styles.buttonsIsCheckGroup}>
          <div
            className={styles.buttonDelete}
            onClick={() => {
              setIsConfirmActiveProductDeletionModal(true);
            }}
          >
            <TrashIcon className={styles.trashIcon} />
            <div>Delete</div>
          </div>
          <Button
            className={styles.buttonActivate}
            type="primary"
            onClick={() => {
              setIsOpenActiveProductChangeStatusModal(true);
            }}
          >
            <div>To drafts</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filter;
