import { createSlice } from "@reduxjs/toolkit";
import {
  CompaniesRejectedAction,
  CompaniesState,
  CompaniesValidationErrorAction,
  DeleteCompaniesAction,
  GetCompaniesFulfilledAction,
  GetCompanyFulfilledAction,
  SetUserCompanyIdAction,
  SetUserCompanyTypeAction,
} from "../../../modules/companies/types";

const initialState: CompaniesState = {
  companies: null,
  company: null,
  userCompanyId: "",
  totalItems: 0,
  companiesForDeletion: [],
  isLoading: false,
  validationError: null,
  error: null,
  userCompanyType: ""
};

export const companiesSlice = createSlice({
  name: "companiesSlice",
  initialState,
  reducers: {
    getAllCompaniesPending: (state: CompaniesState) => {
      state.isLoading = true;
    },
    getAllCompaniesFulfilled: (
      state: CompaniesState,
      action: GetCompaniesFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.companies = action.payload.companies;
      state.totalItems = action.payload.totalItems;
    },
    getAllCompaniesRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCompanyPending: (state: CompaniesState) => {
      state.isLoading = true;
    },
    getCompanyFulfilled: (
      state: CompaniesState,
      action: GetCompanyFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.company = action.payload.company;
    },
    getCompanyRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createCompanyPending: (state: CompaniesState) => {
      state.isLoading = true;
    },
    createCompanyFulfilled: (state: CompaniesState) => {
      state.isLoading = false;
      state.error = null;
    },
    createCompanyRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editCompanyPending: (state: CompaniesState) => {
      state.isLoading = true;
    },
    editCompanyFulfilled: (state: CompaniesState) => {
      state.isLoading = false;
      state.error = null;
    },
    editCompanyRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusCompanyPending: (state: CompaniesState) => {
      state.isLoading = true;
    },

    changeStatusCompanyFulfilled: (state: CompaniesState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusCompanyRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCompaniesPending: (state: CompaniesState) => {
      state.isLoading = true;
    },

    deleteCompaniesFulfilled: (state: CompaniesState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteCompaniesRejected: (
      state: CompaniesState,
      action: CompaniesRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCompaniesForDeletion: (
      state: CompaniesState,
      action: DeleteCompaniesAction
    ) => {
      state.companiesForDeletion = action.payload;
    },
    setUserCompanyId: (
      state: CompaniesState,
      action: SetUserCompanyIdAction
    ) => {
      state.userCompanyId = action.payload;
    },
    setValidationError: (state: CompaniesState, action: CompaniesValidationErrorAction) => {
      state.isLoading = false;
      state.validationError = action.payload;
    },
    clearCompaniesForDeletion: (state: CompaniesState) => {
      state.companiesForDeletion = [];
    },
    cleareCompanyStore: (state: CompaniesState) => {
      state.company = null;
    },
    cleareCompaniesStore: (state: CompaniesState) => {
      state.companies = null;
      state.company = null;
      state.totalItems = 0;
      state.companiesForDeletion = [];
      state.isLoading = false;
      state.userCompanyId = "";
      state.error = null;
    },
    cleareCompanyValidationError: (state: CompaniesState) => {
      state.isLoading = false;
      state.validationError = null;
    },
    setUserCompanyType: (
      state: CompaniesState,
      action: SetUserCompanyTypeAction
    ) => {
      state.userCompanyType = action.payload;
    },
  },
});
