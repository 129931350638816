import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import { Summary } from "../components/Summary/indexRetailer";
import ProductsList from "../../products/components/ActiveProductsListRetailer";
import { useActions } from "../../../hooks/useActions";
import { DashboardOutlined } from '@ant-design/icons';

const DashboardPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Dashboard"
      headerLogo={<DashboardOutlined />}
    >
      <div style={{marginBottom: '20px'}}>
        <Summary />
      </div>
      <h3>Recent Updates</h3>
      <ProductsList size={475} title={'Products'} showImages={true} showFilters={false} pageSize={8} showPagination={false}/>
    </AppLayout>
  );
};

export default DashboardPage;
