import { FC } from "react";
import { ProductItem } from "../../types";
import { ReactComponent as UploadIcon } from "../../../common/icons/upload.svg";
import styles from "./raftProductsFormItemsView.module.scss";
import "./draftProductsFormItemsView.global.scss";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useActions } from "../../../../hooks/useActions";
import { getItemFormFieldsByCompanyId } from "../../../../api/itemForm";
import { getActiveItemById } from "../../../../api/items";
import { ModalTabs } from "../../../common/constants/modalTabs";

interface DraftProductsFormItemsViewOwnProps {
  productItems: ProductItem[] | [];
  companyId: number;
}

const DraftProductsFormItemsView: FC<DraftProductsFormItemsViewOwnProps> = ({
  productItems,
  companyId
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    cleareActiveItemStore,
    cleareDraftItemStore,
    setIsOpenDraftItemsFormModal,
    setModalActiveTab,
  } = useActions();

  const tableColumns = [
    {
      Header: "GTIN",
      accessor: "gtin",
    },
    {
      Header: "ITEM DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "DESCRIPTOR",
      accessor: "descriptor",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
  ];
  return (
    <div className="draftProductsFormItemsViewWrapper">
      <ul className={styles.listTbodyItemsField}>
        {productItems.length !== 0 ? (
          productItems.map((item: any, index) => {
            return (
              <li key={index} className={`itemTr${item.descriptor}`}>
                {tableColumns.map((column) => {
                  const key: string = column.accessor;
                  if (key === "gtin") {
                    return (
                      <div
                        key={item[key]}
                        className="itemTdGtin"
                        onClick={() => {
                          cleareDraftItemStore();
                          cleareActiveItemStore();
                          dispatch(
                            getItemFormFieldsByCompanyId(String(companyId))
                          );
                          dispatch(getActiveItemById(item.id as number));
                          setIsOpenDraftItemsFormModal(true);
                          setModalActiveTab(ModalTabs.MAIN_FORM);
                        }}
                      >
                        {item[key]}
                      </div>
                    );
                  } else {
                    return (
                      <div key={item[key]} className="itemTd">
                        {item[key]}
                      </div>
                    );
                  }
                })}
              </li>
            );
          })
        ) : (
          <div className={styles.uploadWrapper}>
            <UploadIcon className={styles.uploadIcon} />
            <div className={styles.uploadText}>Drag&drop to add an item</div>
          </div>
        )}
      </ul>
    </div>
  );
};

export default DraftProductsFormItemsView;
