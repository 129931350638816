import { createSlice } from "@reduxjs/toolkit";
import { FilterControlAction, FilterControlState, SetStatusAction } from "../../../modules/common/types";

export const filterControlSlice = createSlice({
  name: "filterControlSlice",
  initialState: {
    sortedByData: [],
    isSortedByStatus: false,
    isLoading: false,
    error: null
  },
  reducers: {
    clearFilterControl: (state: FilterControlState) => {
      state.sortedByData = [];
      state.isSortedByStatus = false;
    },
    getFilterControlSortedByDataPending: (state: FilterControlState) => {
      state.isLoading = true;
    },
    getFilterControlSortedByDataFulfilled: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.sortedByData = action.payload.sortedByData;
    },
    getFilterControlSortedByDataRejected: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    setFilterControlSortedByStatus: (
      state: FilterControlState,
      action: SetStatusAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.isSortedByStatus = action.payload;
    },
  },
});
