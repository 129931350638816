import { Checkbox, Table, Select, Button } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./itemFormFieldsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import {
  companyAssignFields,
  getAllItemFormFields,
} from "../../../../api/itemForm";
import InputFieldTitle from "../../../common/components/InputFieldTitle";
import { FieldsToAddToCompanyProps } from "../../types";
const { Option } = Select

export const CompanyFieldsExpandForm: FC = (): JSX.Element => {
  const [changingCompany, setChangingCompany] = useState()
  const dispatch = useAppDispatch();
  const {
    setFieldsToAddToCompany,
    setIsOpenCompanyAddFieldsModal
  } = useActions();

  const {
    itemFormFields,
    totalItems,
    isLoading,
    fieldsToAddToCompany
  } = useAppSelector((state) => state.itemForm);

  const { companies } = useAppSelector((state) => state.companies);

  useEffect(() => {
    totalItems || dispatch(getAllItemFormFields({
      page: 1,
      sortedByFilterIds: null,
    }));
    totalItems && dispatch(getAllItemFormFields({
      page: 1,
      sortedByFilterIds: null,
      paginate: totalItems
    }));
  }, [dispatch, totalItems]);

  const getColumns = () => [
    {
      title: (
        <Checkbox
          className={styles.mainCheckbox}
          checked={fieldsToAddToCompany.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const checkAllItemFormFields = itemFormFields?.map((field) => {
                return { isChecked: true, id: field.id };
              });
              setFieldsToAddToCompany(
                checkAllItemFormFields as FieldsToAddToCompanyProps[]
              );
            } else {
              setFieldsToAddToCompany([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "FIELD NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "FIELD TYPE",
      dataIndex: "field_type_name",
      key: "field_type_name",
    },
    {
      title: "FORM TAB TYPE",
      dataIndex: "item_tab_name",
      key: "item_tab_name",
    },
  ];

  const getDataSource = () => {
    return itemFormFields?.map(
      ({ id, name, field_type_name, item_tab_name }) => {
        return {
          key: id,
          check: (
            <Checkbox
              checked={
                fieldsToAddToCompany.find(
                  (value: FieldsToAddToCompanyProps) => value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setFieldsToAddToCompany([
                    ...fieldsToAddToCompany,
                    data as FieldsToAddToCompanyProps,
                  ]);
                } else {
                  (
                    fieldsToAddToCompany.filter((field) => field.id !== id)
                  );
                }
              }}
            />
          ),
          name: (
            <div
              className={styles.fieldInfo}
            >
              {name}
            </div>
          ),
          field_type_name: (
            <div className={styles.fieldTypeName}>{field_type_name}</div>
          ),
          item_tab_name: (
            <div className={styles.itemTabName}>{item_tab_name}</div>
          ),
        };
      }
    );
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        <InputFieldTitle
          title=""
          required={false}
        >
          <Select
            size="middle"
            value={changingCompany}
            placeholder="Company"
            onChange={(value) => {
              setChangingCompany(value);
            }}
          >
            {companies && companies.map((company) => (
              <Option key={company.id} value={`${company.id}`}>
                {company.company}
              </Option>
            ))}
          </Select>
        </InputFieldTitle>
        <Table
          className={styles.table}
          locale={{ emptyText: "No search results found" }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: "calc(100vh - 458px)" }}
          loading={isLoading}
        />
        <Button
          type="primary"
          className='apply'
          disabled={!changingCompany || !fieldsToAddToCompany || fieldsToAddToCompany.length < 1}
          onClick={() => {
            dispatch(companyAssignFields({
              'company_id': `${changingCompany}`,
              fields: fieldsToAddToCompany.map(field => field.id)
            }))
            setIsOpenCompanyAddFieldsModal(false)
            setFieldsToAddToCompany([])
          }}
        >
          {'Assign Fields to Company'}
        </Button>
      </div>
    </>
  );
};
