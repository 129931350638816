import { Col } from 'antd';
import { InputProps } from 'antd/lib/input';
import styles from './index.module.scss';

type ProductHierarchyElementInputProps = {
    descriptor: string,
    big: boolean,
    gtin?: string,
    click?: Function,
    highlight: boolean,
} & InputProps;

const ProductHierarchyElement = ({
    descriptor,
    big,
    gtin,
    click,
    highlight,
    ...props
}: ProductHierarchyElementInputProps) => {
    let style = descriptor === 'PL' ? `${styles.descriptorPL}` :
        descriptor === 'CS' ? `${styles.descriptorCS}` :
        descriptor === 'SHR' || descriptor === 'PK' ? styles.descriptorSHR :
        descriptor === 'EA' ? styles.descriptorEA :
        styles.descriptorOther;

    style += big ? ` ${styles.big}` : ''
    style += highlight ? ` ${styles.active}` : ''

    const voidFunction = (g) => {
        //console.log(g)
    }

    const funcc = () => {
        if(click !== undefined) {
            click(gtin)
        }
        return gtin
    }

    if(click === undefined || click === null) {
        click = voidFunction
    }

    return (<span className={ 
        style
    } onClick={funcc}>
        {descriptor}
    </span>)
}

export default ProductHierarchyElement;