import { createSlice } from "@reduxjs/toolkit";
import { FilterControlAction, FilterControlState, SetStatusAction } from "../../../modules/common/types";

export const additionalFilterControlSlice = createSlice({
  name: "additionalFilterControlSlice",
  initialState: {
    sortedByData: [],
    isSortedByStatus: false,
    isLoading: false,
    error: null
  },
  reducers: {
    clearAdditionalFilterControl: (state: FilterControlState) => {
      state.sortedByData = [];
      state.isSortedByStatus = false;
    },
    getAdditionalFilterControlSortedByDataPending: (state: FilterControlState) => {
      state.isLoading = true;
    },
    getAdditionalFilterControlSortedByDataFulfilled: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.sortedByData = action.payload.sortedByData;
    },
    getAdditionalFilterControlSortedByDataRejected: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    setAdditionalFilterControlSortedByStatus: (
      state: FilterControlState,
      action: SetStatusAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.isSortedByStatus = action.payload;
    },
  },
});
