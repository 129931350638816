import { Dispatch } from '@reduxjs/toolkit';
import authAxios from '../../helpers/authAxios';
import { modalsSlice } from '../../redux/reducers/modals';
import {
    ChangeItemFormFieldStatusRequest,
    GetAllItemFormFieldsRequestData,
    ItemFormField,
} from '../../modules/itemForm/types';
import { itemFormSlice } from '../../redux/reducers/itemForm';
import { filterSlice } from '../../redux/reducers/filter';
import { useActions } from '../../hooks/useActions';

export const getAllItemFormFields = (
    props: GetAllItemFormFieldsRequestData
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.getAllItemFormFieldsPending());

            // const { data } = await authAxios(dispatch).post(
            //   `/api/item-fields?page=${props.page}&paginate=${props.paginate}`,
            //   {
            //     [props.sortedByFilterIds?.sortByName as any]: props.sortedByFilterIds
            //       ?.filterIds as number[],
            //   }
            // );

            const { data } = await authAxios(dispatch).post(
                `/api/item-fields?page=${props.page}&paginate=${
                    props.paginate ? props.paginate : 10
                }`,
                {
                    companies: props.sortedByFilterIds
                        ? (props.sortedByFilterIds?.filterIds as number[])
                        : [],
                }
            );

            const responseData = data;

            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsFulfilled({
                    itemFormFields: responseData.data,
                    totalItems: responseData.pagination.total,
                })
            );

            if (responseData.data.length === 0) {
                dispatch(
                    filterSlice.actions.setFilter({
                        page: Number(responseData.pagination.total_pages),
                        paginate: Number(responseData.pagination.per_page),
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getItemFormFieldsByCompanyId = (companyId: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.getAllItemFormFieldsPending());

            const { data } = await authAxios(dispatch).get(
                `/api/get-item-fields/${companyId}`
            );

            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsFulfilled({
                    itemFormFields: data,
                    totalItems: 0,
                    company_id: companyId,
                })
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const requestDescription = (gtin: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(itemFormSlice.actions.setItemDescription('Generating description... (this may take a while)'))

        const { data } = await authAxios(dispatch).get(
            `/api/supplier/item/${gtin}/generate-description`
        );
        dispatch(itemFormSlice.actions.setItemDescription(data))
    };
};

export const requestImages = (gtin: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(itemFormSlice.actions.setItemImages(['/loading.gif']))

        const { data } = await authAxios(dispatch).get(
            `/api/supplier/item/${gtin}/generate-images`
        );
        dispatch(itemFormSlice.actions.setItemImages(data))
    };
};

export const getItemFormStaticFields = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.getAllItemFormStaticFieldsPending());

            const { data } = await authAxios(dispatch).get(
                `/api/item-fields/static-fields`
            );

            dispatch(
                itemFormSlice.actions.getAllItemFormStaticFieldsFulfilled(data)
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getAllItemFormStaticFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const companyAssignFields = (body: any) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            const { data } = await authAxios(dispatch).post(
                `/api/item-fields/assigned-companies`,
                body
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getAllItemFormStaticFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const CreateNewTab = (body: any) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            const { data } = await authAxios(dispatch).post(
                `/api/item-tabs`,
                body
            );
        } catch (error: any) {
            // dispatch(
            //   itemFormSlice.actions.getAllItemFormStaticFieldsRejected(
            //     error.data.message
            //   )
            // );
        }
    };
};

export const getItemFormFieldById = (id: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.getItemFormFieldPending());

            const { data } = await authAxios(dispatch).get(
                `/api/item-fields/${id}`
            );

            dispatch(itemFormSlice.actions.getItemFormFieldFulfilled(data));
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getItemFormFieldRejected(
                    error.data.message
                )
            );
        }
    };
};

export const createItemFormField = (body: ItemFormField) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.createItemFormFieldPending());

            const { data } = await authAxios(dispatch).post(
                `/api/item-fields/create`,
                body
            );

            dispatch(
                modalsSlice.actions.setItemFormFieldSuccessfulStatus({
                    status: true,
                    message: data,
                })
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.createItemFormFieldRejected(
                    error.data.message
                )
            );
        }
    };
};

export const editItemFormField = (body: ItemFormField) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.editItemFormFieldPending());

            const { data } = await authAxios(dispatch).put(
                `/api/item-fields/${body.id}`,
                body
            );

            dispatch(
                modalsSlice.actions.setItemFormFieldSuccessfulStatus({
                    status: true,
                    message: data,
                })
            );

            dispatch(itemFormSlice.actions.cleareItemFormFieldStore());
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.editItemFormFieldRejected(
                    error.data.message
                )
            );
        }
    };
};

export const changeItemFormFieldStatusRequest = (
    body: ChangeItemFormFieldStatusRequest
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.changeStatusItemFormFieldPending());

            await authAxios(dispatch).post(
                `/api/item-fields/change-status/${body.id}`,
                { status: body.status }
            );

            dispatch(
                modalsSlice.actions.setIsChangeItemFormFieldDeletionActivationActions(
                    false
                )
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.changeStatusItemFormFieldRejected(
                    error.data.message
                )
            );
        }
    };
};

export const deleteItemFormFields = (body: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const config = {
            data: { array_ids: body },
        };

        try {
            dispatch(itemFormSlice.actions.deleteItemFormFieldsPending());

            await authAxios(dispatch).delete(`/api/item-fields`, config);

            dispatch(itemFormSlice.actions.clearItemFormFieldsForDeletion());

            dispatch(
                modalsSlice.actions.setIsChangeItemFormFieldDeletionActivationActions(
                    false
                )
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.deleteItemFormFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const deleteTab = (id: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            await authAxios(dispatch).delete(`/api/item-tabs/${id}`);
        } catch (error: any) {}
    };
};

export const getElabelItemFormFieldsByCompanyId = (companyId: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemFormSlice.actions.getAllItemFormFieldsPending());

            const { data } = await authAxios(dispatch).get(
                `/api/get-elabel-item-fields/${companyId}`
            );

            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsFulfilled({
                    itemFormFields: data,
                    totalItems: 0,
                    company_id: companyId,
                })
            );
        } catch (error: any) {
            dispatch(
                itemFormSlice.actions.getAllItemFormFieldsRejected(
                    error.data.message
                )
            );
        }
    };
};