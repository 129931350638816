export enum SortedByCompaniesFilterTypes {
  COMPANIES = "Companies",
  GLN = "GLN",
  STATUS = "Status",
}

export enum SortedByCompaniesFilterValues {
  COMPANIES = "Companies",
  GLNS = "GLNS",
  STATUS = "Status",
}
