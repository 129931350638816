import { createSlice } from "@reduxjs/toolkit";
import {
  ItemsRejectedAction,
  ItemInfoState,
  GetDraftItemFulfilledAction,
  GetItemInfoFulfilledAction,
  DeletionActivationDeactivationDraftItemsAction,
  DraftItemFulfilledAction,
  DraftItemsValidationErrorAction,
} from "../../../modules/items/types";

const initialState: ItemInfoState = {
  item: null,
  isLoading: false,
  error: null,
};

export const itemInfoSlice = createSlice({
  name: "itemInfoSlice",
  initialState,
  reducers: {
    getItemInfo: (state: ItemInfoState) => {
        state.isLoading = true;
    },
    getItemInfoFulfilled: (
        state: ItemInfoState,
        action: GetItemInfoFulfilledAction
    ) => {
        console.log(action.payload);
        state.isLoading = false;
        state.item = action.payload.item.item_info;
    },
//     getAllDraftItemsFulfilled: (
//       state: ItemInfoState,
//       action: GetDraftItemsFulfilledAction
//     ) => {
//       state.isLoading = false;
//       state.error = null;
//       state.draftItems = action.payload.draftItems;
//       state.totalItems = action.payload.totalItems;
//       state.totalValidItems = action.payload.totalValidItems;
//     },
//     getAllDraftItemsRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     getDraftItemPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },
//     getDraftItemFulfilled: (
//       state: ItemInfoState,
//       action: GetDraftItemFulfilledAction
//     ) => {
//       state.isLoading = false;
//       state.error = null;
//       state.draftItem = action.payload.draftItem;
//     },
//     getDraftItemRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     getItemInfo: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },
//     createDraftItemPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },
//     createDraftItemFulfilled: (
//       state: ItemInfoState,
//       action: DraftItemFulfilledAction
//     ) => {
//       state.isLoading = false;
//       state.error = null;
//       state.draftItem = action.payload;
//     },
//     createDraftItemRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     editDraftItemPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },
//     editDraftItemFulfilled: (
//       state: ItemInfoState,
//       action: DraftItemFulfilledAction
//     ) => {
//       state.isLoading = false;
//       state.error = null;
//       state.draftItem = action.payload;
//     },
//     editDraftItemRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     changeStatusDraftItemPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },

//     changeStatusDraftItemFulfilled: (state: ItemInfoState) => {
//       state.isLoading = false;
//       state.error = null;
//     },
//     changeStatusDraftItemRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     deleteDraftItemsPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },

//     deleteDraftItemsFulfilled: (state: ItemInfoState) => {
//       state.isLoading = false;
//       state.error = null;
//     },
//     deleteDraftItemsRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     validateDraftItemPending: (state: ItemInfoState) => {
//       state.isLoading = true;
//     },

//     validateDraftItemFulfilled: (
//       state: ItemInfoState,
//       action: DraftItemFulfilledAction
//     ) => {
//       state.isLoading = false;
//       state.error = null;
//       state.draftItem = action.payload;
//     },
//     validateDraftItemRejected: (
//       state: ItemInfoState,
//       action: ItemsRejectedAction
//     ) => {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//     setDraftItemsForActivationDeletion: (
//       state: ItemInfoState,
//       action: DeletionActivationDeactivationDraftItemsAction
//     ) => {
//       state.draftItemsForActivationDeletion = action.payload;
//     },
//     setValidationError: (
//       state: ItemInfoState,
//       action: DraftItemsValidationErrorAction
//     ) => {
//       state.isLoading = false;
//       state.validationError = action.payload;
//     },
//     clearDraftItemsForActivationDeletion: (state: ItemInfoState) => {
//       state.draftItemsForActivationDeletion = [];
//     },
//     cleareDraftItemStore: (state: ItemInfoState) => {
//       state.draftItem = null;
//     },
//     cleareDraftItemsStore: (state: ItemInfoState) => {
//       state.draftItems = null;
//       state.draftItem = null;
//       state.totalItems = 0;
//       state.draftItemsForActivationDeletion = [];
//       state.isLoading = false;
//       state.error = null;
//     },
//     cleareDraftItemsValidationError: (state: ItemInfoState) => {
//       state.isLoading = false;
//       state.validationError = null;
//     },
   },
});
