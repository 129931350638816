import { ItemFormFieldIds } from "../constants";
import { ItemFormField } from "../types";

export const convertToItemFormFieldsWithSize = (itemFormFields: ItemFormField[]) => {
  const newItemFormFields: ItemFormField[] = [];
  const sizeSelects: ItemFormField[] = itemFormFields.filter(field => field.name === 'size');
  const itemFormFieldsWithoutSizeSelects = itemFormFields.filter(field => field.name !== 'size');

  itemFormFieldsWithoutSizeSelects.forEach(field => {
    newItemFormFields.push(field)
    if(field.field_type_id === ItemFormFieldIds.INPUT_NUMBER) {
      newItemFormFields.push(...sizeSelects.filter(selectField => selectField.key === `${field.key}Size`))
    }
  })

  return newItemFormFields;
}