import { createSlice } from "@reduxjs/toolkit";
import {
  FilterAction,
  FilterState,
  FilterStatusAction,
} from "../../../modules/common/types";

export const additionalFilterSlice = createSlice({
  name: "additionalFilterSlice",
  initialState: {
    page: 1,
    paginate: 15,
    search: "",
    status: "",
    sortedByFilterIds: null,
    dateStart: "",
    dateEnd: "",
    isLoading: false,
    error: null,
  },
  reducers: {
    setAdditionalFilter: (state: FilterState, action: FilterAction) => {
      const { page, paginate } = action.payload;
      state.page = page;
      state.paginate = paginate;
    },
    clearAdditionalFilter: (state: FilterState) => {
      state.page = 1;
      state.paginate = 15;
      state.status = "";
      state.search = "";
      state.sortedByFilterIds = null;
      state.dateStart = "";
      state.dateEnd = "";
    },
    setAdditionalFilterSearch: (state: FilterState, action: FilterAction) => {
      state.search = action.payload.search;
    },
    setAdditionalFilterStatus: (
      state: FilterState,
      action: FilterStatusAction
    ) => {
      state.status = action.payload;
    },
    sortedByAdditionalFilterIds: (state: FilterState, action: FilterAction) => {
      state.sortedByFilterIds = action.payload.sortedByFilterIds;
    },
    setAdditionalFilterStartDate: (
      state: FilterState,
      action: FilterAction
    ) => {
      state.dateStart = action.payload.dateStart;
    },
    setAdditionalFilterEndDate: (state: FilterState, action: FilterAction) => {
      state.dateEnd = action.payload.dateEnd;
    },
  },
});
