import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import InputField from "../../../common/components/InputField";
import InputFieldTitle from '../../../common/components/InputFieldTitle';
import styles from "./companiesForm.module.scss";
import { Button, Select } from "antd";
import { CompaniesValidationError, ICompany } from "../../types";
import {
  addressRegExp,
  glnRegExp,
  phoneRegExp,
  // zipRegExp,
} from "../../../common/constants/regExp";
import { createCompany, editCompany } from "../../../../api/companies";
import { useEffect, useState } from "react";
import { checkEqualObjectsProps } from "../../../../utils/checkEqualObjectsProps";
import { checkObjectsProps } from "../../../../utils/checkObjectProps";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import ImageUploader from "../../../common/components/ImageUploader";
import AppModal from "../../../common/components/AppModal";
import isBase64 from "is-base64";
import { useActions } from "../../../../hooks/useActions";

interface CompaniesFormOwnProps {
  dispatch: (value: any) => void;
  setFormIsChange: (value: boolean) => void;
  company: ICompany;
  isLoadingCompany: boolean;
  isErrorCompany: CompaniesValidationError | null;
}

const CompaniesForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  setFormIsChange,
  company,
  isLoadingCompany,
  isErrorCompany,
}: FormikProps<ICompany> & CompaniesFormOwnProps) => {
  const { isLoading } = useAppSelector((state) => state.companies);
  const [isConfirmEditModal, setIsConfirmEditModal] = useState(false);
  const { cleareCompanyValidationError } = useActions();

  useEffect(() => {
    const fieldsIsEmpty = checkObjectsProps(values);
    const fieldsIsChange = checkEqualObjectsProps(values, company);
    if (fieldsIsEmpty) {
      setFormIsChange(false);
    } else {
      if (company) {
        if (fieldsIsChange) {
          setFormIsChange(true);
        } else {
          setFormIsChange(false);
        }
      } else {
        setFormIsChange(true);
      }
    }
  }, [company, setFieldValue, setFormIsChange, values]);

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.form}
    >
      <div>
        <div className={styles.formTitle}>COMPANY INFO</div>
        <div className={styles.companyInfoFields}>
          <div className={styles.companyInfoFieldsLeft}>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Company"
                value={values.company}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="Company"
                required={false}
                errorMessage={
                  (touched.company && errors.company) ||
                  (isErrorCompany && (isErrorCompany.company as any))
                }
                onBlur={() => setFieldTouched("company")}
                onChange={(event: any) => {
                  setFieldValue("company", event.target.value);
                  cleareCompanyValidationError();
                }}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="GLN"
                value={values.gln}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="GLN"
                required={false}
                errorMessage={
                  (touched.gln && errors.gln) ||
                  (isErrorCompany && (isErrorCompany.gln as any))
                }
                onBlur={() => setFieldTouched("gln")}
                onChange={(event: any) => {
                  setFieldValue("gln", event.target.value);
                  cleareCompanyValidationError();
                }}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Phone"
                value={values.phone}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="Phone"
                required={false}
                errorMessage={touched.phone && errors.phone}
                onBlur={() => setFieldTouched("phone")}
                onChange={(event: any) =>
                  setFieldValue("phone", event.target.value)
                }
              />
            </div>
             <div className={styles.fieldWrapper}>
              <InputFieldTitle
                    title="Company Type"
                    required={false}
                    
                >
                <Select
                  size="middle"
                  className={styles.selectField}
                  placeholder="Company type"
                  value={values.type}
                  options={[{ value: '', label: ''},{value: 'wine', label: 'Wine' }]}
                  onChange={(event: any) => setFieldValue("type", event)}
                />
                </InputFieldTitle>
              </div>
          </div>
          <div className={styles.companyInfoFieldsRight}>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Address (Street, building, appartament etc.)"
                value={values.address}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="Address"
                required={false}
                errorMessage={touched.address && errors.address}
                onBlur={() => setFieldTouched("address")}
                onChange={(event: any) =>
                  setFieldValue("address", event.target.value)
                }
              />
            </div>
            <div className={styles.companyInfoFieldsRightWrapper}>
              <div className={styles.companyInfoFieldsRightWrapperLeft}>
                <div className={styles.fieldWrapper}>
                  <InputField
                    title="City"
                    value={values.city}
                    className={styles.field}
                    disabled={isLoadingCompany}
                    placeholder="City"
                    required={false}
                    errorMessage={touched.city && errors.city}
                    onBlur={() => setFieldTouched("city")}
                    onChange={(event: any) =>
                      setFieldValue("city", event.target.value)
                    }
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <InputField
                    title="ZIP Code"
                    value={values.zip_code || ""}
                    className={styles.field}
                    disabled={isLoadingCompany}
                    placeholder="Postal code"
                    required={false}
                    errorMessage={touched.zip_code && errors.zip_code}
                    onBlur={() => setFieldTouched("zip_code")}
                    onChange={(event: any) => setFieldValue("zip_code", event.target.value)}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className={styles.companyInfoFieldsRightWrapperRight}>
                <div className={styles.fieldWrapper}>
                  <InputField
                    title="State"
                    value={values.state}
                    className={styles.field}
                    disabled={isLoadingCompany}
                    placeholder="State"
                    required={false}
                    errorMessage={touched.state && errors.state}
                    onBlur={() => setFieldTouched("state")}
                    onChange={(event: any) =>
                      setFieldValue("state", event.target.value)
                    }
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <InputField
                    title="Country"
                    value={values.country}
                    className={styles.field}
                    disabled={isLoadingCompany}
                    placeholder="Country"
                    required={false}
                    errorMessage={touched.country && errors.country}
                    onBlur={() => setFieldTouched("country")}
                    onChange={(event: any) =>
                      setFieldValue("country", event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactPerson}>
        <div className={styles.formTitle}>CONTACT PERSON INFO</div>
        <div className={styles.contactInfoFields}>
          <div className={styles.contactInfoFieldsLeft}>
            <div className={styles.fieldWrapper}>
              <InputField
                title="First and Last name"
                value={values.contact_name}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="First and Last name"
                required={false}
                errorMessage={touched.contact_name && errors.contact_name}
                onBlur={() => setFieldTouched("contact_name")}
                onChange={(event: any) =>
                  setFieldValue("contact_name", event.target.value)
                }
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Email"
                value={values.contact_email}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="Email"
                required={false}
                errorMessage={touched.contact_email && errors.contact_email}
                onBlur={() => setFieldTouched("contact_email")}
                onChange={(event: any) =>
                  setFieldValue("contact_email", event.target.value)
                }
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Phone Number"
                value={values.contact_phone}
                className={styles.field}
                disabled={isLoadingCompany}
                placeholder="Phone number"
                required={false}
                errorMessage={touched.contact_phone && errors.contact_phone}
                onBlur={() => setFieldTouched("contact_phone")}
                onChange={(event: any) =>
                  setFieldValue("contact_phone", event.target.value)
                }
              />
            </div>
          </div>
          <div className={styles.contactInfoFieldsRight}>
            <ImageUploader
              imageUrl={values.photo}
              setImage={(file) => {
                setFieldValue("photo", file);
                !isBase64(values.photo, { allowMime: true }) &&
                  setFieldValue("contact_photo", file);
              }}
            />
          </div>
        </div>
      </div>

      <AppModal
        visible={isConfirmEditModal}
        handleOk={() => {
          handleSubmit();
          setIsConfirmEditModal(false);
        }}
        handleCancel={() => setIsConfirmEditModal(false)}
        buttonOkName="SAVE"
        buttonCancelName="CANCEL"
        modalInfoText="Are you sure you want to save the changes?"
        onCancel={() => setIsConfirmEditModal(false)}
      />

      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          loading={isLoading}
          type="primary"
          onClick={() => {
            if (company) {
              setIsConfirmEditModal(true);
            } else {
              handleSubmit();
            }
          }}
        >
          {company ? "Save Changes" : "Save Company"}
        </Button>
      </div>
    </form>
  );
};

const CompaniesFormWithFormik = withFormik<CompaniesFormOwnProps, ICompany>({
  enableReinitialize: true,
  mapPropsToValues: ({ company }) => {
    if (!company) {
      return {
        address: "",
        city: "",
        company: "",
        contact_email: "",
        contact_name: "",
        contact_phone: "",
        country: "",
        gln: "",
        phone: "",
        photo: null,
        state: "",
        zip_code: "",
        type: ""
      };
    } else {
      return {
        id: company.id,
        address: company.address,
        city: company.city,
        company: company.company,
        contact_email: company.contact_email,
        contact_name: company.contact_name,
        contact_phone: company.contact_phone,
        country: company.country,
        gln: company.gln,
        phone: company.phone,
        photo: company.contact_photo,
        contact_photo: company.contact_photo,
        state: company.state,
        zip_code: String(company.zip_code),
        type: company.type
      };
    }
  },
  handleSubmit: (values, { props: { dispatch, company } }) => {
    const requestData = {
      ...values,
      photo: isBase64(values.photo, { allowMime: true }) ? values.photo : null,
    };

    if (!company) {
      dispatch(createCompany(requestData));
    } else {
      dispatch(editCompany(requestData));
    }
  },
  validationSchema: yup.object().shape({
    zip_code: yup
      .string()
      .min(4, "Minimum 4 characters")
      .max(10, "Maximum 10 characters"),
    contact_email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
    company: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(100, "Maximum 100 characters")
      .required("This field is required"),
    gln: yup
      .string()
      .min(13, "GLN number is invalid")
      .max(13, "GLN number is invalid")
      .matches(glnRegExp, "GLN number is invalid")
      .required("This field is required"),
    contact_name: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(30, "Maximum 30 characters")
      .required("This field is required"),
    phone: yup
      .string()
      .min(7, "Phone number is invalid")
      .max(15, "Phone number is invalid")
      .matches(phoneRegExp, "Phone number is invalid")
      .required("This field is required"),
    country: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(100, "Maximum 100 characters")
      .required("This field is required"),
    city: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(100, "Maximum 100 characters")
      .required("This field is required"),
    state: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(100, "Maximum 100 characters")
      .required("This field is required"),
    address: yup
      .string()
      .min(6, "Minimum 6 characters")
      .max(100, "Maximum 100 characters")
      .matches(addressRegExp, "Not allowed chars")
      .required("This field is required"),
    contact_phone: yup
      .string()
      .min(7, "Phone number is invalid")
      .max(15, "Phone number is invalid")
      .matches(phoneRegExp, "Phone number is invalid")
      .required("This field is required"),
  }),
  displayName: "CompaniesForm",
})(CompaniesForm);

export default CompaniesFormWithFormik;
