import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import {
  GetProductsRequestData,
  GetProductsResponseData,
  IProduct,
} from "../../modules/products/types";
import { draftProductsSlice } from "../../redux/reducers/draftProducts";
import { notification } from "antd";
import { activeProductsSlice } from "../../redux/reducers/activeProducts";
import { modalsSlice } from "../../redux/reducers/modals";
import { filterSlice } from "../../redux/reducers/filter";
import { additionalFilterSlice } from "../../redux/reducers/additionalFilter";
import { productFormFilterControlSlice } from "../../redux/reducers/productFormFilterControl";
import { productFormFilterSlice } from "../../redux/reducers/productFormFilter";
import { activeItemsSlice } from '../../redux/reducers/activeItems';
import parse from 'html-react-parser';

export const getAllDraftProductsForView = (props: GetProductsRequestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.getAllDraftProductsPending());
      let responseData: GetProductsResponseData;
      const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
      const checkDateParams = !props.dateStart || !props.dateEnd;

      const requestDataWithParams = () => {
        if (!checkIdsParams && !checkDateParams) {
          return {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
          };
        } else {
          if (!checkIdsParams) {
            return {
              [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
            };
          }

          if (!checkDateParams) {
            return {
              date_start: !checkDateParams ? props.dateStart : null,
              date_end: !checkDateParams ? props.dateEnd : null,
            };
          }
        }
      };

      if (checkIdsParams && checkDateParams) {
        const { data } = await authAxios(dispatch).post(
          `/api/draft-products-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
        );
        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `/api/draft-products-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
          requestDataWithParams()
        );
        responseData = data;
      }

      dispatch(
        draftProductsSlice.actions.getAllDraftProductsFulfilled({
          draftProducts: responseData.data,
          totalProducts: responseData.pagination.total,
          totalValidProducts: responseData.pagination.total_validated,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          filterSlice.actions.setFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        draftProductsSlice.actions.getAllDraftProductsRejected(
          error.data.message
        )
      );
    }
  };
};

export const getAllDraftProducts = (
  userCompanyId: number,
  props: GetProductsRequestData
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.getAllDraftProductsPending());
      let responseData: GetProductsResponseData;
      const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
      const checkDateParams = !props.dateStart || !props.dateEnd;

      const requestDataWithParams = () => {
        if (!checkIdsParams && !checkDateParams) {
          return {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
          };
        } else {
          if (!checkIdsParams) {
            return {
              [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
            };
          }

          if (!checkDateParams) {
            return {
              date_start: !checkDateParams ? props.dateStart : null,
              date_end: !checkDateParams ? props.dateEnd : null,
            };
          }
        }
      };

      if (checkIdsParams && checkDateParams) {
        const { data } = await authAxios(dispatch).post(
          `/api/draft-products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`
        );
        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `/api/draft-products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`,
          requestDataWithParams()
        );
        responseData = data;
      }

      dispatch(
        draftProductsSlice.actions.getAllDraftProductsFulfilled({
          draftProducts: responseData.data,
          totalProducts: responseData.pagination.total,
          totalValidProducts: responseData.pagination.total_validated,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          filterSlice.actions.setFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        draftProductsSlice.actions.getAllDraftProductsRejected(
          error.data.message
        )
      );
    }
  };
};

export const createDraftProduct = (userCompanyId: number, body: IProduct) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.cleareDraftProductsValidationError());
      dispatch(draftProductsSlice.actions.createDraftProductPending());

      const { data } = await authAxios(dispatch)({
        method: "POST",
        url: `/api/draft-products/save?&company_id=${userCompanyId}`,
        data: body,
      });

      dispatch(modalsSlice.actions.setIsOpenDraftProductsFormModal(false));

      dispatch(draftProductsSlice.actions.createDraftProductFulfilled(data));
      dispatch(
        modalsSlice.actions.setDraftProductSuccessfulStatus({
          status: true,
          message: "Product created successfully",
        })
      );

      dispatch(productFormFilterSlice.actions.clearProductFormFilter());
      dispatch(
        productFormFilterControlSlice.actions.clearProductFormFilterControl()
      );
    } catch (error: any) {
      if (error.status === 422) {
        const gtinErrorText = error.data.errors?.gtin
          ? error.data.errors.gtin
          : "";

        dispatch(
          draftProductsSlice.actions.setValidationError({ gtin: gtinErrorText })
        );
      }
    }
  };
};

export const editDraftProduct = (userCompanyId: number, body: IProduct) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.cleareDraftProductsValidationError());
      dispatch(draftProductsSlice.actions.editDraftProductPending());

      const { data } = await authAxios(dispatch)({
        method: "PUT",
        url: `/api/draft-products/update/${body.id}?&company_id=${userCompanyId}`,
        data: body,
      });

      dispatch(modalsSlice.actions.setIsOpenDraftProductsFormModal(false));

      dispatch(draftProductsSlice.actions.editDraftProductFulfilled(data));
      dispatch(
        modalsSlice.actions.setDraftProductSuccessfulStatus({
          status: true,
          message: "Product updated successfully",
        })
      );

      dispatch(productFormFilterSlice.actions.clearProductFormFilter());
      dispatch(
        productFormFilterControlSlice.actions.clearProductFormFilterControl()
      );
    } catch (error: any) {
      if (error.status === 422) {
        const gtinErrorText = error.data.errors?.gtin
          ? error.data.errors.gtin
          : "";

        dispatch(
          draftProductsSlice.actions.setValidationError({ gtin: gtinErrorText })
        );
      }
    }
  };
};

export const getDraftProductById = (id: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.getDraftProductPending());

      const { data } = await authAxios(dispatch).get(
        `/api/draft-products/${id}`
      );

      dispatch(
        draftProductsSlice.actions.getDraftProductFulfilled({
          draftProduct: data,
        })
      );
    } catch (error: any) {
      dispatch(
        draftProductsSlice.actions.getDraftProductRejected(error.data.message)
      );
    }
  };
};

export const changeDraftProductsStatus = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.changeStatusDraftProductPending());

      const { data } = await authAxios(dispatch).post(
        `/api/products/change-status`,
        {
          array_ids: body,
          status: 1,
        }
      );

      dispatch(
        draftProductsSlice.actions.clearDraftProductsForActivationDeletion()
      );
      dispatch(
        modalsSlice.actions.setIsChangeProductsDeletionActivationActions(false)
      );

      notification.info({
        message: "Active status info",
        description: parse(data),
        duration: 7,
      });
    } catch (error: any) {
      if (error.status === 403) {
        notification.error({
          message: "Selected Product can't be activated!",
          description: "Please, fill in all mandatory fields.",
          duration: 6,
        });
      }
      dispatch(
        draftProductsSlice.actions.changeStatusDraftProductRejected(
          error.data.message
        )
      );
    }
  };
};

export const deleteDraftProducts = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const config = {
      data: { array_ids: body },
    };

    try {
      dispatch(draftProductsSlice.actions.deleteDraftProductsPending());

      await authAxios(dispatch).delete(`/api/draft-products/delete`, config);

      dispatch(
        draftProductsSlice.actions.clearDraftProductsForActivationDeletion()
      );
      dispatch(
        modalsSlice.actions.setIsChangeProductsDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(
        draftProductsSlice.actions.deleteDraftProductsRejected(
          error.data.message
        )
      );
    }
  };
};

export const validateDraftProduct = (userCompanyId: number, body: IProduct) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(draftProductsSlice.actions.validateDraftProductPending());

      const { data } = await authAxios(dispatch).post(
        `/api/draft-products/validated/${body.id}?&company_id=${userCompanyId}`,
        body
      );

      dispatch(draftProductsSlice.actions.validateDraftProductFulfilled(data));
    } catch (error: any) {
      dispatch(
        draftProductsSlice.actions.validateDraftProductRejected(
          error.data.message
        )
      );
    }
  };
};

export const getAllActiveProductsForView = (props: GetProductsRequestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      let responseData: GetProductsResponseData;
      const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
      const checkDateParams = !props.dateStart || !props.dateEnd;

      const requestDataWithParams = () => {
        if (!checkIdsParams && !checkDateParams) {
          return {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
          };
        } else {
          if (!checkIdsParams) {
            return {
              [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
            };
          }

          if (!checkDateParams) {
            return {
              date_start: !checkDateParams ? props.dateStart : null,
              date_end: !checkDateParams ? props.dateEnd : null,
            };
          }
        }
      };

      dispatch(activeProductsSlice.actions.getAllActiveProductsPending());

      if (checkIdsParams && checkDateParams) {
        const { data } = await authAxios(dispatch).post(
          `api/products-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
        );

        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `api/products-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
          requestDataWithParams()
        );

        responseData = data;
      }

      dispatch(
        activeProductsSlice.actions.getAllActiveProductsFulfilled({
          activeProducts: responseData.data,
          totalProducts: responseData.pagination.total,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          additionalFilterSlice.actions.setAdditionalFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        activeProductsSlice.actions.getAllActiveProductsRejected(
          error.data.message
        )
      );
    }
  };
};

export const getAllActiveProducts = (
  userCompanyId: number,
  props: GetProductsRequestData
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      let responseData: GetProductsResponseData;
      const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
      const checkDateParams = !props.dateStart || !props.dateEnd;

      const requestDataWithParams = () => {
        if (!checkIdsParams && !checkDateParams) {
          return {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
            date_start: !checkDateParams ? props.dateStart : null,
            date_end: !checkDateParams ? props.dateEnd : null,
          };
        } else {
          if (!checkIdsParams) {
            return {
              [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
            };
          }

          if (!checkDateParams) {
            return {
              date_start: !checkDateParams ? props.dateStart : null,
              date_end: !checkDateParams ? props.dateEnd : null,
            };
          }
        }
      };

      dispatch(activeProductsSlice.actions.getAllActiveProductsPending());

      if (checkIdsParams && checkDateParams) {
        const { data } = await authAxios(dispatch).post(
          `/api/products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`
        );

        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `/api/products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`,
          requestDataWithParams()
        );

        responseData = data;
      }

      dispatch(
        activeProductsSlice.actions.getAllActiveProductsFulfilled({
          activeProducts: responseData.data,
          totalProducts: responseData.pagination.total,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          additionalFilterSlice.actions.setAdditionalFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        activeProductsSlice.actions.getAllActiveProductsRejected(
          error.data.message
        )
      );
    }
  };
};

export const getAllActiveProductsRetailer = (
    userCompanyId: number,
    props: GetProductsRequestData
  ) => {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        let responseData: GetProductsResponseData;
        const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
        const checkDateParams = !props.dateStart || !props.dateEnd;
  
        const requestDataWithParams = () => {
          if (!checkIdsParams && !checkDateParams) {
            return {
              [props.sortedByFilterIds?.sortByName as any]: props
                .sortedByFilterIds?.filterIds as number[],
              date_start: !checkDateParams ? props.dateStart : null,
              date_end: !checkDateParams ? props.dateEnd : null,
            };
          } else {
            if (!checkIdsParams) {
              return {
                [props.sortedByFilterIds?.sortByName as any]: props
                  .sortedByFilterIds?.filterIds as number[],
              };
            }
  
            if (!checkDateParams) {
              return {
                date_start: !checkDateParams ? props.dateStart : null,
                date_end: !checkDateParams ? props.dateEnd : null,
              };
            }
          }
        };
  
        dispatch(activeProductsSlice.actions.getAllActiveProductsPending());
  
        if (checkIdsParams && checkDateParams) {
          const { data } = await authAxios(dispatch).post(
            `/api/retailer/products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`
          );
  
          responseData = data;
        } else {
          const { data } = await authAxios(dispatch).post(
            `/api/retailer/products?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`,
            requestDataWithParams()
          );
  
          responseData = data;
        }
  
        dispatch(
          activeProductsSlice.actions.getAllActiveProductsFulfilled({
            activeProducts: responseData.data,
            totalProducts: responseData.pagination.total,
          })
        );
  
        if (responseData.data.length === 0) {
          dispatch(
            additionalFilterSlice.actions.setAdditionalFilter({
              page: Number(responseData.pagination.total_pages),
              paginate: Number(responseData.pagination.per_page),
            })
          );
        }
      } catch (error: any) {
        dispatch(
          activeProductsSlice.actions.getAllActiveProductsRejected(
            error.data.message
          )
        );
      }
    };
  };

export const getActiveProductById = (id: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(activeProductsSlice.actions.getActiveProductPending());

      const { data } = await authAxios(dispatch).get(`/api/products/${id}`);

      dispatch(
        activeProductsSlice.actions.getActiveProductFulfilled({
          activeProduct: data,
        })
      );
    } catch (error: any) {
      dispatch(
        activeProductsSlice.actions.getActiveProductRejected(error.data.message)
      );
    }
  };
};

export const getRetailerProductByGtin = (gtin: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        //dispatch(activeProductsSlice.actions.getActiveProductPending());
  
        const { data } = await authAxios(dispatch).get(`/api/retailer/product/${gtin}`);
  
        dispatch(
          activeProductsSlice.actions.getActiveProductFulfilled({
            activeProduct: data.item
          })
        );
        dispatch(
            activeItemsSlice.actions.getActiveItemFulfilled({
                activeItem: data.item,
                activeHierarchy: data.hierarchies,
                photos: data.photos
            })
        );
      } catch (error: any) {
        dispatch(
          activeProductsSlice.actions.getActiveProductRejected(error.data.message)
        );
      }
    };
  };

export const changeActiveProductsStatus = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(activeProductsSlice.actions.changeStatusActiveProductPending());

      await authAxios(dispatch).post(`/api/products/change-status`, {
        array_ids: body,
        status: 0,
      });

      dispatch(
        activeProductsSlice.actions.clearActiveProductsForDeactivationDeletion()
      );
      dispatch(
        modalsSlice.actions.setIsChangeProductsDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(
        activeProductsSlice.actions.changeStatusActiveProductRejected(
          error.data.message
        )
      );
    }
  };
};

export const deleteActiveProducts = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const config = {
      data: { array_ids: body },
    };

    try {
      dispatch(activeProductsSlice.actions.deleteActiveProductsPending());

      await authAxios(dispatch).delete(`/api/products/delete`, config);

      dispatch(
        activeProductsSlice.actions.clearActiveProductsForDeactivationDeletion()
      );
      dispatch(
        modalsSlice.actions.setIsChangeProductsDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(
        activeProductsSlice.actions.deleteActiveProductsRejected(
          error.data.message
        )
      );
    }
  };
};
