import { CloseOutlined } from "@ant-design/icons";
import { InputProps, notification } from "antd";
import Input from "antd/lib/input/Input";
import { FC, useState } from "react";
import { ReactComponent as PhotoLogo } from "../../../common/icons/photo.svg";
import styles from "./imageUploader.module.scss";

type ImageUploaderOwnProps = {
  imageUrl: string | null | undefined;
  setImage: (file: ArrayBuffer | string | null) => void;
} & InputProps;

const ImageUploader: FC<ImageUploaderOwnProps> = ({
  imageUrl,
  setImage,
  ...props
}): JSX.Element => {
  const [prewievImage, setPrewievImage] = useState<any>(null);

  const validateImage = (file: File) => {
    if (
      ![
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/bmp",
        "image/gif",
      ].includes(file.type)
    ) {
      notification.error({
        message: "The format of image is not supported.",
        description: "Please use jpg, jpeg, png, bmp or gif",
        duration: 5,
      });
      return false;
    }

    if (file.size >= 2000000) {
      notification.error({
        message: "The size of the image is too big.",
        description: "Please upload an image not bigger than 2mb",
        duration: 5,
      });
      return false;
    }

    return file;
  };

  const imageSelectedHandler = (event: any) => {
    try {
      const file = validateImage(event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(file as any);
      reader.onload = () => {
        let fileBase64 = reader.result;
        setPrewievImage(reader.result);
        setImage(fileBase64);
      };
    } catch (error) {
      return false;
    }
  };

  const showCurrentImage = () =>
    !imageUrl ? (
      <PhotoLogo className={styles.baseImage} />
    ) : (
      <div className={styles.imagePreview}>
        <CloseOutlined
          className={styles.deleteImageButton}
          onClick={() => {
            setImage("");
            setPrewievImage(null);
          }}
        />
        <img className={styles.imageView} src={imageUrl} alt="user img" />
      </div>
    );

  return (
    <div className={styles.wrapper}>
      {!prewievImage ? (
        showCurrentImage()
      ) : (
        <div className={styles.imagePreview}>
          <CloseOutlined
            className={styles.deleteImageButton}
            onClick={() => {
              setImage("");
              setPrewievImage(null);
            }}
          />
          <img
            className={styles.imageView}
            src={prewievImage as string}
            alt="user img"
          />
        </div>
      )}
      <label className={styles.inputLabel}>
        Upload new photo
        <Input
          {...props}
          className={styles.inputFile}
          accept=".jpg,.jpeg,.png,.bmp,.gif"
          value={""}
          type="file"
          required={false}
          onChange={imageSelectedHandler}
        />
      </label>
    </div>
  );
};

export default ImageUploader;
