import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import InputFieldTitle from '../InputFieldTitle';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

type InputFieldProps = {
    title?: string;
    errorMessage?: string | boolean | undefined;
    titleStyle?: React.CSSProperties;
    wrapperClassName?: string;
    titleClassName?: string;
    passwordEye?: boolean;
    type?: number | string;
} & InputProps;

const InputField = ({
    title,
    required,
    wrapperClassName,
    titleClassName,
    errorMessage,
    titleStyle,
    passwordEye,
    ...props
}: InputFieldProps) => {
    return (
        <InputFieldTitle
            required={required}
            title={title || ''}
            errorMessage={errorMessage}
            style={titleStyle}
            className={wrapperClassName}
            titleClassName={titleClassName}
        >
            {!passwordEye ? (
                <Input {...props} />
            ) : (
                <Input.Password
                    iconRender={(visible: any) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    {...props}
                />
            )}
        </InputFieldTitle>
    );
};

export default InputField;
