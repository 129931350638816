import { Button, Checkbox, Pagination, Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Status } from "../../../common/constants/status";
import styles from "./usersList.module.scss";
import classnames from "classnames";
import { MinusOutlined } from "@ant-design/icons";
import { useActions } from "../../../../hooks/useActions";
import Filter from "../Filter";
import { DeletionUsersProps } from "../../types";
import { getAllUsers, getUserById } from "../../../../api/users";
import { removeDuplicates } from "../../../../utils/removeDuplicates";
import { ReactComponent as AvatarLogo } from "../../../common/icons/avatar.svg";

const UsersList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setFilter,
    setIsOpenUsersFormModal,
    setIsOpenUserChangeStatusModal,
    setIsConfirmUserDeletionModal,
    setUsersForDeletion,
    cleareUserStore,
  } = useActions();

  const { users, totalItems, totalUsers, isLoading, usersForDeletion } =
    useAppSelector((state) => state.users);

  const { userSuccessfulStatus, isChangeUserDeletionActivationActions } =
    useAppSelector((state) => state.modals);

  const filter = useAppSelector((state) => state.filter);

  useEffect(() => {
    dispatch(getAllUsers(filter));
  }, [
    dispatch,
    filter,
    userSuccessfulStatus,
    isChangeUserDeletionActivationActions,
  ]);

  const getColumns = () => [
    {
      title: (
        <Checkbox
          className={styles.mainCheckbox}
          checked={usersForDeletion.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const userWithoutDuplicate = removeDuplicates(
                users as [],
                "user_id"
              );
              const checkAllUsers = userWithoutDuplicate?.map((user) => {
                return { isChecked: true, user_id: user.user_id };
              });
              setUsersForDeletion(checkAllUsers as DeletionUsersProps[]);
            } else {
              setUsersForDeletion([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "GLN",
      dataIndex: "gln",
      key: "gln",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "PROFILE",
      dataIndex: "avatar",
      key: "avatar",
    },
    {
      title: "STATUS",
      align: "center" as "center",
      dataIndex: "isActive",
      key: "isActive",
    },
  ];

  const getDataSource = () => {
    return users?.map(
      ({ id, user_id, company, gln, avatar, email, phone, name, status }) => {
        return {
          key: id,
          check: (
            <Checkbox
              checked={
                usersForDeletion.find(
                  (value: DeletionUsersProps) => value.user_id === user_id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, user_id };
                  setUsersForDeletion([
                    ...usersForDeletion,
                    data as DeletionUsersProps,
                  ]);
                } else {
                  setUsersForDeletion(
                    usersForDeletion.filter((item) => item.user_id !== user_id)
                  );
                }
              }}
            />
          ),
          name: (
            <div
              className={styles.companyInfo}
              onClick={() => {
                cleareUserStore();
                dispatch(getUserById(user_id as number));
                setIsOpenUsersFormModal(true);
              }}
            >
              {name}
            </div>
          ),
          company,
          gln,
          email,
          phone,
          avatar: avatar ? (
            <img
              className={styles.avatar}
              src={avatar as string}
              alt="user avatar"
            />
          ) : (
            <AvatarLogo className={styles.avatar} />
          ),
          isActive: (
            <Button
              className={classnames([
                status === Status.ACIVE
                  ? styles.buttonStatusActive
                  : styles.buttonStatusInactive,
              ])}
              type="primary"
              onClick={() => {
                setIsOpenUserChangeStatusModal({
                  isOpen: true,
                  changedStatusUser: {
                    id: user_id as number,
                    name,
                    status: status as string,
                  },
                });
              }}
            >
              {status === Status.ACIVE ? Status.ACIVE : Status.INACTIVE}
            </Button>
          ),
        };
      }
    );
  };

  return (
    <>
      <Filter
        setOpenModal={setIsOpenUsersFormModal}
        totalUsersCount={totalUsers}
        countCheckedUsers={usersForDeletion.length}
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{ emptyText: "No search results found" }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: "calc(100vh - 205px)" }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={users?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setFilter({ page, paginate: perPage }));
              setUsersForDeletion([]);
            } else {
              dispatch(setFilter({ page }));
              setUsersForDeletion([]);
            }
          }}
          total={totalItems || 10}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
        {usersForDeletion.length !== 0 && (
          <Button
            className={styles.buttonDelete}
            type="primary"
            onClick={() => {
              setIsConfirmUserDeletionModal(true);
            }}
          >
            <MinusOutlined className={styles.minusIcon} />
            <div>Delete Users</div>
          </Button>
        )}
      </div>
    </>
  );
};

export default UsersList;
