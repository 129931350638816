import { createSlice } from "@reduxjs/toolkit";
import {
  DeleteUsersAction,
  GetUserFulfilledAction,
  GetUsersFulfilledAction,
  UsersRejectedAction,
  UsersState,
  UsersValidationErrorAction,
} from "../../../modules/users/types";

const initialState: UsersState = {
  users: null,
  user: null,
  totalItems: 0,
  totalUsers: 0,
  usersForDeletion: [],
  isLoading: false,
  validationError: null,
  error: null,
};

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    getAllUsersPending: (state: UsersState) => {
      state.isLoading = true;
    },
    getAllUsersFulfilled: (
      state: UsersState,
      action: GetUsersFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.users = action.payload.users;
      state.totalItems = action.payload.totalItems;
      state.totalUsers = action.payload.totalUsers;
    },
    getAllUsersRejected: (state: UsersState, action: UsersRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserPending: (state: UsersState) => {
      state.isLoading = true;
    },
    getUserFulfilled: (state: UsersState, action: GetUserFulfilledAction) => {
      state.isLoading = false;
      state.error = null;
      state.user = action.payload.user;
    },
    getUserRejected: (state: UsersState, action: UsersRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createUserPending: (state: UsersState) => {
      state.isLoading = true;
    },
    createUserFulfilled: (state: UsersState) => {
      state.isLoading = false;
      state.error = null;
    },
    createUserRejected: (state: UsersState, action: UsersRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editUserPending: (state: UsersState) => {
      state.isLoading = true;
    },
    editUserFulfilled: (state: UsersState) => {
      state.isLoading = false;
      state.error = null;
    },
    editUserRejected: (state: UsersState, action: UsersRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusUserPending: (state: UsersState) => {
      state.isLoading = true;
    },

    changeStatusUserFulfilled: (state: UsersState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusUserRejected: (
      state: UsersState,
      action: UsersRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteUsersPending: (state: UsersState) => {
      state.isLoading = true;
    },

    deleteUsersFulfilled: (state: UsersState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteUsersRejected: (state: UsersState, action: UsersRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUsersForDeletion: (state: UsersState, action: DeleteUsersAction) => {
      state.usersForDeletion = action.payload;
    },
    setValidationError: (state: UsersState, action: UsersValidationErrorAction) => {
      state.isLoading = false;
      state.validationError = action.payload;
    },
    clearUsersForDeletion: (state: UsersState) => {
      state.usersForDeletion = [];
    },
    cleareUserStore: (state: UsersState) => {
      state.user = null;
      state.error = null;
    },
    cleareUsersStore: (state: UsersState) => {
      state.users = null;
      state.user = null;
      state.totalItems = 0;
      state.totalUsers = 0;
      state.usersForDeletion = [];
      state.isLoading = false;
      state.error = null;
    },
    cleareUsersValidationError: (state: UsersState) => {
      state.isLoading = false;
      state.validationError = null;
    },
  },
});
