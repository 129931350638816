import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import { uploadSlice } from "../../redux/reducers/upload";

export const uploadFile = (file: File) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(uploadSlice.actions.uploadPending());
      let bodyFormData = new FormData();
      bodyFormData.set("file", file);

      const { data } = await authAxios(dispatch)({
        method: "POST",
        url: `/api/uploadFile`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(uploadSlice.actions.uploadFulfilled(data));
    } catch (error: any) {
      dispatch(uploadSlice.actions.uploadRejected(error.data.message));
    }
  };
};
