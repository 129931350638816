import { Checkbox, Pagination, Table } from 'antd';
import { FC, useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import styles from './activeItemsList.module.scss';
import { useActions } from '../../../../hooks/useActions';
import ActiveItemsFilter from '../ActiveItemsFilter';
import { DeletionActivateDeactiveItemProps } from '../../types';
import {
    getRetailerItemByGtin,
    getAllActiveItems,
    getAllActiveItemsForView,
} from '../../../../api/items';
import { ReactComponent as ActiveItemIcon } from '../../../common/icons/active.svg';
import { ModalTabs } from '../../../common/constants/modalTabs';
import { getItemFormFieldsByCompanyId } from '../../../../api/itemForm';
import { RoutesNames } from "../../../../routes";
import { useNavigate } from "react-router-dom";

const ActiveItemsList: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const {
        setAdditionalFilter,
        setActiveItemsForDeactivationDeletion,
        cleareActiveItemStore,
        cleareDraftItemStore,
        setIsOpenDraftItemsFormModal,
        setModalActiveTab,
    } = useActions();

    const {
        activeItems,
        totalItems,
        isLoading,
        activeItemsForDeactivationDeletion,
    } = useAppSelector((state) => state.activeItems);

    const { isChangeItemsDeletionActivationActions } = useAppSelector(
        (state) => state.modals
    );

    const { userCompanyId } = useAppSelector((state) => state.companies);
    const { user } = useAppSelector((state) => state.auth);
    const filter = useAppSelector((state) => state.additionalFilter);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.is_admin) {
            dispatch(getAllActiveItems(userCompanyId as number, filter));
        } else {
            dispatch(getAllActiveItemsForView(filter));
        }
    }, [
        dispatch,
        filter,
        isChangeItemsDeletionActivationActions,
        user?.is_admin,
        userCompanyId,
    ]);

    const getColumns = () => [
        {
            title: !user?.is_admin && (
                <Checkbox
                    className={styles.mainCheckbox}
                    checked={activeItemsForDeactivationDeletion.length !== 0}
                    onChange={(e) => {
                        if (e.target.checked) {
                            const checkAllActiveItems = activeItems?.map(
                                (activeItem) => {
                                    return {
                                        isChecked: true,
                                        id: activeItem.id,
                                    };
                                }
                            );
                            setActiveItemsForDeactivationDeletion(
                                checkAllActiveItems as DeletionActivateDeactiveItemProps[]
                            );
                        } else {
                            setActiveItemsForDeactivationDeletion([]);
                        }
                    }}
                />
            ),
            dataIndex: 'check',
            align: 'center' as 'center',
            key: 'check',
        },
        {
            title: 'GTIN',
            dataIndex: 'gtin',
            key: 'gtin',
        },
        {
            title: 'ITEM DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'DESCRIPTOR',
            dataIndex: 'trade_item_descriptor_name',
            key: 'trade_item_descriptor_name',
        },
        {
            title: 'BRAND',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: user?.is_admin && 'COMPANY',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '',
            dataIndex: 'empty',
            key: 'empty',
        },
    ];

    const getDataSource = () => {
        return activeItems?.map(
            ({
                id,
                description,
                trade_item_descriptor_name,
                brand,
                company_name,
                company_id,
                date,
                gtin,
                quantity,
            }) => {
                return {
                    key: id,
                    check: !user?.is_admin && (
                        <Checkbox
                            checked={
                                activeItemsForDeactivationDeletion.find(
                                    (
                                        value: DeletionActivateDeactiveItemProps
                                    ) => value.id === id
                                )?.isChecked
                            }
                            className={styles.checkbox}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const data = {
                                        isChecked: e.target.checked,
                                        id,
                                    };
                                    setActiveItemsForDeactivationDeletion([
                                        ...activeItemsForDeactivationDeletion,
                                        data as DeletionActivateDeactiveItemProps,
                                    ]);
                                } else {
                                    setActiveItemsForDeactivationDeletion(
                                        activeItemsForDeactivationDeletion.filter(
                                            (item) => item.id !== id
                                        )
                                    );
                                }
                            }}
                        />
                    ),
                    gtin: (
                        <div
                            className={styles.activeItemInfo}
                            onClick={() => {
                                // cleareDraftItemStore();
                                // cleareActiveItemStore();
                                // !userCompanyId &&
                                //     dispatch(
                                //         getItemFormFieldsByCompanyId(
                                //             String(company_id)
                                //         )
                                //     );
                                dispatch(getRetailerItemByGtin(gtin));
                                navigate(`/items/${gtin}/details`);
                                //navigate(RoutesNames.ITEM_DETAILS);
                                //setIsOpenDraftItemsFormModal(true);
                                //setModalActiveTab('main');
                            }}
                        >
                            {gtin}
                        </div>
                    ),
                    description: (
                        <span
                            className={
                                user?.is_admin
                                    ? styles.descriptionAdmin
                                    : styles.description
                            }
                        >
                            {description}
                        </span>
                    ),
                    trade_item_descriptor_name: (
                        <div className={styles.descriptor}>
                            {trade_item_descriptor_name}
                        </div>
                    ),
                    brand: <div className={styles.brand}>{brand}</div>,
                    company: user?.is_admin && (
                        <div className={styles.companyName}>{company_name}</div>
                    ),
                    date: <div className={styles.date}>{date}</div>,
                    quantity: <div className={styles.quantity}>{quantity}</div>,
                    empty: <div className={styles.emptyField} />,
                };
            }
        );
    };

    return (
        <>
            <ActiveItemsFilter
                totalDraftItemsCount={totalItems}
                countCheckedActiveItems={
                    activeItemsForDeactivationDeletion.length
                }
            />
            <div className={styles.tableWrapper}>
                <Table
                    className={styles.table}
                    locale={{
                        emptyText: (
                            <div>
                                <ActiveItemIcon className={styles.emptyIcon} />
                                <div>Not items activated</div>
                            </div>
                        ),
                    }}
                    dataSource={getDataSource()}
                    columns={getColumns()}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 594px)' }}
                    loading={isLoading}
                />
            </div>
            <div className={styles.footerWrapper}>
                <Pagination
                    disabled={activeItems?.length === 0}
                    className={styles.pagination}
                    current={filter.page}
                    onChange={(page, perPage) => {
                        if (perPage) {
                            dispatch(
                                setAdditionalFilter({ page, paginate: perPage })
                            );
                            setActiveItemsForDeactivationDeletion([]);
                        } else {
                            dispatch(setAdditionalFilter({ page }));
                            setActiveItemsForDeactivationDeletion([]);
                        }
                    }}
                    total={totalItems || 10}
                    showSizeChanger={true}
                    pageSizeOptions={['10', '20', '50', '100']}
                />
            </div>
        </>
    );
};

export default ActiveItemsList;
