import React from 'react';
import { Form, Formik, useFormikContext, useField } from 'formik';
import { Button } from 'antd';
import InputField from '../../../common/components/InputField';
import styles from './CreateTabExpandForm.module.scss';
import { CreateTabData } from '../../../users/types';
import { useDispatch } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';
import { CreateNewTab } from '../../../../api/itemForm';
import { getItemTabsList } from '../../../../api/filter';

interface CreateTabExpandFormProps {
    // Add any additional props you need
}

const initialValues: CreateTabData = {
    name: '',
};

const CreateTabExpandForm: React.FC<CreateTabExpandFormProps> = () => {
    const { values, handleChange } = useFormikContext<CreateTabData>();
    const dispatch = useDispatch();
    const { setIsOpenCreateTabModal, setNowCreatedTab } = useActions();

    const handleSubmit = async (values: CreateTabData) => {
        if (values.name) {
            await dispatch(
                CreateNewTab({ name: values.name, title: values.name })
            );
            await dispatch(setNowCreatedTab(values.name));
            await dispatch(getItemTabsList());
            await setIsOpenCreateTabModal(false);
        }
    };

    return (
        <div className={styles.wrapper}>
            <Form
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSubmit(values);
                    }
                }}
            >
                <InputField
                    title="Name of Tab"
                    required={true}
                    value={values.name}
                    onChange={handleChange}
                    name="name"
                />
                <div className={styles.wrap_for_button}>
                    <Button
                        className={styles.buttonSave}
                        onClick={() => handleSubmit(values)}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const CreateTabExpandFormWrapper: React.FC = () => {
    return (
        <Formik<CreateTabData>
            initialValues={initialValues}
            onSubmit={() => {}}
            component={CreateTabExpandForm}
        />
    );
};

export default CreateTabExpandFormWrapper;
