import authAxios from "../../../../helpers/authAxios";
import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./activeProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import ActiveProductsFilter from "../ActiveProductsFilter";
import { DeletionActivateDeactiveProductProps } from "../../types";
import { ReactComponent as ActiveProductIcon } from "../../../common/icons/activeProduct.svg";
import {
  getAllActiveProducts,
  getAllActiveProductsForView,
  getDraftProductById,
  getRetailerProductByGtin
} from "../../../../api/products";
import ProductItemsTable from "../ProductItemsTable";
import ProductHierarchy from "../../../common/components/ProductHierarchy";
import noImage from "../../../common/icons/noImage.png"
import { useNavigate } from "react-router-dom";

interface ProductListProps {
    title?: string;
    showImages?: boolean;
    showCheckbox?: boolean;
    size: number;
}

const SuppliersList: FC<ProductListProps> = ({
    title,
    showImages,
    showCheckbox,
    size
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    setAdditionalFilter,
    setActiveProductsForDeactivationDeletion,
    setIsOpenProductFormForView,
    cleareDraftProductStore,
    setIsOpenDraftProductsFormModal,
  } = useActions();

  const {
    activeProducts,
    totalProducts,
    isLoading,
    activeProductsForDeactivationDeletion,
  } = useAppSelector((state) => state.activeProducts);

  const { isChangeProductsDeletionActivationActions } = useAppSelector(
    (state) => state.modals
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.additionalFilter);
  const navigate = useNavigate();
  const [supplierData, setSupplierData] = useState([]);
  title = title ?? 'Products';

  useEffect(() => {
    if (!user?.is_admin) {
      //dispatch(getAllActiveProducts(userCompanyId as number, filter));
    } else {
      //dispatch(getAllActiveProductsForView(filter));
    }
  }, [
    dispatch,
    filter,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
  ]);

  const getData = async () => {
    const {data} = await authAxios(dispatch).get(`/api/retailer/suppliers`);
    setSupplierData(data);
  }

  if(supplierData.length === 0) {
    getData();
  }

  const getColumns = () => {
    let columns = [];

    columns = columns.concat([
        {
            title: "NAME",
            dataIndex: "supplier",
            key: "supplier",
        },
        {
            title: "GLN",
            dataIndex: "gln",
            key: "gln",
        },
        {
            title: "TOTAL PRODUCTS",
            dataIndex: "products",
            key: "products",
        },
        {
            title: "PHONE",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "ADDRESS",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "CITY",
            dataIndex: "city",
            key: "city",
        }
    ])

    return columns;
  };

  const getDataSource = () => {
    return supplierData?.map(
      ({ company_id, supplier, gln, phone, address, city, products }) => {
        return {
          key: company_id,
          supplier: (
            <div
              className={styles.gtin}
              onClick={() => {
                // setIsOpenProductFormForView(true);
                // cleareDraftProductStore();
                //dispatch(getRetailerProductByGtin(gtin));
                //navigate(`/items/${gtin}/details`);
                // setIsOpenDraftProductsFormModal(true);
              }}
            >
              {supplier}
            </div>
          ),
          gln: (
            <span>
              {gln}
            </span>
          ),
          phone: (
            <div className={styles.companyName}>{phone}</div>
          ),
          address: (
            <div className={styles.companyName}>{address}</div>
          ),
          city: (
            <div className={styles.companyName}>{city}</div>
          ),
          products: (
            <div className={styles.companyName}>{products}</div>
          ),
        };
      }
    );
  };

  const getScroll = () => {
    let s = size !== undefined && size !== null ?
        `calc(100vh - ${size}px)` :
        `300px`;
    return s;
  }

  return (
    <>
      <ActiveProductsFilter
        title={title}
        totalDraftProductsCount={supplierData.length}
        countCheckedActiveProducts={
          activeProductsForDeactivationDeletion.length
        }
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <ActiveProductIcon className={styles.emptyIcon} />
                <div>Not products activated</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          // calc(-475px + 100vh)
          // calc(-294px + 100vh)
          scroll={{ y: getScroll() }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={activeProducts?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setAdditionalFilter({ page, paginate: perPage }));
              setActiveProductsForDeactivationDeletion([]);
            } else {
              dispatch(setAdditionalFilter({ page }));
              setActiveProductsForDeactivationDeletion([]);
            }
          }}
          total={supplierData.length || 1}
          showSizeChanger={true}
          pageSizeOptions={["15", "20", "50", "100"]}
        />
      </div>
    </>
  );
};

export default SuppliersList;
