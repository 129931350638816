import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import InputField from "../../../common/components/InputField";
import styles from "./usersForm.module.scss";
import "./usersForm.global.scss";
import { Button, Select } from "antd";
import { IUser, IUserCreateEditData, UsersValidationError } from "../../types";
import { phoneRegExp } from "../../../common/constants/regExp";
import { useEffect, useState } from "react";
import ImageUploader from "../../../common/components/ImageUploader";
import { checkEqualObjectsProps } from "../../../../utils/checkEqualObjectsProps";
import { checkObjectsProps } from "../../../../utils/checkObjectProps";
import { createUser, editUser } from "../../../../api/users";
import { SelectTagData } from "../../../common/types";
import { getCompaniesNames } from "../../../../api/filter";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import InputFieldTitle from "../../../common/components/InputFieldTitle";
import AppModal from "../../../common/components/AppModal";
import isBase64 from "is-base64";
import { useActions } from "../../../../hooks/useActions";
const { Option } = Select;

interface UsersFormOwnProps {
  dispatch: (value: any) => void;
  setFormIsChange: (value: boolean) => void;
  user: IUser;
  isLoadingUser: boolean;
  isErrorUser: UsersValidationError | null;
}

const UsersForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  setFormIsChange,
  user,
  dispatch,
  isLoadingUser,
  isErrorUser,
}: FormikProps<IUserCreateEditData> & UsersFormOwnProps) => {
  const { sortedByData } = useAppSelector((state) => state.filterControl);
  const [isConfirmEditModal, setIsConfirmEditModal] = useState(false);
  const { cleareUsersValidationError } = useActions();

  useEffect(() => {
    const fieldsIsEmpty = checkObjectsProps(values);
    const fieldsIsChange = checkEqualObjectsProps(values, user);
    if (fieldsIsEmpty) {
      setFormIsChange(false);
    } else {
      if (user) {
        if (fieldsIsChange) {
          setFormIsChange(true);
        } else {
          setFormIsChange(false);
        }
      } else {
        setFormIsChange(true);
      }
    }
  }, [user, setFieldValue, setFormIsChange, values]);

  useEffect(() => {
    dispatch(getCompaniesNames());
  }, [dispatch]);

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.form}
    >
      <div>
        <div className={styles.formTitle}>PERSONAL USER INFO</div>
        <div className={styles.userInfoFields}>
          <div className={styles.userInfoFieldsLeft}>
            <div className={styles.fieldWrapper}>
              <InputField
                title="First and Last Name"
                value={values.name}
                className={styles.field}
                disabled={isLoadingUser}
                placeholder="First and Last Name"
                required={false}
                errorMessage={touched.name && errors.name}
                onBlur={() => setFieldTouched("name")}
                onChange={(event: any) =>
                  setFieldValue("name", event.target.value)
                }
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Email"
                value={values.email}
                className={styles.field}
                disabled={isLoadingUser}
                placeholder="Email"
                required={false}
                errorMessage={
                  (touched.email && errors.email) ||
                  (isErrorUser && (isErrorUser.email as any))
                }
                onBlur={() => setFieldTouched("email")}
                onChange={(event: any) => {
                  setFieldValue("email", event.target.value);
                  cleareUsersValidationError();
                }}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <InputField
                title="Phone"
                value={values.phone}
                className={styles.field}
                disabled={isLoadingUser}
                placeholder="Phone"
                required={false}
                errorMessage={touched.phone && errors.phone}
                onBlur={() => setFieldTouched("phone")}
                onChange={(event: any) =>
                  setFieldValue("phone", event.target.value)
                }
              />
            </div>
          </div>
          <div className={styles.userInfoFieldsRight}>
            <ImageUploader
              imageUrl={values.photo}
              setImage={(file) => {
                setFieldValue("photo", file);
                !isBase64(values.photo, { allowMime: true }) &&
                  setFieldValue("avatar", file);
              }}
            />
          </div>
        </div>
      </div>
      <div className="userAccountInfo">
        <div className={styles.formTitle}>ACCOUNT USER INFO</div>
        <InputFieldTitle
          title="Companies"
          className={styles.selectTags}
          required={false}
        >
          <Select
            size="middle"
            maxTagTextLength={4}
            autoClearSearchValue={true}
            disabled={isLoadingUser}
            allowClear
            labelInValue
            value={values.companies}
            maxTagCount="responsive"
            onBlur={() => setFieldTouched("companies")}
            filterOption={(inputValue, option) =>
              option?.props.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            mode="multiple"
            placeholder={`Please select companies`}
            onChange={(optionData: SelectTagData[]) => {
              setFieldValue(
                "companies",
                optionData.map((item: SelectTagData) => {
                  return { key: Number(item.key), label: item.label };
                })
              );
            }}
          >
            {sortedByData?.map((sortOption: SelectTagData) => (
              <Option key={Number(sortOption.value)} value={sortOption.value}>
                {sortOption.label}
              </Option>
            ))}
          </Select>
          {touched.companies && errors.companies && (
            <span className={styles.errorCompanies}>
              This field is required
            </span>
          )}
        </InputFieldTitle>
      </div>

      <AppModal
        visible={isConfirmEditModal}
        handleOk={() => {
          handleSubmit();
          setIsConfirmEditModal(false);
        }}
        handleCancel={() => setIsConfirmEditModal(false)}
        buttonOkName="SAVE"
        buttonCancelName="CANCEL"
        modalInfoText="Are you sure you want to save the changes?"
        onCancel={() => setIsConfirmEditModal(false)}
      />

      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          loading={isLoadingUser}
          type="primary"
          onClick={() => {
            if (user) {
              setIsConfirmEditModal(true);
            } else {
              handleSubmit();
            }
          }}
        >
          {user ? "Save Changes" : "Save User"}
        </Button>
      </div>
    </form>
  );
};

const UsersFormWithFormik = withFormik<UsersFormOwnProps, IUserCreateEditData>({
  enableReinitialize: true,
  mapPropsToValues: ({ user }) => {
    if (!user) {
      return {
        name: "",
        email: "",
        phone: "",
        companies: [],
        photo: null,
      };
    } else {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        companies: user.companies.map((item: any) => {
          return { key: item.id, label: item.company };
        }),
        photo: user.avatar,
        avatar: user.avatar,
      };
    }
  },
  handleSubmit: (values, { props: { dispatch, user } }) => {
    const companiesIds = values.companies.map((company) => Number(company.key));
    const requestData = {
      ...values,
      companies: companiesIds,
      photo: isBase64(values.photo, { allowMime: true }) ? values.photo : null,
    };

    if (!user) {
      dispatch(createUser(requestData));
    } else {
      dispatch(editUser(requestData));
    }
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
    name: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(100, "Maximum 100 characters")
      .required("This field is required"),
    phone: yup
      .string()
      .min(7, "Phone number is invalid")
      .max(15, "Phone number is invalid")
      .matches(phoneRegExp, "Phone number is invalid")
      .required("This field is required"),
    // companies: yup.array().min(1, "This field is required"),
  }),
  displayName: "UsersForm",
})(UsersForm);

export default UsersFormWithFormik;
