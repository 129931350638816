import { ReactNode, FC } from "react";
import Header from "../Header";
import styles from "./appLayout.module.scss";

interface AppLayoutOwnProps {
  children: ReactNode;
  headerLogo: ReactNode;
  title: string;
}

const AppLayout: FC<AppLayoutOwnProps> = ({ children, title, headerLogo }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <Header title={title}>{headerLogo}</Header>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
