import { Dispatch } from "@reduxjs/toolkit";
import { localStorageUtil } from "../../helpers";
import authAxios from "../../helpers/authAxios";
import { authSlice } from "../../redux/reducers/auth";
import {
  ForgotRequestData,
  LogInRequestData,
  LogInResponseData,
  ResetRequestData,
} from "../../modules/auth/types";
import { notification } from "antd";
import { ReactComponent as EmailLogo } from "../../modules/common/icons/email.svg";
import { companiesSlice } from "../../redux/reducers/companies";
import { LogInTypes } from "../../modules/auth/constants";
import { sessionStorageUtil } from "../../helpers/sessionStorage";
import { RoutesNames } from "../../routes";

export const singIn = (type: LogInTypes, body: LogInRequestData, setErrors: any) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.cleareAuthValidationError());
      dispatch(authSlice.actions.signInPending());
      let response: LogInResponseData;

      if (type === LogInTypes.USER) {
        response = await authAxios(dispatch).post(`/api/auth/login-user`,
          body
        );
      } else {
        response = await authAxios(dispatch).post(
          `/api/auth/login-admin`,
          body
        );
      }

      const { data } = response;

      if (body.isRemember) {
        localStorageUtil.setStorage("authData", {
          token: data.token,
          user: data.user,
        });
      } else {
        sessionStorageUtil.setStorage("authData", {
          token: data.token,
          user: data.user,
        });
      }

      dispatch(authSlice.actions.getUserFulfilled(data.user));
      dispatch(authSlice.actions.setIsAuth(true));
      dispatch(authSlice.actions.signInFulfilled());
    } catch (error: any) {
      if (error.status === 422) {
        const emailErrorText = error.data.errors?.email
          ? error.data.errors.email
          : "";
        const passwordErrorText = error.data.errors?.password
          ? error.data.errors.password
          : "";

        dispatch(
          authSlice.actions.setValidationError({
            email: emailErrorText,
            password: passwordErrorText,
          })
        );
      }
      if (error.status === 403) {
        notification.error({
          message: "User not activated",
          description: error.data.error,
          duration: 6,
        });
      }
      if (error.status === 422 || 404) {
        setErrors({
          email: 'The email or password is incorrect',
          password: 'The email or password is incorrect',
        })
      }
    }
  };
};

export const signOut = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.signOutPending());

      await authAxios(dispatch).post(`/api/auth/logout`);
      localStorageUtil.removeStorageItem("authData");
      sessionStorageUtil.removeStorageItem("authData");

      dispatch(authSlice.actions.setIsAuth(false));
      dispatch(authSlice.actions.clearUserStore());
    } catch (error: any) {
      dispatch(authSlice.actions.signOutRejected(error.data.message));
    }
  };
};

export const checkAuth = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    const authData = localStorageUtil.getStorage("authData")
      ? localStorageUtil.getStorage("authData")
      : sessionStorageUtil.getStorage("authData");

    if (authData?.token) {
      dispatch(authSlice.actions.setIsAuth(true));
      authData?.user &&
        dispatch(authSlice.actions.getUserFulfilled(authData?.user));
      if (!authData?.user?.is_admin) {
        dispatch(
          companiesSlice.actions.setUserCompanyId(
            Number(authData?.user.companies[0]?.id)
          )
        );
        dispatch(
          companiesSlice.actions.setUserCompanyType(
            authData?.user.companies[0]?.type
          )
        );
      }
    } else {
      dispatch(authSlice.actions.setIsAuth(false));
    }
  };
};

export const getUser = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.getUserPending());

      const { data } = await authAxios(dispatch).get(`/api/auth/user`);
      dispatch(authSlice.actions.getUserFulfilled(data));
    } catch (error: any) {
      dispatch(authSlice.actions.getUserRejected(error.data.message));
    }
  };
};

export const forgotPassword = (
  body: ForgotRequestData,
  navigate: (value: string) => void
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.cleareAuthValidationError());
      dispatch(authSlice.actions.forgotPasswordPending());

      const { data } = await authAxios(dispatch).post(
        `/api/auth/password/email`,
        body
      );

      dispatch(authSlice.actions.forgotPasswordFulfilled());
      navigate(RoutesNames.LOGIN);

      notification.info({
        icon: <EmailLogo style={{ width: 27, height: 27 }} />,
        message: "Check email please",
        description: data,
        duration: 6,
      });
    } catch (error: any) {
      if (error.status === 422) {
        const emailErrorText = error.data.errors?.email
          ? error.data.errors.email
          : "";

        dispatch(
          authSlice.actions.setValidationError({
            email: emailErrorText,
          })
        );
      }
      if (error.status === 404) {
        notification.error({
          message: error.data.message,
          description: error.data.error,
          duration: 6,
        });
      }
    }
  };
};

export const resetPassword = (body: ResetRequestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.resetPasswordPending());
      await authAxios(dispatch).post(`/api/auth/password/reset`, body);
      dispatch(authSlice.actions.resetPasswordFulfilled());
    } catch (error: any) {
      if (error.status === 422) {
        const emailErrorText = error.data.errors?.email
          ? error.data.errors.email
          : "";

        dispatch(
          authSlice.actions.resetPasswordRejected(
            `${error.data.message} ${emailErrorText}`
          )
        );
      }
    }
  };
};

export const checkResetLink = (email: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.setIsResetLinkValidPending);

      const { data } = await authAxios(dispatch).post(
        `/api/auth/password/checkToken`,
        {
          email,
        }
      );

      dispatch(authSlice.actions.setIsResetLinkValidFulfilled(data));
    } catch (error: any) {
      dispatch(
        authSlice.actions.setIsResetLinkValidRejected(error.data.message)
      );
    }
  };
};
