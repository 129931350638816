export const removeDuplicates = (originalArray: [], prop: string) => {
  var newArray = [];
  var lookupObject: any  = {};

  for(var i in originalArray) {
     lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for(i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
   return newArray;
}