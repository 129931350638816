import { FC } from "react";

import styles from "./header.module.scss";

interface HeaderOwnProps {
  logo: string;
}

const Header: FC<HeaderOwnProps> = ({ logo }): JSX.Element => {
  return (
    <header className={styles.container}>
      <div style={{paddingLeft: '40px', paddingTop: '10px'}}>
        <img src={logo} />
      </div>
    </header>
  );
};

export default Header;
