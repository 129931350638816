import { Button, Input, Select } from "antd";
import { FC, useState } from "react";
import styles from "./filterControl.module.scss";
import { ReactComponent as SearchIcon } from "../../../common/icons/search.svg";
import { ReactComponent as FilterIcon } from "../../../common/icons/filter.svg";
import "./filterControl.global.scss";
import classnames from "classnames";
import { useActions } from "../../../../hooks/useActions";
import useDebounce from "../../../../hooks/useDebounce";
import { CloseOutlined } from "@ant-design/icons";
import { SelectData, SelectTagData } from "../../../common/types";
const { Option } = Select;

interface ProductFormFilteControlOwnProps {
  sortedBy: SelectData[];
  sortedByData: SelectTagData[];
  getSortedByData: (value: string) => void;
  searchName: string;
}

const ProductFormFilterControl: FC<ProductFormFilteControlOwnProps> = ({
  sortedBy,
  sortedByData,
  getSortedByData,
  searchName,
}): JSX.Element => {
  const {
    setProductFormFilterSearch,
    sortedByProductFormFilterIds,
    clearProductFormFilterControl,
  } = useActions();

  const [tagsData, setTagsData] = useState<SelectTagData[]>([]);
  const [sortedByValue, setSortedByValue] = useState<string>(
    sortedBy[0].value as string
  );
  const [isOpen, setIsOpen] = useState(false);
  const debounced = useDebounce(setProductFormFilterSearch, 1000);

  const handleSortedChange = (value: string) => {
    clearProductFormFilterControl();
    setTagsData([]);
    getSortedByData(value);
    setSortedByValue(value);
  };

  const handleTagsChange = (values: SelectTagData[]) => {
    setTagsData(values);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames([
          isOpen
            ? styles.filterControlFieldShow
            : styles.filterControlFieldHide,
        ])}
      >
        <div className="productFilterFormSelectGroup">
          <Select
            size="middle"
            defaultValue={String(sortedBy[0].value)}
            className={styles.selectSortedByField}
            onChange={handleSortedChange}
          >
            {sortedBy?.map((sortOption: SelectData) => (
              <Option key={sortOption.name} value={`${sortOption.value}`}>
                {sortOption.name}
              </Option>
            ))}
          </Select>

          <Select
            size="middle"
            maxTagTextLength={4}
            autoClearSearchValue={true}
            className={styles.selectTagsField}
            allowClear
            maxTagCount="responsive"
            value={tagsData}
            labelInValue
            filterOption={(inputValue, option: any) =>
              option?.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            mode="multiple"
            placeholder={`Please select ${sortedByValue}`}
            onChange={handleTagsChange}
          >
            {sortedByData?.map((sortOption: SelectTagData) => (
              <Option key={sortOption.key} value={`${sortOption.value}`}>
                {sortOption.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.buttonSet}
            onClick={() => {
              sortedByProductFormFilterIds({
                sortedByFilterIds: {
                  sortByName: `${sortedByValue.toLowerCase()}`,
                  filterIds: tagsData.map((item) => Number(item.key)),
                },
              });
              setIsOpen(false);
            }}
          >
            Apply
          </Button>
          <CloseOutlined
            className={styles.closeFilterButton}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </div>
      <Button
        className={classnames([
          isOpen ? styles.buttonFilterActive : styles.buttonFilter,
        ])}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FilterIcon />
        <div className={styles.buttonFilterName}>Filter</div>
      </Button>
      <div className="productFilterFormSearch">
        <SearchIcon className={styles.searchIcon} />
        <Input
          className={styles.inputSearch}
          placeholder={`Search ${searchName}`}
          required={false}
          onChange={(e) => {
            (e.target.value.length > 2 || e.target.value.length === 0) &&
              debounced({ search: e.target.value });
          }}
        />
      </div>
    </div>
  );
};

export default ProductFormFilterControl;
