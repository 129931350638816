import { FC, useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import AppLayout from "../../common/components/AppLayout";
import ModalsGroup from "../components/ModalsGroup";
import UsersList from "../components/UsersList";
import styles from "./usersPage.module.scss";
import { ReactComponent as ProductsLogo } from "../../common/icons/products.svg";

const UsersPage: FC = (): JSX.Element => {
  const {
    cleareUsersStore,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearAdditionalFilter();
    clearAdditionalFilterControl();
    return () => {
      cleareUsersStore();
    };
  }, [clearAdditionalFilter, clearAdditionalFilterControl, cleareUsersStore]);

  return (
    <AppLayout
      title="Manage Users"
      headerLogo={<ProductsLogo className={styles.headerLogo} />}
    >
      <UsersList />
      <ModalsGroup />
    </AppLayout>
  );
};

export default UsersPage;
