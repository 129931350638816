import { Button } from "antd";
import { FC, useEffect } from "react";
import styles from "./filter.module.scss";
import FilterControl from "../../../common/components/FilterControl";
import {
  SortedByProductsFilterTypes,
  SortedByProductsFilterValues,
} from "../../constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  getCompaniesNames,
  getItemsGtins,
  getProductsGtins,
} from "../../../../api/filter";
import { useActions } from "../../../../hooks/useActions";
import { SelectData } from "../../../common/types";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ReactComponent as ProductIcon } from "../../../common/icons/activeProduct.svg";
import { ReactComponent as TrashIcon } from "../../../common/icons/trash.svg";
import {
  NumberValidationStatus,
  ValidationStatus,
} from "../../../common/constants/status";

interface FilterOwnProps {
  setOpenModal: (value: boolean) => void;
  countCheckedDraftProducts: number;
  totalDraftProductsCount: number;
  title?: string;
}

const sortedByValues: SelectData[] = [
  {
    name: SortedByProductsFilterTypes.PRODUCT_ID,
    value: SortedByProductsFilterValues.GTINS,
  },
  {
    name: SortedByProductsFilterTypes.STATUS,
    value: SortedByProductsFilterValues.STATUS,
  },
];

const sortedStatusData: SelectData[] = [
  {
    name: ValidationStatus.COMPLETE.toLowerCase(),
    value: NumberValidationStatus.COMPLETE,
  },
  {
    name: ValidationStatus.INCOMPLETE.toLowerCase(),
    value: NumberValidationStatus.INCOMPLETE,
  },
  {
    name: ValidationStatus.ALL.toLowerCase(),
    value: NumberValidationStatus.ALL,
  },
];

const DraftProductsFilter: FC<FilterOwnProps> = ({
  setOpenModal,
  countCheckedDraftProducts,
  totalDraftProductsCount,
  title
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const {
    cleareDraftProductStore,
    setFilterControlSortedByStatus,
    setIsConfirmDraftProductDeletionModal,
    setIsOpenDraftProductChangeStatusModal,
    setDraftProductsForActivationDeletion,
  } = useActions();

  const { draftProductsForActivationDeletion, totalValidProducts } =
    useAppSelector((state) => state.draftProducts);

  const { sortedByData, isSortedByStatus } = useAppSelector(
    (state) => state.filterControl
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);

  useEffect(() => {
    if (userCompanyId) {
      dispatch(getProductsGtins(undefined, userCompanyId as number));
    } else {
      dispatch(getProductsGtins());
    }
  }, [dispatch, userCompanyId]);

  const sortedByValuesWithCompanies = () => {
    if (user?.is_admin) {
      return [
        ...sortedByValues,
        {
          name: SortedByProductsFilterTypes.COMPANIES,
          value: SortedByProductsFilterValues.COMPANIES,
        },
      ];
    }

    return sortedByValues;
  };

  title = title ?? 'Drafts';

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterControlWrapper}>
        <div className={styles.title}>
          <ProductIcon className={styles.titleIcon} />
          <div className={styles.titleText}>
            <div className={styles.titleTextTop}>{title}</div>
            <div
              className={styles.titleTextBottom}
            >{`${totalDraftProductsCount}`}</div>
          </div>
        </div>
        <FilterControl
          searchName="Products"
          cleareActivationDeletionIdes={() =>
            setDraftProductsForActivationDeletion([])
          }
          sortedBy={sortedByValuesWithCompanies()}
          sortedByData={sortedByData}
          isSortedByStatus={isSortedByStatus}
          sortedStatusData={sortedStatusData}
          dateRangePicker={true}
          getSortedByData={(sortValue) => {
            if (sortValue === SortedByProductsFilterValues.GTINS) {
              if (userCompanyId) {
                dispatch(getItemsGtins(undefined, userCompanyId as number));
              } else {
                dispatch(getItemsGtins());
              }
            }
            if (sortValue === SortedByProductsFilterValues.STATUS) {
              setFilterControlSortedByStatus(true);
            }
            if (sortValue === SortedByProductsFilterValues.COMPANIES) {
              dispatch(getCompaniesNames());
            }
          }}
        />
        <div className={styles.countInfoWrapper}>
          {/*<div className={styles.countInfoField}>
            {!countCheckedDraftProducts
              ? totalDraftProductsCount
              : countCheckedDraftProducts}
          </div>
           <div className={styles.countInfoTitle}>
            {!countCheckedDraftProducts ? "Draft products" : "Selected"}
          </div> */}
        </div>
      </div>
      {draftProductsForActivationDeletion.length !== 0 ? (
        <div className={styles.buttonsIsCheckGroup}>
          <div
            className={styles.buttonDelete}
            onClick={() => {
              setIsConfirmDraftProductDeletionModal(true);
            }}
          >
            <TrashIcon className={styles.trashIcon} />
            <div>Delete</div>
          </div>
          <Button
            className={styles.buttonActivate}
            type="primary"
            onClick={() => {
              setIsOpenDraftProductChangeStatusModal(true);
            }}
          >
            <div>Activate</div>
          </Button>
        </div>
      ) : (
        !user?.is_admin && ( ''
        //   <Button
        //     type="primary"
        //     className={styles.buttonAdd}
        //     onClick={() => {
        //       cleareDraftProductStore();
        //       setOpenModal(true);
        //     }}
        //   >
        //     <div>Create</div>
        //   </Button>
        )
      )}
    </div>
  );
};

export default DraftProductsFilter;
