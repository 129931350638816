import { Col, Row, Tabs } from 'antd';
import style from './summaryRetailer.module.scss'

interface iconInfoProps {
  icon: string;
  label: string;
  amount: number;
  span: number;
  offset: number;
}

export const IconInfoBlock = ({span, offset, icon, label, amount}: iconInfoProps) => {
    
    return (
        <>
            <Col span={span} offset={offset} className={style.infoBlock}>
                <Row>
                    <span><img src={icon} /></span>
                </Row>
                <Row>
                    <span className={style.infoBlockLabel}>{label}</span>
                </Row> 
                <Row>
                    <span className={style.infoBlockAmount}>{amount}</span>
                </Row>
            </Col>
        </>
    );
};