import React from "react";
import {useIntl} from "react-intl";

interface LanguageSwitcherProps {
  onChangeLanguage?: (lang: string) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({onChangeLanguage}) =>{
  const intl = useIntl();
  
  return (
    <div className="select" style={{paddingTop: '20px', fontFamily: 'Inter-Regular, sans-serif'}}>
      <select
        value={intl.locale}
        onChange={(e) => {
          if (onChangeLanguage) {
            onChangeLanguage(e.target.value)
          }
        }
        }
      >
        <option value="en">
          {intl.formatMessage({
            id: "languageOption1",
            defaultMessage: "English"
          })}
        </option>
        <option value="es">
          {intl.formatMessage({
            id: "languageOption2",
            defaultMessage: "Spanish"
          })}
        </option>
      </select>
    </div>
  );
}
export default LanguageSwitcher;