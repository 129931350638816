import intlMessagesES from '../lang/es.json';
import intlMessagesEN from '../lang/en.json';

type i18nConfig = {
  defaultLocale: string;
  availableLocales: string[],
  availableLocaleCodes: string[]
}

const messagesMap: Record<string, any> = {
  'es': intlMessagesES,
  'en': intlMessagesEN
}

class LocaleService {
  private readonly defaultLocale: string;
  private readonly availableLocales: string[];
  private readonly availableLocaleCodes: string[];
  constructor(config: i18nConfig) {
   this.defaultLocale = config.defaultLocale;
   this.availableLocales = config.availableLocales;
   this.availableLocaleCodes = config.availableLocaleCodes;
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  getDefaultLocale() {
    return this.defaultLocale;
  }

  async getMessages(lang: string) {
    if (this.availableLocales.includes(lang)) {
      let messages = null;
      try {
        messages = await this.loadMessages(lang);
      } catch (e) {
        console.error(e);
      }
      return messages;
    }
  }

  loadMessages(lang: string) {
    return import( `../lang/${lang}.json`);
  }

  getAvailableLocaleCodes(lang) {
    if(lang == 'en') {
      return '1';
    } else {
      return '2'
    }
  }
}

export default new LocaleService({defaultLocale: 'en', availableLocales: ['en', 'es'], availableLocaleCodes: ['1', '2']});