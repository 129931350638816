import axios from 'axios';
import { localStorageUtil } from '../../helpers';
import { sessionStorageUtil } from '../../helpers/sessionStorage';
const BASE_URL = process.env.REACT_APP_API_URL;

const authData = localStorageUtil.getStorage('authData')
  ? localStorageUtil.getStorage('authData')
  : sessionStorageUtil.getStorage('authData');

export const GET_QR_LINK = (
  bcid: string,
  url: string,
  gtin: string,
  lot_number: string,
  serial_number: string,
  expiration_date: string
) => {
  return axios.get(
    `${BASE_URL}/api/qr/generate?gtin=${gtin}${bcid ? `&bcid=${bcid}` : ''}${
      url ? `&url=${url}` : ''
    }${lot_number ? `&lot_number=${lot_number}` : ''}${
      serial_number ? `&serial_number=${serial_number}` : ''
    }${expiration_date ? `&expiration_date=${expiration_date}` : ''}`,
    {
      headers: {
        Authorization: `Bearer ${authData !== undefined?authData.token:''}`,
      },
    }
  );
};

export const GET_QR_LINK_DYNAMIC = (
    bcid: string,
    url: string,
    gtin: string,
    linkData: any[]
  ) => {

    let genUrl = `${BASE_URL}/api/qr/generate?gtin=${gtin}${bcid ? `&bcid=${bcid}` : ''}${
        url ? `&url=${url}` : ''
    }`;

    

    linkData.map((link, key) => {
        
        if(link.type === 'text') {
            genUrl += `/${link.value}/${link.content}`
        } else {
            // Date string
            let d = new Date(link.content);
            
            let parsedDate = ('' + d.getFullYear()).slice(-2) + ("0" + (d.getMonth() + 1)).slice(-2) + ("0" + d.getDate()).slice(-2);
            
            genUrl += `/${link.value}/${parsedDate}`;
        }
        
        return genUrl;
        
    })
    
    return axios.get(
      genUrl,
      {
        headers: {
          Authorization: `Bearer ${authData !== undefined?authData.token:''}`,
        },
      }
    );
  };

export const GET_GTIN = (search: string) => {
  return axios.get(`${BASE_URL}/api/filters/items-gtin?search=${search}`, {
    headers: {
      Authorization: `Bearer ${authData !== undefined?authData.token:''}`,
    },
  });
};

export const POST_QR_CODE = (data: any) => {
  return axios.post(`${BASE_URL}/api/qr/store`, data, {
    headers: {
      Authorization: `Bearer ${authData.token}`,
    },
  });
};

export const POST_ELABEL = (data: any) => {
  return axios.post(`${BASE_URL}/api/elabel/store`, data, {
    headers: {
      Authorization: `Bearer ${authData.token}`,
    },
  });
};

export const GET_GTIN_DATA = (id: string) => {
  return axios.get(`${BASE_URL}/api/item/${id}`, {
    headers: {
    },
  });
};