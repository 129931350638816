export enum Status {
  ACIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum NumberStatus {
  ACIVE = 1,
  INACTIVE = 0,
}

export enum ValidationStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  ALL = "ALL",
}

export enum NumberValidationStatus {
  COMPLETE = 1,
  INCOMPLETE = 0,
  ALL = 0,
}
