/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FC } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as CloseIcon } from "../../../common/icons/close.svg";
import { ReactComponent as UploadIcon } from "../../../common/icons/upload.svg";
import "./droppableItems.global.scss";
import { uniqueArray } from "../../../../utils/uniqueArray";
import { ProductItem } from "../../types";
// import { setDescriptorsPriority } from "../../utils/setDescriptorsPriority";
import { Input, Spin } from "antd";
import { ReactComponent as ActiveItemIcon } from "../../../common/icons/active.svg";
import useDebounceWithoutDispatch from "../../../../hooks/useDebounceWithoutDispatch";
import { getActiveItemById } from "../../../../api/items";
import { ModalTabs } from "../../../common/constants/modalTabs";
import { getItemFormFieldsByCompanyId } from "../../../../api/itemForm";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useActions } from "../../../../hooks/useActions";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import ProductFormFilter from "../ProductFormFilter";

interface DroppableColumn {
  Header: string;
  accessor: string;
}

interface DroppableItemsOwnProps {
  dataSource: ProductItem[] | [];
  tableColumns: DroppableColumn[];
  isLoading: boolean;
  setDraftProductItems: (productItems: ProductItem[]) => void;
  productItems: ProductItem[] | [];
}

interface DragProps {
  items: ProductItem[];
  droppableId: "ITEMS_TABLE" | "ITEMS_FIELD";
  tableColumns: DroppableColumn[];
  removeItemFromList?: (id: number) => void;
  changeItemFromList?: (changedItem: ProductItem) => void;
}

const getRenderItem =
  (items: ProductItem[], tableColumns: DroppableColumn[]) =>
  (provided: any, snapshot: any, rubric: any) => {
    const item: any = items[rubric.source.index];
    return (
      <li
        className={snapshot.isDragging ? "listTrDragging" : "listTr"}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {tableColumns.map((column) => {
          const key: string = column.accessor;
          return (
            <div key={item[key]} className="listTd">
              {item[key]}
            </div>
          );
        })}
      </li>
    );
  };

const Copyable = (props: DragProps) => {
  return (
    <Droppable
      renderClone={getRenderItem(props.items, props.tableColumns)}
      droppableId={props.droppableId}
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <ul ref={provided.innerRef} className="listTbody">
          {props.items.map((item: any, index) => {
            const shouldRenderClone =
              String(item.gtin + item.quantity) ===
              snapshot.draggingFromThisWith;
            return (
              <React.Fragment key={item.gtin}>
                {shouldRenderClone ? (
                  <li className="listTrCopy">
                    {props.tableColumns.map((column) => {
                      const key: string = column.accessor;
                      return (
                        <div key={item[key]} className="listTd">
                          {item[key]}
                        </div>
                      );
                    })}
                  </li>
                ) : (
                  <Draggable
                    draggableId={String(item.gtin + item.quantity)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        className={
                          snapshot.isDragging ? "listTrDragging" : "listTr"
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {props.tableColumns.map((column) => {
                          const key: string = column.accessor;
                          return (
                            <div key={item[key]} className="listTd">
                              {item[key]}
                            </div>
                          );
                        })}
                      </li>
                    )}
                  </Draggable>
                )}
              </React.Fragment>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

const TBody = (props: DragProps) => {
  return (
    <Copyable
      items={props.items}
      droppableId={props.droppableId}
      tableColumns={props.tableColumns}
    />
  );
};

const DraggableItemsField = (props: DragProps) => {
  const debounced = useDebounceWithoutDispatch(props.changeItemFromList, 1000);
  const dispatch = useAppDispatch();
  const { userCompanyId } = useAppSelector((state) => state.companies);
  const {
    cleareActiveItemStore,
    cleareDraftItemStore,
    setIsOpenDraftItemsFormModal,
    setModalActiveTab,
  } = useActions();

  return (
    <Droppable droppableId={props.droppableId}>
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          className={
            snapshot.draggingOverWith
              ? "listTbodyItemsFieldDragging"
              : "listTbodyItemsField"
          }
        >
          {props.items.length !== 0 ? (
            props.items.map((item: any, index) => {
              return (
                <Draggable
                  key={item.gtin}
                  draggableId={item.gtin as string}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      className={`listTr${item.descriptor}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {props.tableColumns.map((column) => {
                        const key: string = column.accessor;
                        if (column.accessor === "quantity") {
                          return (
                            <div className="listInput" key={item[key]}>
                              <Input
                                defaultValue={item[key]}
                                size="small"
                                type="number"
                                min={0}
                                max={99999}
                                required={false}
                                onChange={(event: any) => {
                                  debounced({
                                    ...item,
                                    quantity: Number(event.target.value),
                                  });
                                }}
                              />
                            </div>
                          );
                        } else {
                          if (column.accessor === "gtin") {
                            return (
                              <div
                                key={item[key]}
                                className="listTdGtin"
                                onClick={() => {
                                  cleareDraftItemStore();
                                  cleareActiveItemStore();
                                  dispatch(
                                    getItemFormFieldsByCompanyId(
                                      String(userCompanyId)
                                    )
                                  );
                                  dispatch(
                                    getActiveItemById(item.id as number)
                                  );
                                  setIsOpenDraftItemsFormModal(true);
                                  setModalActiveTab(ModalTabs.MAIN_FORM);
                                }}
                              >
                                {item[key]}
                              </div>
                            );
                          } else {
                            return (
                              <div key={item[key]} className="listTd">
                                {item[key]}
                              </div>
                            );
                          }
                        }
                      })}
                      <div
                        className="itemDeleteButton"
                        onClick={() =>
                          props.removeItemFromList &&
                          props.removeItemFromList(item.id as number)
                        }
                      >
                        <CloseIcon />
                      </div>
                    </li>
                  )}
                </Draggable>
              );
            })
          ) : (
            <div className="uploadWrapper">
              <UploadIcon className="uploadIcon" />
              <div className="uploadText">Drag&drop to add an item</div>
            </div>
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

const reorder = (list: any, startIndex: any, endIndex: any) => {
  let copiedList: any = [...list];
  const [removed] = copiedList.splice(startIndex, 1);
  copiedList.splice(endIndex, 0, removed);
  // copiedList = setDescriptorsPriority(copiedList);
  return copiedList;
};

const copy = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const item = source[droppableSource.index];
  destination.splice(droppableDestination.index, 0, item);
  // destination = setDescriptorsPriority(
  //   uniqueArray(destination) as ProductItem[]
  // );
  destination = uniqueArray(destination) as ProductItem[];
  return destination;
};

const DroppableItems: FC<DroppableItemsOwnProps> = ({
  dataSource,
  tableColumns,
  isLoading,
  setDraftProductItems,
  productItems,
}): JSX.Element => {
  const [activeItemsField, setActiveItemsFiels] = React.useState([]);
  const onDragEnd = React.useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          if (activeItemsField.length === 0) {
            setActiveItemsFiels(productItems as any);
          }
          setActiveItemsFiels((state) =>
            reorder(state, source.index, destination.index)
          );
          break;
        case "ITEMS_TABLE":
          if (productItems.length === 0 && activeItemsField.length !== 0) {
            setActiveItemsFiels(productItems as any);
          }
          setActiveItemsFiels((state) => {
            const stateWithoutDuplicate = uniqueArray([
              ...state,
              ...productItems,
            ]) as ProductItem[];
            return copy(dataSource, stateWithoutDuplicate, source, destination);
          });
          break;
        default:
          break;
      }
    },
    [dataSource]
  );

  useEffect(() => {
    setDraftProductItems(activeItemsField);
  }, [activeItemsField]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
              <DraggableItemsField
          items={
            activeItemsField.length === 0 ? productItems : activeItemsField
          }
          tableColumns={tableColumns}
          droppableId="ITEMS_FIELD"
          removeItemFromList={(id: number) => {
            setActiveItemsFiels(
              productItems.filter((item: ProductItem) => item.id !== id) as any
            );
          }}
          changeItemFromList={(changedItem) => {
            const newActiveItemsField: ProductItem[] = productItems.map(
              (item: ProductItem) => {
                if (changedItem.id === item.id) {
                  return changedItem;
                }
                return item;
              }
            );

            setActiveItemsFiels(newActiveItemsField as any);
          }}
        />

<ProductFormFilter />
      <div className="dragableWrapper">
        <div className="droppableTable">
          <div className="listHeader">
            {tableColumns.map((column) => (
              <div key={column.Header} className="listTh">
                {column.Header}
              </div>
            ))}
          </div>
          {!isLoading ? (
            dataSource.length !== 0 ? (
              <TBody
                items={dataSource}
                tableColumns={tableColumns}
                droppableId="ITEMS_TABLE"
              />
            ) : (
              <div className="emptyList">
                <ActiveItemIcon className="emptyIcon" />
                <div className="emptyText">Not items yet</div>
              </div>
            )
          ) : (
            <div className="loadingWrapper">
              <Spin />
            </div>
          )}
        </div>
      </div>
    </DragDropContext>
  );
};

export default DroppableItems;
