/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import { ForgotProps } from "../../types";
import InputField from "../../../common/components/InputField";
import styles from "./forgotForm.module.scss";
import { Button, Row, Col } from "antd";
import { forgotPassword } from "../../../../api/auth";
import { NavLink } from "react-router-dom";
import { RoutesNames } from "../../../../routes";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import VindixLogo from "../../../common/icons/vindixlogocolor.png";
import VindixSubtitle from "../../../common/icons/vindixloginsubtitle.png";

interface ForgotFormOwnProps {
  dispatch: (value: any) => void;
  navigate: (value: string) => void;
}

const ForgotForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  dispatch,
}: FormikProps<ForgotProps> & ForgotFormOwnProps) => {
  const { validationError } = useAppSelector((store) => store.auth);
  const { cleareAuthValidationError } = useActions();

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.wineForm}
    >
        <Row align="middle">
            <Col span={24}>
                <Row justify="center" align="middle">
                    <img src={VindixLogo} width="300px" />
                </Row>
                <Row justify="center" align="middle">
                    <img src={VindixSubtitle} style={{paddingTop: '25px'}} />
                </Row>
            </Col>
            <Col span={24}><h1 className={styles.formTitle}>FORGOT PASSWORD</h1></Col>
            <Row className={styles.fields}>
            <Col span={24} >    
                <Col span={12} offset={6}>
                    <InputField
                    title="Email"
                    className={styles.field}
                    titleClassName={styles.fieldTitleWine}
                    value={values.email}
                    required={false}
                    style={{color: '#000000'}}
                    errorMessage={
                        (touched.email && errors.email) ||
                        (validationError && (validationError.email as any))
                    }
                    onBlur={() => setFieldTouched("email")}
                    onChange={(event: any) => {
                        setFieldValue("email", event.target.value);
                        cleareAuthValidationError();
                    }}
                    />
                </Col>
            </Col>
            </Row>
            <Col span={12} offset={6} style={{textAlign: 'center'}}>
                <div className={styles.buttonGroup}>
                    <Button
                        className={styles.button}
                        type="primary"
                        onClick={() => handleSubmit()}
                        >Send
                    </Button>
                </div>
                    <NavLink className={styles.buttonLink} to={RoutesNames.LOGIN}>
                    Back
                    </NavLink>
            </Col>
        </Row>
    </form>
  );
};

const ForgotFormWithFormik = withFormik<ForgotFormOwnProps, ForgotProps>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: "",
  }),
  handleSubmit: (values, { props: { dispatch, navigate } }) => {
    dispatch(forgotPassword(values, navigate));
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
  }),
  displayName: "ForgotForm",
})(ForgotForm);

export default ForgotFormWithFormik;
