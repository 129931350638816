import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./activeProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import ActiveProductsFilter from "../ActiveProductsFilter";
import { DeletionActivateDeactiveProductProps } from "../../types";
import { ReactComponent as ActiveProductIcon } from "../../../common/icons/activeProduct.svg";
import {
  getAllActiveProducts,
  getAllActiveProductsForView,
  getDraftProductById,
} from "../../../../api/products";
import ProductItemsTable from "../ProductItemsTable";

const ActiveProductsList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    setAdditionalFilter,
    setActiveProductsForDeactivationDeletion,
    setIsOpenProductFormForView,
    cleareDraftProductStore,
    setIsOpenDraftProductsFormModal,
  } = useActions();

  const {
    activeProducts,
    totalProducts,
    isLoading,
    activeProductsForDeactivationDeletion,
  } = useAppSelector((state) => state.activeProducts);

  const { isChangeProductsDeletionActivationActions } = useAppSelector(
    (state) => state.modals
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.additionalFilter);

  useEffect(() => {
    if (!user?.is_admin) {
      dispatch(getAllActiveProducts(userCompanyId as number, filter));
    } else {
      dispatch(getAllActiveProductsForView(filter));
    }
  }, [
    dispatch,
    filter,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
  ]);

  const getColumns = () => [
    {
      title: !user?.is_admin && (
        <Checkbox
          className={styles.mainCheckbox}
          checked={activeProductsForDeactivationDeletion.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const checkAllActiveProducts = activeProducts?.map(
                (activeProduct) => {
                  return { isChecked: true, id: activeProduct.id };
                }
              );
              setActiveProductsForDeactivationDeletion(
                checkAllActiveProducts as DeletionActivateDeactiveProductProps[]
              );
            } else {
              setActiveProductsForDeactivationDeletion([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "PRODUCT ID",
      dataIndex: "gtin",
      key: "gtin",
    },
    {
      title: "PRODUCT DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
    {
      title: user?.is_admin && "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      dataIndex: "empty",
      key: "empty",
    },
    Table.EXPAND_COLUMN,
  ];

  const getDataSource = () => {
    return activeProducts?.map(
      ({ id, description, date, items, company_name, gtin }) => {
        return {
          key: id,
          check: !user?.is_admin && (
            <Checkbox
              checked={
                activeProductsForDeactivationDeletion.find(
                  (value: DeletionActivateDeactiveProductProps) =>
                    value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setActiveProductsForDeactivationDeletion([
                    ...activeProductsForDeactivationDeletion,
                    data as DeletionActivateDeactiveProductProps,
                  ]);
                } else {
                  setActiveProductsForDeactivationDeletion(
                    activeProductsForDeactivationDeletion.filter(
                      (product) => product.id !== id
                    )
                  );
                }
              }}
            />
          ),
          gtin: (
            <div
              className={styles.gtin}
              onClick={() => {
                setIsOpenProductFormForView(true);
                cleareDraftProductStore();
                dispatch(getDraftProductById(id as number));
                setIsOpenDraftProductsFormModal(true);
              }}
            >
              {gtin}
            </div>
          ),
          description: (
            <span
              className={
                user?.is_admin ? styles.descriptionAdmin : styles.description
              }
            >
              {description}
            </span>
          ),
          company: user?.is_admin && (
            <div className={styles.companyName}>{company_name}</div>
          ),
          date: <div className={styles.date}>{date}</div>,
          empty: <div className={styles.emptyField} />,
          items,
        };
      }
    );
  };

  return (
    <>
      <ActiveProductsFilter
        totalDraftProductsCount={totalProducts}
        countCheckedActiveProducts={
          activeProductsForDeactivationDeletion.length
        }
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <ActiveProductIcon className={styles.emptyIcon} />
                <div>Not products activated</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          expandable={{
            expandedRowRender: (product) => (
              <ProductItemsTable productItems={product.items} />
            ),
          }}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: "calc(100vh - 594px)" }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={activeProducts?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setAdditionalFilter({ page, paginate: perPage }));
              setActiveProductsForDeactivationDeletion([]);
            } else {
              dispatch(setAdditionalFilter({ page }));
              setActiveProductsForDeactivationDeletion([]);
            }
          }}
          total={totalProducts || 10}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </div>
    </>
  );
};

export default ActiveProductsList;
