export const checkItemProps = (obj: any): any => {
  if (obj) {
    let resArr: any = [];

    Object.keys(obj).forEach((key) => {
      if (key === "item_forms") {
        return resArr.push(...checkItemProps(obj[key]));
      } else {
        if (!obj[key]) {
          return false;
        } else {
          return resArr.push(obj[key]);
        }
      }
    });

    return resArr;
  }
};
