import { createSlice } from "@reduxjs/toolkit";
import {
  AuthRejectedAction,
  AuthState,
  AuthValidationErrorAction,
  GetIsResetLinkValidFulfilledAction,
  GetUserFulfilledAction,
} from "../../../modules/auth/types";

const initialState: AuthState = {
  isAuth: false,
  user: null,
  isResetLinkValid: null,
  isLoading: false,
  validationError: null,
  error: null,
  isWineApp: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInPending: (state: AuthState) => {
      state.isLoading = true;
    },
    signInFulfilled: (state: AuthState) => {
      state.isLoading = false;
      state.error = null;
    },
    signInRejected: (state: AuthState, action: AuthRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setIsResetLinkValidPending: (state: AuthState) => {
      state.isLoading = true;
    },
    setIsResetLinkValidFulfilled: (
      state: AuthState,
      action: GetIsResetLinkValidFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.isResetLinkValid = action.payload;
    },
    setIsResetLinkValidRejected: (
      state: AuthState,
      action: AuthRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setIsAuth: (state: AuthState, action) => {
      state.isAuth = action.payload;
    },
    signOutPending: (state: AuthState) => {
      state.isLoading = true;
    },
    signOutFulfilled: (state: AuthState) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
    },
    signOutRejected: (state: AuthState, action: AuthRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserPending: (state: AuthState) => {
      state.isLoading = true;
    },
    getUserFulfilled: (state: AuthState, action: GetUserFulfilledAction) => {
      state.isLoading = false;
      state.error = null;
      state.user = action.payload;
    },
    getUserRejected: (state: AuthState, action: AuthRejectedAction) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    forgotPasswordPending: (state: AuthState) => {
      state.isLoading = true;
    },
    forgotPasswordFulfilled: (state: AuthState) => {
      state.isLoading = false;
      state.error = null;
    },
    forgotPasswordRejected: (state: AuthState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetPasswordPending: (state: AuthState) => {
      state.isLoading = true;
    },
    resetPasswordFulfilled: (state: AuthState) => {
      state.isLoading = false;
      state.error = null;
    },
    resetPasswordRejected: (state: AuthState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setValidationError: (state: AuthState, action: AuthValidationErrorAction) => {
      state.isLoading = false;
      state.validationError = action.payload;
    },
    clearUserStore: (state: AuthState) => {
      state.user = null;
    },
    cleareAuthValidationError: (state: AuthState) => {
      state.isLoading = false;
      state.validationError = null;
    },
    setIsWineApp: (state: AuthState, action) => {
      state.isWineApp = action.payload;
    },
  },
});
