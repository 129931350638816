import { ItemFormFieldIds } from "../../itemForm/constants";
import { ItemFormField } from "../../itemForm/types";
import { ItemFormsWithValue } from "../types";

export const converToItemFormsData = (data: ItemFormField[]) => {
  const newData: any = {};
  data.forEach((item) => {
    if (item.key) {
      if (item.field_type_id === ItemFormFieldIds.SWITCH) {
        newData[item.key] = false;
      } else {
        if (item.field_type_id === ItemFormFieldIds.SELECT) {
          newData[item.key] = null;
        } else {
          newData[item.key] = "";
        }
      }
    }
  });
  return newData;
};

export const converToItemFormsDataWithValue = (data: ItemFormsWithValue[]) => {
  const newData: any = {};
  data.forEach((item) => {
    if (item.item_field.key) {
      if (item.value) {
        newData[item.item_field.key] = item.value;
      } else {
        newData[item.item_field.key] = null;
      }
    }
  });
  return newData;
};
