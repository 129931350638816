import { useCallback, useRef } from "react";

export default function useDebounceWithoutDispatch(callback: any, delay: number) {
  const timer: any = useRef();

  const debouncedCallback = useCallback(
    (values) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        callback(values);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
}
