import { Button, Checkbox, Pagination, Table } from "antd";
import { FC, useEffect } from "react";
import { getAllCompanies, getCompanyById } from "../../../../api/companies";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Status } from "../../../common/constants/status";
import styles from "./companiesList.module.scss";
import classnames from "classnames";
import { MinusOutlined } from "@ant-design/icons";
import { useActions } from "../../../../hooks/useActions";
import Filter from "../Filter";
import { DeletionCompaniesProps } from "../../types";
import { useNavigate } from "react-router-dom";
import { SortedByCompaniesFilterTypes } from "../../constants";

const CompaniesList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    setFilter,
    setIsOpenCompaniesFormModal,
    setIsOpenCompanyChangeStatusModal,
    setIsConfirmCompanyDeletionModal,
    setCompaniesForDeletion,
    cleareCompanyStore,
    sortedByFilterIds,
    clearFilter,
  } = useActions();
  const { companies, totalItems, isLoading, companiesForDeletion } =
    useAppSelector((state) => state.companies);
  const { companySuccessfulStatus, isChangeCompanyDeletionActivationActions } =
    useAppSelector((state) => state.modals);

  const filter = useAppSelector((state) => state.filter);

  useEffect(() => {
    dispatch(getAllCompanies(filter));
  }, [
    dispatch,
    filter,
    companySuccessfulStatus,
    isChangeCompanyDeletionActivationActions,
  ]);

  const getColumns = () => [
    {
      title: (
        <Checkbox
          className={styles.mainCheckbox}
          checked={companiesForDeletion.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const checkAllCompanies = companies?.map((company) => {
                return { isChecked: true, id: company.id };
              });
              setCompaniesForDeletion(
                checkAllCompanies as DeletionCompaniesProps[]
              );
            } else {
              setCompaniesForDeletion([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "COMPANY NAME",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "GLN",
      dataIndex: "gln",
      key: "gln",
    },
    {
      title: "CONTACT NAME",
      dataIndex: "contactName",
      key: "contactName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "USERS",
      dataIndex: "users",
      align: "center" as "center",
      key: "users",
    },
    {
      title: "STATUS",
      align: "center" as "center",
      dataIndex: "isActive",
      key: "isActive",
    },
  ];

  const getDataSource = () => {
    return companies?.map(
      ({
        id,
        company,
        gln,
        contact_name,
        email,
        contact_phone,
        users,
        status,
      }) => {
        return {
          key: id,
          check: (
            <Checkbox
              checked={
                companiesForDeletion.find(
                  (value: DeletionCompaniesProps) => value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setCompaniesForDeletion([
                    ...companiesForDeletion,
                    data as DeletionCompaniesProps,
                  ]);
                } else {
                  setCompaniesForDeletion(
                    companiesForDeletion.filter((item) => item.id !== id)
                  );
                }
              }}
            />
          ),
          companyName: (
            <div
              className={styles.companyInfo}
              onClick={() => {
                cleareCompanyStore();
                dispatch(getCompanyById(id as number));
                setIsOpenCompaniesFormModal(true);
              }}
            >
              {company}
            </div>
          ),
          gln,
          contactName: contact_name,
          email,
          phone: contact_phone,
          users: (
            <div
              className={styles.usersInfo}
              onClick={() => {
                navigate("/users");
                clearFilter();
                sortedByFilterIds({
                  sortedByFilterIds: {
                    sortByName:
                      SortedByCompaniesFilterTypes.COMPANIES.toLowerCase(),
                    filterIds: [id as number],
                  },
                });
              }}
            >
              {users}
            </div>
          ),
          isActive: (
            <Button
              className={classnames([
                status === Status.ACIVE
                  ? styles.buttonStatusActive
                  : styles.buttonStatusInactive,
              ])}
              type="primary"
              onClick={() => {
                setIsOpenCompanyChangeStatusModal({
                  isOpen: true,
                  changedStatusCompany: {
                    id: id as number,
                    name: company,
                    status: status as string,
                  },
                });
              }}
            >
              {status === Status.ACIVE ? Status.ACIVE : Status.INACTIVE}
            </Button>
          ),
        };
      }
    );
  };

  return (
    <>
      <Filter
        setOpenModal={setIsOpenCompaniesFormModal}
        totalCompaniesCount={totalItems}
        countCheckedCompanies={companiesForDeletion.length}
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{ emptyText: "No search results found" }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: "calc(100vh - 205px)" }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={companies?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setFilter({ page, paginate: perPage }));
              setCompaniesForDeletion([]);
            } else {
              dispatch(setFilter({ page }));
              setCompaniesForDeletion([]);
            }
          }}
          total={totalItems || 10}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
        {companiesForDeletion.length !== 0 && (
          <Button
            className={styles.buttonDelete}
            type="primary"
            onClick={() => {
              setIsConfirmCompanyDeletionModal(true);
            }}
          >
            <MinusOutlined className={styles.minusIcon} />
            <div>Delete Companies</div>
          </Button>
        )}
      </div>
    </>
  );
};

export default CompaniesList;
