export const convertFilesForRequest = (files: any) => {
    if (!files) {
      return [];
    } else {
        return files.map((item: any) => {
            if (item.file.originFileObj) {
                  const data: any = [];
                  for (let prop in item.fields) {
                    data.push({
                      field_id: +prop,
                      value: item.fields[prop],
                  });
                }
                return {
                    fields: data,
                    file: item.file.originFileObj,
                };
            }
            if (typeof item.file === 'string') {
                const data: any = [];
                for (let prop in item.fields) {
                    data.push({
                      field_id: +prop,
                      value: item.fields[prop],
                  });
                }

                return {
                    fields: data,
                    file: item.file,
                };
            } else {
                const data: any = [];
                for (let prop in item.fields) {
                    data.push({
                      field_id: +prop,
                      value: item.fields[prop],
                  });
                }
                
                return {
                    file: item.file.name,
                    fields: data,
                };
            }
        });
    }
};