import { FC } from 'react'
import AppLayout from "../../common/components/AppLayout";
import styles from './index.module.scss'
import { CloudUploadOutlined } from '@ant-design/icons';

const PublishPage: FC = (): JSX.Element => (
  <AppLayout
    headerLogo={<CloudUploadOutlined />}
    title="Publish"
  >
    <div />
  </AppLayout>
)

export default PublishPage