import { FC } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import AppAuthLayout from "../../../common/components/AppAuthLayout/retailer";
import SignInForm from "../../components/SignInFormRetailer";

const SignInPage: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <AppAuthLayout>
      <SignInForm dispatch={dispatch} />
    </AppAuthLayout>
  );
};

export default SignInPage;
