import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import { modalsSlice } from "../../redux/reducers/modals";
import {
  ChangeUserStatusRequest,
  GetUsersRequestData,
  GetUsersResponseData,
  IUser,
} from "../../modules/users/types";
import { usersSlice } from "../../redux/reducers/users";
import { SortedByUsersFilterTypes } from "../../modules/users/constants";
import { filterSlice } from "../../redux/reducers/filter";

export const getAllUsers = (props: GetUsersRequestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(usersSlice.actions.getAllUsersPending());
      let responseData: GetUsersResponseData;

      const checkParams =
        props.sortedByFilterIds?.sortByName ===
          SortedByUsersFilterTypes.STATUS.toLocaleLowerCase() ||
        props.sortedByFilterIds?.filterIds.length === 0;

      if (checkParams) {
        const { data } = await authAxios(dispatch).post(
          `/api/get-users?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
        );

        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `/api/get-users?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
          {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
          }
        );
        responseData = data;
      }

      dispatch(
        usersSlice.actions.getAllUsersFulfilled({
          users: responseData.data,
          totalItems: responseData.pagination.total,
          totalUsers: responseData.pagination.total_users,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          filterSlice.actions.setFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(usersSlice.actions.getAllUsersRejected(error.data.message));
    }
  };
};

export const getUserById = (id: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(usersSlice.actions.getUserPending());

      const { data } = await authAxios(dispatch).get(`/api/users/${id}`);

      dispatch(
        usersSlice.actions.getUserFulfilled({
          user: data,
        })
      );
    } catch (error: any) {
      dispatch(usersSlice.actions.getUserRejected(error.data.message));
    }
  };
};

export const createUser = (body: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(usersSlice.actions.cleareUsersValidationError());
      dispatch(usersSlice.actions.createUserPending());

      const { data } = await authAxios(dispatch).post(`/api/users`, body);

      dispatch(modalsSlice.actions.setIsOpenUsersFormModal(false));

      dispatch(
        modalsSlice.actions.setUserSuccessfulStatus({
          status: true,
          message: data,
        })
      );
    } catch (error: any) {
      if (error.status === 422) {
        const emailErrorText = error.data.errors?.email
          ? error.data.errors.email
          : "";

        dispatch(
          usersSlice.actions.setValidationError({ email: emailErrorText })
        );
      }
    }
  };
};

export const editUser = (body: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(usersSlice.actions.cleareUsersValidationError());
      dispatch(usersSlice.actions.editUserPending());

      const { data } = await authAxios(dispatch).put(
        `/api/users/${body.id}`,
        body
      );

      dispatch(modalsSlice.actions.setIsOpenUsersFormModal(false));

      dispatch(
        modalsSlice.actions.setUserSuccessfulStatus({
          status: true,
          message: data,
        })
      );

      dispatch(usersSlice.actions.cleareUserStore());
    } catch (error: any) {
      if (error.status === 422) {
        const emailErrorText = error.data.errors?.email
          ? error.data.errors.email
          : "";
        dispatch(
          usersSlice.actions.setValidationError({ email: emailErrorText })
        );
      }
    }
  };
};

export const changeUserStatusRequest = (body: ChangeUserStatusRequest) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(usersSlice.actions.changeStatusUserPending());

      await authAxios(dispatch).post(`/api/users/change-status/${body.id}`, {
        status: body.status,
      });

      dispatch(
        modalsSlice.actions.setIsChangeUserDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(usersSlice.actions.changeStatusUserRejected(error.data.message));
    }
  };
};

export const deleteUsers = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const config = {
      data: { array_ids: body },
    };

    try {
      dispatch(usersSlice.actions.deleteUsersPending());

      await authAxios(dispatch).delete(`/api/users/delete`, config);

      dispatch(usersSlice.actions.clearUsersForDeletion());

      dispatch(
        modalsSlice.actions.setIsChangeUserDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(usersSlice.actions.deleteUsersRejected(error.data.message));
    }
  };
};
