import { FC, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { checkAuth } from "../../../../api/auth";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { privateRoutes, publicRoutes, publicPrivateRoutes, RoutesNames } from "../../../../routes";
import settings from "../../../../settings";
import components from "../../../../components";

const AppRouter: FC = (): JSX.Element => {
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch, isAuth]);

  const getDefaultRoute = () => {
    if (user?.is_admin) {
      return RoutesNames.COMPANIES;
    }
    return RoutesNames[components[settings.platform].defaultRoute];
  };

  return isAuth ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      {publicPrivateRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      <Route path="*" element={<Navigate to={getDefaultRoute()} />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      {publicPrivateRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      <Route path="*" element={<Navigate to={RoutesNames.LOGIN} />} />
    </Routes>
  );
};

export default AppRouter;
