export const checkEqualItemProps = (objForKeys: any, checkedObj: any) => {
  if (objForKeys && checkedObj) {
    let resArr: any = [];

    const keys = Object.keys(objForKeys).filter(
      (key) => objForKeys[key] && checkedObj[key]
    );
    // .every((key) => String(objForKeys[key]) === String(checkedObj[key]));
    keys.forEach((key) => {
      if (key === "item_forms") {
        return resArr.push(
          ...checkEqualItemProps(objForKeys[key], checkedObj[key])
        );
      } else {
        if (String(objForKeys[key]) === String(checkedObj[key])) {
          return false;
        } else {
          return resArr.push(key);
        }
      }
    });

    return resArr;
  }
};
