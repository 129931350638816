import { Col, Row, Tabs } from 'antd';
import { Component } from 'react';

interface iconInfoProps {
  Icon: any;
  label: string;
  amount: number;
  span: number;
  offset: number;
  style: any;
}

export const IconInfoBlock = ({span, offset, Icon, label, amount, style}: iconInfoProps) => {
    
    return (
        <>
            <Col span={span} offset={offset} className={style.infoBlock}>
                <Row>
                    <span><Icon /></span>
                </Row>
                <Row>
                    <span className={style.infoBlockLabel}>{label}</span>
                </Row> 
                <Row>
                    <span className={style.infoBlockAmount}>{amount}</span>
                </Row>
            </Col>
        </>
    );
};