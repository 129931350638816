import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/retailer";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';
import SuppliersList from "../../products/components/SuppliersList";

const SuppliersPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Suppliers"
      headerLogo={<GoldOutlined />}
    >
      <SuppliersList size={475} title={'Suppliers'} showImages={true} />
    </AppLayout>
  );
};

export default SuppliersPage;
