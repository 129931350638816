import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout";
import styles from "./productsPage.module.scss";
import { Summary } from "../components/Summary/indexSupplier";
import ProductsList from "../../products/components/ActiveProductsList";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';

const DashboardPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Products"
      headerLogo={<GoldOutlined />}
    >
      <div style={{marginBottom: '20px'}}>
        <h2 style={{marginTop: '0px'}}>Dashboard</h2>
        <Summary />
      </div>
      <h3>Recent Updates</h3>
      <ProductsList size={475} title={'Products'} showImages={true} showFilters={false} pageSize={8} showPagination={false}/>
    </AppLayout>
  );
};

export default DashboardPage;
