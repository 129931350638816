import { ReactNode, FC } from "react";
import Header from "../Header";
import WineSideBar from "../WineSideBar";
import styles from "./appLayoutVindix.module.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface AppLayoutOwnProps {
  children: ReactNode;
  headerLogo: ReactNode;
  title: string;
}

const AppLayout: FC<AppLayoutOwnProps> = ({ children, title, headerLogo }): JSX.Element => {
  const { userCompanyType } = useAppSelector((state) => state.companies);

  return (
    <div className={styles.wineWrapper}>
      <WineSideBar />
      <div className={styles.contentWrapper}>
        <Header title={title} ><div className={styles.headerLogo}>{headerLogo}</div></Header>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
