import { FC, ChangeEvent } from 'react';
import InputField from '../../../common/components/InputField';
import styles from './itemFormInputNumberField.module.scss';

interface ItemFormInputNumberFieldOwnProps {
    fieldName: string;
    fieldValue: string;
    isFieldDisabled: boolean;
    setFieldValue: (value: string) => void;
    errorMessage?: any;
    maxLength?: number;
}

const ItemFormInputNumberField: FC<ItemFormInputNumberFieldOwnProps> = ({
    fieldName,
    fieldValue,
    isFieldDisabled,
    setFieldValue,
    errorMessage,
    maxLength,
}) => {
    const onInput = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Remove non-numeric characters
        setFieldValue(numericValue);
    };

    return (
        <>
            <div className={styles.fieldWrapper}>
                <div title={fieldName} className={styles.fieldName}>
                    {fieldName}
                </div>
                <InputField
                    maxLength={maxLength}
                    errorMessage={errorMessage}
                    value={fieldValue}
                    className={styles.field}
                    type="text"
                    min={0}
                    max={99999}
                    disabled={isFieldDisabled}
                    placeholder="Count"
                    required={false}
                    onInput={onInput}
                />
            </div>
        </>
    );
};

export default ItemFormInputNumberField;
