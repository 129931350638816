import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveItemsState,
  DeletionActivationDeactivationDraftItemsAction,
  ItemsRejectedAction,
  GetActiveItemFulfilledAction,
  GetActiveItemsFulfilledAction,
} from "../../../modules/items/types";

const initialState: ActiveItemsState = {
  activeItems: null,
  activeItem: null,
  activeHierarchy: [],
  photos: [],
  totalItems: 0,
  activeItemsForDeactivationDeletion: [],
  isLoading: false,
  error: null,
};

export const activeItemsSlice = createSlice({
  name: "activeItemsSlice",
  initialState,
  reducers: {
    getAllActiveItemsPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },
    getAllActiveItemsFulfilled: (
      state: ActiveItemsState,
      action: GetActiveItemsFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.activeItems = action.payload.activeItems;
      state.totalItems = action.payload.totalItems;
    },
    getAllActiveItemsRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getActiveItemPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },
    getActiveItemFulfilled: (
      state: ActiveItemsState,
      action: GetActiveItemFulfilledAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.activeItem = action.payload.activeItem;
      state.activeHierarchy = action.payload.activeHierarchy;
      state.photos = action.payload.photos;
    },
    getActiveItemRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createActiveItemPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },
    createActiveItemFulfilled: (state: ActiveItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    createActiveItemRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editActiveItemPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },
    editActiveItemFulfilled: (state: ActiveItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    editActiveItemRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    changeStatusActiveItemPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },

    changeStatusActiveItemFulfilled: (state: ActiveItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    changeStatusActiveItemRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteActiveItemsPending: (state: ActiveItemsState) => {
      state.isLoading = true;
    },

    deleteActiveItemsFulfilled: (state: ActiveItemsState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteActiveItemsRejected: (
      state: ActiveItemsState,
      action: ItemsRejectedAction
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setActiveItemsForDeactivationDeletion: (
      state: ActiveItemsState,
      action: DeletionActivationDeactivationDraftItemsAction
    ) => {
      state.activeItemsForDeactivationDeletion = action.payload;
    },
    clearActiveItemsForDeactivationDeletion: (state: ActiveItemsState) => {
      state.activeItemsForDeactivationDeletion = [];
    },
    cleareActiveItemStore: (state: ActiveItemsState) => {
      state.activeItem = null;
    },
    cleareActiveItemsStore: (state: ActiveItemsState) => {
      state.activeItems = null;
      state.activeItem = null;
      state.totalItems = 0;
      state.activeItemsForDeactivationDeletion = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});
