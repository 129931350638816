import { Table } from "antd";
import { FC } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ProductItem } from "../../types";
import ProductHierarchyElement from "../../../common/components/ProductHierarchy/ProductHierarchyElement";
import styles from "./productItemsTable.module.scss";

interface ProductItemsTableOwnProps {
  productItems: ProductItem[];
}

const ProductItemsTable: FC<ProductItemsTableOwnProps> = ({
  productItems,
}): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  const getColumns = () => [
    {
      title: "",
      dataIndex: "emptyStart",
      key: "emptyStart",
    },
    {
      title: "GTIN",
      dataIndex: "gtin",
      key: "gtin",
    },
    {
      title: "ITEM DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "emptyCenter",
      key: "emptyCenter",
    },
    {
      title: "DESCRIPTOR",
      dataIndex: "descriptor",
      key: "descriptor",
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "",
      dataIndex: "emptyEnd",
      key: "emptyEnd",
    },
  ];

  const getDataSource = () => {
    return productItems?.map(
      ({ id, description, descriptor, quantity, gtin }) => {
        return {
          key: id,
          emptyStart: !user?.is_admin && <div className={styles.emptyStart} />,
          gtin: <div className={styles.gtin}>{gtin}</div>,
          description: (
            <span
              className={
                !user?.is_admin ? styles.description : styles.descriptionAdmin
              }
            >
              {description}
            </span>
          ),
          emptyCenter: (
            <div
              className={
                !user?.is_admin ? styles.emptyCenter : styles.emptyCenterAdmin
              }
            />
          ),
          descriptor: (
            <div
              className={
                !user?.is_admin ? styles.descriptor : styles.descriptorAdmin
              }
            >
              <ProductHierarchyElement descriptor={descriptor} big={false} highlight={false} />
            </div>
          ),
          quantity: (
            <div
              className={
                !user?.is_admin ? styles.quantity : styles.quantityAdmin
              }
            >
              {quantity}
            </div>
          ),
          emptyEnd: (
            <div
              className={
                !user?.is_admin ? styles.emptyEnd : styles.emptyEndAdmin
              }
            />
          ),
        };
      }
    );
  };

  return (
    <div className="itemsTableWrapper">
      <Table
        dataSource={getDataSource()}
        columns={getColumns()}
        pagination={false}
      />
    </div>
  );
};

export default ProductItemsTable;
