import { FC } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import AppModal from "../../../common/components/AppModal";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  changeDraftItemsStatus,
  deleteDraftItems,
} from "../../../../api/items";
import DraftItemsFormModal from "../DraftItemsFormModal";
import { ModalTabs } from "../../../common/constants/modalTabs";

const DraftItemsModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { draftItemsForActivationDeletion } = useAppSelector(
    (state) => state.draftItems
  );
  const {
    isConfirmDraftItemDeletionModal,
    isOpenDraftItemAreYouSureModal,
    isOpenDraftItemChangeStatusModal,
  } = useAppSelector((state) => state.modals);

  const {
    cleareDraftItemStore,
    setDraftItemsForActivationDeletion,
    setIsConfirmDraftItemDeletionModal,
    setIsOpenDraftItemsFormModal,
    setIsOpenDraftItemAreYouSureModal,
    setIsOpenDraftItemChangeStatusModal,
    setIsChangeItemsDeletionActivationActions,
    setModalActiveTab,
    cleareDraftItemsValidationError
  } = useActions();
  return (
    <>
      <DraftItemsFormModal />

      <AppModal
        visible={isOpenDraftItemAreYouSureModal}
        handleOk={() => {
          cleareDraftItemStore();
          setIsOpenDraftItemsFormModal(false);
          setIsOpenDraftItemAreYouSureModal(false);
          cleareDraftItemsValidationError()
          setModalActiveTab(ModalTabs.MAIN_FORM);
        }}
        handleCancel={() => setIsOpenDraftItemAreYouSureModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to close
          without saving?"
        onCancel={() => setIsOpenDraftItemAreYouSureModal(false)}
      />

      <AppModal
        visible={isOpenDraftItemChangeStatusModal}
        handleOk={() => {
          dispatch(
            changeDraftItemsStatus(
              draftItemsForActivationDeletion.map((value) => value.id)
            )
          );
          setDraftItemsForActivationDeletion([]);
          setIsOpenDraftItemChangeStatusModal(false);
          setIsChangeItemsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsOpenDraftItemChangeStatusModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to change status to Active?"
        onCancel={() => setIsOpenDraftItemChangeStatusModal(false)}
      />

      <AppModal
        visible={isConfirmDraftItemDeletionModal}
        handleOk={() => {
          dispatch(
            deleteDraftItems(
              draftItemsForActivationDeletion.map((value) => value.id)
            )
          );
          setDraftItemsForActivationDeletion([]);
          setIsConfirmDraftItemDeletionModal(false);
          setIsChangeItemsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsConfirmDraftItemDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${draftItemsForActivationDeletion.length} Draft Item(s)?`}
        onCancel={() => setIsConfirmDraftItemDeletionModal(false)}
      />
    </>
  );
};

export default DraftItemsModalsGroup;
