export const convertToSortedByData = (data: []) => {
  return data.map((item) => {
    const keys = Object.keys(item);
    return { key: item[keys[0]], label: item[keys[1]], value: item[keys[0]] };
  });
};

export const convertToSelectData = (data: []) => {
  if (data.length !== 0) {
    return data.map((item) => {
      const keys = Object.keys(item);
      return { value: item[keys[0]], name: item[keys[1]] };
    });
  }

  return data;
};
