import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { ValidationStatus } from "../../../common/constants/status";
import styles from "./draftProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import DraftProductsFilter from "../DraftProductsFilter";
import {
  DeletionActivateDeactiveItemProps,
  DeletionActivateDeactiveProductProps,
} from "../../types";
import {
  getAllDraftProducts,
  // getDraftProductById,
  getAllDraftProductsForView,
  getDraftProductById,
  validateDraftProduct,
} from "../../../../api/products";
import { ReactComponent as CheckMarkIcon } from "../../../common/icons/checkMark.svg";
import { ReactComponent as CrossIcon } from "../../../common/icons/cross.svg";
import { ReactComponent as DraftProductIcon } from "../../../common/icons/draftProduct.svg";
import { checkValidateFields } from "../../utils/checkValidateFields";
import ProductItemsTable from "../ProductItemsTable";

const DraftProductsList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    setFilter,
    setIsOpenDraftProductsFormModal,
    setDraftProductsForActivationDeletion,
    cleareDraftProductStore,
    setIsOpenProductFormForView,
  } = useActions();

  const {
    draftProducts,
    draftProduct,
    totalProducts,
    isLoading,
    draftProductsForActivationDeletion,
  } = useAppSelector((state) => state.draftProducts);

  const {
    isChangeProductsDeletionActivationActions,
    draftProductSuccessfulStatus,
  } = useAppSelector((state) => state.modals);

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.filter);

  useEffect(() => {
    if (!user?.is_admin) {
      dispatch(getAllDraftProducts(userCompanyId as number, filter));
    } else {
      dispatch(getAllDraftProductsForView(filter));
    }

    if (
      draftProduct &&
      draftProduct.validated === ValidationStatus.INCOMPLETE &&
      checkValidateFields(draftProduct)
    ) {
      dispatch(
        validateDraftProduct(userCompanyId as number, {
          ...draftProduct,
          items: draftProduct.items,
        })
      );
    }
  }, [
    dispatch,
    filter,
    draftProduct,
    draftProductSuccessfulStatus,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
  ]);

  const getColumns = () => [
    {
      title: !user?.is_admin && (
        <Checkbox
          className={styles.mainCheckbox}
          checked={draftProductsForActivationDeletion.length !== 0}
          onChange={(e) => {
            if (e.target.checked) {
              const checkAllDraftProducts = draftProducts?.map(
                (draftProduct) => {
                  return { isChecked: true, id: draftProduct.id };
                }
              );
              setDraftProductsForActivationDeletion(
                checkAllDraftProducts as DeletionActivateDeactiveItemProps[]
              );
            } else {
              setDraftProductsForActivationDeletion([]);
            }
          }}
        />
      ),
      dataIndex: "check",
      align: "center" as "center",
      key: "check",
    },
    {
      title: "PRODUCT ID",
      dataIndex: "gtin",
      key: "gtin",
    },
    {
      title: "PRODUCT DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
    {
      title: user?.is_admin && "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "STATUS",
      dataIndex: "validated",
      key: "validated",
    },
    Table.EXPAND_COLUMN,
  ];

  const getDataSource = () => {
    return draftProducts?.map(
      ({ id, description, date, validated, items, company_name, gtin }) => {
        return {
          key: id,
          check: !user?.is_admin && (
            <Checkbox
              checked={
                draftProductsForActivationDeletion.find(
                  (value: DeletionActivateDeactiveProductProps) =>
                    value.id === id
                )?.isChecked
              }
              className={styles.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  const data = { isChecked: e.target.checked, id };
                  setDraftProductsForActivationDeletion([
                    ...draftProductsForActivationDeletion,
                    data as DeletionActivateDeactiveProductProps,
                  ]);
                } else {
                  setDraftProductsForActivationDeletion(
                    draftProductsForActivationDeletion.filter(
                      (product) => product.id !== id
                    )
                  );
                }
              }}
            />
          ),
          gtin: (
            <div
              className={styles.gtin}
              onClick={() => {
                if (user?.is_admin) {
                  setIsOpenProductFormForView(true);
                }
                cleareDraftProductStore();
                dispatch(getDraftProductById(id as number));
                setIsOpenDraftProductsFormModal(true);
              }}
            >
              {gtin}
            </div>
          ),
          description: (
            <span
              className={
                user?.is_admin ? styles.descriptionAdmin : styles.description
              }
            >
              {description}
            </span>
          ),
          company: user?.is_admin && (
            <div className={styles.companyName}>{company_name}</div>
          ),
          date: <div className={styles.date}>{date}</div>,
          validated:
            validated === ValidationStatus.INCOMPLETE ? (
              <div className={styles.buttonStatusInvalidate}>
                <CrossIcon className={styles.buttonIcon} />
                {ValidationStatus.INCOMPLETE}
                <div />
              </div>
            ) : (
              <div className={styles.buttonStatusValidate}>
                <CheckMarkIcon className={styles.buttonIcon} />
                {ValidationStatus.COMPLETE}
                <div />
              </div>
            ),
          items,
        };
      }
    );
  };

  return (
    <>
      <DraftProductsFilter
        setOpenModal={setIsOpenDraftProductsFormModal}
        totalDraftProductsCount={totalProducts}
        countCheckedDraftProducts={draftProductsForActivationDeletion.length}
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <DraftProductIcon className={styles.emptyIcon} />
                <div>No drafts yet</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          expandable={{
            expandedRowRender: (product) => (
              <ProductItemsTable productItems={product.items} />
            ),
          }}
          columns={getColumns()}
          pagination={false}
          scroll={{ y: 250 }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={draftProducts?.length === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setFilter({ page, paginate: perPage }));
              setDraftProductsForActivationDeletion([]);
            } else {
              dispatch(setFilter({ page }));
              setDraftProductsForActivationDeletion([]);
            }
          }}
          total={totalProducts || 10}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </div>
    </>
  );
};

export default DraftProductsList;
