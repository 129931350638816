import { Modal } from 'antd';
import { FC, useState } from 'react';
import './modalsGroup.global.scss';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { NumberStatus } from '../../../common/constants/status';
import { useActions } from '../../../../hooks/useActions';
import AppModal from '../../../common/components/AppModal';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { ItemFormField } from '../../types';
import { CompanyFieldsExpandForm } from '../CompanyFieldsExpandForm';
import {
    changeItemFormFieldStatusRequest,
    deleteItemFormFields,
    deleteTab,
} from '../../../../api/itemForm';
import ItemFormFieldForm from '../ItemFormFieldForm';
import CreateTabExpandFormWrapper from '../ItemFormFieldForm/CreateTabExpandForm';
import { getItemTabsList } from '../../../../api/filter';

const ModalsGroup: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [formIsChange, setFormIsChange] = useState(false);
    const {
        itemFormField,
        itemFormFieldsForDeletion,
        isLoading,
        selectedTabForDeletion,
    } = useAppSelector((state) => state.itemForm);

    const {
        itemFormFieldSuccessfulStatus,
        isConfirmItemFormFieldDeletionModal,
        isOpenItemFormFieldFormModal,
        isOpenItemFormFieldAreYouSureModal,
        isOpenItemFormFieldChangeStatusModal,
        isOpenCompanyAddFieldsModal,
        isOpenCreateTabModal,
        isOpenAreYouSureDeleteTabModal,
    } = useAppSelector((state) => state.modals);
    const {
        cleareSuccessfulStatus,
        cleareItemFormFieldStore,
        setItemFormFieldsForDeletion,
        setIsConfirmItemFormFieldDeletionModal,
        setIsOpenItemFormFieldFormModal,
        setIsOpenItemFormFieldAreYouSureModal,
        setIsOpenItemFormFieldChangeStatusModal,
        setIsChangeItemFormFieldDeletionActivationActions,
        setIsOpenCompanyAddFieldsModal,
        setIsOpenCreateTabModal,
        setNowCreatedTab,
        setIsOpenAreYouSureDeleteTabModal,
    } = useActions();
    return (
        <div className="modalGroup">
            <AppModal
                visible={isOpenAreYouSureDeleteTabModal}
                handleOk={async () => {
                    if (selectedTabForDeletion) {
                        await dispatch(deleteTab(selectedTabForDeletion));
                        await dispatch(getItemTabsList());
                    }
                    setIsOpenAreYouSureDeleteTabModal(false);
                }}
                handleCancel={() => setIsOpenAreYouSureDeleteTabModal(false)}
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want to delete tab?"
                onCancel={() => setIsOpenAreYouSureDeleteTabModal(false)}
            />
            <Modal
                visible={isOpenCompanyAddFieldsModal}
                footer={null}
                title={'ADD FIELDS TO COMPANY'}
                destroyOnClose
                onCancel={() => {
                    setIsOpenCompanyAddFieldsModal(false);
                }}
            >
                <CompanyFieldsExpandForm />
            </Modal>
            <Modal
                visible={isOpenCreateTabModal}
                footer={null}
                title={'Create New Tab'}
                destroyOnClose
                onCancel={() => {
                    setIsOpenCreateTabModal(false);
                }}
            >
                <CreateTabExpandFormWrapper />
            </Modal>

            <Modal
                visible={isOpenItemFormFieldFormModal}
                footer={null}
                title={itemFormField ? 'FIELD PROFILE' : 'ADD FIELD'}
                destroyOnClose
                onCancel={() => {
                    cleareItemFormFieldStore();
                    setIsOpenItemFormFieldFormModal(false);
                    if (formIsChange) {
                        setIsOpenItemFormFieldFormModal(true);
                        setIsOpenItemFormFieldAreYouSureModal(true);
                    }
                }}
                className="itemFormFieldFormModal"
            >
                <ItemFormFieldForm
                    dispatch={dispatch}
                    setFormIsChange={setFormIsChange}
                    setIsOpenItemFormFieldFormModal={
                        setIsOpenItemFormFieldFormModal
                    }
                    itemFormField={itemFormField as ItemFormField}
                    isItemFormLoading={isLoading}
                    setNowCreatedTab={setNowCreatedTab}
                />
            </Modal>

            <AppModal
                visible={isOpenItemFormFieldAreYouSureModal}
                handleOk={() => {
                    setNowCreatedTab('');
                    cleareItemFormFieldStore();
                    setIsOpenItemFormFieldFormModal(false);
                    setIsOpenItemFormFieldAreYouSureModal(false);
                }}
                handleCancel={() =>
                    setIsOpenItemFormFieldAreYouSureModal(false)
                }
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want to close
          without saving?"
                onCancel={() => setIsOpenItemFormFieldAreYouSureModal(false)}
            />
            <AppModal
                visible={itemFormFieldSuccessfulStatus?.status}
                handleOk={() => {
                    cleareSuccessfulStatus();
                }}
                buttonOkName="OK"
                modalInfoText={`Congratulations!`}
                modalInfoTextMiddle={itemFormFieldSuccessfulStatus?.message}
                onCancel={() => cleareSuccessfulStatus()}
                isOnlyButtonOk={true}
            />
            <AppModal
                visible={isOpenItemFormFieldChangeStatusModal.isOpen}
                handleOk={() => {
                    if (
                        isOpenItemFormFieldChangeStatusModal
                            .changedStatusItemFormField?.status
                    ) {
                        dispatch(
                            changeItemFormFieldStatusRequest({
                                id: isOpenItemFormFieldChangeStatusModal
                                    .changedStatusItemFormField?.id as number,
                                status: NumberStatus.INACTIVE,
                            })
                        );
                    } else {
                        dispatch(
                            changeItemFormFieldStatusRequest({
                                id: isOpenItemFormFieldChangeStatusModal
                                    .changedStatusItemFormField?.id as number,
                                status: NumberStatus.ACIVE,
                            })
                        );
                    }

                    setIsChangeItemFormFieldDeletionActivationActions(true);
                    setIsOpenItemFormFieldChangeStatusModal({
                        isOpen: false,
                        changedStatusItemFormField: null,
                    });
                }}
                handleCancel={() =>
                    setIsOpenItemFormFieldChangeStatusModal({
                        isOpen: false,
                        changedStatusItemFormField: null,
                    })
                }
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want to Change Status for Item Form Field?"
                onCancel={() =>
                    setIsOpenItemFormFieldChangeStatusModal({
                        isOpen: false,
                        changedStatusItemFormField: null,
                    })
                }
            />
            <AppModal
                visible={isConfirmItemFormFieldDeletionModal}
                handleOk={() => {
                    dispatch(
                        deleteItemFormFields(
                            itemFormFieldsForDeletion.map((value) => value.id)
                        )
                    );
                    setIsChangeItemFormFieldDeletionActivationActions(true);
                    setItemFormFieldsForDeletion([]);
                    setIsConfirmItemFormFieldDeletionModal(false);
                }}
                handleCancel={() =>
                    setIsConfirmItemFormFieldDeletionModal(false)
                }
                buttonOkName="CONFIRM"
                buttonCancelName="CANCEL"
                modalInfoText={`Are you sure you want to Delete chosen Field(s) for all assigned Companies?`}
                onCancel={() => setIsConfirmItemFormFieldDeletionModal(false)}
            />
        </div>
    );
};

export default ModalsGroup;
