import { FC } from "react";
import "./modalsGroup.global.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import AppModal from "../../../common/components/AppModal";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  changeActiveItemsStatus,
  deleteActiveItems,
} from "../../../../api/items";

const ModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeItemsForDeactivationDeletion } = useAppSelector(
    (state) => state.activeItems
  );
  const {
    isConfirmActiveItemDeletionModal,
    isOpenActiveItemChangeStatusModal,
  } = useAppSelector((state) => state.modals);
  const {
    setActiveItemsForDeactivationDeletion,
    setIsConfirmActiveItemDeletionModal,
    setIsOpenActiveItemChangeStatusModal,
    setIsChangeItemsDeletionActivationActions,
  } = useActions();

  return (
    <>
      <AppModal
        visible={isOpenActiveItemChangeStatusModal}
        handleOk={() => {
          dispatch(
            changeActiveItemsStatus(
              activeItemsForDeactivationDeletion.map((value) => value.id)
            )
          );
          setActiveItemsForDeactivationDeletion([]);
          setIsOpenActiveItemChangeStatusModal(false);
          setIsChangeItemsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsOpenActiveItemChangeStatusModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to put Active item(s) to Drafts?"
        onCancel={() => setIsOpenActiveItemChangeStatusModal(false)}
      />

      <AppModal
        visible={isConfirmActiveItemDeletionModal}
        handleOk={() => {
          dispatch(
            deleteActiveItems(
              activeItemsForDeactivationDeletion.map((value) => value.id)
            )
          );
          setActiveItemsForDeactivationDeletion([]);
          setIsConfirmActiveItemDeletionModal(false);
          setIsChangeItemsDeletionActivationActions(true);
        }}
        handleCancel={() => setIsConfirmActiveItemDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${activeItemsForDeactivationDeletion.length} Active Item(s)?`}
        onCancel={() => setIsConfirmActiveItemDeletionModal(false)}
      />
    </>
  );
};

export default ModalsGroup;
