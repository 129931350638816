import { Button, Input, Select } from "antd";
import { FC, useState } from "react";
import styles from "./filterControl.module.scss";
import { ReactComponent as SearchIcon } from "../../../common/icons/search.svg";
import { ReactComponent as FilterIcon } from "../../../common/icons/filter.svg";
import "./filterControl.global.scss";
import classnames from "classnames";
import { useActions } from "../../../../hooks/useActions";
import useDebounce from "../../../../hooks/useDebounce";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { CloseOutlined } from "@ant-design/icons";
import { SelectData, SelectTagData } from "../../types";
import { SortedByUsersFilterTypes } from "../../../users/constants";
import { FilterType } from "../../constants/filter";
import { DatePicker } from "antd";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../hooks/useAppSelector';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface FilterControlOwnProps {
  sortedBy: SelectData[];
  sortedByData: SelectTagData[];
  isSortedByStatus: boolean;
  sortedStatusData?: SelectData[];
  getSortedByData: (value: string) => void;
  filterControlType?: FilterType;
  dateRangePicker?: boolean;
  searchName: string;
  isSearch?: boolean
  cleareActivationDeletionIdes: () => void
}

const FilterControl: FC<FilterControlOwnProps> = ({
  sortedBy,
  sortedByData,
  getSortedByData,
  isSortedByStatus,
  sortedStatusData,
  filterControlType,
  dateRangePicker,
  searchName,
  isSearch = true,
  cleareActivationDeletionIdes
}): JSX.Element => {
  const {
    setFilterSearch,
    setAdditionalFilterSearch,
    sortedByFilterIds,
    sortedByAdditionalFilterIds,
    setFilterStatus,
    setAdditionalFilterStatus,
    setFilterStartDate,
    setFilterEndDate,
    setAdditionalFilterStartDate,
    setAdditionalFilterEndDate,
    clearFilterControl,
    clearAdditionalFilterControl,
  } = useActions();
  const isAdditional = filterControlType === FilterType.ADDITIONAL;
  const setFilterSearchAction = !isAdditional
    ? setFilterSearch
    : setAdditionalFilterSearch;
  const sortedByFilterIdsAction = !isAdditional
    ? sortedByFilterIds
    : sortedByAdditionalFilterIds;
  const setFilterStatusAction = !isAdditional
    ? setFilterStatus
    : setAdditionalFilterStatus;
  const clearFilterControlAction = !isAdditional
    ? clearFilterControl
    : clearAdditionalFilterControl;
  const setStartDate = !isAdditional
    ? setFilterStartDate
    : setAdditionalFilterStartDate;
  const setEndDate = !isAdditional
    ? setFilterEndDate
    : setAdditionalFilterEndDate;

  const [tagsData, setTagsData] = useState<SelectTagData[]>([]);
  const [sortedStatus, setSortedStatus] = useState<string>("");
  const [sortedByValue, setSortedByValue] = useState<string>(
    sortedBy[0].value as string
  );
  const [sortedByDataRange, setSortedByDataRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const debounced = useDebounce(setFilterSearchAction, 1000);
  const dispatch = useAppDispatch();
  const { userCompanyType } = useAppSelector((state) => state.companies);

  const handleSortedChange = (value: string) => {
    clearFilterControlAction();
    value === SortedByUsersFilterTypes.STATUS
      ? setSortedStatus(
        sortedStatusData ? String(sortedStatusData[0].value) : ""
      )
      : setSortedStatus("");
    setTagsData([]);
    setSortedByDataRange({
      startDate: "",
      endDate: "",
    });
    getSortedByData(value);
    setSortedByValue(value);
  };

  const handleSortedChangeStatus = (value: string) => {
    setSortedStatus(value);
  };

  const handleTagsChange = (values: SelectTagData[]) => {
    setTagsData(values);
  };

  const { t } = useTranslation();

  return (
    <div className={isSearch ? styles.wrapper : styles.wrapperWithoutSearch}>
      <div
        className={classnames([
          isOpen
            ? styles.filterControlFieldShow
            : styles.filterControlFieldHide,
        ])}
      >
        <div className="selectGroup">
          <Select
            size="middle"
            defaultValue={String(sortedBy[0].value)}
            className={styles.selectSortedByField}
            onChange={handleSortedChange}
          >
            {sortedBy?.map((sortOption: SelectData) => (
              <Option key={sortOption.name} value={`${sortOption.value}`}>
                {t(sortOption.name)}
              </Option>
            ))}
          </Select>

          {isSortedByStatus && (
            <Select
              size="middle"
              defaultValue={
                sortedStatusData && String(sortedStatusData[0].name)
              }
              className={styles.selectSortedByField}
              onChange={handleSortedChangeStatus}
            >
              {sortedStatusData?.map((sortOption: SelectData) => (
                <Option key={sortOption.name} value={`${sortOption.value}`}>
                  {t(sortOption.name)}
                </Option>
              ))}
            </Select>
          )}

          {dateRangePicker && (
            <RangePicker
              className={styles.dataRange}
              value={
                sortedByDataRange.startDate && sortedByDataRange.endDate
                  ? [
                    dayjs(sortedByDataRange.startDate, "YYYY/MM/DD"),
                    dayjs(sortedByDataRange.endDate, "YYYY/MM/DD"),
                  ]
                  : undefined
              }
              format={"DD/MM/YYYY"}
              onChange={(_, dateString) => {
                setSortedByDataRange({
                  startDate: dateString[0].split("/").reverse().join("/"),
                  endDate: dateString[1].split("/").reverse().join("/"),
                });
              }}
            />
          )}

          <Select
            size="middle"
            maxTagTextLength={4}
            autoClearSearchValue={true}
            disabled={!!sortedStatus}
            className={classnames([
              isSortedByStatus
                ? dateRangePicker
                  ? styles.selectTagsFieldWithDataStatus
                  : styles.selectTagsFieldWithStatus
                : dateRangePicker
                  ? styles.selectTagsFieldWithData
                  : styles.selectTagsField,
            ])}
            allowClear
            maxTagCount="responsive"
            value={tagsData}
            labelInValue
            filterOption={(inputValue, option: any) => {
              return option?.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }}
            mode="multiple"
            placeholder={`Please select ${sortedByValue}`}
            onChange={handleTagsChange}
          >
            {sortedByData?.map((sortOption: SelectTagData) => (
              <Option key={sortOption.key} value={`${sortOption.value}`}>
                {sortOption.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            className={userCompanyType === 'wine'?styles.buttonSetWine:styles.buttonSet}
            onClick={() => {
              cleareActivationDeletionIdes();
              setFilterStatusAction(sortedStatus);
              dispatch(
                sortedByFilterIdsAction({
                  sortedByFilterIds: {
                    sortByName: `${sortedByValue.toLowerCase()}`,
                    filterIds: tagsData.map((item) => Number(item.key)),
                  },
                })
              );

              setStartDate({ dateStart: sortedByDataRange.startDate });
              setEndDate({ dateEnd: sortedByDataRange.endDate });
              setIsOpen(false);
            }}
          >
            Apply
          </Button>
          <CloseOutlined
            className={styles.closeFilterButton}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </div>
      <Button
        className={classnames([
          isOpen ? styles.buttonFilterActive : styles.buttonFilter,
        ])}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FilterIcon />
        <div className={styles.buttonFilterName}>{t('Filter')}</div>
      </Button>
      {isSearch && <div className="search">
        <SearchIcon className={styles.searchIcon} />
        <Input
          className={styles.inputSearch}
          placeholder={`${t('Search')} ${t('vindix_' + searchName)}`}
          required={false}
          onChange={(e) => {
            (e.target.value.length > 2 || e.target.value.length === 0) &&
              debounced({ search: e.target.value });
          }}
        />
      </div>}
    </div>
  );
};

export default FilterControl;
