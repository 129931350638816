import { FC } from 'react';
import styles from './appAuthLayout.module.scss';
import stylesForm from "../../../auth/components/SignInFormRetailer/signInForm.module.scss";
import { ReactComponent as TrustedLogo } from '../../icons/AuthlogoPXM.svg';
import VindixLoginImage from "../../icons/bottlevindix.png";
import LoginImage from '../../icons/LoginImage.png';
import settings from '../../../../settings';
import { Col, Row, Image } from 'antd';
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { authSlice } from "../../../../redux/reducers/auth";
import productHubLogo from "../../img/product_hub/product-hub-logo-login.png"

interface AppLayoutOwnProps {
    children: React.ReactNode;
}

const AppAuthLayout: FC<AppLayoutOwnProps> = ({ children }): JSX.Element => {
    const dispatch = useAppDispatch();
    dispatch(authSlice.actions.setIsWineApp(settings.vindixDomain == window.location.origin && window.location.pathname !== '/admin/login'));

    return (
        <Row className={styles.retailerWrapper}>
            <Col span={24}>
                <img src={productHubLogo} className={styles.retailerLogoLogin} />
            </Col>
            <Col className={styles.leftColumn} span={12}>
                <Row justify="center" align="bottom" className={stylesForm.formShadow}>
                    <Row className={styles.retailerLeftBlock}>
                        <h1>
                            The best solution for your<br/>
                            <span>product data management</span>
                        </h1>
                        <p>Optimise your data quality through our portal, enabling direct access to rich product information validated according to <strong>GS1 standards</strong></p>
                        <p>Benefit from <strong>API support</strong> for streamlined automation, and easily export data in various formats to generate insightful reports for informed decision-making</p>
                    </Row>
                </Row>
            </Col>
            <Col className={styles.rightColumn} span={12}>{children}</Col>
        </Row>
    );
};

export default AppAuthLayout;
