import { createSlice } from "@reduxjs/toolkit";
import { FilterAction, FilterState } from "../../../modules/common/types";

export const productFormFilterSlice = createSlice({
  name: "productFormFilterSlice",
  initialState: {
    page: 1,
    paginate: 15,
    search: "",
    sortedByFilterIds: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setProductFormFilter: (state: FilterState, action: FilterAction) => {
      const { page, paginate } = action.payload;
      state.page = page;
      state.paginate = paginate;
    },
    clearProductFormFilter: (state: FilterState) => {
      state.page = 1;
      state.paginate = 15;
      state.search = "";
      state.sortedByFilterIds = null;
    },
    setProductFormFilterSearch: (state: FilterState, action: FilterAction) => {
      state.search = action.payload.search;
    },
    sortedByProductFormFilterIds: (state: FilterState, action: FilterAction) => {
      state.sortedByFilterIds = action.payload.sortedByFilterIds;
    },
  },
});
