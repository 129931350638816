/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import { SignInProps } from "../../types";
import InputField from "../../../common/components/InputField";
import styles from "./signInForm.module.scss";
import { Button, Checkbox } from "antd";
import { singIn } from "../../../../api/auth";
import { NavLink } from "react-router-dom";
import { RoutesNames } from "../../../../routes";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import { LogInTypes } from "../../constants";

interface SignInAdminFormOwnProps {
  dispatch: (value: any) => void;
}

const SignInAdminForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  dispatch,
}: FormikProps<SignInProps> & SignInAdminFormOwnProps) => {
  const { validationError } = useAppSelector((store) => store.auth);
  const { cleareAuthValidationError } = useActions();

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.form}
    >
      <h1 className={styles.formTitle}>LOGIN</h1>
      <div className={styles.fields}>
        <div className={styles.fieldWrapper}>
          <InputField
            title="Email"
            value={values.email}
            required={false}
            className={styles.field}
            titleClassName={styles.fieldTitle}
            errorMessage={
              (touched.email && errors.email) ||
              (validationError && (validationError.email as any))
            }
            onBlur={() => setFieldTouched("email")}
            onChange={(event: any) => {
              setFieldValue("email", event.target.value);
              cleareAuthValidationError();
            }}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <InputField
            title="Password"
            value={values.password}
            className={styles.field}
            titleClassName={styles.fieldTitle}
            type="password"
            required={false}
            passwordEye={true}
            errorMessage={
              (touched.password && errors.password) ||
              (validationError && (validationError.password as any))
            }
            onBlur={() => setFieldTouched("password")}
            onChange={(event: any) => {
              setFieldValue("password", event.target.value);
              cleareAuthValidationError();
            }}
          />
        </div>
        <Checkbox
          className={styles.checkbox}
          onChange={(event) => setFieldValue("isRemember", event.target.checked)}
        >
          <div className={styles.checkboxTitle}>Remember Me</div>
        </Checkbox>
      </div>

      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          type="primary"
          onClick={() => handleSubmit()}
        >Login</Button>
      </div>

      <NavLink to={RoutesNames.FORGOT} className={styles.buttonLink}>
        FORGOT PASSWORD
      </NavLink>
    </form>
  );
};

const SignInAdminFormWithFormik = withFormik<
  SignInAdminFormOwnProps,
  SignInProps
>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: "",
    password: "",
    isRemember: false,
    showLogo: true
  }),
  handleSubmit: (values, { setErrors, props: { dispatch } }) => {
    dispatch(singIn(LogInTypes.ADMIN, values, setErrors));
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
    password: yup
      .string()
      .min(6, "Must be more than 6 characters")
      .max(32, "Must be less than 32 characters")
      .required("This field is required"),
  }),
  displayName: "SignInForm",
})(SignInAdminForm);

export default SignInAdminFormWithFormik;
