export enum SortedByUsersFilterTypes {
  STATUS = "Status",
  USERS = "Users",
  COMPANIES = "Companies",
  GLN = "GLN",
}

export enum SortedByUsersFilterValues {
  STATUS = "Status",
  USERS = "Users",
  COMPANIES = "Companies",
  GLNS = "GLNS",
}

