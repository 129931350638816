import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import { RecoveryProps } from "../../types";
import InputField from "../../../common/components/InputField";
import styles from "./recoverForm.module.scss";
import { Button, Row, Col } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { RoutesNames } from "../../../../routes";
import { useState } from "react";
import { resetPassword } from "../../../../api/auth";
import { passRegExp } from "../../../common/constants/regExp";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import VindixLogo from "../../../common/icons/vindixlogocolor.png";
import VindixSubtitle from "../../../common/icons/vindixloginsubtitle.png";

interface RecoverFormOwnProps {
  dispatch: (value: any) => void;
  setOpenModal: (value: boolean) => void;
  urlParams: any;
}

const RecoverForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
}: FormikProps<RecoveryProps>) => {
  const [error, setError] = useState(false);
  const { isWineApp } = useAppSelector((store) => store.auth);
  const urlParams = useParams();

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={isWineApp?styles.wineForm:styles.form}
    >
    {isWineApp?
      <Row align="middle">
        <Col span={24}>
          <Row justify="center" align="middle">
              <img src={VindixLogo} width="300px" />
          </Row>
          <Row justify="center" align="middle">
              <img src={VindixSubtitle} style={{paddingTop: '25px'}} />
          </Row>
        </Col>
      </Row>
      :''}
      <h1 className={styles.formTitle}>{urlParams.new !== undefined?'CREATE YOUR PASSWORD':'RECOVER PASSWORD'}</h1>
      <div className={styles.fields}>
        <div className={styles.fieldWrapper}>
          <InputField
            title="Create Password"
            value={values.password}
            className={styles.field}
            titleClassName={isWineApp?styles.fieldTitleWine:styles.fieldTitle}
            type="password"
            required={false}
            passwordEye={true}
            errorMessage={touched.password && errors.password}
            onBlur={() => setFieldTouched("password")}
            onChange={(event: any) =>
              setFieldValue("password", event.target.value)
            }
          />
        </div>
        <div className={styles.fieldWrapper}>
          <InputField
            title="Confirm Password"
            value={values.confirmPassword}
            className={styles.field}
            titleClassName={isWineApp?styles.fieldTitleWine:styles.fieldTitle}
            type="password"
            required={false}
            passwordEye={true}
            errorMessage={touched.confirmPassword && errors.confirmPassword}
            onBlur={() => setFieldTouched("confirmPassword")}
            onChange={(event: any) =>
              setFieldValue("confirmPassword", event.target.value)
            }
          />
        </div>
      </div>

      <div className={styles.error}>{error && "Password not identical"}</div>
      <div className={styles.buttonGroup}>
        <Button
          className={styles.button}
          type="primary"
          onClick={() => {
            if (values.password === values.confirmPassword) {
              setError(false);
              handleSubmit()
            } else {
              setError(true);
            }
          }}
        >
          Confirm
        </Button>
      </div>
      {isWineApp?
      <Col span={12} offset={6} style={{textAlign: 'center'}}>
        <NavLink className={styles.buttonLink} to={RoutesNames.LOGIN}>
          BACK
        </NavLink>
      </Col>:
      <NavLink className={styles.buttonLink} to={RoutesNames.LOGIN}>
        BACK
      </NavLink>}
    </form>
  );
};

const RecoverFormWithFormik = withFormik<RecoverFormOwnProps, RecoveryProps>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    password: "",
    confirmPassword: "",
  }),
  handleSubmit: (values, { props: { dispatch, setOpenModal, urlParams } }) => {
    if (values.password === values.confirmPassword) {
      dispatch(
        resetPassword({
          ...urlParams,
          password_confirmation: values.confirmPassword,
          password: values.password,
        })
      );
      setOpenModal(true);
    }
  },
  validationSchema: yup.object().shape({
    password: yup
      .string()
      .min(6, "Must be more than 6 characters")
      .max(32, "Must be less than 32 characters")
      .matches(
        passRegExp,
        "The password must have at least 1 lowercase, 1 uppercase, 1 special symbol, and 1 number"
      )
      .required("This field is required"),
    confirmPassword: yup
      .string()
      .min(6, "Must be more than 6 characters")
      .max(32, "Must be less than 32 characters")
      .matches(
        passRegExp,
        "The password must have at least 1 lowercase, 1 uppercase, 1 special symbol, and 1 number"
      )
      .required("This field is required")
      .oneOf([yup.ref('password'), null], 'Your passwords do not match'),
  }),
  displayName: "RecoverForm",
})(RecoverForm);

export default RecoverFormWithFormik;
