export enum SortedByProductsFilterTypes {
  PRODUCT_ID = "GTIN",
  STATUS = "Status",
  COMPANIES = "Companies",
  SUPPLIERS = "Supplier",
  GPCS = "Gpc",
  BRANDS = "Brand"
}

export enum SortedByProductsFilterValues {
  GTINS = "GTINS",
  STATUS = "Status",
  COMPANIES = "Companies",
  SUPPLIERS = "Supplier",
  GPCS = "Gpcs",
  BRANDS = "Brands"
}