import { FC, useState, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { GoldOutlined } from '@ant-design/icons';
import { Checkbox, Pagination, Table, Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import { DeletionActivateDeactiveItemProps } from '../../items/types';
import { convertToItemFormFieldsWithSize } from '../../itemForm/utils/convertToItemFormFieldsWithSize';
import { ItemFormTabIds } from '../../itemForm/constants';
import { getItemFormFieldsByCompanyId } from '../../../api/itemForm';
import { ItemCatalog } from "../../items/components/ItemCatalog";
import template1 from "../../common/img/templateCatalogo11.jpg"
import template2 from "../../common/img/templateCatalogo12.png"
import template3 from "../../common/img/templateCatalogo13.png"
import {
    getRetailerItemByGtin,
    getDraftItemById,
    getAllActiveItems,
    getAllDraftItems,
    getAllActiveItemsForView,
} from '../../../api/items';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const CataloguePreviewVindix: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);
    const { user } = useAppSelector((state) => state.auth);
    const filter = useAppSelector((state) => state.additionalFilter);
    const urlParams = useParams();

    const { activeItem } = useAppSelector((state) => state.activeItems);
    const { draftItem } = useAppSelector((state) => state.draftItems);
    const [isMainFieldsFull, setIsMainFieldsFull] = useState<any>(false);
    const [isLastTab, setIsLastTab] = useState<boolean>(false);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);
    const [activeTabs, setActiveTabs] = useState<any>([]);
    const [currentItem, setCurrentItem] = useState<number>(0);
    
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
    );
    const element = itemFormFields?.find(
        (elem) => elem.name === selectedTab
    );

    const { t } = useTranslation();

    const { title } = useAppSelector((state) => state.catalogue);
    const { website } = useAppSelector((state) => state.catalogue);
    const { logo } = useAppSelector((state) => state.catalogue);
    const { info } = useAppSelector((state) => state.catalogue);
    const { wines } = useAppSelector((state) => state.catalogue);
    
    const currentTemplate = () => { 
        switch(urlParams?.template) {
            case 'template1': return template1; break;
            case 'template2': return template2; break;
            case 'template3': return template3; break;
            default: return template3; break;
       };
    }

    const {
        setAdditionalFilter,
        setActiveItemsForDeactivationDeletion,
        cleareActiveItemStore,
        cleareDraftItemStore,
        setIsOpenDraftItemsFormModal,
        setModalActiveTab,
    } = useActions();

    const {
        activeItems,
        totalItems,
        isLoading,
        activeItemsForDeactivationDeletion,
    } = useAppSelector((state) => state.activeItems);

    const {
        draftItems
    } = useAppSelector((state) => state.draftItems);

    const { isChangeItemsDeletionActivationActions } = useAppSelector(
        (state) => state.modals
    );

    const goCataloguePreview = () => {
        navigate('/catalogue/preview/' + urlParams?.template)
    }

    const changePage = (change) => {
        let newPage = currentItem + change
        if(newPage < -1) {
            newPage = -1;
        }
        if(newPage > wines.length + 1) {
            newPage = wines.length + 1;
        }
        setCurrentItem(newPage)
    }

    useEffect(() => {
        if (!user?.is_admin) {
            dispatch(getAllActiveItems(userCompanyId as number, filter));
            dispatch(getAllDraftItems(userCompanyId as number, filter));
        } else {
            dispatch(getAllActiveItemsForView(filter));
        }
    }, [
        dispatch,
        filter,
        isChangeItemsDeletionActivationActions,
        user?.is_admin,
        userCompanyId,
    ]);
    
    useEffect(() => {
        if (!itemFormFields) return;
        let array: any = [];
        itemFormFields.forEach((elem: any) => {
            if (
                elem.name === 'main' ||
                elem.name === 'measurements' ||
                elem.name === 'digital assets' ||
                elem?.items?.length
            ) {
                array.push(elem);
            }
        });
        setActiveTabs(array);
    }, [itemFormFields]);

    useEffect(() => {
        if(currentItem > 0) {
            if(typeof wines[currentItem] !== undefined) {
                console.log("Downloading draft item...");
                dispatch(getDraftItemById(wines[currentItem]));
            }
        }
    }, [currentItem, wines]);

    useEffect(() => {
        if (itemFormFields) {
            dispatch(setModalActiveTab(itemFormFields[0].name));
        }
    }, [itemFormFields]);

    let itemFormFieldsMain =
        itemFormFields &&
        convertToItemFormFieldsWithSize(
            itemFormFields?.filter(
                (field) => field.item_tab_id === ItemFormTabIds.MAIN
            )
        );

    let blocksCount = itemFormFieldsMain
        ? Math.ceil((itemFormFieldsMain.length - 10) / 15) + 1
        : 0;

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getItemFormFieldsByCompanyId(String(userCompanyId)));
        }
    }, [dispatch, userCompanyId]);

  const steps = [
    {
      title: 'Template',
      content: 'First-content',
    },
    {
      title: 'Customize',
      content: 'Second-content',
    },
    {
      title: 'Select products',
      content: 'Last-content',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  
  return (
    <AppLayout
      title={t('Catalogues')}
      headerLogo={<GoldOutlined />}
    > 
        {/* <ActiveItemsFilter
            totalDraftItemsCount={totalItems}
            countCheckedActiveItems={
                activeItemsForDeactivationDeletion.length
            }
        /> */}

        <Button onClick={() => changePage(-1)} style={{position: 'absolute', zIndex: '2', top: '50%', marginLeft: '-20px', height: '40px', borderRadius: '30px'}}>{'<'}</Button>
        
        { currentItem >= 0 ?
        <ItemCatalog 
            refPage="cataloguePage"
            isDraft={draftItem ? false : true}
            element={element}
            data={draftItem ? draftItem : activeItem}
            setIsMainFieldsFull={setIsMainFieldsFull}
            isLastTab={isLastTab}
        />
        :
            <img src={currentTemplate()} style={{width: '50%', margin: '0 auto', display: 'block'}} />
        }

        <Button onClick={() => changePage(1)} style={{position: 'absolute', zIndex: '2', top: '50%', right: '0', marginRight: '10px', height: '40px', borderRadius: '30px'}}>{'>'}</Button>
      
    </AppLayout>
  );
};

export default CataloguePreviewVindix;
