import { FC, useEffect, useState } from 'react';
import { Col, Row, Empty, Image} from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import ProductImg from "../../common/icons/defaultwine.jpg";
import responsible1 from "../../common/icons/responsible1.png";
import responsible2 from "../../common/icons/responsible2.png";
import responsible3 from "../../common/icons/responsible3.png";
import sustainability1 from "../../common/icons/sustainability1.png";
import sustainability2 from "../../common/icons/sustainability2.png";
import sustainability3 from "../../common/icons/sustainability3.png";
import sustainability4 from "../../common/icons/sustainability4.png";
import styles from './itemFormPublic.module.scss';
import { GET_GTIN_DATA } from '../../../api/qrcode';
import { IntlProvider } from 'react-intl'
import LanguageSwitcher from "../../common/components/LanguageSwitcher";
import i18n from "../../../services/i18n";
import ItemPublic from "../components/ItemPublic";



const ItemFormPublicPage: FC = ({}: any) => {
    const urlParams = useParams();
    const [data, setData] = useState(null);
    const [lang, setLang] = useState(i18n.getDefaultLocale())
    const onChangeLanguage = (locale: string) => {
        setLang(locale);
    }
    const [messages, setMessages] = useState();
    
    useEffect(() => {
        GET_GTIN_DATA(urlParams.gtin).then((res) => {
            setData(res.data.data);

        });
    }, [])

    useEffect(() => {
        i18n.getMessages(lang).then(data => {
            setMessages(data)
        });
    }, [lang])

   return (
        
        <>
        {data !== null && data !== undefined && data.item_elabel !== null && data.item_elabel !== undefined ? 
        <Row className={styles.publicProduct}>
            <IntlProvider messages={messages} key={lang} locale={lang} defaultLocale={i18n.getDefaultLocale()}>
                <Col span={24}><Row justify="center"><LanguageSwitcher onChangeLanguage={onChangeLanguage}/></Row></Col>
                <ItemPublic data={data} lang={lang}/>
            </IntlProvider>
        </Row>:<Empty />}</>
  );
};

export default ItemFormPublicPage;
