import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import InputField from "../../../common/components/InputField";
import { Button } from "antd";
import { Item } from "../../types";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./digitalAssetsForm.module.scss";
import "./digitalAssetsForm.global.scss";
import MultiFileUploader from "../../../common/components/MultiFileUploader";
import { editDraftItem } from "../../../../api/items";
import { ItemFormField } from "../../../itemForm/types";
import { ItemFormFieldIds, ItemFormTabIds } from "../../../itemForm/constants";
import ItemFormInputSelectField from "../../../itemForm/components/ItemFormInputSelectField";
import { convertToSelectData } from "../../../../helpers";
import ItemFormInputTextField from "../../../itemForm/components/ItemFormInputTextField";
import ItemFormInputNumberField from "../../../itemForm/components/ItemFormInputNumberField";
import ItemFormInputDateTimeField from "../../../itemForm/components/ItemFormInputDateTimeField";
import ItemFormInputSwitchField from "../../../itemForm/components/ItemFormInputSwitchField";
import { DownloadOutlined } from "@ant-design/icons";
import {
  converToItemFormsData,
  converToItemFormsDataWithValue,
} from "../../utils/converToItemFormsData";
import { checkItemProps } from "../../utils/checkItemProps";
import { checkEqualItemProps } from "../../utils/checkEqualItemProps";
import { convertToItemFormFieldsWithSize } from "../../../itemForm/utils/convertToItemFormFieldsWithSize";

interface DigitalAssetsFormOwnProps {
  dispatch: (value: any) => void;
  setFormIsChange: (value: boolean) => void;
  itemData: Item | null;
  userCompanyId: number;
  itemFormFields: ItemFormField[] | null;
}

const DigitalAssetsForm = ({
  handleSubmit,
  values,
  // setFieldTouched,
  // touched,
  // errors,
  setFieldValue,
  setFormIsChange,
  itemData,
  userCompanyId,
  // dispatch,
  itemFormFields,
}: FormikProps<Item> & DigitalAssetsFormOwnProps) => {
  const [chosenImage, setChosenImage] = useState(0)
  const itemFormFieldsDigitalAssets =
    itemFormFields &&
    convertToItemFormFieldsWithSize(
      itemFormFields?.filter(
        (field) =>
          field.item_tab_id === ItemFormTabIds.DIGITAL_ASSETS &&
          field.name !== ""
      )
    );

  const { isLoading } = useAppSelector((state) => state.draftItems);

  useEffect(() => {
    const fieldsIsEmpty = checkItemProps(values).length === 0;
    const fieldsIsChange =
      itemData &&
      checkEqualItemProps(values, {
        ...itemData,
        item_forms: converToItemFormsDataWithValue(itemData.item_forms),
      }).length !== 0;

    if (fieldsIsEmpty) {
      setFormIsChange(false);
    } else {
      if (itemData) {
        if (fieldsIsChange) {
          setFormIsChange(true);
        } else {
          setFormIsChange(false);
        }
      } else {
        setFormIsChange(true);
      }
    }
  }, [itemData, setFieldValue, setFormIsChange, values]);
  

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className="digitalAssetsForm"
    >
      <div className={styles.dynamicFieldsWrapper}>
        {itemFormFieldsDigitalAssets?.map((field) => {
          if (
            field.field_type_id === ItemFormFieldIds.SELECT &&
            !field.is_size
          ) {
            return (
              <ItemFormInputSelectField
                key={field.key}
                fieldName={field.name}
                //@ts-ignore
                fieldValue={field.key && values.files.length ? values.files[chosenImage === -1 ? 0 : chosenImage]?.fields.find(item => item.item_field.key === field.key).value : null}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                fieldPlaceholder={field.name}
                fieldList={convertToSelectData(
                  field.field_select_options as any
                )}
                setFieldValue={(value) => {
                  field.key &&
                    //@ts-ignore
                    setFieldValue(`files[${chosenImage === -1 ? 0 : chosenImage}].fields[${values.files[chosenImage === -1 ? 0 : chosenImage].fields.indexOf(values.files[chosenImage === -1 ? 0 : chosenImage].fields.find((item) => item.item_field.key === field.key))}].value`, value)
                }}
              />
            );
          }
          if (field.field_type_id === ItemFormFieldIds.INPUT_TEXT) {
            return (
              <ItemFormInputTextField
                key={field.key}
                fieldName={field.name}
                //@ts-ignore
                fieldValue={field.key && values.files.length ? values.files[chosenImage === -1 ? 0 : chosenImage]?.fields.find(item => item.item_field.key === field.key).value : null}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                fieldPlaceholder={field.name}
                setFieldValue={(value) =>
                  field.key &&
                  //@ts-ignore
                  setFieldValue(`files[${chosenImage === -1 ? 0 : chosenImage}].fields[${values.files[chosenImage === -1 ? 0 : chosenImage].fields.indexOf(values.files[chosenImage === -1 ? 0 : chosenImage].fields.find((item) => item.item_field.key === field.key))}].value`, value)
                }
              />
            );
          }
          if (
            field.field_type_id === ItemFormFieldIds.INPUT_NUMBER ||
            (field.field_type_id === ItemFormFieldIds.SELECT && field.is_size)
          ) {
            return field.field_type_id === ItemFormFieldIds.INPUT_NUMBER ? (
              <ItemFormInputNumberField
                fieldName={field.name}
                key={field.key}
                //@ts-ignore
                fieldValue={field.key && values.files.length ? values.files[chosenImage === -1 ? 0 : chosenImage]?.fields.find(item => item.item_field.key === field.key).value : null}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                setFieldValue={(value) =>
                  field.key &&
                  //@ts-ignore
                  setFieldValue(`files[${chosenImage === -1 ? 0 : chosenImage}].fields[${values.files[chosenImage === -1 ? 0 : chosenImage].fields.indexOf(values.files[chosenImage === -1 ? 0 : chosenImage].fields.find((item) => item.item_field.key === field.key))}].value`, value)
                }
              />
            ) : (
              <ItemFormInputSelectField
                fieldName={field.name}
                key={field.key}
                //@ts-ignore
                fieldValue={field.key && values.files.length ? values.files[chosenImage === -1 ? 0 : chosenImage]?.fields.find(item => item.item_field.key === field.key).value : null}
                is_size={field.is_size}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                fieldPlaceholder={field.name}
                fieldList={convertToSelectData(
                  field.field_select_options as any
                )}
                setFieldValue={(value) =>
                  field.key &&
                  //@ts-ignore
                  setFieldValue(`files[${chosenImage === -1 ? 0 : chosenImage}].fields[${values.files[chosenImage === -1 ? 0 : chosenImage].fields.indexOf(values.files[chosenImage === -1 ? 0 : chosenImage].fields.find((item) => item.item_field.key === field.key))}].value`, value)
                }
              />
            );
          }
          if (field.field_type_id === ItemFormFieldIds.DATETIME) {
            return (
              <ItemFormInputDateTimeField
                key={field.key}
                fieldName={field.name}
                //@ts-ignore
                fieldValue={field.key && values.files?.length ? values.files[chosenImage === -1 ? 0 : chosenImage]?.fields.find(item => item.item_field.key === field.key).value : null}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                setFieldValue={(value) =>
                  field.key &&
                  //@ts-ignore
                  setFieldValue(`files[${chosenImage === -1 ? 0 : chosenImage}].fields[${values.files[chosenImage === -1 ? 0 : chosenImage].fields.indexOf(values.files[chosenImage === -1 ? 0 : chosenImage].fields.find((item) => item.item_field.key === field.key))}].value`, value)
                }
              />
            );
          }
          return false;
        })}
      </div>

      <div className={styles.fieldWrapper}>
        <div className={styles.fieldName}>FILE LINK</div>
        <div className={styles.inputUrlWrapper}>
          {userCompanyId && !itemData?.status && (
            <a
              className={styles.urlButton}
              href={values.url}
              download
              target="_blank"
              rel="noreferrer"
            >
              <DownloadOutlined className={styles.downloadIcon} />
            </a>
          )}
          <InputField
            value={values.url}
            disabled={!userCompanyId || (itemData?.status as any)}
            className={styles.field}
            placeholder="URL"
            required={false}
          />
        </div>
      </div>

      <div className={styles.switchControl}>
        {itemFormFieldsDigitalAssets?.map((field) => {
          if (field.field_type_id === ItemFormFieldIds.SWITCH) {
            return (
              <ItemFormInputSwitchField
                key={field.key}
                fieldName={field.name}
                fieldValue={field.key && values.item_forms[field.key]}
                isFieldDisabled={!userCompanyId || (itemData?.status as any)}
                setFieldValue={(value) =>
                  field.key &&
                  setFieldValue("item_forms", {
                    ...values.item_forms,
                    [field.key]: value,
                  })
                }
              />
            );
          }
          return false;
        })}
      </div>
      <div className={styles.imagesWrapper}>
        <MultiFileUploader
          isHideUploadButton={!userCompanyId || (itemData?.status as any)}
          fieldsForImage={itemFormFieldsDigitalAssets}
          imagesData={
            values.files.length && values.files[0].file !== null ? values.files?.map((file: any, index: any) => {
              if (typeof file.file === "string") {
                return { name: file.file, url: file.file, uid: `${file.file}/${index}` };
              } else {
                return file.file;
              }
            }) : []
          }
          setImages={(filesData) => {
            // delete one last file
            if (filesData.length === 0) {
              setChosenImage(-1);
              return setFieldValue('files', []);
            }

            // delete files
            if (filesData.length < values.files.length) {
              const index = values.files.findIndex((value: any) => {
                let res = true;
                filesData.forEach(item => {
                  if (
                    item.file.name === value.file.name ||
                    item.file.name === value.file ||
                    item.file === value.file
                  ) res = false;
                })
                return res;
              })
              const result = [...values.files];
              result.splice(index, 1);
              setChosenImage(result.length - 1);
              return setFieldValue('files', result);
            }

            // add files
            const arr = filesData;
            arr.splice(0, values.files.length);
            const result = [...values.files, ...arr];
            setChosenImage(result.length - 1);
            setFieldValue('files', result);
          }}
          setFileUrl={(fileUrl) => setFieldValue("url", fileUrl)}
          setChosenImage={setChosenImage}
        />
      </div>

      {userCompanyId && !itemData?.status && (
        <div className={styles.buttonGroup}>
          <Button
            className={styles.buttonDiscard}
            loading={isLoading}
            onClick={() => {
              // const clearedDynamicData = converToItemFormsData(
              //   itemFormFieldsDigitalAssets as any
              // );
              setFieldValue("url", "");
              setFieldValue("files", []);
              setFieldValue("item_forms", {
                ...values.item_forms,
                // clearedDynamicData,
              });
            }}
          >
            Clear
          </Button>
          <Button
            className={styles.buttonSave}
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Confirm
          </Button>
        </div>
      )}
    </form>
  );
};

const DigitalAssetsFormWithFormik = withFormik<DigitalAssetsFormOwnProps, Item>(
  {
    enableReinitialize: true,
    mapPropsToValues: ({ itemData, itemFormFields }) => {
      if (!itemData) {
        return {
          item_forms:
            itemFormFields && converToItemFormsData(itemFormFields as any),
        };
      } else {
        return {
          id: itemData?.id,
          gtin: itemData?.gtin,
          description: itemData?.description,
          trade_item_descriptor_id: itemData?.trade_item_descriptor_id,
          brand: itemData?.brand,
          target_market_id: itemData?.target_market_id,
          quantity: itemData?.quantity,
          url: itemData?.url,
          files: itemData?.file_links,
          item_forms:
            itemData?.item_forms &&
            converToItemFormsDataWithValue(itemData?.item_forms as any),
        };
      }
    },
    handleSubmit: (
      values,
      { props: { dispatch, userCompanyId } }
    ) => {
      const convertFilesForRequest = (files: any) => {
        return files.map((item: any) => {
          if (item.file.originFileObj) {
            return ({
              fields: item.fields.map((item: any) => ({
                field_id: item.item_field.id,
                value: item.value
              })),
              file: item.file.originFileObj,
            })
          }
          if (typeof item.file === 'string') {
            return ({
              fields: item.fields.map((item: any) => ({
                field_id: item.item_field.id,
                value: item.value
              })),
              file: item.file
            })
          } else {
            return ({
              file: item.file.name,
              fields: item.fields.map((item: any) => ({
                field_id: item.item_field.id,
                value: item.value
              }))
            })
          }
        });
      };

      const requestData = {
        ...values,
        files: convertFilesForRequest(values.files),
        item_forms: JSON.stringify(values.item_forms),
      };
      delete requestData.url;

      dispatch(editDraftItem(userCompanyId, requestData));
    },
    validationSchema: yup.object().shape({
      item_type_id: yup.string().nullable(),
      effective_start_date: yup.string().nullable(),
      file_format_id: yup.string().nullable(),
      url: yup.string().max(255, "Maximum 255 characters").nullable(),
    }),
    displayName: "DigitalAssetsForm",
  }
)(DigitalAssetsForm);

export default DigitalAssetsFormWithFormik;
