import { FC, useState } from 'react';
import styles from './retailerSideBar.module.scss';
import '../SideBar/sidebar.global.scss';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { signOut } from '../../../../api/auth';
import { NavLink, useLocation } from 'react-router-dom';
import { RoutesNames } from '../../../../routes';
import AppModal from '../AppModal';
import UserCompaniesSelect from '../../../companies/components/UserCompaniesSelect';
import { useActions } from '../../../../hooks/useActions';
import VindixLogo from "../../../common/icons/vindixlogocolor.png";
import { QrcodeOutlined, BlockOutlined, CloudUploadOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg'
import { ReactComponent as ProductsIcon } from '../../icons/products.svg'
import { ReactComponent as Avatar } from '../../icons/avatar.svg';
import { ReactComponent as SupplierIcon } from '../../icons/suppliers.svg';
import { ReactComponent as ReportsIcon } from '../../icons/reports.svg';

const RetailerSideBar: FC = (): JSX.Element => {
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [isSignOutModalVisible, setIsSignOutModalVisible] = useState(false);
    const {
        clearFilter,
        clearFilterControl,
        clearAdditionalFilter,
        clearAdditionalFilterControl,
        cleareDraftItemStore,
        cleareDraftItemsStore,
    } = useActions();
    const location = useLocation()
    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);

    return (
        <>
            <aside className={styles.sidebar}>
                <div>
                    <nav className={styles.navbar}>
                        <NavLink 
                            to={RoutesNames.DASHBOARD}
                            onClick={() => {
                                cleareDraftItemStore();
                                cleareDraftItemsStore();
                            }}
                        >
                            <div className={styles.menuEntry}>
                                <div><div className={styles.outlinedIcon}><DashboardIcon /></div></div>
                                Dashboard
                            </div>
                        </NavLink>
                        <NavLink
                            to={RoutesNames.PRODUCTS}
                            onClick={() => {
                                clearFilter();
                                clearFilterControl();
                                clearAdditionalFilter();
                                clearAdditionalFilterControl();
                            }}
                        >
                            <div className={styles.menuEntry}>
                                <div><div className={styles.outlinedIcon}><ProductsIcon /></div></div>
                                Products
                            </div>
                        </NavLink>
                        <NavLink
                            to={RoutesNames.PRODUCTS}
                            onClick={() => {
                                clearFilter();
                                clearFilterControl();
                                clearAdditionalFilter();
                                clearAdditionalFilterControl();
                            }}
                        >
                            <div className={styles.menuEntry}>
                                <div><div className={styles.outlinedIcon}><Avatar /></div></div>
                                User management
                            </div>
                        </NavLink>
                        <NavLink to={RoutesNames.SUPPLIERS}>
                            <div className={styles.menuEntry}>
                                <div><div className={styles.outlinedIcon}><SupplierIcon /></div></div>
                                Suppliers
                            </div>
                        </NavLink>
                        <NavLink to={RoutesNames.REPORTS}>
                            <div className={styles.menuEntry}>
                                <div><div className={styles.outlinedIcon}><ReportsIcon /></div></div>
                                Reports
                            </div>
                        </NavLink>
                    </nav>
                </div>
                <footer className={styles.footer}>
                    <button
                        className={styles.logout}
                        onClick={() => {
                            setIsSignOutModalVisible(true);
                        }}
                    >
                        <LogoutOutlined className={styles.outlinedIcon} />
                        <span className={styles.logoutTitle}>Logout</span>
                    </button>
                    <div className={styles.user}>
                        {!user?.avatar ? (
                            <UserOutlined className={styles.outlinedIcon} />
                        ) : (
                            <img
                                className={styles.iconAvatar}
                                src={user?.avatar}
                                alt="user avatar"
                            />
                        )}
                        <span className={styles.userTitle}>{user?.email}</span>
                    </div>
                    {!user?.is_admin && <div className={styles.userCompaniesSelect}><UserCompaniesSelect /></div>}
                </footer>
            </aside>

            <AppModal
                visible={isSignOutModalVisible}
                handleOk={() => {
                    dispatch(signOut());
                    setIsSignOutModalVisible(false);
                }}
                handleCancel={() => {
                    setIsSignOutModalVisible(false);
                }}
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want logout?"
                onCancel={() => setIsSignOutModalVisible(false)}
            />
        </>
    );
};

export default RetailerSideBar;