import { createSlice } from "@reduxjs/toolkit";
import {
  CatalogueState,
  CustomTemplateAction,
  CustomTemplateWinesAction,
} from "../../../modules/auth/types";

const initialState: CatalogueState = {
  title: '',
  website: null,
  logo: '',
  info: '',
  template: 'template1',
  wines: [],
  fontSizeTitle: 20,
  fontSizeWebsite: 20,
  fontSizeInfo: 20,
  sizeLogo: 20
};

export const catalogueSlice = createSlice({
  name: "catalogue",
  initialState,
  reducers: {
    setCustomizationData: (state: CatalogueState, action: CustomTemplateAction) => {
      state.title = action.payload.title;
      state.website = action.payload.website;
      state.logo = action.payload.logo;
      state.info = action.payload.info;
      state.template = action.payload.template;
      state.fontSizeTitle = action.payload.fontSizeTitle;
      state.fontSizeWebsite = action.payload.fontSizeWebsite;
      state.fontSizeInfo = action.payload.fontSizeInfo;
      state.sizeLogo = action.payload.sizeLogo;
    },
    setWines: (state: CatalogueState, action: CustomTemplateWinesAction) => {
      state.wines = action.payload.wines;
    },
    getCatalogue: (state: CatalogueState) => {

    }
  },
});
