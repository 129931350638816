export enum SortedByItemsFilterTypes {
  GTIN = "GTIN",
  STATUS = "Status",
  DESCRIPTORS = "Descriptors",
  COMPANIES = "Companies",
}

export enum SortedByItemsFilterValues {
  GTINS = "GTINS",
  STATUS = "Status",
  DESCRIPTORS = "Descriptors",
  COMPANIES = "Companies",
}
