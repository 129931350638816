import { Col, Row, Tabs } from 'antd';
import { IconInfoBlock } from './IconInfoBlock';
import iconSuppliers from '../../../common/icons/supplier_pack.svg';
import iconTotal from '../../../common/icons/total_icons.svg';
import iconCategory from '../../../common/icons/categories.svg';

export const IconInfoHolder = () => {
    
    return (
        <>
            <Row>
                <IconInfoBlock span={7} offset={0} icon={iconTotal} label={'Total Products'} amount={331} />
                <IconInfoBlock span={7} offset={1} icon={iconSuppliers} label={'Total Suppliers'} amount={2} />
                <IconInfoBlock span={7} offset={1} icon={iconCategory} label={'Product Categories'} amount={14} />
            </Row>
        </>
    );
};