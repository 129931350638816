import { DatePicker, Col, Row } from 'antd';
import { FC } from 'react';
import InputFieldTitle from '../../../common/components/InputFieldTitle';
import styles from './itemFormInputDateTimeField.module.scss';
import moment from 'moment';
import dayjs from 'dayjs';

interface ItemFormInputDateTimeFieldOwnProps {
    fieldName: string;
    fieldValue: string;
    isFieldDisabled: boolean;
    setFieldValue: (value: string) => void;
    errorMessage?: any;
}

const ItemFormInputDateTimeField: FC<ItemFormInputDateTimeFieldOwnProps> = ({
    fieldName,
    fieldValue,
    isFieldDisabled,
    setFieldValue,
    errorMessage,
}) => {
    return (
        <Row className={styles.fieldWrapper} justify="start">
            <Col title={fieldName} className={styles.fieldName} span={4}>
                {fieldName}
            </Col>
            <Col xs={{span: 8}} lg={{span: 5}}>
            <InputFieldTitle
                title=""
                required={false}
                errorMessage={errorMessage}
            >
                <DatePicker
                    className={styles.dataField}
                    disabled={isFieldDisabled}
                    value={
                        fieldValue
                            ? dayjs(fieldValue, 'YYYY/MM/DD')
                            : undefined
                    }
                    format={'DD/MM/YYYY'}
                    onChange={(_, dateString) => {
                        setFieldValue(
                            dateString.split('/').reverse().join('/')
                        );
                    }}
                />
            </InputFieldTitle>
            </Col>
        </Row>
    );
};

export default ItemFormInputDateTimeField;
