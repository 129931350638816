import { createSlice } from "@reduxjs/toolkit";
import { FilterControlAction, FilterControlState } from "../../../modules/common/types";

export const productFormFilterControlSlice = createSlice({
  name: "productFormFilterControlSlice",
  initialState: {
    sortedByData: [],
    isLoading: false,
    error: null
  },
  reducers: {
    clearProductFormFilterControl: (state: FilterControlState) => {
      state.sortedByData = [];
    },
    getProductFormFilterControlSortedByDataPending: (state: FilterControlState) => {
      state.isLoading = true;
    },
    getProductFormFilterControlSortedByDataFulfilled: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = null;
      state.sortedByData = action.payload.sortedByData;
    },
    getProductFormFilterControlSortedByDataRejected: (
      state: FilterControlState,
      action: FilterControlAction
    ) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
  },
});
