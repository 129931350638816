import React, { PropsWithChildren } from 'react';
import { Form } from 'antd';
import classnames from 'classnames';

import styles from './inputFieldTitleMain.module.scss';
import "../../styles/colors-supplier.scss";

interface InputFieldProps {
    title: string;
    errorMessage?: string | boolean | any;
    required?: boolean;
    style?: any;
    className?: any;
    titleClassName?: string;
}

const InputFieldTitle = ({
    title,
    required = true,
    errorMessage,
    className,
    titleClassName,
    children,
    style,
}: PropsWithChildren<InputFieldProps>) => {
    return (
        <div
            className={classnames([styles.inputWrapper, className])}
            style={style}
        >
            <div className={styles.inputInfo}>
                {required && <span className={styles.asterisk}>*</span>}{' '}
                <span className={classnames([styles.title, titleClassName])}>
                    {title}
                </span>
            </div>
            <Form.Item
                className={errorMessage ? styles.errorMassage : ''}
                validateStatus={errorMessage ? 'error' : undefined}
                help={errorMessage}
            >
                {children}
            </Form.Item>
        </div>
    );
};

export default InputFieldTitle;
