import { createSlice } from '@reduxjs/toolkit';
import {
    GetOptionListsAction,
    OptionListsState,
} from '../../../modules/common/types';

export const optionListsSlice = createSlice({
    name: 'optionListsSlice',
    initialState: {
        descriptorsList: [],
        countriesList: [],
        sizesList: [],
        fileTypesList: [],
        fileFormatsList: [],
        companiesList: [],
        itemTabsList: [],
        fieldTypesList: [],
        nowCreatedTab: null,
        isLoading: false,
        error: null,
        languagesList: [],
        allergenCodesList: [],
        levelOfContainmentList: [],
        nutrientCodes: [],
        measurementPrecisions: [],
        unitMeasurements: [],
        derivationCodes: []
    },
    reducers: {
        clearOptionLists: (state: OptionListsState) => {
            state.descriptorsList = [];
            state.countriesList = [];
            state.fileTypesList = [];
            state.fileFormatsList = [];
            state.sizesList = [];
            state.companiesList = [];
            state.itemTabsList = [];
            state.fieldTypesList = [];
        },
        getDescriptorsListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        setNowCreatedTab: (state: any, action: any) => {
            state.nowCreatedTab = action.payload;
        },

        getDescriptorsListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.descriptorsList = action.payload.descriptorsList;
        },
        getDescriptorsListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getCountriesListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getCountriesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.countriesList = action.payload.countriesList;
        },
        getCountriesListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getSizesListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getSizesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.sizesList = action.payload.sizesList;
        },
        getSizesListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getFileTypesListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getFileTypesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.fileTypesList = action.payload.fileTypesList;
        },
        getFileTypesListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },

        getFileFormatsListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getFileFormatsListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.fileFormatsList = action.payload.fileFormatsList;
        },
        getFileFormatsListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },
        getCompaniesListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getCompaniesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.companiesList = action.payload.companiesList;
        },
        getCompaniesListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },

        getItemTabsListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getItemTabsListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.itemTabsList = action.payload.itemTabsList;
        },
        getItemTabsListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },

        getFieldTypesListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getFieldTypesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.fieldTypesList = action.payload.fieldTypesList;
        },
        getFieldTypesListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },

        getListPending: (state: OptionListsState) => {
            state.isLoading = true;
        },
        getLanguagesListFulfilled: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.languagesList = action.payload.languagesList;
        },
        getListRejected: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = action.payload.error;
        },

        getAllergenCodesList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.allergenCodesList = action.payload.allergenCodesList;
        },

        getLevelOfContainmentCodesList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.levelOfContainmentList = action.payload.levelOfContainmentList;
        },
        getNutrientCodesList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.nutrientCodes = action.payload.nutrientCodes;
        },
        getMeasurementPrecisionsList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.measurementPrecisions = action.payload.measurementPrecisions;
        },
        getUnitMeasurementsList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.unitMeasurements = action.payload.unitMeasurements;
        },
        getDerivationCodesList: (
            state: OptionListsState,
            action: GetOptionListsAction
        ) => {
            state.isLoading = false;
            state.error = null;
            state.derivationCodes = action.payload.derivationCodes;
        },
    },
});
