import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/retailer";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';
import ReportsList from "../../products/components/ReportsList";

const SuppliersPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Reports"
      headerLogo={<GoldOutlined />}
    >
      <ReportsList size={475} title={'Reports'} showImages={false} />
    </AppLayout>
  );
};

export default SuppliersPage;
