export enum ItemFormTabIds {
  MAIN = 1,
  MEASUREMENTS = 2,
  DIGITAL_ASSETS = 3,
}

export enum ItemFormFieldIds {
  SELECT = 1,
  SWITCH = 2,
  INPUT_TEXT = 3,
  INPUT_NUMBER = 4,
  DATETIME = 5,
}

export enum SortedByItemFormFields {
  COMPANIES = "Companies",
}