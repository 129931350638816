import { FC, useEffect, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import ActiveItemsList from "../components/ActiveItemsList";
import ActiveItemsModalsGroup from "../components/ActiveItemsModalsGroup";
import DraftItemsList from "../components/DraftItemsListRetailer";
import DraftItemsModalsGroup from "../components/DraftItemsModalsGroup";
import { BlockOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import AppLayout from "../../common/components/AppLayout/retailer";

const ItemsPage: FC = (): JSX.Element => {
  const {
    cleareActiveItemsStore,
    cleareDraftItemsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();
  
  const dispatch = useAppDispatch();
  const { userCompanyType } = useAppSelector((state) => state.companies);

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveItemsStore();
      cleareDraftItemsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveItemsStore,
    cleareDraftItemsStore,
  ]);

  return (
    <AppLayout
      title={"Products"}
      headerLogo={<BlockOutlined />}
    >
      <DraftItemsList />
      
      <DraftItemsModalsGroup />
      <ActiveItemsModalsGroup />
    </AppLayout>
  );
};

export default ItemsPage;
