import { FC } from 'react';
import styles from './appAuthLayoutMain.module.scss';
import VindixLoginImage from "../../icons/bottlevindix.png";
import LoginImage from '../../icons/LoginImage.png';
import settings from '../../../../settings';
import { Col, Row, Image } from 'antd';
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { authSlice } from "../../../../redux/reducers/auth";
import { ReactComponent as DnaIcon } from '../../icons/dna.svg';
import { ReactComponent as PackIcon } from '../../icons/package.svg';
import { ReactComponent as AmazonLogo } from '../../img/supplier_hub/amazon_logo.svg';
import { ReactComponent as AliLogo } from '../../img/supplier_hub/aliexpress_logo.svg';
import { ReactComponent as WalmartLogo } from '../../img/supplier_hub/walmart_logo.svg';

interface AppLayoutOwnProps {
    children: React.ReactNode;
}

const AppAuthLayout: FC<AppLayoutOwnProps> = ({ children }): JSX.Element => {
    const dispatch = useAppDispatch();
    dispatch(authSlice.actions.setIsWineApp(settings.vindixDomain == window.location.origin && window.location.pathname !== '/admin/login'));

    return (
        <Row className={styles.wrapper}>
            <Col className={styles.rightColumn} span={11} offset={1}>{children}</Col>
            <Col className={styles.leftColumn} span={12} style={{margin: '10% auto'}}>
                <div className={styles.text}>
                    <h1>Optimize your product data with our PIM system</h1>
                </div>
                <div className={styles.text}>
                    <DnaIcon /><br/>
                    Effectively manage your product information using our PIM system. 
                </div>
                <div className={styles.text}>
                    <PackIcon /><br/>
                    Organise and enrich your product content for retailer and consumer benefit, such as imagery, nutritional information and packaging details.                </div>
                <div className={styles.text}>
                    <AmazonLogo /> <AliLogo style={{marginBottom: '7px'}}/> <WalmartLogo style={{marginBottom: '6px'}}/><br/>
                    Validate and publish directly to major retailers in the market.
                </div>
            </Col>
            
            
        </Row>
    );
};

export default AppAuthLayout;

