import { Modal, Button, ModalProps } from "antd";
import styles from "./appModal.module.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { authSlice } from "../../../../redux/reducers/auth";
import settings from '../../../../settings';

type AppModalOwnProps = {
  handleOk: () => void;
  handleCancel?: () => void;
  buttonOkName: string;
  buttonCancelName?: string;
  modalInfoText: string;
  modalInfoSubText?: string;
  modalInfoTextMiddle?: string;
  modalIcon?: any;
  isOnlyButtonOk?: boolean;
} & ModalProps;

const AppModal = ({
  handleOk,
  handleCancel,
  buttonOkName,
  buttonCancelName,
  modalInfoSubText,
  modalInfoText,
  modalInfoTextMiddle,
  modalIcon,
  isOnlyButtonOk = false,
  ...props
}: AppModalOwnProps): JSX.Element => {
  //const dispatch = useAppDispatch();
  //dispatch(authSlice.actions.setIsWineApp(settings.vindixDomain !== window.location.origin && window.location.pathname !== '/admin/login'));
  const { isWineApp } = useAppSelector((store) => store.auth);
  
  return (
    <>
    
      <Modal {...props} footer={null} destroyOnClose className={styles.modal}>
      <div className={isWineApp?styles.wineWrapper:styles.wrapper}>
        {modalIcon && <div className={styles.modalIcon}>{modalIcon}</div>}
        <div className={styles.modalInfo}>
          <div className={styles.modalInfoText}>{modalInfoText}</div>
          <div className={styles.modalInfoText}>{modalInfoTextMiddle}</div>
          <div className={styles.modalInfoSubText}>{modalInfoSubText}</div>
        </div>
        <div className={styles.buttonGroup}>
          {!isOnlyButtonOk && (
            <Button className={styles.buttonCancel} onClick={handleCancel}>
              {buttonCancelName}
            </Button>
          )}
          <Button type="primary" className={styles.buttonOk} onClick={handleOk}>
            {buttonOkName}
          </Button>
        </div>
        </div>
      </Modal>
    
    </>
  );
};

export default AppModal;
