export enum SortedByProductsFilterTypes {
  PRODUCT_ID = "Product id",
  STATUS = "Status",
  COMPANIES = "Companies",
}

export enum SortedByProductsFilterValues {
  GTINS = "GTINS",
  STATUS = "Status",
  COMPANIES = "Companies",
}