import { Col, Row, Tabs } from 'antd';
import { IconInfoBlock } from './IconInfoBlock';
import { ReactComponent as iconSuppliers} from '../../../common/icons/supplier_pack.svg';
import { ReactComponent as iconTotal} from '../../../common/icons/total_icons.svg';
import { ReactComponent as iconCategory} from '../../../common/icons/categories.svg';
import style from './summaryRetailer.module.scss'

export const IconInfoHolder = () => {
    
    return (
        <>
            <Row>
                <IconInfoBlock style={style} span={7} offset={0} Icon={iconTotal} label={'Total Products'} amount={3785} />
                <IconInfoBlock style={style} span={7} offset={1} Icon={iconSuppliers} label={'Total Suppliers'} amount={7} />
                <IconInfoBlock style={style} span={7} offset={1} Icon={iconCategory} label={'Product Categories'} amount={75} />
            </Row>
        </>
    );
};