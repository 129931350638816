/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import styles from './itemFormFieldForm.module.scss';
import './itemFormFieldForm.global.scss';
import { Button, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { checkEqualObjectsProps } from '../../../../utils/checkEqualObjectsProps';
import { checkObjectsProps } from '../../../../utils/checkObjectProps';
import {
    getCompaniesNames,
    getFieldTypesList,
    getItemTabsList,
} from '../../../../api/filter';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import AppModal from '../../../common/components/AppModal';
import { ItemFormField, ItemFormFieldCreateEditData } from '../../types';
import {
    createItemFormField,
    editItemFormField,
} from '../../../../api/itemForm';
import InputFieldTitle from '../../../common/components/InputFieldTitle';
import { SelectData, SelectTagData } from '../../../common/types';
import InputField from '../../../common/components/InputField';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from '../../../common/icons/trash.svg';
import { ReactComponent as TrashIconGrey } from '../../../common/icons/trashGrey.svg';
import { ItemFormFieldIds } from '../../constants';
import { Checkbox } from 'antd';
import { useActions } from '../../../../hooks/useActions';
const { Option } = Select;

interface UsersFormOwnProps {
    dispatch: (value: any) => void;
    setFormIsChange: (value: boolean) => void;
    setIsOpenItemFormFieldFormModal: (value: boolean) => void;
    itemFormField: ItemFormField;
    isItemFormLoading: boolean;
    setNowCreatedTab: (value: any) => void;
}

const ItemFormFieldForm = ({
    handleSubmit,
    values,
    setFieldTouched,
    touched,
    errors,
    setFieldValue,
    setFormIsChange,
    itemFormField,
    dispatch,
    isItemFormLoading,
}: FormikProps<ItemFormFieldCreateEditData> & UsersFormOwnProps) => {
    const [isConfirmEditModal, setIsConfirmEditModal] = useState(false);
    const { sortedByData } = useAppSelector((state) => state.filterControl);
    const { selectedTabForDeletion } = useAppSelector(
        (state) => state.itemForm
    );
    const { itemTabsList, fieldTypesList, nowCreatedTab } = useAppSelector(
        (state) => state.optionLists
    );
    const {
        setIsOpenCreateTabModal,
        setIsOpenAreYouSureDeleteTabModal,
        setSelectedTabForDeletion,
    } = useActions();
    const [itemTubArray, setItemTubArray] = useState<
        { value: number; name: string }[]
    >([]);
    const [isDisableDeleting, setIsDisableDeleting] = useState<boolean>(false);
    const [options, setOptions] = useState<string[]>([]);
    const [optionName, setOptionName] = useState('');
    const manatoryOptions = [
        { value: 'Yes', name: 'YES' },
        { value: 'No', name: 'NO' },
    ];

    useEffect(() => {
        if (itemTabsList && selectedTabForDeletion) {
            const index = itemTabsList.findIndex(
                (elem: SelectData) => elem.value === selectedTabForDeletion
            );
            if (index < 0) {
                setFieldValue('item_tab_id', null);
            }
        }
    }, [selectedTabForDeletion, itemTabsList]);

    useEffect(() => {
        if (nowCreatedTab && itemTabsList) {
            const element: any = itemTabsList.find(
                (elem: SelectData) => elem.name === nowCreatedTab
            );
            setFieldValue('item_tab_id', element?.value);
        }
    }, [nowCreatedTab, itemTabsList]);

    useEffect(() => {
        const fieldsIsEmpty = checkObjectsProps(values);
        const fieldsIsChange = checkEqualObjectsProps(values, itemFormField);
        if (fieldsIsEmpty) {
            setFormIsChange(false);
        } else {
            if (itemFormField) {
                if (fieldsIsChange) {
                    setFormIsChange(true);
                } else {
                    setFormIsChange(false);
                }
            } else {
                setFormIsChange(true);
            }
        }
    }, [itemFormField, setFieldValue, setFormIsChange, values]);

    useEffect(() => {
        if (values.item_tab_id) {
            dispatch(setSelectedTabForDeletion(values.item_tab_id));
        } else {
            dispatch(setSelectedTabForDeletion(null));
        }
    }, [values.item_tab_id]);

    useEffect(() => {
        if (itemFormField && itemFormField.field_select_options) {
            setOptions(itemFormField.field_select_options as any);
        }
    }, [itemFormField]);

    useEffect(() => {
        dispatch(getItemTabsList());
        dispatch(getFieldTypesList());
        dispatch(getCompaniesNames());
    }, [dispatch]);

    useEffect(() => {
        setFieldValue('field_select_options', options);
    }, [options, setFieldValue]);

    useEffect(() => {
        if (itemTabsList.length) {
            const array: { value: number; name: string }[] = [...itemTabsList];
            array.push({ value: 999, name: '--Create New Tab--' });
            setItemTubArray(array);
        }
    }, [itemTabsList]);

    useEffect(() => {
        if (!values.item_tab_id || values.item_tab_id == 999) {
            setIsDisableDeleting(true);
        } else {
            setIsDisableDeleting(false);
        }
    }, []);

    return (
        <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleSubmit();
                }
            }}
            className="itemFormFieldForm"
        >
            <div className={styles.fieldWrapper}>
                <div className={styles.fieldName}>ITEM FORM TAB</div>
                <div className={styles.tabFieldWrap}>
                    <InputFieldTitle
                        title=""
                        required={false}
                        errorMessage={touched.item_tab_id && errors.item_tab_id}
                    >
                        <Select
                            size="middle"
                            className={`${styles.selectField} ${styles.selectFieldTab}`}
                            disabled={isItemFormLoading}
                            value={
                                values.item_tab_id && String(values.item_tab_id)
                            }
                            placeholder="ITEM FORM TAB"
                            onBlur={() => setFieldTouched('item_tab_id')}
                            onChange={(value) => {
                                if (value === '999') {
                                    dispatch(setIsOpenCreateTabModal(true));
                                } else {
                                    setFieldValue('item_tab_id', Number(value));
                                }
                            }}
                        >
                            {itemTubArray.map((sortOption: SelectData) => (
                                <Option
                                    key={sortOption.name}
                                    value={`${sortOption.value}`}
                                >
                                    {sortOption.name}
                                </Option>
                            ))}
                        </Select>
                    </InputFieldTitle>

                    <TrashIconGrey
                        className={styles.icon}
                        // src={trushIcon}
                        onClick={() => {
                            if (selectedTabForDeletion) {
                                dispatch(
                                    setIsOpenAreYouSureDeleteTabModal(true)
                                );
                            }
                        }}
                    />
                    {/* <img
                        className={styles.icon}
                        src={trushIcon}
                        onClick={() => {
                            if (selectedTabForDeletion) {
                                dispatch(
                                    setIsOpenAreYouSureDeleteTabModal(true)
                                );
                            }
                        }}
                    /> */}
                </div>
            </div>
            <div className={styles.fieldWrapper}>
                <div className={styles.fieldName}>FIELD TYPE</div>

                <InputFieldTitle
                    title=""
                    required={false}
                    errorMessage={touched.field_type_id && errors.field_type_id}
                >
                    <Select
                        size="middle"
                        className={styles.selectField}
                        disabled={isItemFormLoading}
                        value={
                            values.field_type_id && String(values.field_type_id)
                        }
                        placeholder="FIELD TYPE"
                        onBlur={() => setFieldTouched('field_type_id')}
                        onChange={(value) => {
                            setFieldValue('field_type_id', Number(value));
                        }}
                    >
                        {fieldTypesList.map((sortOption: SelectData) => (
                            <Option
                                key={sortOption.name}
                                value={`${sortOption.value}`}
                            >
                                {sortOption.name}
                            </Option>
                        ))}
                    </Select>
                </InputFieldTitle>
            </div>
            <div className={styles.fieldWrapper}>
                <div className={styles.fieldName}>FIELD NAME</div>
                <InputField
                    value={values.name}
                    className={styles.field}
                    disabled={isItemFormLoading}
                    placeholder="FIELD NAME"
                    required={false}
                    errorMessage={touched.name && errors.name}
                    onBlur={() => setFieldTouched('name')}
                    onChange={(event: any) =>
                        event.target.value.length <= 100 &&
                        setFieldValue('name', event.target.value)
                    }
                />
            </div>
            <div className={styles.fieldWrapper}>
                <div className={styles.fieldName}>MANDATORY</div>
                <InputFieldTitle
                    title=""
                    required={false}
                    errorMessage={touched.mandatory && errors.mandatory}
                >
                    <Select
                        size="middle"
                        className={styles.selectField}
                        disabled={isItemFormLoading}
                        value={values.mandatory && String(values.mandatory)}
                        placeholder="MANDATORY"
                        onBlur={() => setFieldTouched('mandatory')}
                        onChange={(value) => {
                            setFieldValue('mandatory', String(value));
                        }}
                    >
                        {manatoryOptions.map((sortOption: SelectData) => (
                            <Option
                                key={sortOption.name}
                                value={`${sortOption.value}`}
                            >
                                {sortOption.name}
                            </Option>
                        ))}
                    </Select>
                </InputFieldTitle>
            </div>
            <div className={styles.fieldWrapper}>
                <div className={styles.fieldName}>COMPANIES</div>
                <InputFieldTitle
                    title=""
                    className={styles.selectTags}
                    required={false}
                >
                    <Select
                        size="middle"
                        maxTagTextLength={4}
                        autoClearSearchValue={true}
                        className={styles.field}
                        disabled={isItemFormLoading}
                        allowClear
                        labelInValue
                        value={values.companies}
                        maxTagCount="responsive"
                        onBlur={() => setFieldTouched('companies')}
                        filterOption={(inputValue, option) =>
                            option?.props.children
                                .toString()
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                        }
                        mode="multiple"
                        placeholder={`Please select companies`}
                        onChange={(optionData: SelectTagData[]) => {
                            setFieldValue(
                                'companies',
                                optionData.map((item: SelectTagData) => {
                                    return {
                                        key: Number(item.key),
                                        label: item.label,
                                    };
                                })
                            );
                        }}
                    >
                        {sortedByData?.map((sortOption: SelectTagData) => (
                            <Option
                                key={Number(sortOption.value)}
                                value={sortOption.value}
                            >
                                {sortOption.label}
                            </Option>
                        ))}
                    </Select>
                    {touched.companies && errors.companies && (
                        <span className={styles.errorCompanies}>
                            This field is required
                        </span>
                    )}
                </InputFieldTitle>
            </div>

            {values.field_type_id === ItemFormFieldIds.INPUT_NUMBER && (
                <div className={styles.switchFieldWrapper}>
                    <div className={styles.switchTitle}>IS SIZE</div>
                    <Switch
                        disabled={isItemFormLoading}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={Boolean(Number(values.is_size))}
                        onChange={(value) => {
                            setFieldValue('is_size', value);
                        }}
                    />
                </div>
            )}

            {values.field_type_id === ItemFormFieldIds.INPUT_TEXT && (
                <div>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.switchTitle}>IS LANGUAGE</div>
                        <Switch
                            disabled={isItemFormLoading}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={Boolean(Number(values.is_language))}
                            onChange={(value) => {
                                setFieldValue('is_language', value);
                            }}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.switchTitle}>IS MULTIPLE</div>
                        <Switch
                            disabled={isItemFormLoading}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            checked={Boolean(Number(values.is_multiple))}
                            onChange={(value) => {
                                setFieldValue('is_multiple', value);
                            }}
                        />
                    </div>
                </div>
            )}

            {values.field_type_id === ItemFormFieldIds.SELECT && (
                <div className={styles.optionsWrapper}>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.fieldOptionsName}>OPTIONS</div>
                        <InputField
                            value={optionName}
                            className={styles.fieldOptions}
                            disabled={isItemFormLoading}
                            placeholder="SET OPTIONS"
                            required={false}
                            //errorMessage={
                              //  touched.field_select_options &&
                              //  errors.field_select_options
                            //}
                            onChange={(event: any) =>
                                event.target.value.length <= 100 &&
                                setOptionName(event.target.value)
                            }
                        />
                        <Button
                            disabled={isItemFormLoading}
                            className={styles.buttonOptionAdd}
                            onClick={() => {
                                if (optionName) {
                                    setOptions((prevState) => [
                                        ...prevState,
                                        optionName,
                                    ]);
                                }
                                setOptionName('');
                            }}
                        >
                            <PlusOutlined />
                        </Button>
                    </div>
                    <div className={styles.optionsList}>
                        {options.map((option, index) => (
                            <div className={styles.optionItem} key={index}>
                                <div className={styles.optionItemName}>
                                    {option}
                                </div>
                                <div
                                    className={styles.optionItemDelete}
                                    onClick={() =>
                                        setOptions(
                                            options.filter(
                                                (item, currentIndex) =>
                                                    currentIndex !== index
                                            )
                                        )
                                    }
                                >
                                    <TrashIcon
                                        className={styles.optionItemDeleteIcon}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className={styles.fieldWrapper}>
                <div className={styles.switchTitle}>IS ELABEL</div>
                <Switch
                    disabled={isItemFormLoading}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={Boolean(Number(values.is_elabel))}
                    onChange={(value) => {
                        setFieldValue('is_elabel', value);
                    }}
                />
            </div>
            <Checkbox
                className={styles.checkbox}
                checked={values.is_file}
                onChange={(event) => {
                    setFieldValue('is_file', event.target.checked);
                }}
            >
                <div className={styles.checkboxTitle}>Assign to file</div>
            </Checkbox>

            <AppModal
                visible={isConfirmEditModal}
                handleOk={() => {
                    handleSubmit();
                    setIsConfirmEditModal(false);
                }}
                handleCancel={() => setIsConfirmEditModal(false)}
                buttonOkName="SAVE"
                buttonCancelName="CANCEL"
                modalInfoText="Are you sure you want to save the changes?"
                onCancel={() => setIsConfirmEditModal(false)}
            />

            <div className={styles.buttonGroup}>
                <Button
                    className={styles.buttonDiscard}
                    loading={isItemFormLoading}
                    onClick={() => {
                        setFieldValue('name', '');
                        setFieldValue('item_tab_id', null);
                        setFieldValue('field_type_id', null);
                        setFieldValue('field_select_options', []);
                        setFieldValue('is_size', false);
                        setFieldValue('mandatory', null);
                        setFieldValue('is_language', false);
                        setFieldValue('is_multiple', false);
                    }}
                >
                    Clear
                </Button>
                <Button
                    className={styles.buttonSave}
                    loading={isItemFormLoading}
                    type="primary"
                    onClick={() => {
                        if (itemFormField) {
                            setIsConfirmEditModal(true);
                        } else {
                            handleSubmit();
                        }
                    }}
                >
                    {itemFormField ? 'Save Changes' : 'Save Field'}
                </Button>
            </div>
        </form>
    );
};

const ItemFormFieldFormWithFormik = withFormik<
    UsersFormOwnProps,
    ItemFormFieldCreateEditData
>({
    enableReinitialize: true,
    mapPropsToValues: ({ itemFormField }) => {
        if (!itemFormField) {
            return {
                name: '',
                field_type_id: null,
                item_tab_id: null,
                field_select_options: [],
                companies: [],
                is_size: false,
                is_file: false,
                mandatory: null,
                is_language: false,
                is_multiple: false,
                is_elabel: false
            };
        } else {
            return {
                id: itemFormField.id,
                name: itemFormField.name,
                field_type_id: itemFormField.field_type_id,
                field_type_name: itemFormField.field_type_name,
                item_tab_id: itemFormField.item_tab_id,
                item_tab_name: itemFormField.item_tab_name,
                key: itemFormField.key,
                field_select_options: itemFormField.field_select_options,
                is_size: itemFormField.is_size,
                company_name: itemFormField.company_name,
                companies: itemFormField.companies.map((item: any) => {
                    return { key: item.id, label: item.company };
                }),
                status: itemFormField.status,
                is_file: itemFormField.is_file,
                mandatory: itemFormField.mandatory,
                is_language: itemFormField.is_language,
                is_multiple: itemFormField.is_multiple,
                is_elabel: itemFormField.is_elabel
            };
        }
    },
    handleSubmit: (
        values,
        {
            props: {
                dispatch,
                itemFormField,
                setIsOpenItemFormFieldFormModal,
                setNowCreatedTab,
            },
        }
    ) => {
        const companiesIds = values.companies.map((company) =>
            Number(company.key)
        );
        const requestData = {
            ...values,
            companies: companiesIds,
        };

        if (!itemFormField) {
            dispatch(createItemFormField(requestData));
            dispatch(setNowCreatedTab(''));
        } else {
            dispatch(editItemFormField(requestData));
            dispatch(setNowCreatedTab(''));
        }

        setIsOpenItemFormFieldFormModal(false);
    },
    validationSchema: yup.object().shape({
        name: yup.string().required('This field is required'),
        field_type_id: yup
            .string()
            .nullable()
            .required('This field is required'),
        item_tab_id: yup.string().nullable().required('This field is required'),
        mandatory: yup.string().nullable().required('This field is required'),
    }),
    displayName: 'ItemFormFieldForm',
})(ItemFormFieldForm);

export default ItemFormFieldFormWithFormik;
