import { Select } from "antd";
import { FC } from "react";
import InputFieldTitle from "../../../common/components/InputFieldTitle";
import { SelectData } from "../../../common/types";
import styles from "./itemFormInputSelectField.module.scss";
const { Option } = Select;

interface ItemFormInputSelectFieldOwnProps {
  fieldName: string;
  fieldValue: string;
  isFieldDisabled: boolean;
  fieldPlaceholder: string;
  fieldList: SelectData[];
  is_size?: boolean;
  setFieldValue: (value: number) => void;
}

const ItemFormInputSelectField: FC<ItemFormInputSelectFieldOwnProps> = ({
  fieldName,
  fieldValue,
  isFieldDisabled,
  fieldPlaceholder,
  fieldList,
  is_size,
  setFieldValue,
}) => {
  return (
    <div className={is_size ? styles.fieldWrapperIsSize : styles.fieldWrapper}>
      {!is_size && (
        <div title={fieldName} className={styles.fieldName}>
          {fieldName}
        </div>
      )}
      <InputFieldTitle title="" required={false}>
        <Select
          size="middle"
          className={styles.selectField}
          disabled={isFieldDisabled}
          value={fieldValue && String(fieldValue)}
          placeholder={fieldPlaceholder.toUpperCase()}
          onChange={(value) => {
            setFieldValue(Number(value));
          }}
        >
          {fieldList.map((sortOption: SelectData) => (
            <Option key={sortOption.name} value={`${sortOption.value}`}>
              {sortOption.name}
            </Option>
          ))}
        </Select>
      </InputFieldTitle>
    </div>
  );
};

export default ItemFormInputSelectField;
