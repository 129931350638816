import { FC, useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import AppLayout from "../../common/components/AppLayout";
import CompaniesList from "../components/CompaniesList";
import ModalsGroup from "../components/ModalsGroup";
import styles from "./companiesPage.module.scss";
import { ReactComponent as ItemsLogo } from "../../common/icons/items.svg";

const CompaniesPage: FC = (): JSX.Element => {
  const {
    cleareCompaniesStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();
    return () => {
      cleareCompaniesStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareCompaniesStore,
  ]);

  return (
    <AppLayout
      title="Manage Companies"
      headerLogo={<ItemsLogo className={styles.headerLogo} />}
    >
      <CompaniesList />
      <ModalsGroup />
    </AppLayout>
  );
};

export default CompaniesPage;
