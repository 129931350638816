import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import { companiesSlice } from "../../redux/reducers/companies";
import {
  ChangeCompanyStatusRequest,
  GetCompaniesRequestData,
  GetCompaniesResponseData,
  ICompany,
} from "../../modules/companies/types";
import { modalsSlice } from "../../redux/reducers/modals";
import { filterSlice } from "../../redux/reducers/filter";
import { SortedByCompaniesFilterTypes } from "../../modules/companies/constants";

export const getAllCompanies = (props: GetCompaniesRequestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(companiesSlice.actions.getAllCompaniesPending());
      let responseData: GetCompaniesResponseData;

      const checkParams =
        props.sortedByFilterIds?.sortByName ===
          SortedByCompaniesFilterTypes.STATUS.toLocaleLowerCase() ||
        props.sortedByFilterIds?.filterIds.length === 0;

      if (checkParams) {
        const { data } = await authAxios(dispatch).post(
          `/api/get-companies?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
        );

        responseData = data;
      } else {
        const { data } = await authAxios(dispatch).post(
          `/api/get-companies?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
          {
            [props.sortedByFilterIds?.sortByName as any]: props
              .sortedByFilterIds?.filterIds as number[],
          }
        );
        responseData = data;
      }

      dispatch(
        companiesSlice.actions.getAllCompaniesFulfilled({
          companies: responseData.data,
          totalItems: responseData.pagination.total,
        })
      );

      if (responseData.data.length === 0) {
        dispatch(
          filterSlice.actions.setFilter({
            page: Number(responseData.pagination.total_pages),
            paginate: Number(responseData.pagination.per_page),
          })
        );
      }
    } catch (error: any) {
      dispatch(
        companiesSlice.actions.getAllCompaniesRejected(error.data.message)
      );
    }
  };
};

export const getCompanyById = (id: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(companiesSlice.actions.getCompanyPending());

      const { data } = await authAxios(dispatch).get(`/api/companies/${id}`);

      dispatch(
        companiesSlice.actions.getCompanyFulfilled({
          company: data,
        })
      );
    } catch (error: any) {
      dispatch(companiesSlice.actions.getCompanyRejected(error.data.message));
    }
  };
};

export const createCompany = (body: ICompany) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(companiesSlice.actions.cleareCompanyValidationError());
      dispatch(companiesSlice.actions.createCompanyPending());

      const { data } = await authAxios(dispatch).post(`/api/companies`, body);

      dispatch(modalsSlice.actions.setIsOpenCompaniesFormModal(false));

      dispatch(
        modalsSlice.actions.setCompanySuccessfulStatus({
          status: true,
          message: data,
        })
      );
    } catch (error: any) {
      if (error.status === 422) {
        const companyErrorText = error.data.errors?.company
          ? error.data.errors.company
          : "";
        const glnErrorText = error.data.errors?.gln
          ? error.data.errors.gln
          : "";

        dispatch(
          companiesSlice.actions.setValidationError({
            company: companyErrorText,
            gln: glnErrorText,
          })
        );
      }
    }
  };
};

export const editCompany = (body: ICompany) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(companiesSlice.actions.cleareCompanyValidationError());
      dispatch(companiesSlice.actions.editCompanyPending());

      const { data } = await authAxios(dispatch).put(
        `/api/companies/${body.id}`,
        body
      );

      dispatch(modalsSlice.actions.setIsOpenCompaniesFormModal(false));

      dispatch(
        modalsSlice.actions.setCompanySuccessfulStatus({
          status: true,
          message: data,
        })
      );

      dispatch(companiesSlice.actions.cleareCompanyStore());
    } catch (error: any) {
      if (error.status === 422) {
        const companyErrorText = error.data.errors?.company
          ? error.data.errors.company
          : "";
        const glnErrorText = error.data.errors?.gln
          ? error.data.errors.gln
          : "";

        dispatch(
          companiesSlice.actions.setValidationError({
            company: companyErrorText,
            gln: glnErrorText,
          })
        );
      }
    }
  };
};

export const changeCompanyStatusRequest = (
  body: ChangeCompanyStatusRequest
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(companiesSlice.actions.changeStatusCompanyPending());

      await authAxios(dispatch).post(
        `/api/companies/change-status/${body.id}`,
        { status: body.status }
      );

      dispatch(
        modalsSlice.actions.setIsChangeCompanyDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(
        companiesSlice.actions.changeStatusCompanyRejected(error.data.message)
      );
    }
  };
};

export const deleteCompanies = (body: number[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const config = {
      data: { array_ids: body },
    };

    try {
      dispatch(companiesSlice.actions.deleteCompaniesPending());

      await authAxios(dispatch).delete(`/api/companies/delete`, config);

      dispatch(companiesSlice.actions.clearCompaniesForDeletion());

      dispatch(
        modalsSlice.actions.setIsChangeCompanyDeletionActivationActions(false)
      );
    } catch (error: any) {
      dispatch(
        companiesSlice.actions.deleteCompaniesRejected(error.data.message)
      );
    }
  };
};
