import { Switch } from "antd";
import { FC } from "react";
import styles from "./itemFormInputSwitchField.module.scss";

interface ItemFormInputSwitchFieldOwnProps {
  fieldName: string;
  fieldValue: string;
  isFieldDisabled: boolean;
  setFieldValue: (value: boolean) => void;
}

const ItemFormInputSwitchField: FC<ItemFormInputSwitchFieldOwnProps> = ({
  fieldName,
  fieldValue,
  isFieldDisabled,
  setFieldValue,
}) => {
  return (
    <div className={styles.switchFieldWrapper}>
      <div title={fieldName} className={styles.fieldName}>
        {fieldName}
      </div>
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        disabled={isFieldDisabled}
        checked={Boolean(Number(fieldValue))}
        onChange={(value) => {
          setFieldValue(value);
        }}
      />
    </div>
  );
};

export default ItemFormInputSwitchField;
