export const checkValidateFields = (obj: any) => {
  const requiredKeys = Object.keys(obj).filter((key) => {
    if (key === "sub_brand") return false;
    if (key === "url") return false;
    if (key === "quantity_size_id") return false;
    return key;
  });

  if (obj) {
    return requiredKeys.every((key) => {
      if (typeof obj[key] === "object") {
        return Object.keys(obj[key]).length !== 0;
      }
      return obj[key] !== null;
    });
  }
};
