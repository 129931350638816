import { FC, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/retailer";
import styles from "./productsPage.module.scss";
import ProductsListRetailer from "../components/ActiveProductsListRetailer";
import DraftProductsModalsGroup from "../components/DraftProductsModalsGroup";
import ActiveProductsList from "../components/ActiveProductsList";
import ActiveProductsModalsGroup from "../components/ActiveProductsModalsGroup";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';

const ProductsPage: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  return (
    <AppLayout
      title="Products"
      headerLogo={<GoldOutlined />}
    >
      <ProductsListRetailer size={294} title={'Products'} showImages={true} />
    </AppLayout>
  );
};

export default ProductsPage;
