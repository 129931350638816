import { Button } from "antd";
import { FC, useEffect } from "react";
import styles from "./filter.module.scss";
import FilterControl from "../../../common/components/FilterControl";
import { SortedByCompaniesFilterTypes, SortedByCompaniesFilterValues } from "../../constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getCompaniesNames, getGlnNames } from "../../../../api/filter";
import { useActions } from "../../../../hooks/useActions";
import { SelectData } from "../../../common/types";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { NumberStatus, Status } from "../../../common/constants/status";

interface FilterOwnProps {
  setOpenModal: (value: boolean) => void;
  countCheckedCompanies: number;
  totalCompaniesCount: number;
}

const sortedByValues: SelectData[] = [
  {
    name: SortedByCompaniesFilterTypes.COMPANIES,
    value: SortedByCompaniesFilterValues.COMPANIES,
  },
  {
    name: SortedByCompaniesFilterTypes.GLN,
    value: SortedByCompaniesFilterValues.GLNS,
  },
  {
    name: SortedByCompaniesFilterTypes.STATUS,
    value: SortedByCompaniesFilterValues.STATUS,
  },
];

const sortedStatusData: SelectData[] = [
  {
    name: Status.ACIVE.toLowerCase(),
    value: NumberStatus.ACIVE,
  },
  {
    name: Status.INACTIVE.toLowerCase(),
    value: NumberStatus.INACTIVE,
  },
];

const Filter: FC<FilterOwnProps> = ({
  setOpenModal,
  countCheckedCompanies,
  totalCompaniesCount,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { cleareCompanyStore, setFilterControlSortedByStatus, setCompaniesForDeletion } = useActions();
  const { sortedByData, isSortedByStatus } = useAppSelector(
    (state) => state.filterControl
  );

  useEffect(() => {
    dispatch(getCompaniesNames());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterControlWrapper}>
        <FilterControl
          sortedBy={sortedByValues}
          cleareActivationDeletionIdes={() => setCompaniesForDeletion([])}
          isSortedByStatus={isSortedByStatus}
          sortedStatusData={sortedStatusData}
          sortedByData={sortedByData}
          searchName='Companies'
          isSearch={false}
          getSortedByData={(sortValue) => {
            if (sortValue === SortedByCompaniesFilterValues.COMPANIES) {
              dispatch(getCompaniesNames());
            }
            if (sortValue === SortedByCompaniesFilterValues.GLNS) {
              dispatch(getGlnNames());
            }
            if (sortValue === SortedByCompaniesFilterValues.STATUS) {
              setFilterControlSortedByStatus(true);
            }
          }}
        />
        <div className={styles.countInfoWrapper}>
          <div className={styles.countInfoField}>
            {!countCheckedCompanies
              ? totalCompaniesCount
              : countCheckedCompanies}
          </div>
          <div className={styles.countInfoTitle}>
            {!countCheckedCompanies
              ? "Companies in Total"
              : "Total of selected Companies"}
          </div>
        </div>
      </div>
      <Button
        type="primary"
        className={styles.buttonAdd}
        onClick={() => {
          cleareCompanyStore();
          setOpenModal(true);
        }}
      >
        <div>Create</div>
      </Button>
    </div>
  );
};

export default Filter;
