import { Upload, Modal, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import styles from "./multiFileUploader.module.scss";
import "./multiFileUploader.global.scss";

interface MultiFileUploaderOwnProps {
  imagesData?: any[];
  setImages: (images: any[]) => void;
  setFileUrl: (url: string) => void;
  isHideUploadButton: boolean;
  fieldsForImage: any;
  setChosenImage: any;
}

interface MultiFileUploaderState {
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
  fileList: FileProps[];
}

interface FileProps {
  uid: string;
  name: string;
  url: string;
}

const MultiFileUploader: FC<MultiFileUploaderOwnProps> = ({
  imagesData,
  setImages,
  setFileUrl,
  isHideUploadButton,
  fieldsForImage,
  setChosenImage
}): JSX.Element => {
  const [errorData, setErrorData] = useState(false);
  const [uploaderData, setUploaderData] = useState<MultiFileUploaderState>({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () =>
    setUploaderData({ ...uploaderData, previewVisible: false });

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploaderData({
      ...uploaderData,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  function beforeUpload(file: any) {
    // const isJpgOrPng =
    //   file.type === "image/jpeg" ||
    //   file.type === "image/png" ||
    //   file.type === "image/jpg" ||
    //   file.type === "image/bmp" ||
    //   file.type === "image/gif";
    // if (!isJpgOrPng) {
    //   notification.error({
    //     message: "The format of image is not supported.",
    //     description: "Please use jpg, jpeg, png, bmp or gif",
    //     duration: 5,
    //   });

    //   setErrorData(true);
    // }
    const isLt2M = file.size >= 10000000;
    if (isLt2M) {
      notification.error({
        message: "The size of the image is too big.",
        description: "Please upload an image not bigger than 10mb",
        duration: 5,
      });

      setErrorData(true);
    }
    return false;
  }

  const handleChange = ({ fileList }: any) => {
    if (!errorData) {
      //@ts-ignore
      setUploaderData({ ...uploaderData, fileList: imagesData.map((item: any) => item.file) });
      setImages(fileList.map((image: any) => {
        return {
          file: image,
          fields: fieldsForImage?.map((field: any) => ({
            item_field: field,
            value: null
          }))
        }
      }));
    }
  };

  const uploadButton = (
    <div onClick={() => setErrorData(false)}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  
  return (
    <div className="multiImageUploader">
      <div
        onClick={(event) => {
          const targetElement: any = event.target;
          targetElement.textContent !== "Upload" &&
            imagesData?.length &&
            setChosenImage(imagesData.indexOf(imagesData.find((image: any) => (image.file?.name ? image.file?.name : image.name) === targetElement.textContent)))
          const fileUrl = targetElement.querySelector("a")?.href;
          const isLink = fileUrl && (fileUrl.split(":")[0] === "https" || fileUrl.split(":")[0] === "http");

          if (isLink) {
            setFileUrl(fileUrl);
          }
        }}
      >
        <Upload
          className={styles.fileList}
          disabled={isHideUploadButton}
          listType="picture-card"
          onPreview={handlePreview}
          //@ts-ignore
          fileList={imagesData}
          onChange={handleChange}
          beforeUpload={beforeUpload}
        >
          {/*@ts-ignore*/}
          {imagesData.length >= 8 ? null : uploadButton}
        </Upload>
      </div>
      <Modal
        visible={uploaderData.previewVisible}
        title={uploaderData.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={uploaderData.previewImage}
        />
      </Modal>
    </div>
  );
};

export default MultiFileUploader;
