import { FormikProps, withFormik } from "formik";
import { DraftProductsValidationError, IProduct } from "../../types";
import DraftProductsFormItems from "../DraftProductsFormItems";
import styles from "./draftProductsForm.module.scss";
import * as yup from "yup";
import InputField from "../../../common/components/InputField";
import { Button } from "antd";
import { gtinRegExp } from "../../../common/constants/regExp";
import { createDraftProduct, editDraftProduct } from "../../../../api/products";
import { useEffect, useState } from "react";
import { checkObjectsProps } from "../../../../utils/checkObjectProps";
import { checkEqualObjectsProps } from "../../../../utils/checkEqualObjectsProps";
import AppModal from "../../../common/components/AppModal";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import DraftProductsFormItemsView from "../DraftProductsFormItemsView";
import { useActions } from "../../../../hooks/useActions";
import { setPriorityId } from "../../utils/setPriorityId";
import { convertByPriorityId } from "../../utils/convertByPriorityId";

interface DraftProductsFormOwnProps {
  dispatch: (value: any) => void;
  setFormIsChange: (value: boolean) => void;
  draftProduct: IProduct | null;
  draftProductIsLoading: boolean;
  draftProductIsError: DraftProductsValidationError | null;
  userCompanyId: number;
}

const DraftProductsForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  draftProduct,
  setFormIsChange,
  draftProductIsLoading,
  draftProductIsError,
}: FormikProps<IProduct> & DraftProductsFormOwnProps): JSX.Element => {
  const [isConfirmEditModal, setIsConfirmEditModal] = useState(false);
  const { isOpenProductFormForView } = useAppSelector((state) => state.modals);
  const { setIsOpenProductFormForView, cleareDraftProductsValidationError } =
    useActions();

  useEffect(() => {
    const fieldsIsEmpty = checkObjectsProps(values);
    const fieldsIsChange = checkEqualObjectsProps(values, draftProduct);
    if (fieldsIsEmpty) {
      setFormIsChange(false);
    } else {
      if (draftProduct) {
        if (fieldsIsChange) {
          setFormIsChange(true);
        } else {
          setFormIsChange(false);
        }
      } else {
        setFormIsChange(true);
      }
    }
  }, [draftProduct, setFieldValue, setFormIsChange, values]);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
    >
            <div className={styles.fieldWrapper}>
        <div className={styles.fieldName}>PRODUCT ID</div>
        <InputField
          value={values.gtin}
          className={styles.field}
          placeholder="PRODUCT ID"
          disabled={isOpenProductFormForView || draftProductIsLoading}
          required={false}
          errorMessage={
            (touched.gtin && errors.gtin) ||
            (!!draftProductIsError && draftProductIsError.gtin)
          }
          onBlur={() => setFieldTouched("gtin")}
          onChange={(event: any) => {
            setFieldValue("gtin", event.target.value);
            cleareDraftProductsValidationError();
          }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.fieldName}>DESCRIPTION</div>
        <InputField
          value={values.description}
          className={styles.field}
          placeholder="DESCRIPTION"
          disabled={isOpenProductFormForView || draftProductIsLoading}
          required={false}
          errorMessage={touched.description && errors.description}
          onBlur={() => setFieldTouched("description")}
          onChange={(event: any) =>
            setFieldValue("description", event.target.value)
          }
        />
      </div>
      {!isOpenProductFormForView ? (
        <DraftProductsFormItems
          setDraftProductItems={(items) => setFieldValue("items", items)}
          productItems={convertByPriorityId(values.items)}
        />
      ) : (
        <DraftProductsFormItemsView
          productItems={convertByPriorityId(values.items)}
          companyId={draftProduct?.company_id as number}
        />
      )}
      <AppModal
        visible={isConfirmEditModal}
        handleOk={() => {
          handleSubmit();
          setIsConfirmEditModal(false);
          setTimeout(() => {
            setIsOpenProductFormForView(false);
          }, 1000);
        }}
        handleCancel={() => setIsConfirmEditModal(false)}
        buttonOkName="SAVE"
        buttonCancelName="CANCEL"
        modalInfoText="Are you sure you want to save the changes?"
        onCancel={() => setIsConfirmEditModal(false)}
      />

      {!isOpenProductFormForView && (
        <div className={styles.buttonGroup}>
          <Button
            className={styles.buttonDiscard}
            loading={draftProductIsLoading}
            onClick={() => {
              setFieldValue("gtin", "");
              setFieldValue("description", "");
              setFieldValue("items", []);
            }}
          >
            Clear
          </Button>
          <Button
            className={styles.buttonSave}
            loading={draftProductIsLoading}
            onClick={() => {
              if (draftProduct) {
                setIsConfirmEditModal(true);
              } else {
                handleSubmit();
              }
            }}
          >
            {draftProduct ? "Save Changes" : "Save Product"}
          </Button>
        </div>
      )}
    </form>
  );
};

const DraftProductsFormWithFormik = withFormik<DraftProductsFormOwnProps, any>({
  enableReinitialize: true,
  mapPropsToValues: ({ draftProduct }) => {
    if (draftProduct) {
      return {
        id: draftProduct?.id,
        gtin: draftProduct?.gtin,
        description: draftProduct?.description,
        items: draftProduct?.items,
      };
    } else {
      return {
        gtin: "",
        description: "",
        items: [],
      };
    }
  },
  handleSubmit: (
    values,
    { props: { dispatch, draftProduct, userCompanyId } }
  ) => {
    if (!draftProduct) {
      dispatch(
        createDraftProduct(userCompanyId, {
          ...values,
          items: values.items.length !== 0 ? setPriorityId(values.items) : null,
        })
      );
    } else {
      dispatch(
        editDraftProduct(userCompanyId, {
          ...values,
          items: setPriorityId(values.items),
        })
      );
    }
  },
  validationSchema: yup.object().shape({
    gtin: yup
      .string()
      .required("This field is required")
      .min(1, "Minimum 1 characters")
      .max(14, "Maximum 14 characters")
      .matches(gtinRegExp, "GTIN is not valid"),
    description: yup
      .string()
      .required("This field is required")
      .max(1000, "Maximum 1000 characters"),
  }),
  displayName: "DraftProductsForm",
})(DraftProductsForm);

export default DraftProductsFormWithFormik;
