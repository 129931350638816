import { Modal } from "antd";
import { FC, useState } from "react";
import {
  changeCompanyStatusRequest,
  deleteCompanies,
} from "../../../../api/companies";
import "./modalsGroup.global.scss";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { NumberStatus, Status } from "../../../common/constants/status";
import { useActions } from "../../../../hooks/useActions";
import CompaniesForm from "../CompaniesForm";
import AppModal from "../../../common/components/AppModal";
import { ICompany } from "../../types";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";

const ModalsGroup: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [formIsChange, setFormIsChange] = useState(false);
  const { isLoading, validationError, company, companiesForDeletion } = useAppSelector(
    (state) => state.companies
  );

  const {
    companySuccessfulStatus,
    isConfirmCompanyDeletionModal,
    isOpenCompaniesFormModal,
    isOpenCompanyAreYouSureModal,
    isOpenCompanyChangeStatusModal,
  } = useAppSelector((state) => state.modals);
  const {
    cleareSuccessfulStatus,
    cleareCompanyValidationError,
    cleareCompanyStore,
    setCompaniesForDeletion,
    setIsConfirmCompanyDeletionModal,
    setIsOpenCompaniesFormModal,
    setIsOpenCompanyAreYouSureModal,
    setIsOpenCompanyChangeStatusModal,
    setIsChangeCompanyDeletionActivationActions,
  } = useActions();
  return (
    <div className="modalGroup">
      <Modal
        visible={isOpenCompaniesFormModal}
        footer={null}
        title={company ? "COMPANY PROFILE" : "CREATE NEW COMPANY"}
        destroyOnClose
        onCancel={() => {
          setIsOpenCompaniesFormModal(false);
          cleareCompanyValidationError();
          if (formIsChange) {
            setIsOpenCompaniesFormModal(true);
            setIsOpenCompanyAreYouSureModal(true);
          }
        }}
        className="companyFormModal"
      >
        <CompaniesForm
          dispatch={dispatch}
          setFormIsChange={setFormIsChange}
          company={company as ICompany}
          isLoadingCompany={isLoading}
          isErrorCompany={validationError}
        />
      </Modal>

      <AppModal
        visible={isOpenCompanyAreYouSureModal}
        handleOk={() => {
          cleareCompanyStore();
          setIsOpenCompaniesFormModal(false);
          setIsOpenCompanyAreYouSureModal(false);
        }}
        handleCancel={() => setIsOpenCompanyAreYouSureModal(false)}
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to close
          without saving?"
        onCancel={() => setIsOpenCompanyAreYouSureModal(false)}
      />

      <AppModal
        visible={companySuccessfulStatus?.status}
        handleOk={() => {
          cleareSuccessfulStatus();
        }}
        buttonOkName="OK"
        modalInfoText={`Congratulations!`}
        modalInfoTextMiddle={companySuccessfulStatus?.message}
        onCancel={() => cleareSuccessfulStatus()}
        isOnlyButtonOk={true}
      />

      <AppModal
        visible={isOpenCompanyChangeStatusModal.isOpen}
        handleOk={() => {
          if (
            isOpenCompanyChangeStatusModal.changedStatusCompany?.status ===
            Status.ACIVE
          ) {
            dispatch(
              changeCompanyStatusRequest({
                id: isOpenCompanyChangeStatusModal.changedStatusCompany
                  ?.id as number,
                status: NumberStatus.INACTIVE,
              })
            );
          } else {
            dispatch(
              changeCompanyStatusRequest({
                id: isOpenCompanyChangeStatusModal.changedStatusCompany
                  ?.id as number,
                status: NumberStatus.ACIVE,
              })
            );
          }

          setIsChangeCompanyDeletionActivationActions(true);
          setIsOpenCompanyChangeStatusModal({
            isOpen: false,
            changedStatusCompany: null,
          });
        }}
        handleCancel={() =>
          setIsOpenCompanyChangeStatusModal({
            isOpen: false,
            changedStatusCompany: null,
          })
        }
        buttonOkName="YES"
        buttonCancelName="NO"
        modalInfoText="Are you sure you want to Change Status for Company?"
        onCancel={() =>
          setIsOpenCompanyChangeStatusModal({
            isOpen: false,
            changedStatusCompany: null,
          })
        }
      />

      <AppModal
        visible={isConfirmCompanyDeletionModal}
        handleOk={() => {
          dispatch(
            deleteCompanies(companiesForDeletion.map((value) => value.id))
          );
          setIsChangeCompanyDeletionActivationActions(true);
          setCompaniesForDeletion([]);
          setIsConfirmCompanyDeletionModal(false);
        }}
        handleCancel={() => setIsConfirmCompanyDeletionModal(false)}
        buttonOkName="CONFIRM"
        buttonCancelName="CANCEL"
        modalInfoText={`Are you sure you want to Delete ${companiesForDeletion.length} Company(ies)?`}
        onCancel={() => setIsConfirmCompanyDeletionModal(false)}
      />
    </div>
  );
};

export default ModalsGroup;
