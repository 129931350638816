import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          remember:{
            me: 'Remember me'
          },
          password: 'Password',
          forgot_password: 'Forgot password',
          powered_by: 'Powered By',
          login_button: 'Login',
          'vindix_items': 'Wines'
        }
      },
      es: {
        translation: {
          // here we will place our translations...
          remember:{
            me: 'Recordar usuario'
          },
          password: 'Password',
          forgot_password: 'Recordar contraseña',
          powered_by: 'Powered By',
          login_button: 'Entrar',
          'My Wines': 'Mis Vinos',
          'Create e-Label': 'Crear e-label',
          'Generate e-Label': 'Crear e-label',
          'Reports': 'Reportes',
          'Upload': 'Cargar vinos',
          'Catalogues': 'Catálogos',
          'Digital Links': 'Digital Links',
          'Logout': 'Salir',
          'Create a Wine': 'Nuevo vino',
          'vindix_items': 'Vinos',
          'vindix_Items': 'vinos',
          'vindix_Products': 'vinos',
          'Filter': 'Filtros',
          'Search': 'Buscar',
          'Search Items': 'Buscar vinos',
          'complete': 'activos',
          'ITEM DESCRIPTION': 'DESCRIPCIÓN',
          'DESCRIPTOR': 'TIPO',
          'BRAND': 'MARCA',
          'Brand': 'Marca',
          'DATE': 'FECHA',
          'QUANTITY': 'CANTIDAD',
          'ELABEL': 'E-LABEL',
          'ACTIONS': 'ACCIONES',
          'STATUS': 'ESTADO',
          'NAME': 'NOMBRE',
          'SUPPLIER': 'PROVEEDOR',
          'MARKET NAME': 'MERCADO',
          'PRODUCT TYPE': 'TIPO',
          'NET CONTENT': 'CONT. NETO',
          'EXPORT': 'EXPORTAR',
          'Browse a file to upload': 'Subir archivo',
          'XLS, XLSX files are supported': 'Formatos soportados: XLS y XLSX (EXCEL)',
          'Download your template here': 'Descarga plantilla en XLS',
          'Upload file': 'Subir archivo',
          'Next': 'Siguiente',
          'Clear': 'Limpiar',
          'Save': 'Guardar',
          'Confirm': 'Confirmar',
          'Next Step': 'Siguiente',

          // CATALOGUE
          'Select the template': 'Seleccionar plantilla',
          'We will generate your new catalogue based on this template look and feel': 'Crearemos el catálogo basado en el estilo visual de la plantilla seleccionada',
          'Customize template': 'Personalizar plantilla',
          'Title': 'Título',
          'Font size': 'Tamaño de letra',
          'Logo size': 'Tamaño de logo',
          'Information': 'Información',
          'Upload logo': 'Subir logo',
          'Select the wines': 'Elegir vinos',
          'Finish': 'Finalizar',

          // Item menu
          'main': 'principal',
          'measurements': 'medidas',
          'digital assets': 'imágenes/Medios',
          'marketing': 'marketing',
          'local': 'local',
          'alcohol': 'alcohol',
          'packaging': 'embalaje',
          'allergens': 'alérgenos',
          'nutritional': 'nutricional',
          'viticultural': 'viticultural',
          'ingredients': 'ingredientes',
          'Ingredients': 'Ingredientes',

          'This field is required': 'Este campo es obligatorio',

          // Item
          'Description': 'Descripción',
          'Level': 'Tipo',
          'Target Market': 'Mercado objetivo',
          'Country of Origin': 'País de origen',
          'Global Product Classification Code': 'Código de clasificación/categoría',
          'Brand Owner Name': 'Nombre del fabricante',
          'Brand Owner GLN': 'GLN del fabricante',
          'Functional Name': 'Nombre funcional',
          'Sub-brand': 'Sub marca',
          'Product Description': 'Descripción de producto',
          'Shelf Life': 'Shelf Life',
          'Start Availability Date': 'Fecha de disponibilidad',
          'Is Orderable Unit?': 'Unidad de venta',
          'Is Shipping Unit?': 'Unidad de distribución',
          'Is Consumer Unit?': 'Unidad de consumo',
          'Is Base Unit?': 'Unidad base',
          'English': 'Inglés',
          'Dutch': 'Holandés',
          'French': 'Francés',
          'Spanish': 'Español',
          'German': 'Alemán',
          'Portuguese': 'Portugués',

          // Measures
          'Quantity': 'Cantidad',
          'Height': 'Alto',
          'Width': 'Ancho',
          'Depth': 'Profundo',
          'size': 'unidad',

          // Marketing
          'Consumer Usage Instructions': 'Instrucciones de uso',
          'Long Product Description': 'Descripción larga del producto',
          'Consumer Storage Instructions': 'Instrucciones de almacenamiento',
          'Features and Benefits 1': 'Características 1',
          'Features and Benefits 2': 'Características 2',
          'Features and Benefits 3': 'Características 3',
          'Features and Benefits 4': 'Características 4',
          'Features and Benefits 5': 'Características 5',
          'Features and Benefits 6': 'Características 6',

          // Local
          'SAWIS Application Number': 'Número SAWIS',
          'Serial Number': 'Número de serie',

          // Alcohol
          'Production Method Type Code': 'Código de método de producción',
          'Container Type Code': 'Código de tipo de contenedor',
          'Container Process Code': 'Código de proceso de contenedor',
          'Sweetness Level of Alcoholic Beverage Code': 'Código de nivel de dulzor',
          'Origin of Wine Code': 'Código de origen del vino',
          'Alcoholic Beverage Colour Code': 'Código de color',
          'Alcohol Beverage Type Code': 'Tipo de bebida',
          'Grape Variety Code': 'Tipo de uva',
          'Vintner': 'Añada',
          'Alcohol Proof': 'Porcentaje de alcohol',
          'Alcoholic Beverage Age': 'Edad de la bebida',

          // Packaging
          'Packaging Labelling Type Code': 'Código de tipo de etiquetado de embalaje',
          'Packaging Material Type Code': 'Código de tipo de material de embalaje',
          'Batch/Lot Number': 'Número de lote',
          'Batch Number Indicator': 'Indicador de número de lote',

          // Allergens
          'Level Of Containment': 'Cantidad de alérgeno',
          'Allergen Code': 'Código de alérgeno',
          'Contains': 'Contiene',
          'Allergen Type Code': 'Código de alérgeno',
          'Sulfur Dioxide and Sulfits': 'Sulfitos y dióxido de azufre',

          // Elabel
          'GTIN-EAN-Barcode': 'GTIN/EAN/Código de barras'
        }
      }
    }
  });

export default i18n;
