import { FC } from 'react';
import AppLayout from '../../common/components/AppLayout';
import { ReactComponent as ItemFormLogo } from '../../common/icons/itemForm.svg';
import ItemFormFieldsList from '../components/ItemFormFieldsList';
import ModalsGroup from '../components/ModalsGroup';
import styles from './itemForm.module.scss';

const ItemFormPage: FC = () => {
    return (
        <AppLayout
            title="Item form page"
            headerLogo={<ItemFormLogo className={styles.headerLogo} />}
        >
            <ItemFormFieldsList />
            <ModalsGroup />
        </AppLayout>
    );
};

export default ItemFormPage;
