import { Dispatch } from "@reduxjs/toolkit";
import authAxios from "../../helpers/authAxios";
import { retailersSlice } from "../../redux/reducers/retailers";
import {
  ChangeCompanyStatusRequest,
  GetCompaniesRequestData,
  GetCompaniesResponseData,
  ICompany,
} from "../../modules/companies/types";
import { modalsSlice } from "../../redux/reducers/modals";
import { filterSlice } from "../../redux/reducers/filter";
import { SortedByCompaniesFilterTypes } from "../../modules/companies/constants";

export const getSupplierStats = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
        const { data } = await authAxios(dispatch).get(
          `/api/retailer/suppliers`
        );
        
        dispatch(retailersSlice.actions.getSupplierStats(data));
        
    } catch (error: any) {
    //   dispatch(
    //     companiesSlice.actions.getAllCompaniesRejected(error.data.message)
    //   );
    }
  };
};
