import { useCallback, useRef } from "react";
import { useAppDispatch } from "./useAppDispatch";

export default function useDebounce(callback: any, delay: number) {
  const dispatch = useAppDispatch()
  const timer: any = useRef();

  const debouncedCallback = useCallback(
    (values) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dispatch(callback(values));
      }, delay);
    },
    [callback, delay, dispatch]
  );

  return debouncedCallback;
}
