import { FC, useEffect, useState } from 'react';
import { Col, Row, Empty, Image, Carousel} from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import ProductImg from "../../../common/icons/defaultwine.jpg";
import responsible1 from "../../../common/icons/responsible1.png";
import responsible2 from "../../../common/icons/responsible2.png";
import responsible3 from "../../../common/icons/responsible3.png";
import sustainability1 from "../../../common/icons/sustainability1.png";
import sustainability2 from "../../../common/icons/sustainability2.png";
import sustainability3 from "../../../common/icons/sustainability3.png";
import sustainability4 from "../../../common/icons/sustainability4.png";
import styles from '../../pages/itemFormPublic.module.scss';
import { GET_GTIN_DATA } from '../../../../api/qrcode';
import { useIntl } from 'react-intl';
import LanguageSwitcher from "../../../common/components/LanguageSwitcher";
import i18n from "../../../../services/i18n";
import { InputProps } from 'antd/lib/input';

type ItemPublicInputProps = {
    data?: any;
    lang?: any;
} & InputProps;

const ItemPublic = ({
    data,
    lang,
    ...props
}: ItemPublicInputProps) => {

    const intl = useIntl();
    const urlParams = useParams();
    const [origin, setOrigin] = useState(null);
    const [ingredients, setIngredients] = useState(null);
    const [additionals, setAdditionals] = useState(null);
    const [nutritionalValue, serNutritionalValue] = useState([]);

    useEffect(() => {
        if(data !== null) {
            let origin = data.item_forms.find((item) => {return item.item_field.key == "country-of-origin"});
            setOrigin(origin)
            
            if(data.item_forms !== null) {
                let ingredientsJson = data.item_forms.find((item) => {return item.item_field.key == "ingredients"});
                let ingredientsField = ingredientsJson !== undefined?JSON.parse(ingredientsJson.value):null;
                if(ingredientsField !== null) {
                    let ingredientLang = ingredientsField.find((ing) => { return ing.languageCode === i18n.getAvailableLocaleCodes(lang)});
                    setIngredients(ingredientLang)   
                }
            }

            let additionals = data.item_forms.filter((item) => {return item.item_field.item_tab_name == "Alcohol" && item.item_field.is_elabel});
            setAdditionals(additionals)

            let nutritionalValue = data.item_nutrients.filter((item) => { return item.qtyMeasurementPrecisionValue !== ""})
            serNutritionalValue(nutritionalValue)
        }

    }, [data])
    
    return (
        <div>
            <Col span={24}>
                {data.file_links !== undefined && data.file_links.length > 0?
                <div style={{width: '100vw', padding: '45px 75px'}} className={styles.productImg}>
                    <Carousel>
                        {data.file_links.map((file) => {
                            if(!file.file.includes('qr_code')) {
                                return <Image src={file.file} />                                
                            }
                        })}
                    </Carousel>
                </div>
                :
                <Image className={styles.productImg} src={ProductImg} width="100%" /> 
                }
            </Col>
            <Col span={24} className={styles.brand}>{data.brand}</Col>
            <Col span={24} className={styles.origin}>{origin !== null && origin !== undefined ?origin.value:''}</Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "ingredients",
                    defaultMessage: "Ingredients"
                  })}
            </Col>
            <Col span={24} className={styles.text} style={{padding: '20px 20px 80px 20px'}}>
            {ingredients !== undefined && ingredients !== null ?
                <div>{ingredients.languageText}</div>
            : <Empty />}
            </Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "nutrition",
                    defaultMessage: "Nutrition"
                })}
            </Col>
            <Col span={24} style={{padding: '20px 20px 80px 20px'}}>
                {data.item_nutrients !== undefined && data.item_nutrients.length > 0 && nutritionalValue.length > 0 ?
                <div>
                    <Row className={styles.nutritionalHeader}>
                        <Col span={12}>
                            {intl.formatMessage({
                                id: "type",
                                defaultMessage: "TYPE"
                            })} - 100ml
                        </Col>
                        <Col span={12} style={{textAlign: 'right'}}>
                            {intl.formatMessage({
                                id: "dailyValue",
                                defaultMessage: "%"
                            })} - 100ml
                        </Col>
                    </Row>
                    {data.item_nutrients.map((nutrient) => {
                        if(nutrient.qtyMeasurementPrecisionValue !== "") {
                            if(nutrient.nutrientCode === "FAT") {
                                let saturatedFat = data.item_nutrients.filter((item) => { return item.nutrientCode === "FASAT"})
                                return <Row className={styles.additional}>
                                            <Col span={15} style={{padding: '10px'}}>
                                                <Row justify="start">
                                                    <Col span={24}><span style={{fontWeight: '600'}}> 
                                                        {intl.formatMessage({
                                                            id: nutrient.nutrientCode,
                                                            defaultMessage: nutrient.nutrientName
                                                        })}</span> {nutrient.qtyMeasurementPrecisionValue} {nutrient.unitMeasure}</Col>
                                                    <Col span={24}><ul><li>{intl.formatMessage({
                                                            id: saturatedFat[0].nutrientCode,
                                                            defaultMessage: saturatedFat[0].nutrientName
                                                        })} {saturatedFat[0].qtyMeasurementPrecisionValue} {saturatedFat[0].unitMeasure}</li></ul></Col>
                                                </Row>
                                            </Col>
                                            <Col span={9} style={{padding: '10px'}}>
                                                <Col span={24}><Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row></Col>
                                                <Col span={24}><Row justify="end">{saturatedFat[0].percentMeasurementPrecisionValue}</Row></Col>
                                            </Col>
                                        </Row>
                            } else if(nutrient.nutrientCode ===  "CHOCSM") {
                                let totalSugar = data.item_nutrients.filter((item) => { return item.nutrientCode === "SUGAR"})
                                let totalFiber = data.item_nutrients.filter((item) => { return item.nutrientCode === "FIBTG"})
                                return  <Row className={styles.additional} >
                                            <Col span={16} style={{padding: '10px'}}>
                                                <Row justify="start">
                                                    <Col span={24}><span style={{fontWeight: '600'}}>{intl.formatMessage({
                                                                id: nutrient.nutrientCode,
                                                                defaultMessage: nutrient.nutrientName
                                                            })}</span> {nutrient.qtyMeasurementPrecisionValue} {nutrient.unitMeasure}</Col>
                                                    <Col span={24}>
                                                        <ul>
                                                            <li>{intl.formatMessage({
                                                            id: totalSugar[0].nutrientCode,
                                                            defaultMessage: totalSugar[0].nutrientName
                                                        })} {totalSugar[0].qtyMeasurementPrecisionValue} {totalSugar[0].unitMeasure}</li>
                                                            <li>{intl.formatMessage({
                                                            id: totalFiber[0].nutrientCode,
                                                            defaultMessage: totalFiber[0].nutrientName
                                                        })} {totalFiber[0].qtyMeasurementPrecisionValue} {totalFiber[0].unitMeasure}</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={8} style={{padding: '10px'}}>
                                                <Col span={24}><Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row></Col>
                                                <Col span={24}><Row justify="end">{totalSugar[0].percentMeasurementPrecisionValue}</Row></Col>
                                                <Col span={24}><Row justify="end">{totalFiber[0].percentMeasurementPrecisionValue}</Row></Col>
                                            </Col>
                                        </Row>
                            } else if(nutrient.nutrientCode === "ENERSF" || nutrient.nutrientCode === "NA" || nutrient.nutrientCode === "PRO-") {
                                return <Row className={styles.additional}>
                                            <Col span={15} style={{padding: '10px'}}>
                                                <Row justify="start"><Col span={24}><span style={{fontWeight: '600'}}>{intl.formatMessage({
                                                            id: nutrient.nutrientCode,
                                                            defaultMessage: nutrient.nutrientName
                                                        })}</span> {nutrient.qtyMeasurementPrecisionValue} {nutrient.unitMeasure}</Col></Row>
                                            </Col>
                                            <Col span={9} style={{padding: '10px'}}>
                                                <Row justify="end">{nutrient.percentMeasurementPrecisionValue}</Row>
                                            </Col>
                                        </Row>
                            } 
                        }
                    })}
                </div>
                :<Empty />}
            </Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "allergens",
                    defaultMessage: "Allergens"
                })}
            </Col>
            <Col span={24} className={styles.text} style={{padding: '20px 20px 80px 20px'}}>
            {data.item_allergens !== undefined && data.item_allergens.length > 0 ?
                data.item_allergens.map((allergen) => {
                    return <div>
                                {intl.formatMessage({
                                    id: allergen.levelOfContainmentCode,
                                    defaultMessage: allergen.levelOfContainmentName
                                })}: {intl.formatMessage({
                                    id: allergen.allergenCode,
                                    defaultMessage: allergen.allergenName
                                })}
                            </div>
                })
            : <Empty />}
            </Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "additionalProductInformation",
                    defaultMessage: "Additional product information"
                })}
            </Col>
            <Col span={24} style={{padding: '20px 20px 80px 20px'}}>
                {additionals !== undefined && additionals !== null && additionals.length > 0 ?
                    additionals.map((additional) => {
                        return <Row className={styles.additional} >
                                    <Col span={15} style={{padding: '10px', textTransform: 'capitalize'}}>
                                        <Row justify="start">
                                            {intl.formatMessage({
                                                id: additional.item_field.key,
                                                defaultMessage: additional.item_field.name
                                            })}
                                        </Row>
                                    </Col>
                                    <Col span={9} style={{padding: '10px'}}>
                                        <Row justify="end">{additional.value}</Row>
                                    </Col>
                                </Row>
                    })
                :<Empty />}
            </Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "responsibleUse",
                    defaultMessage: "Responsible use"
                })}
            </Col>
            <Col span={24}>
                <Row justify="start">
                <img src={responsible1} style={{padding: '20px 10px 20px 20px'}}/>
                <img src={responsible2} style={{padding: '20px 10px'}}/>
                <img src={responsible3} style={{padding: '20px 10px'}}/>
                </Row>
                <Row justify="start" className={styles.responsibleText}>
                    <div>
                        {intl.formatMessage({
                            id: "responsibleUse1",
                            defaultMessage: "Always drink in moderation."
                        })}
                    </div>
                    <div>
                        {intl.formatMessage({
                            id: "responsibleUse2",
                            defaultMessage: "Alcohol abuse is dangerous for your health."
                        })}
                    </div>
                </Row>
            </Col>
            <Col span={24} className={styles.header}>
                {intl.formatMessage({
                    id: "sustainability",
                    defaultMessage: "Sustainability"
                })}
            </Col>
            <Col span={24} style={{paddingLeft: '20px', paddingTop: '25px', paddingBottom: '80px'}}>
                <img src={sustainability1} style={{paddingLeft: '20px'}}/>
                <img src={sustainability2} style={{paddingLeft: '20px'}}/>
                <img src={sustainability3} style={{paddingLeft: '20px'}}/>
                <img src={sustainability4} style={{paddingLeft: '20px'}}/>
            </Col>
        </div>

    )
}

export default ItemPublic;